import React from 'react';
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';
import './Inbox.css';

const Inbox = () => {
    return (
        <div className="customers">
            <Head>
                <title> Omnichannel Messaging Platform | ShoutOUT Inbox </title>
                <meta name="description" content="Omnichannel messaging platform Inbox lets multiple agents reply all SMS, WhatsApp, Messenger, Instagram & Webchat messages from one place." />
                <link rel="canonical" href="https://getshoutout.com/omnichannel-messaging-platform" />
                <meta name="keywords" content="omnichannel messaging platform, omnichannel customer experience, omnichannel customer service, omnichannel communication, omnichannel inbox, WhatsApp automated reply, WhatsApp business API, Integrate chatbot with Facebook Messenger, customer service chatbot" />
            </Head>

            <MainNavbar />
            <div className="container pt-5 opacity-p9">
                <div className="row my-5">
                    <div className="col-lg-6 col-sm-12 my-auto order-lg-1 order-2">
                        <h1 className="font-weight-bold text-center text-lg-left">Omnichannel <span className="text-primary">messaging</span> platform for customer communication</h1>
                        <h4 className="text-center text-lg-left">Every customer is connected. Everywhere. </h4>
                        <p className="text-center text-lg-left">Bring all your customer conversations into one inbox, while still letting them contact you through any channel they prefer. Welcome to omnichannel customer experience. </p>
                        <div className="d-flex justify-content-center d-lg-block">
                            <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2">Book a Demo</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 order-lg-2 order-1">
                        {/* <img className="rounded-circle" src="https://via.placeholder.com/500" /> */}
                        <img className="img-fluid" src="/assets/images/inbox/inbox-main.gif" />
                    </div>
                </div>
                <div className="row text-center mx-auto justify-content-center my-5 pt-3">
                    <div className="col-lg-8 col-sm-12">
                        <h2 className="font-weight-bold">Keep customer conversations going with the shared inbox</h2>
                        <h5>The shared inbox is your superpower. Reply instantly. <br />Never miss a message. </h5>
                    </div>
                    <div className="col-lg-8 col-sm-12">
                        {/* <div className="card shadow p-4"> */}
                        <p className="mb-0">It is no easy task trying to reply to all customers messaging you on a zillion channels. Chances are higher for delayed responses or missed messages all together - the path to quickly losing potential customers. Why go through the hassle and loss when the solution is right here? Switch to our omnichannel messaging platform.</p>
                        {/* </div> */}
                    </div>
                </div>



                <div className="row text-center mx-auto justify-content-center my-4">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="card p-4">
                            <img src="/assets/images/inbox/everywhere-is-one-place.gif" />
                            <h6 className="font-weight-bold mt-2">Everywhere is one place</h6>
                            <p>
                                Let your customers keep talking to you where they feel convenient, be it SMS, WhatsApp, Instagram, Messenger or Web chat. Join omnichannel customer service.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="card p-4">
                            <img className="" src="/assets/images/inbox/everyone-can-give-a-hand.gif" />
                            <h6 className="font-weight-bold mt-2">Everyone can give a hand</h6>
                            <p>
                                Multiple users can access the shared inbox on multiple devices so that conversations run smoothly with no delays. The struggle with single admins for WhatsApp accounts is no more.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="card p-4">
                            <img className="" src="/assets/images/inbox/everything-runs-smoothly.gif" />
                            <h6 className="font-weight-bold mt-2">Everything runs smoothly</h6>
                            <p>
                                No constant switching between messaging apps. No delayed or missed responses. Simply, zero hassle. Win customers with excellent customer service via smooth omnichannel communication.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row text-center mx-auto justify-content-center my-5 pt-3">
                    <div className="col-8">
                        <h2 className="font-weight-bold">Be where your customers are</h2>
                        <h5>Slide into the messaging platform your customer prefers. <br /> Be that company everyone loves.</h5>
                    </div>
                    <div className="col-8">
                        {/* <div className="card shadow p-4"> */}
                        <p className="mb-0">Everything has changed. So have your customers. They expect you to value their time, respond without delay and be easily contactable. A minute’s delay can have your customer in your competitors’ hands. Why let it happen when the solution is right here. </p>
                        {/* </div> */}
                    </div>
                </div>

                <div className="row my-5 pt-3">
                    {/* <div className="col-12">
                        <h2 className="font-weight-bold">Some subtitle comes here</h2>
                        <p>something about the subtitle comes here</p>
                    </div> */}
                    <div className="col-lg-6 col-sm-12 order-2 my-auto">
                        <h3 className="font-weight-bold text-whatsapp">WhatsApp</h3>
                        <p>Let several agents manage the WhatsApp account via one mobile number, with ShoutOUT’s omnichannel inbox. Have a simple chatbot to set up a WhatsApp automated reply option to answer FAQs when agents are away. Easily integrate the WhatsApp business API to send campaigns.</p>
                        <button className="btn btn-transparent btn-sm text-whatsapp p-0">Learn More</button>
                    </div>
                    <div className="col-lg-6 col-sm-12 order-1">
                        <img className="w-75" src="/assets/images/inbox/whatsapp-main.svg" />
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-lg-6 col-sm-12 order-lg-1 order-2 my-auto">
                        <h3 className="font-weight-bold text-messenger">Messenger</h3>
                        <p>Quickly and easily respond to all customer inquiries on Messenger. Let multiple users talk to customers to avoid delays. Integrate chatbot with Facebook Messenger to reply to FAQs when agents are not available. Be present 24/7.</p>
                        <button className="btn btn-transparent btn-sm text-messenger p-0">Learn More</button>
                    </div>
                    <div className="col-lg-6 col-sm-12 order-lg-2 order-1">
                        <img className="w-75" src="/assets/images/inbox/messenger-main.svg" />
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-lg-6 col-sm-12 order-2 my-auto">
                        <h3 className="font-weight-bold text-webchat">Webchat</h3>
                        <p>Manage all customer inquiries without a hassle with several admins responding. Save your sales with quick responses without making the customer wait. When agents are offline, let a simple customer service chatbot handle FAQs.</p>
                        <button className="btn btn-transparent btn-sm text-webchat p-0">Learn More</button>
                    </div>
                    <div className="col-lg-6 col-sm-12 order-1">
                        <img className="w-75" src="/assets/images/inbox/web-main.svg" />
                    </div>
                </div>




                <div className="row text-center mx-auto justify-content-center mt-5 pt-4">
                    <div className="col-8">
                        <h2 className="font-weight-bold">ShoutOUT’s shared Inbox comes with unique features you can’t say no to!</h2>
                        <h5>Be prepared with everything. Keep your customers happy.</h5>
                    </div>
                </div>






                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/multiple-channels.svg" />
                            <span className="mt-2 text-center">Multiple channels</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/admins.svg" />
                            <span className="mt-2 text-center">Multiple admins</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/credentials.svg" />
                            <span className="mt-2 text-center">Agent unique credentials</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/whatsapp.svg" />
                            <span className="mt-2 text-center">Single WhatsApp business number</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-colum align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/faqs.svg" />
                            <span className="mt-2 text-center">Chatbots for FAQs</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/automation.svg" />
                            <span className="mt-2 text-center">Automatic agent assigning to chatbot conversations</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/reports.svg" />
                            <span className="mt-2 text-center">Daily report on conversations</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/resolve.svg" />
                            <span className="mt-2 text-center">Mark conversations as ‘Resolved’</span>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/inbox/icons/canned.svg" />
                            <span className="mt-2 text-center">Canned responses</span>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/solutions/education/icons/results.svg" />
                            <span className="mt-2 text-center">Academic performance results</span>
                        </div>
                    </div> */}
                    {/* <div className="col-12 text-center">
                        <a href="#" className="btn btn-primary btn-sm">Explore features</a>
                    </div> */}
                </div>

                {/* <div id="video" className="row text-center mx-auto justify-content-center mt-5 pt-4">
                    <div className="col-lg-8 col-sm-12">
                        <h2 className="font-weight-bold">A common inbox for all conversational needs</h2>
                        <h5>Efficient, hassle free customer service is here.</h5>
                        <div className="embed-responsive embed-responsive-16by9">
                            <video controls={true} preload={true} muted={true} width="720" src="/assets/images/solutions/education/school-sms-tool.mp4" alt="text-messaging-for-schools" title="schools sms platform" />
                        </div>
                    </div>
                </div> */}


            </div>
            <CallToAction title={"Be where your customers are"} />
            <MainFooter />
        </div>
    )
}
export default Inbox