import React from 'react'

const WhatsAppPage = () => {
    return (
        <div>
            <div className="alert alert-primary text-center" role="alert">
            Whatsapp
    </div>

        <div className="card-deck">
        <div className="card border-secondary">
          
          <div className="card-body">
            <h5 className="card-title">API</h5>
            <p className="card-text">To offer a better customer experience. Get a separate API for your business with all the essential features and texting capabilities.</p>
          </div>
        </div>

        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Message Templates</h5>
            <p className="card-text">A rich set of ready to use templates that help you to compose the perfect message with a professional touch. </p>
          </div>
        </div>

        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Chatbots</h5>
            <p className="card-text">Handle all your customers effectively without extra effort. Create automatic rules to process incoming text messages</p>
           
          </div>
        </div>
       
        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Analytics </h5>
            <p className="card-text">Learn the trends and keep track of your customers’ actions and carry out more targeted and effective campaigns </p>
           
          </div>
        </div>

        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Fall back SMS</h5>
            <p className="card-text">In case if the message didn’t get delivered through Whatsapp, the message will be sent as an SMS to the customer.</p>
           
          </div>
        </div>
        
      </div>
        </div>
    )
}

export default WhatsAppPage
