export default {
  SITE_ROOT: process.env.PRODUCT === "AI" ? process.env.BUILD === "BETA" ? "https://test.shoutout.ai" : "https://shoutout.ai" : process.env.PRODUCT === "LITE" ? process.env.BUILD === "BETA" ? "https://sitetestlite.getshoutout.com" : "https://lite.getshoutout.com" : process.env.BUILD === "BETA" ? "https://sitetest.getshoutout.com" : "https://getshoutout.com",
  SITE_ROOT_STAGING: "https://sitetest.getshoutout.com",
  MONTH_NAMES: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  ENGAGE_PRODUCT_URL: "https://web.getshoutout.com",
  LOYALTY_PRODUCT_URL: "https://app.topnotch.club",
  LITE_PRODUCT_URL: "https://app.lite.getshoutout.com",
  ENGAGE_PRODUCT: "ENGAGE",
  LITE_PRODUCT: "LITE",
  LOYALTY_PRODUCT: "LOYALTY",
  AI_PRODUCT: "AI",
  GTM: {
    AI: "GTM-W257DB7",
    ENGAGE: "GTM-NFXC5LZ",
    LITE: "GTM-NFXC5LZ",
    LOYALTY: "GTM-NFXC5LZ"
  },
  BASE_API_URL: process.env.BUILD === "BETA" ? "https://betaapi.getshoutout.com/" : "https://api.getshoutout.com/",
  BASE_API2_URL: process.env.BUILD === "BETA" ? "https://betaapi.getshoutout.com/" : "https://api2.getshoutout.com/",
  BASE_API3_URL: process.env.BUILD === "BETA" ? "https://betaapi.getshoutout.com/" : "https://api3.getshoutout.com/",
  BASE_API3_URL_TEMP: "https://api3.getshoutout.com/",
  BASE_API_URL_TEMP: "https://api.getshoutout.com/"

  // PRODUCT_URL
};
void function register() {
  /* react-hot-loader/webpack */var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal["default"] : undefined;
  if (!reactHotLoader) {
    return;
  } /* eslint-disable camelcase, no-undef */
  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports; /* eslint-enable camelcase, no-undef */
  if (!webpackExports) {
    return;
  }
  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/home/runner/work/shoutout_site/shoutout_site/Constants.js");
    return;
  } /* eslint-disable no-restricted-syntax */
  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }
    var namedExport = void 0;
    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }
    reactHotLoader.register(namedExport, key, "/home/runner/work/shoutout_site/shoutout_site/Constants.js");
  }
}();