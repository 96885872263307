import React, { useEffect, useState } from 'react'
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


import './Features.css';

export default () => {
    const { item = {} } = useRouteData();
    const { name, image } = item;
    // const [currentFeature, setCurrentFeature] = useState({name:'', image:''});

    // useEffect(() => {
    //     const url = window.location.pathname;
    //     const id = url.split("/").pop();
    //     const feature = features.filter((feature) => feature.id === id);
    //     setCurrentFeature(feature[0]);
    // }, [currentFeature, setCurrentFeature])
    return (<>
        <Head>
            <title> {`ShoutOUT Features |  ${name}`} </title>
        </Head>

        <MainNavbar />



        <div className="container opacity-p8">
            <div className="row justify-content-center">

                <div className="col-md-10 col-sm-12 card shadow-sm border-0 mt-5">
                    <Link to="/features" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-sm-12 my-auto text-lg-right order-lg-2 p-4">
                            <img className="img-fluid" src={image} alt="ShoutOUT Customer" />
                        </div>
                        <div className="col-md-6 col-sm-12 my-auto order-lg-1">
                            <div className="p-4">
                                <h6 className="border-muted border-bottom pb-1 mb-0 w-50">Feature</h6>
                                <h1 className="font-weight-bold mt-0">{name}</h1>
                            </div>
                        </div>
                    </div>





                    <div className="p-4">
                        <div className="subheading ">Intro</div>
                        <p>
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                        </p>
                    </div>



                    <div className="p-4">
                        <div className="subheading ">Video</div>
                        <p>
                            Video Here
                        </p>
                    </div>

                    <div className="p-4">
                        <div className="subheading ">Steps</div>
                        <ol className="ml-n4">
                            <li>Step 01</li>
                            <img className="img-fluid" src={image} />
                            <li>Step 02</li>
                            <img className="img-fluid" src={image} />
                            <li>Step 03</li>
                            <img className="img-fluid" src={image} />
                            <li>Step 04</li>
                            <img className="img-fluid" src={image} />
                            <li>Step 05</li>
                            <img className="img-fluid" src={image} />
                        </ol>
                    </div>
                </div>
            </div>
        </div>


        <CallToAction />
        <MainFooter />
    </>
    )
}
