import React from 'react';
import { Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';
import CustomerProfilesImage from './../../images/customer-profiles.svg';
import './../PricingCommon.css';
import './../ProductsCommon.css';
export default () => (
  <div className="segment">
    <Head>
      <meta charSet="UTF-8" />
      <meta name="keywords" content="ShoutOUT,Segment,Contacts,customer data" />
      <meta property="og:title" content="ShoutOUT Segment: Put Your Customer Data into Action" />
      <meta property="og:site_name" content="ShoutOUT Segment: Put Your Customer Data into Action" />
      <meta property="og:description" content="Get the best out of customer data, your most valuable asset" />
      <meta name="description" content="Get the best out of customer data, your most valuable asset" />
      <title>ShoutOUT | Customer Data Platform</title>
    </Head>
    <MainNavbar />
    <div className="producthead pt-5">
      <div className="producthead-content text-md-left text-center">
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-sm-12 col-md-6 col-lg-6 order-lg-2">

              <img className="img-fluid" src="/assets/images/data-collection.svg" alt="ShoutOUT Segment" />

            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-producthead order-lg-1">
              <div className="platform-tag tag-color-segment">Customer Data Platform</div>
              <h1 className="producthead-heading mb-0"><span className="shoutout-keyword">Put Your</span>  Customer Data into Action</h1>
              <h5 className="mt-2 text-secondary">Get the best out of customer data, your most valuable asset</h5>
              <br /><br />
              <Link to="/signup" className="btn btn-primary btn-sm btn-round px-5">Try for Free</Link>

            </div>

          </div>
        </div>
      </div>
      <div className="section-separator rounded bottom">
        <div className="ss-content">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 962 115" fill="#ffffff" xmlSpace="preserve" preserveAspectRatio="none" className="svg replaced-svg">
            <path className="st0" d="M0,0c0,0,100,94,481,95C862,94,962,0,962,0v115H0V0z"></path>
          </svg>
        </div>
      </div>
    </div>

    <section id="features">
      <div className="container">
        <br /><br />
        <div className="row feature text-center">
          <div className="col-sm-12 col-md-5 col-lg-5">

            <img className="img-fluid" src="/assets/images/all-customer-data-in-one-place.svg" alt="feature" />

          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 text-lg-left my-auto">
            <p className="feature-title">Bring all Customer Data into One Place</p>
            <p>Fetch all customer details from different customer touch points and sources to a single dashboard on ShoutOUT. You can seamlessly integrate ShoutOUT with your existing application or upload data in CSV or Excel formats.</p>
            <p>
              ShoutOUT makes it a point to facilitate organizing all the data since it’s the most important factor for better segmentation and SMS/email personalization.
            </p>
          </div>

        </div>
        <br /><br />
        <div className="row feature text-center">
          <div className="col-sm-12 col-md-5 col-lg-5 order-lg-2">

            <img className="img-fluid" src={CustomerProfilesImage} alt="customer profiles" />

          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 order-lg-1 text-lg-left my-auto">
            <p className="feature-title">360° View of Customer Profiles</p>
            <p>Each customer has a profile which displays all the data ShoutOUT knows about them. It helps you to dive deep into each customer and find out their information to derive data like the average bucket size, frequency of visits, etc.</p>
            <p>
              ShoutOUT helps you to view and analyze individual profiles and reach out to them through the profile via SMS.
            </p>
          </div>

        </div>
        <br /><br />

        <div className="row feature text-center">
          <div className="col-sm-12 col-md-5 col-lg-5">

            <img className="img-fluid" src="/assets/images/segmentation.svg" alt="feature" />

          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 text-lg-left my-auto">
            <p className="feature-title">Powerful Customer Segmentation</p>
            <p>ShoutOUT lets you create dynamically updating segments based on customer actions and various factors such as gender, interests, purchase history, and location, to name a few. Segments being calculated and updated real-time is a convenient feature available.</p>
            <p>Better customer segmentation helps you to retain customers by recognizing customer trends and sending the most relevant messages.</p>
          </div>

        </div>
        <br /><br />
      </div>
    </section>

    <br /> <br />
    
    <section id="features-detailed" >
      <div className="container">

        <div className="row text-center justify-content-center">
          <span className="subheading">Why us?</span>
        </div>
        <div className="row text-center">
          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/csvimport.png" className="img-fluid w-30" alt="Uploading CSV/Excel" />
            </div>
            <div className="p-4 ">
              <h4 className="card-title text-center py-3">Uploading CSV/Excel</h4>
              <p className="card-description text-center">Import customer attributes and actions using CSV/Excel import tools</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/apiintegration.png" className="img-fluid w-30" alt="API Integration" />
            </div>
            <div className="p-4">
              <h4 className="card-title text-center py-3">API Integration</h4>
              <p className="card-description text-center">Automate data flow to ShoutOUT through seamless integration with your application</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/customersegmentation.png" className="img-fluid w-30" alt="Customer Segmentation" />
            </div>
            <div className="p-4">
              <h4 className="card-title text-center py-3">Customer Segmentation</h4>
              <p className="card-description text-center">Create dynamic customer groups with rule-based segmentation engine</p>
            </div>
          </div>


          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/customerprofiles.png" className="img-fluid w-30" alt="Customer Profiles" />
            </div>
            <div className="p-4 ">
              <h4 className="card-title text-center py-3">Customer Profiles</h4>
              <p className="card-description text-center">Single view of each customer with all the attributes and derived insights</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/sendmessages.png" className="img-fluid w-30" alt="Sending Messages" />
            </div>
            <div className="p-4 ">
              <h4 className="card-title text-center py-3">Sending Messages</h4>
              <p className="card-description text-center text-center">Send individual messages to customers inside each customer profile</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/gdpr.png" className="img-fluid w-30" alt="GDPR Compliance" />
            </div>
            <div className="p-4">
              <h4 className="card-title text-center py-3">GDPR Compliance</h4>
              <p className="card-description text-center">Ensures the security of your data and privacy of your customers</p>
            </div>
          </div>

        </div>


      </div>
    </section >


    <br /> <br />
    <CallToAction/>
    <MainFooter />
  </div >
);
