import React from 'react';
import { Link } from '@reach/router';
import Constants from '../../../Constants';
import { useRouteData, Head } from 'react-static'
import './Events.css';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';

const eventCard = ({ id, name, image, dateTime, dateRange, description, venue, location, type }) => {
  const eventDate = dateTime ? new Date(dateTime) : new Date();
  return (
    <div className="col-md-10 col-sm-12" key={id}>
      <div className="card border-0 shadow mb-3">
        <div className="row p-3">
          <div className="col-md-7 col-sm-12 order-1 my-auto text-center text-md-left">
            <span className={`event-label text-white ${type === "ShoutOUT Connect" ? "bg-primary"  : type=== "Webinar" ? "bg-loyalty" : "bg-lite"} px-3 py-1 mr-2`}>{type}</span>
            <h3 className="mt-2"><strong>{name}</strong></h3>
            <div className="small my-3">
              {description.substring(0, (description.substring(0, 150).length, description.substring(0, 150).lastIndexOf(" ")))}...
                      </div>
            <div className="d-inline mr-2"><h5 className="d-inline"><strong>{dateRange === ""? eventDate.getDate(): dateRange}</strong> {Constants.MONTH_NAMES[eventDate.getMonth()]} {eventDate.getFullYear()}</h5></div>
            <div className="d-md-inline">at <h5 className="d-inline"><strong>{venue}</strong></h5>, {location}</div>
            <div className="mt-4">
              <Link to={`/events/${id}`} className="d-inline-block btn btn-dark rounded-0 py-2 px-3">
                <span>View More</span> <img className="ml-2 mb-1" src="/assets/images/temp/arrow-white.svg" alt="next" />
              </Link>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 order-1-sm my-auto text-md-left text-center">
            <img className="zoom" src={`/assets/images/events/${id}/logo.png`} alt={`ShoutOUT Events - ${name}`} />
            <div className="d-sm-none">
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};

const eventCardComingUp = ({ id, name, type }) => {
  return (
    <div className="col-md-4 col-sm-12" key={id}>
      <div className="card border-0 shadow mb-3">
        <div className="row p-3">
          <div className="col-md-7 col-sm-12 order-1 my-auto text-center text-md-left">
            <span className={`event-label text-white ${type === "ShoutOUT Connect" ? "bg-primary"  : type=== "Webinar" ? "bg-loyalty" : "bg-lite"} px-3 py-1 mr-2`}>{type}</span>
            <h6 className="mt-2"><strong>{name}</strong></h6>
          </div>
          <div className="col-md-5 col-sm-12 order-1-sm my-auto text-md-left text-center">
            <img className="zoom w-100" src={`/assets/images/events/${id}/logo.png`} alt={`ShoutOUT Events - ${name}`} />
            <div className="d-sm-none">
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};

export default () => {
  const { events = [] } = useRouteData();
  const today = new Date();


  const eventsTransformed = events.reduce((result, value) => {
    const eventDate = new Date(value.dateTime);
    if(!value.open){
      result.eventComingSoon.push(value)
    }
    else{
    if (today <= eventDate) {
      result.eventsNotPassed.push(value)
    }
    else {
      result.eventsPassed.push(value)
    } 
  }
    return result

  }, { eventsPassed: [], eventsNotPassed: [], eventComingSoon: [] }
  );

  return (
    <div>
        <Head>
        <title>ShoutOUT | Events</title>
        </Head>
      <MainNavbar />
      <div className="container opacity-p8 pt-5">
        <div className="row justify-content-center mt-5 text-center">
          <div className="col-md-10 col-sm-12 pl-5">
            <h2 className="font-weight-bold">ShoutOUT Events</h2>
            <h6>We host both online and offline trade shows, seminars, conferences, and webinars to get insights from the brightest influencers, to discuss buzzworthy trends and uncover current best practices around the world while connecting with our customers and industry peers.</h6>
          </div>
        </div>
        <br />
        <div className="row justify-content-center text-center">
          <div className="col-md-10 col-sm-12 pl-5">
            <h5>Coming Soon</h5>

          </div>
          {eventsTransformed.eventComingSoon.length !== 0 ? eventsTransformed.eventComingSoon.map(({ id, name, image, dateTime, dateRange, description, venue, location, type }) => {

            return (
              eventCardComingUp({ id, name, type })

            );
          }
          ) :
          <div className="col-md-10 col-sm-12 pl-5">
          <h6>Await for more interesting events coming up.</h6>
        </div>
          }
        </div>
        <hr className="border-bottom-2-dashed"/>
        <div className="row justify-content-center text-center">
          <div className="col-md-10 col-sm-12 pl-5">
            <h5>Coming Up</h5>

          </div>
          {eventsTransformed.eventsNotPassed.length !== 0 ? eventsTransformed.eventsNotPassed.map(({ id, name, image, dateTime, dateRange, description, venue, location, type }) => {

            return (
              eventCard({ id, name, image, dateTime, dateRange, description, venue, location, type })

            );
          }
          ) :
            <div className="col-md-10 col-sm-12 pl-5">
              <h6>No events happening now.</h6>
            </div>
          }
        </div>

        <hr className="border-bottom-2-dashed"/>

        <div className="row justify-content-center mt-4 text-center">
          <div className="col-md-10 col-sm-12 pl-5">
            <h5>Passed Events</h5>

          </div>

          {eventsTransformed.eventsPassed.map(({ id, name, image, dateTime,dateRange, description, venue, location, type }) => {

            return (
              eventCard({ id, name, image, dateTime, dateRange, description, venue, location, type })
            );
          }
          )}
        </div>



      </div>
      <CallToAction />
      <MainFooter />
    </div>
  )

}
