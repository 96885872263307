import React from 'react';
import { Head } from 'react-static';
import { Link } from '@reach/router';
import AccountNavbar from './../../components/navbars/AccountNavbar';
import Services from "./../../services/Services";
import Modal from './../../components/modals/Modal';
import './Account.css';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', isSending: false, showAlert: false, isSuccess: false };
    }

    onChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    resetPassword = (e) => {
        e.preventDefault();
        this.setState({ isSending: true });
        Services.resetPassword(this.state.email).then(res => {

            this.setState({ isSending: false, isSuccess: true, showAlert: true });

        }, err => {
            this.setState({ isSending: false, isSuccess: false, showAlert: true });
        });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }
    render() {
        const { email, isSending, isSuccess, showAlert } = this.state;
        return (
            <>
            <Head>
                <title>ShoutOUT | Forgot Password</title>
            </Head>
                {/* <div className="text-center text-md-left fixed-top"><br />
                    <Link to="/">
                        <h5 className="text-secondary">&nbsp;&nbsp;&nbsp;
                <img src={LogoMini} className="img-fluid" alt="Forget Password ShoutOUT Labs" />
                        </h5>
                    </Link>
                </div> */}
                 <AccountNavbar />
                    <div className="container login-block h-100 pt-70">
                        <div className="row justify-content-center h-100">
                            <div className="col-md-4 col-sm-12 input-sec order-2 my-auto">
                                <h3 className="text-center font-weight-bold">Recover your password</h3>

                                <form className="login-form" onSubmit={this.resetPassword}>
                                    <div className="form-group">
                                        <label htmlFor="email" className="">Email</label>
                                        <input type="email" className="form-control" placeholder="sam@abc.com" value={email} onChange={this.onChangeEmail} required/>
                                    </div>

                                    <div className="form-check pl-0">
                                        <label className="form-check-label mt-2">
                                            {/* <input type="checkbox" className="form-check-input" /> */}
                                            <small className="text-dark">Remember the password?<Link to="/login"> Try again</Link></small>
                                        </label>
                                        <button type="submit" className="btn btn-primary btn-round float-right" disabled={isSending}>&nbsp;&nbsp;&nbsp;{isSending ? "Sending..." : "Submit"}&nbsp;&nbsp;&nbsp;</button>
                                    </div>
                                </form>
                            </div>
                       
                            {/* <div className="col-md-1 col-sm-12 order-2">
                <br/>
                <br/>
                <br/>
                </div> */}

                            {/* <div className="col-md-7 col-sm-12 order-1 my-auto">
                <div className="card shadow border-0">
                    <div className="card-body">
                        
                        <h5 className="card-title">What's New</h5>
                        <p className="card-text">With supporting text below as a natural lead-in to additional content.
                        With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content.
                        With supporting text below as a natural lead-in to additional content.</p>
                        <a href="#" className="btn btn-primary btn-round">Continue Reading</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                    <br/><br/>
                    <h5 className="text-secondary">Not a member yet? <Link to="/signup">Sign up now</Link></h5>
                    </div>
                </div>
            </div> */}
                        </div>
                    </div>

                    <Modal visible={showAlert} onClickBackdrop={this._closeAlert}>
                        {isSuccess ?

                            <React.Fragment>
                                <div className="modal-header">

                                    <h5 className="modal-title text-success">Success!</h5>
                                </div>
                                <div className="modal-body">
                                    <p className="text-secondary">Instructions to reset the password sent to {email}. Please check your emails.</p>
                                </div>
                            </React.Fragment> :
                            <React.Fragment>
                                <div className="modal-header">

                                    <h5 className="modal-title text-danger">Failed!</h5>
                                </div>
                                <div className="modal-body">
                                    <p className="text-secondary">Couldn't send the instruction. Please retry.</p>
                                </div>
                            </React.Fragment>
                        }

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.closeAlert}>
                                Close
          </button>
                        </div>
                    </Modal>

            
            </>
        );
    }
}
export default ForgotPassword;