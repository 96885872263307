import React from 'react'

const EmailPage = () => {
    return (
        <div>
            <div className="alert alert-primary text-center" role="alert">
            EMAIL
        </div>

            <div className="card-deck">
                <div className="card border-secondary">

                    <div className="card-body">
                        <h5 className="card-title">HTML Templates</h5>
                        <p className="card-text">Get access to the readymade templates that include all the necessary components to launch a professional-looking email campaign.</p>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        <h5 className="card-title">Analytics </h5>
                        <p className="card-text">Get access to the performance metrics. See the message open rates, messages failed or successful rates and the message received rates.</p>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        <h5 className="card-title">Domain</h5>
                        <p className="card-text">Create your own eye-catching domains according to the purpose. And launch your campaigns successfully. </p>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default EmailPage

