import React, { Component } from 'react';
import UtilService from './../../services/UtilService';
import Logo from './../../images/logo-blue.png';
import Facebook from './../../images/facebook.svg';
import Twitter from './../../images/twitter.svg';
import Instagram from './../../images/instagram.svg';
import Linkedin from './../../images/linkedin.svg';
import Quora from './../../images/quora.svg';
import Phone from './../../images/telephone.svg';
import Email from './../../images/email-icon.svg';
import Location from './../../images/location.svg';
import Youtube from './../../images/youtube.svg'

import { Link } from '@reach/router';
import './MainFooter.css';

export default class MainFooter extends Component {

    constructor() {
        super();
        this.state = { country: "lk" };
    }

    async _getCountry() {

        const response = await UtilService.getIpInfo();
        this.setState({ country: response.country });


    };

    componentDidMount() {
        this._getCountry();
    }

    render() {
        return (

            <footer className="mt-5 footer-text-light footer-bg-dark pt-5 pb-45">
                {/* <span className="footer-bg-img d-lg-block d-none"></span> */}
                <div className="container">
                    <div className="row">

                        <div className="col-md-4 d-flex flex-column">
                            <h5>
                                <Link className="" to="/">
                                    <img className="img-fluid footer-logo-blue" src={Logo} alt="ShoutOUT" />
                                </Link>
                            </h5>
                            <div>
                                {/* <h5 className="font-weight-bold my-1 footer-text">Get in Touh</h5> */}

                                {/* <div className="footer-text mt-auto">Want to discuss more? Contact us.</div> */}
                                <div className="row mb-2">
                                    {(this.state.country === "lk" || this.state.country === "ng") && <div className="col-sm-12">
                                        <a className="footer-text" href="tel:94773034848">
                                            <div className="mt-2">
                                                <img className="mr-2" src={Phone} alt="Phone" />
                                                <span itemProp="telephone">94 (77) 303-4848</span>
                                            </div>
                                        </a>
                                    </div>}
                                </div>
                            </div>
                            <div>
                                <p className="footer-text text-secondary mb-0">Connect with us</p>
                                <ul className="list-unstyled list-inline mb-0">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/ShoutOUTLabs" className="mr-1">
                                            <img src={Facebook} alt="ShoutOUT Facebook" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.youtube.com/channel/UCkIKvDoQr2ktedFzJSo_Wfg" className="mx-1">
                                            <img src={Youtube} alt="ShoutOUT YouTube" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/ShoutOUTLabs" className="mx-1">
                                            <img src={Twitter} alt="ShoutOUT Twitter" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/labsshoutout" className="mx-1">
                                            <img src={Instagram} alt="ShoutOUT Instagram" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/shoutout-labs-pvt-ltd" className="ml-1">
                                            <img src={Linkedin} alt="ShoutOUT Linkedin" />
                                        </a>
                                    </li>
                                    {/* <li className="list-inline-item">
                                            <a href="https://www.quora.com/profile/ShoutOUT-Labs-2" className="mx-1">
                                                <img className="ml-2" src={Quora} alt="ShoutOUT Quora" />
                                            </a>
                                        </li> */}
                                </ul>
                            </div>
                            {/* <div className="footer-text mt-auto">Want to discuss more? Contact us.</div> */}
                            {/* <div className="row mb-2">
                                <div className="col-sm-12">
                                    <a className="footer-text" href={this.state.country === "lk" || this.state.country === "ng" ? "tel:94716550512" : "tel:12029085273"}>
                                        <div className="mt-2">
                                            <img className="mr-3" src={Phone} alt="Phone" />
                                            <span itemProp="telephone">{this.state.country === "lk" || this.state.country === "ng" ? "94 (71) 655-0512" : "1 (202) 908-5273"}</span>
                                        </div>
                                    </a>
                                </div>
                            </div> */}
                            {/* {this.state.country === "lk" && <div className="row mb-2">
                                <div className="col-sm-12">
                                    <a className="footer-text" href={"tel:94766173344"}>
                                        <div className="mt-2">
                                            <img className="mr-3" src={Phone} alt="Phone" />
                                            <span itemProp="telephone">94 (76) 617-3344</span>
                                        </div>
                                    </a>
                                </div>
                            </div>}
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <a className="footer-text" href="mailto:info@getshoutout.com">
                                        <div className="mt-2">
                                            <img className="mr-3" src={Email} alt="Email" />
                                            <span itemProp="email">info@getshoutout.com</span>
                                        </div>
                                    </a>
                                </div>
                            </div> */}
                            {/* <div className="row mt-auto d-sm-block d-none">
                                <div className="col-sm-12">
                                    <div className="footer-text">
                                        © 2022 Copyright: <a className="footer-text" href="https://getshoutout.com/">ShoutOUT Labs</a>
                                    </div>
                                </div>
                            </div> */}
                            {/* {this.state.country === "lk" || this.state.country === "ng" ? <div className="row">
                                <div className="col-sm-12">
                                    <a className="footer-text" href="https://g.page/shoutout-labs-colombo?share" target="_blank" rel="noopener noreferrer">
                                        <div className="mt-2">
                                            <img className="mr-3" src={Location} alt="Location" />
                                            <span className="footer-text" itemProp="address">02 6<sup>th</sup> Lane, Colombo 03, Sri Lanka</span>
                                        </div>
                                    </a>
                                </div>
                            </div> : ''} */}


                        </div>

                        {/* <hr className="clearfix w-100 d-md-none" /> */}

                        <div className="col-md-8">
                            <div className="row mt-3 mt-sm-0">

                                <div className="col-md-3 col-sm-12 mx-auto">
                                    <h5 className="font-weight-bold my-1 footer-text">Products</h5>
                                    <ul className="list-unstyled">
                                    {/* d-flex flex-md-column flex-sm-row */}
                                        {/* <li>
                                    <Link className="footer-text link-ul" to="/people/segment">
                                        <span>Segment</span>
                                    </Link>
                                </li> */}

                                        <li>
                                            <a href="https://getshoutout.com" rel="noopener noreferrer" className="footer-text link-ul">
                                                <span>Messaging Platform</span>
                                            </a>
                                            {/* <Link className="footer-text link-ul" to="https://getshoutout.com">
                                        <span>Engage</span>
                                    </Link> */}
                                        </li>



                                        {/* <li>
                                    <Link className="footer-text link-ul" to="/loyalty">
                                        <span>Reward</span>
                                    </Link>
                                </li> */}
                                        {/* <hr className="my-2 w-100 bg-light" /> */}
                                        <li>
                                            <a href="https://lite.getshoutout.com" rel="noopener noreferrer" target="_blank" className="footer-text link-ul">
                                                <span>SMS API</span>
                                            </a>
                                            {/* <Link className="footer-text" to="/lite">
                                <div className="footer-link">Lite - SMS API</div>
                            </Link> */}
                                        </li>

                                        {/* <li>
                                            <a href="/omnichannel-messaging-platform" rel="noopener noreferrer" className="footer-text link-ul">
                                                <span>Inbox</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>

                                <div className="col-md-3 col-sm-12 mx-auto">
                                    <h5 className="font-weight-bold my-1 footer-text">About us</h5>
                                    <ul className="mb-0 list-unstyled">
                                        {/* d-flex flex-md-column flex-sm-row */}
                                        <li>
                                            <Link className="footer-text link-ul" to="/about-us">
                                                <span>About ShoutOUT</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="footer-text link-ul" to="/customers">
                                                <span>Case Studies</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled">
                                        {/* d-flex flex-md-column flex-sm-row */}
                                        <li>
                                            <Link className="footer-text link-ul" to="/gdpr">
                                                <span>GDPR Compliance</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="footer-text link-ul" to="/contactus">
                                                <span>Contact Us</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>






                                <div className="col-md-3 col-sm-12 mx-auto">
                                    <h5 className="font-weight-bold my-1 footer-text">Resources</h5>
                                    <ul className="list-unstyled ">
                                        {/* d-flex flex-md-column flex-sm-row */}
                                        <li>
                                            <a href="https://blog.getshoutout.com" rel="noopener noreferrer" target="_blank" className="footer-text link-ul">
                                                <span>Blog</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://support.getshoutout.com/help" rel="noopener noreferrer" target="_blank" className="footer-text link-ul">
                                                <span>Knowledge Base</span>
                                            </a>
                                        </li>
                                        <li>
                                            <Link className="footer-text link-ul" to="/events">
                                                <span>Events</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <a href="https://developers.getshoutout.com" rel="noopener noreferrer" target="_blank" className="footer-text link-ul">
                                                <span>Developer Docs</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>

                                {/* <div className="col-md-3 col-sm-12 mx-auto">
                                    <h5 className="font-weight-bold my-1 p-1 footer-text">Get in Touch</h5>

                                    <ul className="list-unstyled d-flex flex-md-column flex-sm-row">
                                        <li className="px-1">
                                            <Link className="footer-text link-ul" to="#">
                                               

                                            </Link>
                                        </li>
                                     
                                        <li className="px-1">
                                            <Link className="footer-text link-ul" to="/pricing">
                                               
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}


                                {/* <div className="col-12">

                                  
                                </div> */}

                                {/* 
                                <div className="col-12">
                                    <div className="footer-text text-sm-right text-center p2244y-3">
                                        © 2021 Copyright: <a className="footer-text" href="https://getshoutout.com/"> ShoutOUT Labs</a>
                                    </div>
                                </div> */}

                            </div>
                            {/* <div className="row text-left text-sm-right mr-3">
                                <div className="col-sm-12">
                                    <Link className="footer-text link-ul" to="/privacy">
                                        <span>Privacy and Policy</span>
                                    </Link>
                                    <span className="footer-text link-ul mx-1"> | </span>
                                    <Link className="footer-text link-ul" to="/terms">
                                        <span>Terms of use</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-auto d-block d-sm-none">
                                <div className="col-sm-12">
                                    <div className="footer-text">
                                        © 2022 Copyright: <a className="footer-text" href="https://getshoutout.com/"> ShoutOUT Labs</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
                <div className="container-fluid footer-bg ">
                    <div className="row pt-4 mx-lg-4">
                        <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center">
                            <div className="footer-text">
                                © 2022 Copyright: <a className="footer-text" href="https://getshoutout.com/"> ShoutOUT Labs</a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-10 col-lg-6 text-lg-right">
                            <Link className="footer-text link-ul" to="/privacy">
                                <span>Privacy and Policy</span>
                            </Link>
                            <span className="footer-text link-ul mx-1"> | </span>
                            <Link className="footer-text link-ul" to="/terms">
                                <span>Terms of use</span>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <br />
                        <br />
                    </div>


                </div>



            </footer>


        )
    }
}