import React, { Component } from 'react';
import Logo from './../../images/logo.svg';
import { Link } from '@reach/router';
import './MainNavbar.css';

export default class AccountNavbar extends Component {
    
    render() {
        return (
            <div className="main-navbar">
                <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg shadow-sm navbar-light headroom fixed-top">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                            <Link to="/" className="navbar-brand mr-lg-5">
                                <img className="logo" src={Logo} alt="ShoutOUT" />
                            </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>  
        )
    }
}