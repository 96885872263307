import React, { useState, useEffect, useCallback } from 'react';
import LogoBlue from './../../../images/ai/logo-blue.svg';
import LogoWhite from './../../../images/ai/logo-white.svg';
import { Link } from '@reach/router'
import { useSiteData } from 'react-static';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavbarAI.css';


const NavbarAI = ({ path }) => {


    const [isScrolled, setIsScrolled] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [isDropdownShowResources, setIsDropdownShowResources] = useState(false);

    const toggleCollapse = () => {

        setIsExpand(!isExpand);
    }

    const toggleDropdownResources = () => {

        setIsDropdownShowResources(!isDropdownShowResources);
    }
    const pathName = typeof window !== 'undefined' ? window.location.pathname : '';

    const navbarScroll = useCallback(() => {
        if (typeof window !== 'undefined') {
            const isScrolledNow = window.scrollY > 1;

            setIsScrolled(isScrolledNow);

        }
    }, [isScrolled, setIsScrolled])

    useEffect(() => {
        if (typeof document !== undefined) {
            document.addEventListener('scroll', navbarScroll);

            return () => {
                document.removeEventListener('scroll', navbarScroll);
            }
        }

    }, [])

    return (

        <nav className={`navbar navbar-expand-lg fixed-top ${pathName !== '/' ? isScrolled ? "shadow-sm navbar-scrolled navbar-light" : "navbar-light" : isScrolled ? "shadow-sm navbar-scrolled navbar-light" : "navbar-light"}`}>
            <div className="container">
                <Link to="/" className="navbar-brand mr-lg-5">
                    <img className="logo" src={pathName !== '/' ? LogoBlue : isScrolled ? LogoBlue : LogoWhite} alt="ShoutOUT" />
                </Link>
                <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleCollapse}>
                    <span className=""><img className="img-fluid w-75" src="/assets/images/temp/menu.svg" alt="menu" /></span>
                </button>
                <div className={`navbar-collapse collapse ${isExpand ? 'show' : ''}`} id="navbar_global">
                    <div className="navbar-collapse-header">
                        <div className="row">
                            <div className="col-6 collapse-brand">
                                <Link to="/">
                                    <img src={LogoBlue} alt="ShoutOUT" className="logo" />
                                </Link>
                            </div>
                            <div className="col-6 collapse-close">
                                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleCollapse}>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-nav navbar-nav-hover align-items-lg-center">


                        <li className="nav-item">
                            <Link to="/answer-bot" className="nav-link" onClick={toggleCollapse}>

                                <i className="ni ni-collection d-lg-none"></i>
                                <span className="nav-link-inner--text position-relative" id="caseStudies-link">
                                    <small className="badge badge-danger badge-position-nav-item">New</small>
                                    Answer Bot
                                </span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/answer-bot" className="nav-link disabled-nav-item">
                                <i className="ni ni-collection d-lg-none"></i>
                                <span className="nav-link-inner--text position-relative" id="caseStudies-link">
                                    <small className="badge badge-muted badge-position-nav-item">Coming Soon</small>
                                    Live Chat
                                </span>

                            </Link>
                        </li>

                        <li className="nav-item">
                            <a href="https://medium.com/shoutout-ai-blog" rel="noopener noreferrer" className="nav-link" target="_blank" >Blog</a>
                        </li>


                        {/* <li className="nav-item dropdown">
                            <a href="#" className="nav-link" onClick={toggleDropdownResources} data-toggle="dropdown" role="button">
                                <i className="ni ni-collection d-lg-none"></i>
                                <span className="nav-link-inner--text" id="navBarResources">Resources</span>
                            </a>
                           

                            <div className={`dropdown-menu dropdown-menu ${isDropdownShowResources ? 'show' : ''}`}>
                                <a href="https://medium.com/shoutout-ai-blog" rel="noopener noreferrer" target="_blank" id="resources-dropdown-blog" className="dropdown-item" onClick={toggleCollapse}>Blog</a>
                                <Link className="dropdown-item" to="/events">
                                    <span>Events</span>
                                </Link>
                            </div>

                        </li> */}

                        {/* <li className="nav-item dropdown">
                                <a href="#" className="nav-link" onClick={this._toggleDropdownCompany} data-toggle="dropdown" role="button">
                                    <i className="ni ni-collection d-lg-none"></i>
                                    <span className="nav-link-inner--text">Company</span>
                                </a>
                                {/* <div className="dropdown-menu"> */}
                        {/*}
                                <div className={`dropdown-menu dropdown-menu ${this.state.isDropdownShowCompany ? 'show' : ''}`}>

                                    <Link to="/about" className="dropdown-item">About Us</Link>
                                    <Link to="/contactus" className="dropdown-item">Contact Us</Link>
                                    <Link to="/privacy" className="dropdown-item">Privacy and Policy</Link>
                                    <Link to="/terms" className="dropdown-item">Terms of use</Link>
                                    <Link to="/gdpr" className="dropdown-item">GDPR Compliance</Link>
                                </div>
                            </li> */}

                        <li className="nav-item d-lg-none">
                            <a href="https://web.shoutout.ai" rel="noopener noreferrer" target="_blank">Login</a>
                        </li>
                        {/* <li className="nav-item d-lg-none">
                            <Link to="/signup" className="nav-link">
                                &nbsp;Get Started
                                    </Link>
                        </li> */}
                    </ul>

                    <ul className="navbar-nav align-items-lg-center ml-lg-auto">
                        <li className="nav-item d-none d-lg-block ml-lg-4">
                            <a href="https://web.shoutout.ai" rel="noopener noreferrer" className={pathName !== '/' ? isScrolled ? "text-dark" : "text-dark" : isScrolled ? "text-dark" : "text-light"}  target="_blank">Login</a>
                        </li>
                        {/* <li className="nav-item d-none d-lg-block ml-lg-4">
                            <Link to="/signup" className="btn btn-dark btn-round btn-icon">
                                Get Started
                                    </Link>
                        </li> */}

                    </ul>

                </div>
            </div>
        </nav>




    )

}

export default NavbarAI;