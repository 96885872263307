import React from 'react'
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router';
import Constants from '../../../Constants';
import './Events.css';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';
import Linkedin from './../../images/linkedin.svg';


export default () => {
  const { item = {} } = useRouteData();
  const { id, name, dateTime, dateRange, venue, location, description, video, photos = [], photosAlbum, articles = {}, register, facebook, type, agenda = [], speakers = [] } = item;
  const eventDate = dateTime ? new Date(dateTime) : new Date();
  const today = new Date();

  return (
    <>
<Head>
  <title>{`ShoutOUT Events | ${name}`}</title>
</Head>
      <MainNavbar />



      <div className="container pt-5 opacity-p9">
        <div className="row justify-content-center">

          <div className="col-md-10 col-sm-12 mt-5">
            <div className="card rounded-0 border-0 shadow p-4">
              <Link to="/events" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
              <div className="row justify-content-center opacity-p8">
                <div className="col-md-6 col-sm-12 my-auto text-lg-right order-lg-2 p-4">
                  <img className="img-fluid mr-5 shadow-sm" src={`/assets/images/events/${id}/logo.png`} alt={`Event : ${name}`} />
                </div>
                <div className="col-md-6 col-sm-12 my-auto order-lg-1">
                  <div className="p-4">
                    <h6 className="border-muted border-bottom pb-1 mb-0 w-50">Event</h6>
                    <div className="heading mt-0">{name}</div>
                    <span className={`event-label text-white ${type === "ShoutOUT Connect" ? "bg-primary"  : type=== "Webinar" ? "bg-loyalty" : "bg-lite"} px-3 py-1 mr-2`}>{type}</span>
                    {today <= eventDate ? <span className="event-label text-white bg-success px-3 py-1">Active</span> : <span className="event-label text-white bg-danger px-3 py-1">Finished</span>}


                  </div>
                </div>
              </div>

              {today <= eventDate ?
                <div className="row p-4 text-center text-md-left">
                  <div className="col-md-4 col-sm-12">
                    <h6 className="border-bottom pb-1">Register</h6>
                    {register === "coming" ? <span>Coming Soon</span>: <h3><a className="btn btn-dark rounded-0 px-5" href={register} target="blank">Register</a></h3>}
                  </div>
                 {facebook!==""&&  <div className="col-md-4 col-sm-12">
                    <h6 className="border-bottom pb-1">Facebook</h6>
                    {facebook === "coming"? <span>Coming soon</span> : <h3><a href={facebook}>Visit Event</a></h3>}
                  </div>}
                </div>
                :
                <></>

              }

              <div className="row p-4 h-100">
                <div className="col border-0 my-auto mr-2 pt-2">
                  <span>Date</span>
                  <h4><strong>{dateRange === ""? eventDate.getDate(): dateRange}</strong> {Constants.MONTH_NAMES[eventDate.getMonth()]} {eventDate.getFullYear()}</h4>
                </div>
                <div className="col border-0 mr-2 my-auto pt-2">
                  <span>Time</span>
                  <h3><strong>{eventDate.getHours()}:{eventDate.getMinutes() < 10 ? "0" + eventDate.getMinutes() : eventDate.getMinutes()}</strong> Onwards</h3>
                </div>
                <div className="col border-0 my-auto pt-2">
                  <span>Venue</span>
                  <h3><strong>{venue}</strong>, {location}</h3>
                </div>
              </div>



              <div className="p-4">
                <h5><strong>Description</strong></h5>
                <p>
                  {description}
                </p>
              </div>

              <div className="row py-4 px-3">
                {agenda.length !== 0 && <div className="col-md-12 col-sm-12">
                  <h5><strong>Agenda</strong></h5>
                  <div className="card-row">
                    {agenda.map(({ title, time, description, video }, index) => {
                      return (

                        <div className="card border-0 shadow-sm rounded-0 p-4 mb-3" key={index}>
                          <span className="badge badge-dark rounded-0 position-absolute position-badge">{index + 1}</span>
                          <small>{time}</small>
                          <h6 className="mb-0"><strong>{title}</strong></h6>
                          {description && <><small className="text-muted">{description}</small> <br /></>}

                          {video && <a href={`https://youtu.be/${video}`}><small>Watch Session</small></a>}
                        </div>
                      );
                    })}
                  </div>
                </div>
                }

               {speakers.length !== 0 && <div className="col-md-12 col-sm-12 mt-3">
                  <h5><strong>Speakers</strong></h5>

                    <div className="card-deck">
                      {speakers.map(({ id: speaker, name, designation, linkedin }) => {
                        return (

                          <div className="card rounded-0 border-0 shadow p-2" key={speaker}>
                            <a href={`https://www.linkedin.com/in/${linkedin}/`} className="bg-primary d-inline position-absolute position-linkedin p-3"><img className="img-fluid w-50 position-absolute position-linkedin-logo" src={Linkedin} alt="ShoutOUT Linkedin" /></a>
                            <div className="card-horizontal h-100">
                              <img className="w-25 my-auto rounded-circle shadow-sm mr-2 border-right" src={`/assets/images/events/${id}/speaker-0${speaker}.png`} alt="Card image cap" />

                              <p className="my-auto mx-auto">{name}<br />
                                <small>{designation}</small>
                              </p>
                            </div>
                          </div>



                        );
                      })}
                    </div>
                </div>}
              </div>

              {!(today <= eventDate) && <div className="p-4">


                <div className="row">
                  {video && <div className="col-md-6 col-sm-12">
                    <h5><strong>Video</strong></h5>
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe id="ytplayer" className="embed-responsive-item" type="text/html" src={`https://www.youtube.com/embed/${video}?controls=0&fs=0&loop=1&modestbranding=1&start=1&iv_load_policy=3`} frameBorder="0" allowFullScreen="1" />
                    </div>
                  </div>}
                  {photos.length !== 0 && <div className="col-md-6 col-sm-12 my-auto">
                    <h5><strong>Photos</strong></h5>
                    <div>
                      {photos.map((photo) => {
                        return <img key={photo} className="w-30 mr-2 mb-2" src={`/assets/images/events/${id}/photo-0${photo}.png`} />
                      })
                      }
                      <a href={photosAlbum} target="blank">
                        <img className="w-30 shadow-sm mb-2 zoom" src={`/assets/images/events/view-more.png`} />
                      </a>
                    </div>
                  </div>}

                  {articles.length !== 0 && <div className="col-md-12 col-sm-12 mt-3">
                    <h5><strong>Articles</strong></h5>
                    <a href={articles.link}>
                      <div className="card shadow border-0 rounded-0 p-2 d-inline-block">
                        <div className="row">
                          <div className="col-md-1 col-sm-12 my-auto mr-1"><img src={`/assets/images/events/${id}/article-0${articles.image}.jpg`} /></div>
                          <div className="col-md col-sm-12 my-auto">{articles.title} <img className="ml-2 mb-1" src="/assets/images/temp/arrow.svg" alt="" /></div>
                        </div>
                      </div>
                    </a>
                  </div>}

                </div>

              </div>
              }

            </div>
          </div>

        </div>
      </div>


      <CallToAction />
      <MainFooter />
    </>
  )
}
