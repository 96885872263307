import jwtDecode from 'jwt-decode';
import axios from 'axios';
import Constants from './../../Constants';
const UTILITY_SERVICE_BASE_URL = `${Constants.BASE_API_URL}utilityservice/`;
const UTILITY_SERVICE_BASE_URL_TEMP = `${Constants.BASE_API_URL_TEMP}utilityservice/`;
const GOOGLE_RECAPTCHA_VERIFY_URL="https://www.google.com/recaptcha/api/siteverify";


class UtilService {
    static queryStringToJSON(queryString) {
        if (queryString) {
            let pairs = queryString.slice(1).split('&');

            let result = {};
            pairs.forEach(function (pair) {
                pair = pair.split('=');
                result[pair[0]] = decodeURIComponent(pair[1] || '');
            });

            return JSON.parse(JSON.stringify(result));
        }
        return {};
    }

    static decodeToken(token) {
        return jwtDecode(token);
    }
    static async getIpInfo() {
        try {

            const countryCodeResponse = await axios.get(`${UTILITY_SERVICE_BASE_URL_TEMP}ipinfo`);
            const country = countryCodeResponse.data.country.toLowerCase();
            sessionStorage.setItem("countryCode", country);
            return Promise.resolve({ country });


        } catch (e) {
            return Promise.resolve({ country: "lk" });
        }
    }
}

export default UtilService;