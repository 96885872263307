import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from '@reach/router';
import { Head } from 'react-static';
const smsColours = ["loyalty", "segment", "lite", "engage"];
import CountrySelector from './CountrySelector';
import PackageSMS from './PackageSMS';
import './Pricing.css';

const PricingSMS = ({ pricingPlans = [], onDemandSMS = [], subscriptionSMS = [] }) => {
    const [countryFilter, setCountryFilter] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [creditValue, setCreditValue] = useState(1);
    const [country, setCountry] = useState({ name: "Sri Lanka", code: "lk" });
    const [countryBillingMap, setCountryBillingMap] = useState([]);
    const [showSelector, setShowSelector] = useState(false);


    const pricingPlansData = pricingPlans.reduce((result, item) => {
        if (item.category === "SMS") {
            item.currency = item.currency.toUpperCase();
            switch (item.metaData.type) {
                case "ondemand": {
                    (result[item.blockSize] || (result[item.blockSize] = {})).ondemand = item;
                    break;
                }
                case "subscription": {
                    (result[item.blockSize] || (result[item.blockSize] = {})).subscription = item;
                    break;
                }
            }
        }
        return result;
    }, {}
    );

    const SMSPackages = pricingPlans.filter((item) => item.category === "SMS")


    // const lowestOnDemandPlan = onDemandSMS[Math.min(...Object.keys(onDemandSMS))]?.ondemand;

    // const plansMonthly = plansSubscriptions.unshift(({ usd_amount: "-" }))



    // const onSMSRange = (e) => {
    //     const value = e.target.value;
    //     setSmsValue(value);
    // }




    // const baseSMSPrice = onDemand.length > 0 ? plansFiltered[0].amount / plansFiltered[0].credits.creditAmount : 1;
    return (
        <>
            {/* <Head>
                <title>ShoutOUT | Pricing and Plans</title>
            </Head> */}
            {/* <div className="row mt-2 justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 px-4">
                    <div className="list-inline text-center">
                        <p className="h5 list-inline-item mb-3 font-weight-bold">SMS Packages</p>
                    </div>

                 
                    {onDemandSMS && <p className="my-4 text-center">Top up credits as per your SMS volume. Starting from {onDemandSMS[0]?.currency?.toUpperCase()} {onDemandSMS[0]?.currency_amount}.</p>}
               

                    <div className="row no-gutters">
                        <div className="col-lg-12 col-sm-12 mx-auto pricing-sms-font-75p">
                            <div className="bg-muted-blue row">
                                <div className='my-auto mt-sm-3 col-lg-4 col-md-6 col-sm-12'>
                                    <CountrySelector setCreditValue={setCreditValue} />
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-12">
                                    <div className="table-responsive p-4">
                                        <table className="table table-sm text-center">
                                            <thead>
                                                <tr>
                                                    {subscriptionSMS.map((item, index) => {
                                                        return (
                                                            <th key={index}>{item.name}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{onDemandSMS[0]?.currency} {(onDemandSMS[0]?.currency_amount / (onDemandSMS[0]?.credits?.creditAmountMap.platform_sms_1 / creditValue)).toFixed(2)} per SMS</td>
                                                    <td>{onDemandSMS[0]?.currency} {(onDemandSMS[0]?.currency_amount / (onDemandSMS[0]?.credits?.creditAmountMap.platform_sms_2 / creditValue)).toFixed(2)} per SMS</td>
                                                    <td>{onDemandSMS[0]?.currency} {(onDemandSMS[0]?.currency_amount / (onDemandSMS[0]?.credits?.creditAmountMap.platform_sms_3 / creditValue)).toFixed(2)} per SMS</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div> */}
            {/* <div className="row mt-3 justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12">
                    <div className="d-flex flex-row justify-content-center">
                        <div className="my-auto mb-0 mr-4">Bulk Orders?
                            <div className="badge badge-pill badge-loyalty position-absolute bulk-badge mr-2">Save</div>
                        </div>
                        <Link to="/bookaDemo" className="ml-2 px-4 btn btn-outline-primary btn-round font-14">Talk to us</Link>

                    </div>
                    <hr />
                </div>
            </div> */}
        </>
    )
}
export default PricingSMS;