


// Template Map
export default {
  '__react_static_root__/src/containers/404': require('__react_static_root__/src/containers/404').default,
'__react_static_root__/src/containers/about/About.js': require('__react_static_root__/src/containers/about/About.js').default,
'__react_static_root__/src/containers/account/AccountCompletion.js': require('__react_static_root__/src/containers/account/AccountCompletion.js').default,
'__react_static_root__/src/containers/account/AccountVerificationFailed.js': require('__react_static_root__/src/containers/account/AccountVerificationFailed.js').default,
'__react_static_root__/src/containers/account/AccountVerificationSuccess.js': require('__react_static_root__/src/containers/account/AccountVerificationSuccess.js').default,
'__react_static_root__/src/containers/account/BookADemo.js': require('__react_static_root__/src/containers/account/BookADemo.js').default,
'__react_static_root__/src/containers/account/ForgotPassword.js': require('__react_static_root__/src/containers/account/ForgotPassword.js').default,
'__react_static_root__/src/containers/account/InvitationFailed.js': require('__react_static_root__/src/containers/account/InvitationFailed.js').default,
'__react_static_root__/src/containers/account/Launchpad.js': require('__react_static_root__/src/containers/account/Launchpad.js').default,
'__react_static_root__/src/containers/account/Login.js': require('__react_static_root__/src/containers/account/Login.js').default,
'__react_static_root__/src/containers/account/LoginWithGoogle.js': require('__react_static_root__/src/containers/account/LoginWithGoogle.js').default,
'__react_static_root__/src/containers/account/Logout.js': require('__react_static_root__/src/containers/account/Logout.js').default,
'__react_static_root__/src/containers/account/Signup.js': require('__react_static_root__/src/containers/account/Signup.js').default,
'__react_static_root__/src/containers/account/UpdatePassword.js': require('__react_static_root__/src/containers/account/UpdatePassword.js').default,
'__react_static_root__/src/containers/account/verify/AccountVerify.js': require('__react_static_root__/src/containers/account/verify/AccountVerify.js').default,
'__react_static_root__/src/containers/ai/index.js': require('__react_static_root__/src/containers/ai/index.js').default,
'__react_static_root__/src/containers/ai/navbar/NavbarAI.js': require('__react_static_root__/src/containers/ai/navbar/NavbarAI.js').default,
'__react_static_root__/src/containers/ai/products/AnswerBot/AnswerBot.js': require('__react_static_root__/src/containers/ai/products/AnswerBot/AnswerBot.js').default,
'__react_static_root__/src/containers/contactUs/ContactUs.js': require('__react_static_root__/src/containers/contactUs/ContactUs.js').default,
'__react_static_root__/src/containers/customers/CaseStudy.js': require('__react_static_root__/src/containers/customers/CaseStudy.js').default,
'__react_static_root__/src/containers/customers/Customers.js': require('__react_static_root__/src/containers/customers/Customers.js').default,
'__react_static_root__/src/containers/customers/CustomersSlidingBar.js': require('__react_static_root__/src/containers/customers/CustomersSlidingBar.js').default,
'__react_static_root__/src/containers/engage/Engage.js': require('__react_static_root__/src/containers/engage/Engage.js').default,
'__react_static_root__/src/containers/events/EventDetails.js': require('__react_static_root__/src/containers/events/EventDetails.js').default,
'__react_static_root__/src/containers/events/Events.js': require('__react_static_root__/src/containers/events/Events.js').default,
'__react_static_root__/src/containers/features/Feature.js': require('__react_static_root__/src/containers/features/Feature.js').default,
'__react_static_root__/src/containers/features/Features.js': require('__react_static_root__/src/containers/features/Features.js').default,
'__react_static_root__/src/containers/home/CustomerLogos.js': require('__react_static_root__/src/containers/home/CustomerLogos.js').default,
'__react_static_root__/src/containers/home/Home.js': require('__react_static_root__/src/containers/home/Home.js').default,
'__react_static_root__/src/containers/inbox/Inbox.js': require('__react_static_root__/src/containers/inbox/Inbox.js').default,
'__react_static_root__/src/containers/lite/Lite.js': require('__react_static_root__/src/containers/lite/Lite.js').default,
'__react_static_root__/src/containers/lite/pricing/LitePricingWrapper.js': require('__react_static_root__/src/containers/lite/pricing/LitePricingWrapper.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/CURLSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/CURLSampleCode.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/NodeSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/NodeSampleCode.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/PHPSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/PHPSampleCode.js').default,
'__react_static_root__/src/containers/lite/sampleCodes/RESTSampleCode.js': require('__react_static_root__/src/containers/lite/sampleCodes/RESTSampleCode.js').default,
'__react_static_root__/src/containers/loyalty/Loyalty.js': require('__react_static_root__/src/containers/loyalty/Loyalty.js').default,
'__react_static_root__/src/containers/people/People.js': require('__react_static_root__/src/containers/people/People.js').default,
'__react_static_root__/src/containers/policies/Gdpr.js': require('__react_static_root__/src/containers/policies/Gdpr.js').default,
'__react_static_root__/src/containers/policies/Privacy.js': require('__react_static_root__/src/containers/policies/Privacy.js').default,
'__react_static_root__/src/containers/policies/Terms.js': require('__react_static_root__/src/containers/policies/Terms.js').default,
'__react_static_root__/src/containers/pricing/CountrySelector.js': require('__react_static_root__/src/containers/pricing/CountrySelector.js').default,
'__react_static_root__/src/containers/pricing/PackageSMS.js': require('__react_static_root__/src/containers/pricing/PackageSMS.js').default,
'__react_static_root__/src/containers/pricing/Pricing.js': require('__react_static_root__/src/containers/pricing/Pricing.js').default,
'__react_static_root__/src/containers/pricing/PricingSMS.js': require('__react_static_root__/src/containers/pricing/PricingSMS.js').default,
'__react_static_root__/src/containers/pricing/SignUpData.js': require('__react_static_root__/src/containers/pricing/SignUpData.js').default,
'__react_static_root__/src/containers/senderIdVerify/VerifyEmailFailed.js': require('__react_static_root__/src/containers/senderIdVerify/VerifyEmailFailed.js').default,
'__react_static_root__/src/containers/senderIdVerify/VerifyEmailSuccess.js': require('__react_static_root__/src/containers/senderIdVerify/VerifyEmailSuccess.js').default,
'__react_static_root__/src/containers/sms/SMS.js': require('__react_static_root__/src/containers/sms/SMS.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/EmailPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/EmailPage.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/MessengerPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/MessengerPage.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/SmsPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/SmsPage.js').default,
'__react_static_root__/src/containers/smsEmailWtsApMessngr/WhatsAppPage.js': require('__react_static_root__/src/containers/smsEmailWtsApMessngr/WhatsAppPage.js').default,
'__react_static_root__/src/containers/solutions/Church.js': require('__react_static_root__/src/containers/solutions/Church.js').default,
'__react_static_root__/src/containers/solutions/Education.js': require('__react_static_root__/src/containers/solutions/Education.js').default,
'__react_static_root__/src/containers/solutions/Industries.js': require('__react_static_root__/src/containers/solutions/Industries.js').default,
'__react_static_root__/src/containers/solutions/Influencer.js': require('__react_static_root__/src/containers/solutions/Influencer.js').default,
'__react_static_root__/src/containers/solutions/Wedding.js': require('__react_static_root__/src/containers/solutions/Wedding.js').default,
'__react_static_root__/src/containers/useCases/Saaraketha.js': require('__react_static_root__/src/containers/useCases/Saaraketha.js').default,
'__react_static_root__/src/containers/engage/Engage': require('__react_static_root__/src/containers/engage/Engage').default,
'__react_static_root__/src/containers/sms/SMS': require('__react_static_root__/src/containers/sms/SMS').default,
'__react_static_root__/src/containers/loyalty/Loyalty': require('__react_static_root__/src/containers/loyalty/Loyalty').default,
'__react_static_root__/src/containers/policies/Terms': require('__react_static_root__/src/containers/policies/Terms').default,
'__react_static_root__/src/containers/policies/Privacy': require('__react_static_root__/src/containers/policies/Privacy').default,
'__react_static_root__/src/containers/policies/Gdpr': require('__react_static_root__/src/containers/policies/Gdpr').default,
'__react_static_root__/src/containers/about/About': require('__react_static_root__/src/containers/about/About').default,
'__react_static_root__/src/containers/contactUs/ContactUs': require('__react_static_root__/src/containers/contactUs/ContactUs').default,
'__react_static_root__/src/containers/events/EventDetails': require('__react_static_root__/src/containers/events/EventDetails').default,
'__react_static_root__/src/containers/events/Events': require('__react_static_root__/src/containers/events/Events').default,
'__react_static_root__/src/containers/features/Feature': require('__react_static_root__/src/containers/features/Feature').default,
'__react_static_root__/src/containers/features/Features': require('__react_static_root__/src/containers/features/Features').default,
'__react_static_root__/src/containers/customers/CaseStudy': require('__react_static_root__/src/containers/customers/CaseStudy').default,
'__react_static_root__/src/containers/customers/Customers': require('__react_static_root__/src/containers/customers/Customers').default,
'__react_static_root__/src/containers/useCases/Saaraketha': require('__react_static_root__/src/containers/useCases/Saaraketha').default,
'__react_static_root__/src/containers/solutions/Industries': require('__react_static_root__/src/containers/solutions/Industries').default,
'__react_static_root__/src/containers/solutions/Wedding': require('__react_static_root__/src/containers/solutions/Wedding').default,
'__react_static_root__/src/containers/solutions/Church': require('__react_static_root__/src/containers/solutions/Church').default,
'__react_static_root__/src/containers/solutions/Influencer': require('__react_static_root__/src/containers/solutions/Influencer').default,
'__react_static_root__/src/containers/solutions/Education': require('__react_static_root__/src/containers/solutions/Education').default,
'__react_static_root__/src/containers/inbox/Inbox': require('__react_static_root__/src/containers/inbox/Inbox').default,
'__react_static_root__/src/containers/people/People': require('__react_static_root__/src/containers/people/People').default,
'__react_static_root__/src/containers/account/Login': require('__react_static_root__/src/containers/account/Login').default,
'__react_static_root__/src/containers/account/ForgotPassword': require('__react_static_root__/src/containers/account/ForgotPassword').default,
'__react_static_root__/src/containers/account/UpdatePassword': require('__react_static_root__/src/containers/account/UpdatePassword').default,
'__react_static_root__/src/containers/account/Signup': require('__react_static_root__/src/containers/account/Signup').default,
'__react_static_root__/src/containers/account/Launchpad': require('__react_static_root__/src/containers/account/Launchpad').default,
'__react_static_root__/src/containers/account/Logout': require('__react_static_root__/src/containers/account/Logout').default,
'__react_static_root__/src/containers/senderIdVerify/VerifyEmailSuccess': require('__react_static_root__/src/containers/senderIdVerify/VerifyEmailSuccess').default,
'__react_static_root__/src/containers/senderIdVerify/VerifyEmailFailed': require('__react_static_root__/src/containers/senderIdVerify/VerifyEmailFailed').default,
'__react_static_root__/src/containers/account/AccountCompletion': require('__react_static_root__/src/containers/account/AccountCompletion').default,
'__react_static_root__/src/containers/account/AccountVerificationSuccess': require('__react_static_root__/src/containers/account/AccountVerificationSuccess').default,
'__react_static_root__/src/containers/account/InvitationFailed': require('__react_static_root__/src/containers/account/InvitationFailed').default,
'__react_static_root__/src/containers/account/AccountVerificationFailed': require('__react_static_root__/src/containers/account/AccountVerificationFailed').default,
'__react_static_root__/src/containers/account/verify/AccountVerify': require('__react_static_root__/src/containers/account/verify/AccountVerify').default,
'__react_static_root__/src/containers/account/BookADemo': require('__react_static_root__/src/containers/account/BookADemo').default,
'__react_static_root__/src/containers/lite/Lite': require('__react_static_root__/src/containers/lite/Lite').default,
'__react_static_root__/src/containers/lite/pricing/LitePricingWrapper': require('__react_static_root__/src/containers/lite/pricing/LitePricingWrapper').default
}

export const notFoundTemplate = '__react_static_root__/src/containers/404'

