import React, { useState } from 'react'
import { useRouteData, Head } from 'react-static';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router'
import Modal from '../../components/modals/Modal';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


import './Solution.css';

const features = [
    { image: "best-way-to-increase-subscribers.svg", imgAlt: "best-way-to-increase-subscribers", imgTitle: "best-way-to-increase-subscribers", title: "Turn social media followers into a loyal subscriber base", description: "By texting a keyword or submitting an online form, build an even more loyal follower base beyond your social media platforms. " },
    { image: "influencer-marketing-performance.svg", imgAlt: "influencer-marketing-performance", imgTitle: "influencer-marketing-performance", title: "Build an engaging follower base ", description: "Share important posts by texting a short link. Don’t let the constantly changing algorithms stop your posts from reaching your followers." },
    { image: "sell-merch.svg", imgAlt: "sell-merch", imgTitle: "sell-merch", title: "Drive more product sales", description: "Text your website link or shop map link. Offer special coupons and attract more people to your SMS list." },
    { image: "how-to-get-paid-partnerships-on-instagram.svg", imgAlt: "how-to-get-paid-partnerships-on-instagram", imgTitle: "how-to-get-paid-partnerships-on-instagram", title: "Attract brand partnerships", description: "Send promotional campaigns for partnerships. Stand out by showing influencer marketing performance with campaign reports and click tracking. Attract more DTC brands and partnerships." },
    { image: "text-my-followers.svg", imgAlt: "text-my-followers", imgTitle: "text-my-followers", title: "Offer SMS campaigns for targeted groups", description: "Group your subscribers based on their interests and send targeted campaigns. It will be a plus point for your influencer strategy." },
    { image: "text-my-only-fans.svg", imgAlt: "text-my-only-fans", imgTitle: "text-my-only-fans", title: "Stand out among the best content creators and top influencers with OnlyFans", description: "Reach your OnlyFans audience easier and quicker by sending short links, promotions, coupon codes, and exclusive offers. Engage with them via two way texting." },
    { image: "increase-my-subscribers.svg", imgAlt: "increase-my-subscribers", imgTitle: "increase-my-subscribers", title: "Increase your social media followers", description: "Interest-based campaigns, short links, campaign success reports, click tracking… Simply put, who would not want to follow and subscribe to you when you have got this much to offer?" },
]

const tableData = [
    { td1: "SMS campaign management platform" },
    { td1: "Dedicated text number" },
    { td1: "Online subscription form facility" },
    { td1: "Campaign tracking reports" },
    { td1: "Click tracking reports for performance based influencer marketing" },
    { td1: "Dedicated web page " },
    { td1: "Web page traffic reports" },
    { td1: "Web visitor retargeting campaigns" },
    { td1: "Two-way texting for OnlyFans" },
    { td1: "Global SMS for international influencer marketing" },
]

const tableData2 = [
    { data: "Platform to manage influencer campaigns" },
    { data: " Multiple dedicated text numbers " },
    { data: "Online subscription form facility" },
    { data: "Influencer campaign tracking reports" },
    { data: "Click tracking reports for performance based influencer marketing" },
    { data: "Multiple admin access" },
    { data: "Influencer marketing site visitor retargeting campaigns" },
]

const Influencer = () => {
    const [isOpen, setIsOpen] = useState(false);


    const viewImage = () => {
        setIsOpen(!isOpen)
    }

    return (<>
        <Helmet>
            <title>Influencer Text Messaging - Text Your Fanbase | ShoutOUT</title>
            <meta name="description" content="Influencer text messaging to text followers & increase brand partnerships. Personalized bulk SMS, two-way messaging & link shortening for content creators & agencies." />
            <link rel="canonical" href="https://getshoutout.com/industries/influencer-text-messaging" />
            <meta name="keywords" content="increase my subscribers, best way to increase subscribers, influencer text messaging, influencer marketing performance, influencer strategy, top influencers with onlyfans, influencer management agency, influencer marketing agency, influencer pr companies, manage influencer campaigns, influencer campaign tracking, performance based influencer marketing, international influencer marketing" />


        </Helmet>

        <MainNavbar />



        <div className="container opacity-p9 h-50">
            {/* <div className="back-button">
                <Link to="/solutions" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
            </div> */}
            <div className="row header-space">
                <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-2 text-center text-sm-left">
                    <h2 className="font-weight-bold mt-4 mb-3">Influencer Text <span className="text-primary">Messaging</span> and Texting for Content Creators</h2>
                    <p>Build & engage with your fanbase via SMS. Text your followers, easily build a subscribers list, promote your brand, attract partnerships.</p>
                    <Link to="/signup" className="btn btn-outline-primary border-radius-2 mt-2">Get Started for Free</Link>
                    <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2 ml-3">Book a Demo</Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1 text-center">
                    <img className="img-fluid" src="/assets/images/solutions/influencer/influencer-text-messaging.svg" alt="influencer-text-messaging" title="influencer-text-messaging" />
                </div>
            </div>
            <div className="row">
                {/* <div className="col-12 text-center">
                    <h4 className="font-weight-bold">Why are Bulk SMS Alerts Perfect to Communicate with Students & Parents?</h4>
                </div> */}

                <div className="col-lg-10 col-md-10 col-sm-12 text-center mx-auto">
                    <h4 className="font-weight-bold text-center my-4">Why text your follower base as a social media influencer or content creator?</h4>
                </div>
                <div className="col-12">

                    <div className="row">
                        <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
                            {features.map(({ image, title, description, imgAlt, imgTitle }, index) => (
                                <div className="card shadow p-4 d-flex flex-lg-row align-items-center mb-3" key={index}>
                                    <img src={`/assets/images/solutions/influencer/${image}`} className="icon-width-2" alt={imgAlt} title={imgTitle} />
                                    <div className="card-body border-dark border-left">
                                        <h5 className="card-title">{title}</h5>
                                        <p className="card-text">{description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>



            </div>


            <div className="row">

                <div className="col-lg-10 col-md-10 col-sm-12 text-center mt-5 mx-auto">
                    <h4 className="font-weight-bold text-center my-4">What we offer</h4>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="shadow p-4">
                        <p className="font-weight-bold">
                            For Influencers and content creators
                        </p>
                        <ul className="ml-n4">
                            {tableData.map(({ td1 }, index) => (
                                <li className="mb-2" key={index}>{td1}</li>
                            ))}
                        </ul>
                    </div>
                    {/* <table className="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">For Influencers and content creators</th>
                                <th scope="col">For influencer management agency / influencer marketing agency / influencer PR companies</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map(({td1, td2}, index) => (
                                <tr key={index}>
                                    <td>{td1}</td>
                                    <td>{td2}</td>
                                </tr>
                            ))}
                           
                        </tbody>
                    </table> */}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="shadow p-4">
                        <p className="font-weight-bold">
                            For influencer management agency / influencer marketing agency / influencer PR companies
                        </p>
                        <ul className="ml-n4">
                            {tableData2.map(({ data }, index) => (
                                <li className="mb-2" key={index}>{data}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-lg-10 col-md-10 col-sm-12 text-center mt-5 mx-auto">
                    <h4 className="font-weight-bold text-center my-4">SMS platform features for content creators, influencers and global influencer marketing agencies</h4>
                </div>
                <div className="col-12">
                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-2">
                            <img className="img-fluid" src="/assets/images/solutions/influencer/manage-influencer-campaigns.jpg" alt="manage-influencer-campaigns" title="manage-influencer-campaigns" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2 order-sm-1">
                            <p className="font-weight-bold mt-auto">Group your subscribers for better management</p>
                            <p>
                                Create groups based on social media platforms, interests, paid and free subscriptions, and other general factors such as age, location, marital status, loyalty, gender etc.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/influencer/performance-based-influencer-marketing.jpg" alt="performance-based-influencer-marketing" title="performance-based-influencer-marketing" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2">
                            <p className="font-weight-bold">Easily send targeted campaigns</p>
                            <p>
                                Easily send separate text message campaigns for users of different social media platforms, OnlyFans group, paid subscribers, free subscribers, location-based groups etc.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-2">
                            <img className="img-fluid" src="/assets/images/solutions/influencer/international-influencer-marketing.jpg" alt="international-influencer-marketing" title="international-influencer-marketing" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2 order-sm-1">
                            <p className="font-weight-bold mt-auto">Send texts to multiple groups in one campaign</p>
                            <p>
                                Select multiple segments (Paid subscribers and free subscribers etc.) in one campaign. Easily send mass general updates. Eliminate the need to send several campaigns for a single text message.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/influencer/personalized-customer-messaging.jpg" alt="personalized-customer-messaging" title="personalized-customer-messaging" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-2 order-2">
                            <p className="font-weight-bold mt-auto">Personalize and customize SMS campaigns for each individual subscriber </p>
                            <p>
                                Automatically include each subscriber’s name in the SMS content. Customize more by automatically including any required individual subscriber-specific data using custom attributes.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-2 order-1">
                            <img className="img-fluid w-100" src="/assets/images/solutions/influencer/sms-automation.jpg" alt="sms-automation" title="sms-automation" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-1 order-2">
                            <p className="font-weight-bold mt-auto">Automate and schedule SMS campaigns</p>
                            <p>
                                Send the text campaigns at the exact time and date required by scheduling them. Easily align SMS campaigns with related brand activities.
                            </p>
                        </div>
                    </div>


                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/influencer/influencer-campaign-tracking.jpg" alt="influencer-campaign-tracking" title="influencer-campaign-tracking" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-2 order-2">
                            <p className="font-weight-bold mt-auto">Use campaign reports and click tracking reports to vouch for campaign performance</p>
                            <p>
                                View reports on campaign deliveries, opens, number of link clicks and who exactly clicked the links. Increase your brand value to attract more partnerships and sponsorships using campaign success rate reports with details.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row mt-5 justify-content-center">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold">More on this</h4>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2022/02/influencer-text-messaging-1-2048x870-1.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">Influencers, Celebrities, Thought Leaders – Are You Texting Your Followers?</p>
                            <small>How to send messages to students? This, in fact, is a frequent and common question that schools, educational institutes, universities, private tutors, all face…</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/?p=662"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div>

            </div>

            <CallToAction title="Start texting your SMS subscriber list" review="true" />

            <MainFooter />


        </div>




    </>
    )
}
export default Influencer;