import React, { useState, useEffect, useCallback } from 'react';
import PricingSMS from './../../pricing/PricingSMS';
import Pricing from './../../pricing/Pricing';
import { useRouteData, Head } from 'react-static';
import MainNavbar from './../../../components/navbars/MainNavbar';
import MainFooter from './../../../components/footers/MainFooter';
import CallToAction from './../../../components/calltoaction/CallToAction';
import Services from './../../../services/Services';

const LitePricingWrapper = () => {
    const { smsPackages, pricingPlans } = useRouteData();
    const [pricing, setPricing] = useState([]);
    


    const getPricingPlans = async () => {
        const pricingData = await Services.getPlans();        
        setPricing(pricingData.data);
    }

    useEffect(() => {
        getPricingPlans();
    }, [])


    const plansPlatform = pricing.filter(plan => plan.category === "SMS");
    const onDemandSMS = plansPlatform.filter((item) => item.metaData.type === "ondemand");
    const subscriptionSMS = plansPlatform.filter((item) => item.metaData.type === "subscription");
    return (
        <div>
            <Head>
                <meta charSet="UTF-8" />
                <meta name="keywords" content="ShoutOUT,pricing, plans" />
                <meta property="og:title" content="ShoutOUT Pricing and Plans" />
                <meta property="og:site_name" content="ShoutOUT Pricing and Plans" />
                <meta property="og:description" content="Pick a Plan. Grow Your Business Faster with ShoutOUT." />
                <meta name="description" content="Best rates for SMS API & OTP service API around the world | SMS Packages | Supports 225 countries | Easy integration" />
                <title>ShoutOUT API | Pricing and Plans</title>
            </Head>
            <MainNavbar />
            <>

{/* 
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <div className="subheading text-center mt-5">
                            Pick a Plan. Grow Your Business Faster with ShoutOUT.
                            <div className="h6">
                                Invest, watch your business boom
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <PricingSMS smsPackages={smsPackages} pricingPlans={pricing} onDemandSMS={onDemandSMS} subscriptionSMS={subscriptionSMS} /> */}
                <Pricing/>
            </>
            <CallToAction />
            <MainFooter />
        </div>

    );

};

export default LitePricingWrapper;