import React from 'react';
import { Link } from '@reach/router'

import G2 from './../../images/review-g2.png';
import Advice from './../../images/review-software-advice.png';
import Gartner from './../../images/review-gartner.png';
import Capterra from './../../images/review-capterra.png';
import Stars from './../../images/stars.svg';

const CallToAction = ({ title, pricing, onDemo, review }) => {

    return (
        <div className="container">
            {!review ?
                <div className="row justify-content-center">
                    <div className="col-md-8 card border-0 mt-4 p-4">
                        <div className="row justify-content-center text-center">
                            <div className="review-box-scale">
                                <ul className="list-unstyled list-inline text-center mb-2">
                                    <li className="list-inline-item align-middle"><p className="font-weight-bold mb-0">Check out more reviews</p></li>
                                    <li className="list-inline-item align-middle"><img className="mb-1" alt="ShoutOUT Reviews" src={Stars} /></li>
                                </ul>
                                <div className='my-4'>
                                    <a href="https://www.producthunt.com/posts/shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff?utm_source=badge-review&utm_medium=badge&utm_souce=badge-shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff#discussion-body" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=332406&theme=light" alt="ShoutOUT - Send personalized bulk SMS to customers | Product Hunt" className="ph-review" width="250" height="54" /></a>
                                </div>
                                <ul className="list-unstyled list-inline text-center mb-2">
                                    <li className="list-inline-item move-up m-2">
                                        <a href="https://www.capterra.com/p/190142/ShoutOUT" className="text-dark bg-white btn-round py-2 px-3 shadow-sm">
                                            <img className="" src={Capterra} alt="ShoutOUT on Capterra" />
                                            Capterra
                                        </a>
                                    </li>
                                    <li className="list-inline-item move-up m-2">
                                        <a href="https://www.softwareadvice.com/sms-marketing/shoutout-profile" className="text-dark bg-white btn-round py-2 px-3 shadow-sm">
                                            <img className="" src={Advice} alt="ShoutOUT on Software Advice" />
                                            Software Advice
                                        </a>
                                    </li>
                                    <li className="list-inline-item move-up m-2">
                                        <a href="https://www.g2.com/products/shoutout/reviews" className="text-dark bg-white btn-round py-2 px-3 shadow-sm">
                                            <img className="" src={G2} alt="ShoutOUT on G2" />
                                            G2 Crowd
                                        </a>
                                    </li>
                                    <li className="list-inline-item move-up m-2">
                                        <a href="https://www.gartner.com/reviews/market/customer-communications-management-software/vendor/shoutout-labs/product/shoutout" className="text-dark bg-white btn-round py-2 px-3 shadow-sm">
                                            <img className="" src={Gartner} alt="ShoutOUT on Gartner" />
                                            Gartner
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            <div className="row justify-content-center cta-bg p-4 p-sm-5 mt-4">
                <div className="col-lg-6 col-sm-12 text-sm-left text-center my-auto">
                    <h4 className="font-weight-bold mb-sm-0 mb-2">{title || "Start Engaging with Your Audiences"}</h4>
                </div>
                <div className="col-lg-2 col-sm-12 d-block d-sm-none text-sm-right text-center my-auto">

                </div>
                {onDemo ?
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-end">
                        <Link to="/signup" id="body-link-getStarted" className="btn btn-primary text-white btn-round border-2 mr-3">Try for Free</Link>
                    </div>
                    : <div className="col-lg-4 col-sm-12 d-sm-block d-flex justify-content-center">
                        <Link to="/signup" id="body-link-getStarted" className="btn btn-primary text-white btn-round border-2 mr-3">Try for Free</Link>
                        {!pricing ? <Link to="/bookaDemo" className="btn btn-outline-secondary btn-round" id="btn-book-a-demo-home-pg">Book a Demo</Link> :
                            <Link to="/pricing" id="body-link-pricing" className="btn btn-outline-secondary btn-round">Check Pricing</Link>}
                    </div>}
            </div>
        </div>

    )
}

export default CallToAction;