import React, { useEffect } from 'react';
import { Root, Routes } from 'react-static';
import { hot } from 'react-hot-loader';
import { Match } from '@reach/router';
import './lib/bootstrap/css/bootstrap.min.css';

//import Routes from 'react-static-routes';
//import registerServiceWorker from './registerServiceWorker';

import './app.css';

const Loading = () => {
    return <div className="container h-100">
        <div className="row  text-center h-100">
            <div className="col my-auto">
                <img src="/assets/images/temp/loading.gif" alt="Loading" />
            </div>
        </div>
    </div>
}

const ScrollRestoration = () => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }

    });
    return null;
};
const App = () => {

    return (
        <Root className="h-100">

            <React.Suspense fallback={<Loading />}>
                <Match path="">{props => <ScrollRestoration {...props} />}</Match>
                <Routes />

            </React.Suspense>

        </Root>
    );
}

export default hot(module)(App)
// if (typeof document !== 'undefined') {
//     registerServiceWorker();
// }
