import React, { useState, useEffect, useCallback } from 'react';
import LogoBlue from './../../images/logo.svg';
import { Link } from '@reach/router'
import Constants from './../../../Constants';
import { useSiteData } from 'react-static';
import { navigate } from '@reach/router';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainNavbar.css';


const MainNavbar = () => {


    const [isScrolled, setIsScrolled] = useState(false);
    const [windowWidth, setWindowWidth] = useState();
    const [isExpand, setIsExpand] = useState(false);
    const [titleNo, setTitleNo] = useState(1);
    const [isDropdownShowResources, setIsDropdownShowResources] = useState(false);
    const [isDropdownShowSolutions, setIsDropdownShowSolutions] = useState(false);
    const [isDropdownShowProducts, setIsDropdownShowProducts] = useState(false);
    const { BUILD_PRODUCT } = useSiteData();

    const toggleCollapse = () => {

        setIsExpand(!isExpand);
    }

    const toggleDropdownResources = () => {

        setIsDropdownShowResources(!isDropdownShowResources);
    }
    const toggleDropdownSolutions = () => {
        setIsDropdownShowSolutions(!isDropdownShowSolutions);
    }

    const toggleDropdownProducts = () => {
        setIsDropdownShowProducts(!isDropdownShowProducts);
    }


    const navbarScroll = useCallback(() => {
        if (typeof window !== 'undefined') {
            const isScrolledNow = window.scrollY > 1;

            setIsScrolled(isScrolledNow);

        }
    }, [isScrolled, setIsScrolled])

    const pageResize = useCallback(() => {
        if (typeof window !== 'undefined') {
            const windowWidth = window.outerWidth;
            setWindowWidth(windowWidth);

        }
    }, [windowWidth, setWindowWidth])

    useEffect(() => {
        if (typeof document !== undefined) {
            document.addEventListener('scroll', navbarScroll);

            return () => {
                document.removeEventListener('scroll', navbarScroll);
            }
        }
    }, [])


    useEffect(() => {
        if (typeof document !== undefined) {
            document.addEventListener('scroll', pageResize);

            return () => {
                document.removeEventListener('scroll', pageResize);
            }
        }
    }, [])

    useEffect(() => {
        if (isScrolled) {
            const number = Math.floor(Math.random() * 3) + 1
            setTitleNo(number)
        }
    }, [isScrolled])

    const navigateToLogin = useCallback(() => {
        console.debug("login")
        switch (BUILD_PRODUCT) {
            case Constants.LITE_PRODUCT: {
                navigate('/login?referrer=' + Constants.LITE_PRODUCT_URL);
                break;
            }

            default: {
                navigate('/login');
            }
        }
    }, [BUILD_PRODUCT, navigate])


    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-light fixed-top ${isScrolled ? "shadow-sm navbar-scrolled" : ""}`}>
                <div className="container">
                    <Link to="/" className="navbar-brand mr-lg-5">
                        <img className="logo" src={LogoBlue} alt="ShoutOUT" />
                    </Link>
                    <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleCollapse}>
                        <span className=""><img className="img-fluid w-75" src="/assets/images/temp/menu.svg" alt="menu" /></span>
                    </button>
                    <div className={`navbar-collapse collapse ${isExpand ? 'show' : ''}`} id="navbar_global">
                        <div className="navbar-collapse-header">
                            <div className="row">
                                <div className="col-6 collapse-brand">
                                    <Link to="/">
                                        <img src={LogoBlue} alt="ShoutOUT" className="logo" />
                                    </Link>
                                </div>
                                <div className="col-6 collapse-close">
                                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleCollapse}>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <ul className="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">

                            {/* {
                                BUILD_PRODUCT === Constants.ENGAGE_PRODUCT &&
                                <li className="nav-item">
                                    <Link to="/campaign-management" className="nav-link" onClick={toggleCollapse}>

                                        <i className="ni ni-collection d-lg-none"></i>
                                        <span className="nav-link-inner--text">Engage</span>
                                    </Link>
                                    <div className="new-link-badge">Temp</div>
                                </li>
                            } */}
                            {BUILD_PRODUCT === Constants.ENGAGE_PRODUCT &&

                                <li className="nav-item dropdown">
                                    {/* href={windowWidth < 992 ? "#" : "/solutions"} */}
                                    <a href="#" className="nav-link" onClick={toggleDropdownProducts} data-toggle="dropdown" role="button">
                                        <i className="ni ni-collection d-lg-none"></i>
                                        <span className="nav-link-inner--text position-relative" id="caseStudies-link">
                                            Products
                                        </span>
                                    </a>
                                    {/* <div className="dropdown-menu"> */}


                                    {/* <div className={`dropdown-menu dropdown-menu-xxl ${isDropdownShowProducts ? 'show' : ''}`}>
                                        <div className="row">
                                            <div className="col-lg-4 flex-row">
                                                <a href="#">Link 1</a>
                                                <a href="#">Link 2</a>
                                                <a href="#">Link 3</a>
                                            </div>
                                            <div className="col-lg-4">
                                                <a href="#">Link 1</a>
                                                <a href="#">Link 2</a>
                                                <a href="#">Link 3</a>
                                            </div>
                                            <div className="col-lg-4">
                                                <a href="#">Link 1</a>
                                                <a href="#">Link 2</a>
                                                <a href="#">Link 3</a>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className={`dropdown-menu dropdown-menu-lg ${isDropdownShowProducts ? 'show' : ''}`}>
                                        <Link className="dropdown-item my-1" to="/campaign-management">
                                            <div className="d-flex justify-content-start align-items-center my-2">
                                                <img className="w-12 mr-2" src="/assets/images/main-icons/engage.svg" />
                                                <span>Messaging Platform</span>
                                            </div>
                                        </Link>
                                        <Link className="dropdown-item my-1" to="/text-messaging-service">
                                            <div className="d-flex justify-content-start align-items-center my-2">
                                                {/* <img className="w-12 mr-2" src="/assets/images/main-icons/engage.svg" /> */}
                                                <span>Text Messaging</span>
                                            </div>
                                        </Link>
                                        {/*  <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item my-1" to="/omnichannel-messaging-platform">
                                            <div className="d-flex justify-content-start align-items-center my-2">
                                                <img className="w-12 mr-2" src="/assets/images/main-icons/inbox.svg" />
                                                <span>Omnichannel Inbox</span>
                                            </div>
                                        </Link> */}
                                        <div className="dropdown-divider"></div>
                                        <a href="https://lite.getshoutout.com" rel="noopener noreferrer" target="_blank" className="dropdown-item my-1">
                                            <div className="d-flex justify-content-start align-items-center my-2">
                                                <img className="w-12 mr-2" src="/assets/images/main-icons/api.svg" />  <span> SMS API</span>
                                            </div>
                                        </a>
                                    </div>

                                </li>
                            }

                            {/* {
                                BUILD_PRODUCT === Constants.ENGAGE_PRODUCT &&
                                <li className="nav-item">
                                    <a href="https://lite.getshoutout.com" rel="noopener noreferrer" target="_blank" className="nav-link" onClick={toggleCollapse}>
                                        SMS API
                                    </a>
                                </li>
                            } */}
                            {
                                BUILD_PRODUCT === Constants.LITE_PRODUCT &&
                                <li className="nav-item">
                                    <a href="https://getshoutout.com" rel="noopener noreferrer" target="_blank" className="nav-link" onClick={toggleCollapse}>
                                        Platform
                                    </a>
                                </li>

                            }
                            {
                                BUILD_PRODUCT === Constants.LITE_PRODUCT ?
                                    <li className="nav-item">
                                        <a href="https://developers.getshoutout.com" rel="noopener noreferrer" target="_blank" id="resources-dropdown-documentation" className="nav-link" onClick={toggleCollapse}>API Docs</a>
                                    </li>
                                    :
                                    <>
                                        <li className="nav-item">
                                            <Link to="/customers" className="nav-link" onClick={toggleCollapse}>

                                                <i className="ni ni-collection d-lg-none"></i>
                                                <span className="nav-link-inner--text" id="caseStudies-link">Case Studies</span>

                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                        <Link to="/solutions" className="nav-link" onClick={toggleCollapse}>

                                            <i className="ni ni-collection d-lg-none"></i>
                                            <span className="nav-link-inner--text position-relative" id="caseStudies-link">Solutions <div className="new-link-badge">New</div></span>

                                        </Link>
                                    </li> */}
                                    </>
                            }

                            {BUILD_PRODUCT === Constants.ENGAGE_PRODUCT &&

                                <li className="nav-item dropdown">
                                    {/* href={windowWidth < 992 ? "#" : "/solutions"} */}
                                    <a href="#" className="nav-link" onClick={toggleDropdownSolutions} data-toggle="dropdown" role="button">
                                        <i className="ni ni-collection d-lg-none"></i>
                                        <span className="nav-link-inner--text position-relative" id="caseStudies-link">
                                            Solutions
                                            {/* <div className="new-link-badge">New</div> */}
                                        </span>
                                    </a>
                                    {/* <div className="dropdown-menu"> */}



                                    <div className={`dropdown-menu dropdown-menu-xxl ${isDropdownShowSolutions ? 'show' : ''}`}>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <Link className="dropdown-item d-flex justify-content-between" to="/industries">
                                                    <span className="">Industries</span>
                                                    {/* <img src="assets/images/chevron-right.svg" /> */}
                                                </Link>
                                                <hr className="border-bottom-3" />
                                                <Link className="dropdown-item" to="/industries/wedding-sms-notifications">
                                                    <span>SMS for Weddings</span>
                                                </Link>
                                                <Link className="dropdown-item" to="/industries/text-messaging-for-schools">
                                                    <span>SMS for Education</span>
                                                </Link>
                                                <Link className="dropdown-item" to="/industries/group-text-messaging-for-churches">
                                                    <span>SMS for Churches</span>
                                                </Link>
                                                <Link className="dropdown-item" to="/industries/text-messaging-for-influencers">
                                                    <span>SMS for Influencers</span>
                                                </Link>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <Link className="dropdown-item d-flex justify-content-between" to="/solutions">
                                                    <span className="">Use Cases</span>
                                                    {/* <img src="assets/images/chevron-right.svg" /> */}
                                                </Link>
                                                <div className="dropdown-divider"></div>
                                                {/* <hr className="border-bottom-3" /> */}
                                                {/* <Link className="dropdown-item" to="/solutions/wedding-sms-notifications">
                                                    <span>SMS for Weddings</span>
                                                </Link>
                                                <Link className="dropdown-item" to="/solutions/text-messaging-for-schools">
                                                    <span>SMS for Education</span>
                                                </Link>
                                                <Link className="dropdown-item" to="solutions/group-text-messaging-for-churches">
                                                    <span>SMS for Churches</span>
                                                </Link>
                                                <Link className="dropdown-item" to="solutions/text-messaging-for-influencers">
                                                    <span>SMS for Influencers</span>
                                                </Link> */}
                                            </div>
                                        </div>

                                        {/* */}

                                    </div>

                                </li>
                            }

                            <li className="nav-item">
                                <Link to="/pricing" className="nav-link" onClick={toggleCollapse}>

                                    <i className="ni ni-collection d-lg-none"></i>
                                    <span className="nav-link-inner--text">Pricing</span>

                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link" onClick={toggleDropdownResources} data-toggle="dropdown" role="button">
                                    <i className="ni ni-collection d-lg-none"></i>
                                    <span className="nav-link-inner--text" id="navBarResources">Resources</span>
                                </a>
                                {/* <div className="dropdown-menu"> */}

                                <div className={`dropdown-menu dropdown-menu ${isDropdownShowResources ? 'show' : ''}`}>
                                    <a href="https://blog.getshoutout.com" rel="noopener noreferrer" target="_blank" id="resources-dropdown-blog" className="dropdown-item" onClick={toggleCollapse}>Blog</a>
                                    <a href="https://support.getshoutout.com" rel="noopener noreferrer" target="_blank" id="resources-dropdown-blog" className="dropdown-item" onClick={toggleCollapse}>Knowledge Base</a>
                                    <Link className="dropdown-item" to="/events">
                                        <span>Events</span>
                                    </Link>
                                    <Link className="dropdown-item" to="/features">
                                        <span>Features</span>
                                    </Link>
                                    {
                                        BUILD_PRODUCT === Constants.LITE_PRODUCT ?
                                            <>
                                                <Link to="/customers" className="dropdown-item" onClick={toggleCollapse}>

                                                    <i className="ni ni-collection d-lg-none"></i>
                                                    <span className="nav-link-inner--text" id="caseStudies-link">Case Studies</span>

                                                </Link>

                                                <Link className="dropdown-item" to="/gdpr">
                                                    <span>GDPR Compliance</span>
                                                </Link>
                                            </>
                                            :

                                            <a href="https://developers.getshoutout.com" rel="noopener noreferrer" target="_blank" id="resources-dropdown-documentation" className="dropdown-item" onClick={toggleCollapse}>Developer Docs</a>

                                    }
                                </div>

                            </li>

                            {/* <li className="nav-item dropdown">
                                <a href="#" className="nav-link" onClick={this._toggleDropdownCompany} data-toggle="dropdown" role="button">
                                    <i className="ni ni-collection d-lg-none"></i>
                                    <span className="nav-link-inner--text">Company</span>
                                </a>
                                {/* <div className="dropdown-menu"> */}
                            {/*}
                                <div className={`dropdown-menu dropdown-menu ${this.state.isDropdownShowCompany ? 'show' : ''}`}>

                                    <Link to="/about-us" className="dropdown-item">About Us</Link>
                                    <Link to="/contactus" className="dropdown-item">Contact Us</Link>
                                    <Link to="/privacy" className="dropdown-item">Privacy and Policy</Link>
                                    <Link to="/terms" className="dropdown-item">Terms of use</Link>
                                    <Link to="/gdpr" className="dropdown-item">GDPR Compliance</Link>
                                </div>
                            </li> */}

                            <li className="nav-item d-lg-none no-decoration" onClick={navigateToLogin}>

                                Login

                            </li>
                            <li className="nav-item d-lg-none">
                                <Link to="/signup" className="nav-link">
                                    Try for Free
                                </Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav align-items-lg-center">
                            <li className="nav-item d-none d-lg-block ml-lg-4 btn btn-link no-decoration" onClick={navigateToLogin}>

                                Login

                            </li>
                            <li className="nav-item d-none d-lg-block ml-lg-4">
                                <Link to="/signup" className="btn btn-outline-primary text-primary btn-round btn-icon">
                                    Try for Free
                                </Link>
                            </li>

                        </ul>

                    </div>
                </div>
            </nav>


            {/* Hello Bar here  */}
            <div className="d-none d-md-block d-lg-block">
                <div className={`w-100 p-3 hello-bar ${isScrolled ? "d-flex justify-content-center align-content-center" : "d-none"}`}>
                    <p className="mb-0  mr-2 hello-bar-text">{titleNo === 1 ? "Sign up now and get 20 FREE SMS credits & 100 FREE email credits" : titleNo === 2 ? "See how text campaigns can increase your conversion rates, in 5 minutes" : "Try us for FREE! No credit card required"}</p>
                    {/* <img className="ph-image" src="/assets/images/ph-cat.png"/>
<p className="mb-0  mr-2 hello-bar-text product-hunt ">ShoutOUT is on Product Hunt. Join the discussion {'&'} give us feedback</p> */}

                    {/* {titleNo === 1 ? <Link to='/signup?utm_source=website&utm_medium=hello+bar&utm_campaign=Sign+up' className="btn btn-primary btn-sm">Try for FREE</Link> 
                    : titleNo === 2 ?  <Link to='/bookaDemo?utm_source=website&utm_medium=hello+bar&utm_campaign=Book+a+demo' className="btn btn-outline-primary btn-sm">Book a Demo</Link> 
                    : <Link to='/signup?utm_source=website&utm_medium=hello+bar&utm_campaign=Try+us' className="btn btn-primary btn-sm">Try for FREE</Link>} */}

                    {titleNo === 1 ? <Link to='/signup' className="btn btn-primary btn-sm">Try for FREE</Link>
                        : titleNo === 2 ? <Link to='/bookaDemo' className="btn btn-outline-primary btn-sm">Book a Demo</Link>
                            : <Link to='/signup' className="btn btn-primary btn-sm">Try for FREE</Link>}
                    {/* <Link className="btn btn-dark btn-sm" to="https://www.producthunt.com/posts/shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff">Visit Product Hunt</Link> */}
                </div>
            </div>
        </>


    )

}

export default MainNavbar;