import React from 'react';
import { Head } from 'react-static';
import './SampleCode.css';
const PHPSampleCode = () => {
  return (

    <div className="card p-4 bg-dark text-white">
      <p className="text-comment">// Installation</p>
      <p>composer <span className="text-orange">require</span> shoutoutlabs/shoutout-sdk</p>
      <p className="text-comment">// Usage</p>
      <p> <span className="text-orange">require __DIR__ </span>.<span className="text-function">'/vendor/autoload'</span>;</p>
      <p> <span className="text-orange">use</span><span className="text-string">Swagger</span>\<span className="text-string">Client</span>\<span className="text-string">Client</span>;</p>
      <p> $apiKey = <span className="text-function">'xxxxx.xx.xx.xxxx'</span>;</p>
      <p> $client = <span className="text-orange">new</span> ShoutoutClient ($apiKey,<span className="text-orange">true</span>, <span className="text-orange">false</span>);</p>
      <p className="text-comment">// (apikey, debugmode, ssl)</p>
      <p className="text-comment">// Set message</p>
      <p> $message = <span className="text-orange">array</span> (</p>
      <p><span className="text-function pl-2">'source'</span> {"=>"} <span className="text-function">'ShoutDEMO'</span>,</p>
      <p><span className="text-function pl-2">'destinations'</span> {"=>"} [<span className="text-string">'94771234567'</span>],</p>
      <p><span className="text-function pl-2">'content'</span> {"=>"} <span className="text-orange">array</span> (</p>
      <p><span className="text-function pl-4">'sms'</span> {"=>"} <span className="text-function">'Sent via ShoutOUT Lite'</span></p>
      <p className="pl-2">),</p>
      <p><span className="text-function pl-2">'transports'</span> {"=>"} [<span className="text-string">'SMS'</span>]</p>
      <p>);</p>
      <p><span className="text-orange">try </span>{"{"}</p>
      <p className="pl-2">  $result = $client {"->"} sendMessage($message);</p>
      <p className="pl-2">print_r($result);</p>
      <p>{"}"}<span className="text-orange"> catch </span>(<span className="text-orange">Exception</span> $e) {"{"}</p>
      <p>  <span className="text-orange pl-2">echo</span><span className="text-string"> 'Exception when sending message:' </span>, $e {"->"} getMessage(), PHP_EOL;</p>
      <p>  {"}"}</p>

    </div>


  )
}
export default PHPSampleCode;