import React from 'react';
import { Head } from 'react-static';
import AccountNavbar from './../../components/navbars/AccountNavbar';
import { Link, navigate } from '@reach/router';
import Services from "./../../services/Services";
import Modal from './../../components/modals/Modal';
import './Account.css';

const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
class UpdatePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = { oobCode: null, password: "", confirmPassword: "", showAlert: false, isSuccess: false, isSending: false, errorMsg: "" };
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            const oobCode = getUrlParameter("oobCode");

            if (oobCode) {
                this.setState({ oobCode });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { password, confirmPassword, oobCode } = this.state;
        if (!oobCode) {
            this.setState({ isSuccess: false, showAlert: true, errorMsg: "Invalid link or link is expired." });

            return;
        }
        if (password !== confirmPassword) {

            this.setState({ isSuccess: false, showAlert: true, errorMsg: "Password does not match. Please check." });
            return;
        }

        this.setState({ isSending: true });

        Services.updatePassword(password, oobCode).then(res => {
            this.setState({ isSending: false, isSuccess: true, showAlert: true });

        }, err => {
            this.setState({ isSending: false, isSuccess: false, showAlert: true, errorMsg: "Couldn't update the password. Please retry." });
        });


    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    closeAlert = () => {
        this.setState({ showAlert: false });
    }
    redirectToLogin = () => {
        navigate("/login");
        this.closeAlert();
    }
    render() {
        const { password, confirmPassword, showAlert, isSending, isSuccess, errorMsg } = this.state;
        return (
            <>
            <Head>
                <title>ShoutOUT | Update Password</title>
            </Head>
                <AccountNavbar />
                <div className="container login-block h-100 pt-70">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-4 col-sm-12 input-sec order-2 my-auto">
                            <h3 className="text-center font-weight-bold">Enter New Password</h3>
                            <form className="login-form" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="password" className="">Password</label>
                                    <input type="password" name="password" className="form-control" placeholder="xxxxxx" onChange={this.onChange} value={password} pattern="^[a-zA-Z0-9@$#.,?%!^*]{3,25}$" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="passwordConfirm" className="">Password Again</label>
                                    <input type="password" name="confirmPassword" className="form-control" placeholder="xxxxxx" onChange={this.onChange} value={confirmPassword} pattern="^[a-zA-Z0-9@$#.,?%!^*]{3,25}$" required />
                                </div>
                                <div className="form-check pl-0">
                                    <label className="form-check-label mt-2">
                                        <small className="text-dark">Remember password?<Link to="/login"> Try again</Link></small>
                                    </label>
                                    <button type="submit" className="btn btn-primary btn-round float-right" disabled={isSending}>&nbsp;&nbsp;&nbsp;{isSending ? "Sending..." : "Submit"}&nbsp;&nbsp;&nbsp;</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <Modal visible={showAlert} onClickBackdrop={this._closeAlert}>
                    {isSuccess ?

                        <React.Fragment>
                            <div className="modal-header">

                                <h5 className="modal-title text-success">Success!</h5>
                            </div>
                            <div className="modal-body">
                                <p className="text-secondary">Password updated. Please login with new password.</p>
                            </div>
                        </React.Fragment> :
                        <React.Fragment>
                            <div className="modal-header">

                                <h5 className="modal-title text-danger">Failed!</h5>
                            </div>
                            <div className="modal-body">
                                <p className="text-secondary">{errorMsg}</p>
                            </div>
                        </React.Fragment>
                    }

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={isSuccess ? this.redirectToLogin : this.closeAlert}>
                            Close
                         </button>
                    </div>
                </Modal>
            </>
        );
    }
}
export default UpdatePassword;