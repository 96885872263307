const submitGTMEvent = (event) => {
    try {
        if(typeof window !== 'undefined'){
        const dataLayer = window.dataLayer || [];
        dataLayer.push(
            event
        );
        }
    } catch (e) {

    }
}

export { submitGTMEvent };