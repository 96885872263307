import React, { useCallback, useState } from 'react';
// import AccountNavbar from './../../components/navbars/AccountNavbar';
import MainNavbar from '../../components/navbars/MainNavbar';
import CallToAction from '../../components/calltoaction/CallToAction';
import MainFooter from '../../components/footers/MainFooter';
import { Head } from 'react-static';
import './Account.css';
import './Signup.css';


const BookADemo = () => {

    const [submitted, setSubmitted] = useState(false);

    const onSubmitForm = useCallback((e) => {
        e.preventDefault();
        setSubmitted(true)

    }, [submitted, setSubmitted])

    return (<div>
        <Head>
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
            <script src="https://webforms.pipedrive.com/f/loader"></script>
            <script src="//embed.typeform.com/next/embed.js"></script>
            <title>ShoutOUT | Book a Demo</title>
        </Head>
        <MainNavbar />

        <div className="container pt-70">
            <div className="demo-bg-container">
                <svg viewBox="0 0 1920 2000" xmlns="http://www.w3.org/2000/svg" className="demo-bg">
                    <g>
                        <path id="path-1" fill="rgba(255,255,255,1)" stroke="rgba(234,234,234,1)" d="M850,404.89008894497465C1019.3827434837298,373.9801435421617,1221.7233353703389,333.81604426803426,1337.7418316922062,461.0388685807052C1457.4484195122836,592.3059567961258,1411.2514023483184,796.1355474539366,1362.6044572913256,966.9986223200493C1317.909746480711,1123.9802315538154,1236.591599635133,1276.8585745678572,1086.9259138272403,1341.9817294647178C942.7406358215641,1404.7202265539108,768.4470664075394,1374.7741292122953,642.8318665313277,1280.1890618356517C537.9081828910851,1201.1841826785285,549.4795833323407,1052.8942963180132,530.415101296006,922.9431676428238C514.2617076066958,812.835180556691,485.48174220750417,700.6159854701862,543.9105457681692,605.9018056325765C611.3364269909806,496.6031951755599,723.6634651520264,427.9447125401258,850,404.89008894497465 " ></path>
                        <path id="path-2" fill="rgba(255,255,255,1)" stroke="rgba(234,234,234,1)" d="M850,404.89008894497465C1019.3827434837298,373.9801435421617,1221.7233353703389,333.81604426803426,1337.7418316922062,461.0388685807052C1457.4484195122836,592.3059567961258,1411.2514023483184,796.1355474539366,1362.6044572913256,966.9986223200493C1317.909746480711,1123.9802315538154,1236.591599635133,1276.8585745678572,1086.9259138272403,1341.9817294647178C942.7406358215641,1404.7202265539108,768.4470664075394,1374.7741292122953,642.8318665313277,1280.1890618356517C537.9081828910851,1201.1841826785285,549.4795833323407,1052.8942963180132,530.415101296006,922.9431676428238C514.2617076066958,812.835180556691,485.48174220750417,700.6159854701862,543.9105457681692,605.9018056325765C611.3364269909806,496.6031951755599,723.6634651520264,427.9447125401258,850,404.89008894497465 "></path>
                        <path id="path-3" fill="rgba(0, 174, 255,0.13)" stroke="rgba(255,255,255,1)" d="M850,404.89008894497465C1019.3827434837298,373.9801435421617,1221.7233353703389,333.81604426803426,1337.7418316922062,461.0388685807052C1457.4484195122836,592.3059567961258,1411.2514023483184,796.1355474539366,1362.6044572913256,966.9986223200493C1317.909746480711,1123.9802315538154,1236.591599635133,1276.8585745678572,1086.9259138272403,1341.9817294647178C942.7406358215641,1404.7202265539108,768.4470664075394,1374.7741292122953,642.8318665313277,1280.1890618356517C537.9081828910851,1201.1841826785285,549.4795833323407,1052.8942963180132,530.415101296006,922.9431676428238C514.2617076066958,812.835180556691,485.48174220750417,700.6159854701862,543.9105457681692,605.9018056325765C611.3364269909806,496.6031951755599,723.6634651520264,427.9447125401258,850,404.89008894497465 "></path>
                    </g>
                </svg>
            </div>
            <div className="row">
                <div className="col-lg-12 col-sm-12 my-auto">
                    <h1 className="font-weight-bold mt-5 text-center">Book a meeting with us.</h1>
                    <p className='text-center'>
                        Learn how personalized SMS can transform your marketing. It’s fast, easy, tracked and integrated with your tech stack.
                    </p>
                    {/* <img className="img-fluid w-75" src="./assets/images/demo/bookAdemo.svg" /> */}


                    {/* <small>
                        <ul className="ml-n4">
                            <li>Send personalized, automated SMS campaigns with just a few clicks</li>
                            <li>Build better one on one relationships with your customers</li>
                            <li>Explore integrations for your product stack</li>
                        </ul>
                    </small> */}
                </div>
                <div className="col-lg-12 col-sm-12">
                    {/* <div className="calendly-inline-widget" data-url="https://calendly.com/rumeshikashoutout/30min" style={{ minWidth: "320px", height: "630px" }}></div> */}
                    {/* <div style={{ height: "800px" }}>
                        <iframe
                            src="https://calendly.com/rumeshikashoutout/30min"
                            width="100%"
                            height="100%"
                            frameborder="0"
                        ></iframe>
                    </div> */}


                    <div className="calendly-inline-widget" data-url="https://calendly.com/rumeshikashoutout/30min" style={{ minWidth: "320px", height: "730px" }}></div>
                    {/* <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> */}



                    {/* <div className="my-5">
                        {submitted ? <div className="text-center border-thanks p-4 my-5">
                            <h3 className="font-weight-bold">Thank you!</h3>
                            <p>
                                We’re excited to discuss your campaign needs and show you why ShoutOUT offers the best options out there!
                                <br /><br />
                                We'll connect with you within 24 hours.
                                <br /><br />
                                Thank you for contacting us.
                            </p>
                        </div>
                            : 
                            
                             <form id="book-a-demo-form" className="p-4 shadow">
                                 <div className="form-row">
                                     <div className="form-group col-md-6">
                                         <label for="inputFirstName">First Name</label>
                                         <input type="text" className="form-control" id="inputFirstName" placeholder="First Name" />
                                     </div>
                                     <div className="form-group col-md-6">
                                         <label for="imputLastName">Last Name</label>
                                         <input type="text" className="form-control" id="imputLastName" placeholder="Last Name" />
                                     </div>
                                 </div>
                                 <div className="form-row">
                                     <div className="form-group col-md-6">
                                         <label for="inputEmail">Email</label>
                                         <input type="email" className="form-control" id="inputEmail" placeholder="Email" />
                                     </div>
                                     <div className="form-group col-md-6">
                                         <label for="inputPhone">Phone Number</label>
                                         <input type="phone" className="form-control" id="inputPhone" placeholder="Phone Number" />
                                     </div>
                                 </div>
                                 <div className="form-row">
                                     <div className="form-group col-md-6">
                                         <label for="inputEmail">Country</label>
                                         <select id="inputCountry" className="form-control">
                                             <option selected>Choose...</option>
                                             <option>Sri Lanka</option>
                                             <option>United States</option>
                                         </select>
                                     </div>
                                     <div className="form-group col-md-6">
                                         <label for="inputCompany">Company URL</label>
                                         <input type="text" className="form-control" id="imputCompany" placeholder="Company URL" />
                                     </div>
                                 </div>
                                 <div className="form-group">
                                     <label for="inputIndustry">Industry</label>
                                     <input type="text" className="form-control" id="inputIndustry" placeholder="Industry" />
                                 </div>
                                 <div className="form-group">
                                     <label for="inputJobFunction">Job function</label>
                                     <input type="text" className="form-control" id="inputJobfunction" placeholder="Job function" />
                                 </div>
                                 <div className="form-row">
                                     <div className="form-group col-md-6">
                                         <label for="inpuInterests">Interested products</label>
                                         <select id="inputState" className="form-control">
                                             <option selected>Choose...</option>
                                             <option>SMS Campaigns</option>
                                             <option>Email Campaigns</option>
                                             <option>SMS API</option>
                                             <option>OTP API</option>
                                             <option>Shared Inbox</option>
                                         </select>
                                     </div>
                                     <div className="form-group col-md-6">
                                         <label for="inputState">Approximate Number of SMS / month</label>
                                         <select id="inputState" className="form-control">
                                             <option selected>Choose...</option>
                                             <option>0-500</option>
                                             <option>500-1K</option>
                                             <option>1K-5K</option>
                                             <option>5K-10K</option>
                                             <option>10K-20K</option>
                                             <option>50K-100K</option>
                                             <option>100K+</option>
                                         </select>
                                     </div>
                                 </div>
                                 <div className="form-group">
                                     <div className="form-check">
                                         <input className="form-check-input" type="checkbox" id="gridCheck" />
                                         <label className="form-check-label" for="gridCheck">
                                             I would like to receive information on upcoming promotions and product updates.
                                         </label>
                                     </div>
                                 </div>
                                 <button type="submit" className="btn btn-primary btn-sm" onClick={onSubmitForm}>Submit</button>
                             </form>
                            <>
                                 <div className="the-form" data-tf-widget="SM9jnYXg" data-tf-opacity="0" data-tf-hide-headers data-tf-iframe-props="title=ShoutOUT Book a Demo"></div>
                            </>
                         }
                </div>
                */}
                    {/* <div class="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6k5eJPgihmojnGGtVOrpvieMlkoX2Epr3q074xUzlWoBjXq09DlPvQyrWWU28DUrMT"></div> */}

                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="card shadow p-4">
                        <img className="w-25 mb-2" src="/assets/images/demo/personalized.svg" />
                        <p>Send personalized, automated SMS campaigns with just a few clicks</p>
                    </div>
                </div>
                <div className="col">
                    <div className="card shadow p-4">
                        <img className="w-25 mb-2" src="/assets/images/demo/relationships.svg" />
                        <p>Build better one on one relationships with your customers</p>
                    </div>
                </div>
                <div className="col">
                    <div className="card shadow p-4">
                        <img className="w-25 mb-2" src="/assets/images/demo/integrations.svg" />
                        <p>Explore integrations for your product stack</p>
                    </div>
                </div>
            </div>
            {/* <div className="row my-5 justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12 my-4">

                </div>
            </div> */}


            {/* <div className="row my-5 justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-12">
                    <h2 className="font-weight-bold text-center">
                        Can’t wait until the meeting? Watch our product demo video!
                    </h2>
                </div>
            </div> */}

            {/* <div className="col-12">
                <div className="mx-auto">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/h9kqiH4NzuE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div> */}
        </div>

        {/* <section className="login-block pt-70">
                <h3 className="text-center text-dark subheading mb-1">Get to know our all features, clear your doubts</h3>
                <h5 className="text-center">Book you free online demonstration</h5>
               

            </section> */}

        <section>
            <CallToAction title={"Try us Free"} onDemo={true} />
        </section>

        <MainFooter />

    </div >);
};

export default BookADemo;