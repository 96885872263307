import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { useRouteData, Head, useSiteData } from 'react-static';
import Constants from './../../../Constants';
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';
import CustomerLogos from './../home/CustomerLogos';
import SignUpData from './SignUpData';
import PricingSMS from './PricingSMS';
import CountrySelector from './CountrySelector';
import { submitGTMEvent } from './../../services/AnalyticsServices';
// import UtilService from './../../services/UtilService';
import Services from './../../services/Services';
import './../PricingCommon.css';
import './Pricing.css';


// const renderPackageFeature = ({ isAvailable, description }) => {
//     return <td align={`${(isAvailable !== "Yes" && isAvailable !== "No") ? "left" : "center"}`}>
//         <div className="table-data">
//             {isAvailable === "Yes" ?
//                 <img className="img-fluid" src="/assets/images/temp/tick.svg" /> : isAvailable === "No" ?
//                     <img className="img-fluid" src="/assets/images/temp/dash.svg" /> : isAvailable
//             }
//             {description && <> <br /> <small>({description})</small> </>}
//         </div>
//     </td>
// };

const PackageEmail = ({ plansSubscriptions, plansOnDemand, index }) => {
    return (
        <tr>
            <td>
                {plansOnDemand[index]?.blockSize}
            </td>
            <td>
                <p className="mb-n1">{plansSubscriptions[index]?.currency.toUpperCase() + " " + plansSubscriptions[index]?.currency_amount}</p>
                <small className="text-muted">{plansSubscriptions[index]?.currency.toUpperCase() + " " + plansSubscriptions[index]?.currency_amount / (plansSubscriptions[index]?.blockSize || 1)} per Email </small>
            </td>
            <td>
                <p className="mb-n1">{plansOnDemand[index]?.currency.toUpperCase() + " " + plansOnDemand[index]?.currency_amount}</p>
                <small className="text-muted">{plansOnDemand[index]?.currency.toUpperCase() + " " + plansOnDemand[index]?.currency_amount / plansOnDemand[index]?.blockSize} per Email  </small>
            </td>
        </tr>
    )
}

const Loading = () => {
    return <div className="container h-100">
        <div className="row text-center h-100">
            <div className="col my-auto">
                <img src="/assets/images/temp/loading.gif" alt="Loading" />
            </div>
        </div>
    </div>
}

const Pricing = () => {

    const { BUILD_PRODUCT } = useSiteData();
    const [activeFaq, setActiveFaq] = useState(null);

    const [plans, setPlans] = useState([]);
    const [creditValue, setCreditValue] = useState(1);
    const [previousPackage, setPreviousPackage] = useState();
    const [countryLocation, setCountryLocation] = useState();

    const getPricingPlans = async () => {
        const plans = await Services.getPlans();
        setPlans(plans.data);
    }

    const onSwitchFAQ = (e) => {
        if (activeFaq === e.currentTarget.dataset.id) {
            setActiveFaq(null);
        }
        else {
            setActiveFaq(e.currentTarget.dataset.id);
        }
    }

    const triggerSupportChat = () => {
        submitGTMEvent({
            "name": "trigger-chatBox"
        });
    }

    useEffect(() => {
        getPricingPlans();
    }, [])

    const plansEmail = plans.filter(plan => plan.category === "EMAIL");
    const plansPlatform = plans.filter(plan => plan.category === "SMS");
    const plansOnDemand = plansEmail.filter(plan => plan.metaData.type === "ondemand");

    const plansSubscriptions = plansEmail.filter(plan => plan.metaData.type === "subscription");

    const onDemandSMS = plansPlatform.filter((item) => item.metaData.type === "ondemand");
    const preSubscriptionSMS = plansPlatform.filter((item) => item.metaData.type === "subscription");
    const subscriptionSMS = plansPlatform.filter((item) => item.metaData.type === "subscription");;


    // const planEnterprise =
    // {
    //     "currency": "lkr",
    //     "id": "platform_ent",
    //     "version": "v3",
    //     "order": 1,
    //     "category": "PLATFORM",
    //     "onlinePurchaseEnabled": false,
    //     "credits": {
    //         "creditAmount": 0
    //     },
    //     "usd_amount": 0,
    //     "lkr_amount": 0,
    //     "no_of_sms": 0,
    //     "name": "Enterprise",
    //     "description": "Enterprise",
    //     "validity": 1,
    //     "product": "engage",
    //     "blockSize": 0,
    //     "metaData": {
    //         "type": "subscription",
    //         "recurrencePeriod": "1 Month",
    //         "packageInclude": "Enterprise Plan"
    //     },
    //     "features": [
    //         "Contact us for more customized features"
    //     ],
    //     "currency_amount": 0
    // }

    // preSubscriptionSMS.push(planEnterprise)

    return (<> {plans.length === 0 ?
        <Loading /> :
        <div>
            {BUILD_PRODUCT === Constants.ENGAGE_PRODUCT && <>
                <Head>
                    <meta charSet="UTF-8" />
                    <meta name="keywords" content="affordable SMS marketing, bulk sms, cheap bulk sms, affordable text marketing, low cost bulk sms, cheap sms provider, cheapest bulk sms provider, cheapest way to send bulk sms, bulk sms low price, bulk sms service provider low cost, cheapest sms service provider, cheapest bulk sms service provider, shoutout pricing, shoutout signup" />
                    <meta name="description" content="ShoutOUT offers affordable SMS marketing pricing & plans for business of all sizes. Try our free trial with 20 free SMS credits. No credit cards required." />
                    <link rel="canonical" href="https://getshoutout.com/pricing" />
                    <title>Affordable SMS Marketing Plans & Pricing| ShoutOUT</title>
                </Head>
                <MainNavbar />
            </>}
            <div className="container opacity-p9 pt-5">


                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="font-weight-bold mt-5 text-center">
                            Affordable <span className="text-primary">SMS</span> Marketing <br /> Plans for Everyone
                        </h1>
                        <p className="text-center">Simple, low cost pricing for text message marketing. No hidden fees.</p>
                    </div>
                </div>



                <section id="products" className="text-center container">
                    <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
                            <h2 className="font-weight-bold mt-4 text-primary text-center">What makes ShoutOUT SMS pricing unique?</h2>
                            <p className="text-center">Unlimited team members, unlimited contacts and unlimited SMS for all free and paid plans!</p>
                        </div>
                    </div>
                    <div className="row">

                        {/* <div className="col d-flex justify-content-start">
                            <div className="list-inline text-center">
                                <p className="h5 list-inline-item font-weight-bold pt-2 pl-3">SMS Packages</p>
                            </div>
                        </div> */}
                        <div className="col mb-4 d-flex justify-content-between">
                            <CountrySelector setCountryLocation={setCountryLocation} setCreditValue={setCreditValue} creditValue={creditValue} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 mb-3">
                            <div className="card-group">

                                {preSubscriptionSMS.map((platform, index) => {


                                    return (<>
                                        <div key={platform.id} className={`${platform.name === "Growth" && 'growth-highlight'} card mx-2 package-shadow border-0 rounded-p5 dark-color product-border-top zoom align-content-between`}>
                                            {/* ${platform.name === "Starter" ? "" : "cursor-not-allowed"} */}
                                            <div className={`rounded-p5 package-${platform.name.toLowerCase()}`}>
                                                <div className="d-flex justify-content-between align-items-start">
                                                    <h5 className="text-left">{platform.name}</h5>
                                                    {platform.name === "Growth" && <small className="badge-platform-popular mb-0">Popular</small>}
                                                </div>
                                                <div className="d-flex">
                                                    {platform.name === "Enterprise" ? <p>Custom Pricing</p> :
                                                        <> <h4>{platform.currency.toUpperCase()}</h4>
                                                            <h2>{platform.currency_amount.toFixed(2)}</h2>
                                                            <small className="opacity-p8 mt-3"> /month</small></>}
                                                </div>
                                            </div>
                                            <div className="mt-3 text-black">

                                                {platform.name === "Starter" ? <p className="mb-0"><span className="font-weight-bold">20 Free</span> SMS Credits</p> : platform.name === "Growth" ? <p className="mb-0"><span className="font-weight-bold">500 Free</span> SMS Credits</p> : platform.name === "Pro" ? <p className="mb-0"><span className="font-weight-bold">1000 Free</span> SMS Credits </p> : ''}
                                                <br />
                                                {platform.no_of_sms !== 0 && <p className="mb-0">Buy credits for only <br /> <span className="font-weight-bold"> {platform.currency.toUpperCase()} {(onDemandSMS[0]?.currency_amount / (onDemandSMS[0]?.credits?.creditAmountMap[platform.id] / creditValue)).toFixed((countryLocation === "us" || countryLocation === "ca") ? 4 : 2)} per SMS </span></p>}


                                                <p className="product-description-2 mt-3 mb-0 text-center">
                                                    <span className={`badge-platform-offer ${platform.name === "Starter" && "opacity-0"}`}>
                                                        {(100 - (((onDemandSMS[0]?.currency_amount / (onDemandSMS[0]?.credits?.creditAmountMap[platform.id] / creditValue)) / (onDemandSMS[0]?.currency_amount / (onDemandSMS[0]?.credits?.creditAmountMap["platform_sms_1"] / creditValue))) * 100)).toFixed(1)}% lower rate than Starter plan
                                                    </span>
                                                </p>
                                                {/* {platform.currency.toUpperCase()} {(platform.currency_amount / platform.no_of_sms).toFixed(0)} per SMS  */}
                                            </div>
                                            <div className="p-4 rounded-p5 d-flex flex-column justify-content-start h-100">
                                                <p className="product-description-1">{platform.name === "Starter" ? "Best for SMS Campaigns" : "Best for API Integrations & Automated / Triggered  Messages"}</p>
                                                <ul className="tick-list-image pricing-features">
                                                    {platform.features.map((feature, index) => {
                                                        return (<li key={index} className="mb-2">{feature}</li>)
                                                    })}
                                                </ul>
                                                {platform.name === "Starter" ? <Link to="/signup" className="btn btn-outline-primary btn-sm-round mt-auto">Try for Free</Link>
                                                    : platform.name === "Enterprise" ? <Link to="/bookaDemo" className="btn btn-outline-primary btn-sm-round mt-auto">Talk to us</Link> :
                                                        <>
                                                            {/* <small className="text-black-50 p-2 mt-auto coming-soon">Coming soon</small> */}
                                                            <Link to="/signup" className={`btn  btn-sm-round mt-auto ${platform.name === "Growth" ? "btn-outline-dark" : "btn-outline-primary"}`}>Get Started</Link>
                                                        </>
                                                }
                                                {platform.name === "Starter" && <small className="no-card">No credit card required</small>}
                                            </div>
                                        </div>

                                    </>)
                                })}
                            </div>
                        </div>
                        {/* <div className="col-lg-12">
                            <div className="mt-3">
                                <p className=""><i>100% money-back guarantee</i></p>
                            </div>
                        </div> */}
                    </div>

                    {/* signup here */}
                    <SignUpData />

                    <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
                            <h2 className="font-weight-bold mt-4 text-primary text-center">Payment is always secure</h2>
                            <p className="text-center">We accept all major credit cards</p>
                        </div>
                        <div className="col-lg-12 col-md-10 col-sm-12">
                            <img className="w-7 mr-2" src="/assets/images/pricing/visa.svg" />
                            <img className="w-7 mr-2" src="/assets/images/pricing/master.svg" />
                            <img className="w-7 mr-2" src="/assets/images/pricing/discover.svg" />
                            <img className="w-7" src="/assets/images/pricing/amex.svg" />
                        </div>
                    </div>
                </section>




            </div>

            <div className="container mt-5">

                <CustomerLogos />
                {/* <PricingSMS pricingPlans={plans} onDemandSMS={onDemandSMS} subscriptionSMS={subscriptionSMS} /> */}

                {/* {BUILD_PRODUCT === Constants.ENGAGE_PRODUCT && <div className="row mt-4 justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 mt-3">
                        <div className="list-inline text-center">
                            <p className="h5 list-inline-item font-weight-bold">Email Packages</p>
                            <br />
                            <br />
                        </div>

                        <div className="row no-gutters pricing-sms-font-75p">
                            <div className="col-12 mx-auto">


                                <table className="pricing-sms table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="w-30p">
                                                Emails
                                            </th>
                                            <th className="w-40p">
                                                Monthly Subscription
                                            </th>
                                            <th className="w-30p">
                                                Pay As You Go
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={0} />
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={1} />
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={2} />
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={3} />
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={4} />
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={5} />
                                        <PackageEmail plansOnDemand={plansOnDemand} plansSubscriptions={plansSubscriptions} index={6} />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>} */}


            </div>
            {BUILD_PRODUCT === Constants.ENGAGE_PRODUCT && <>
                <div className="container-fluid">
                    <div className="row mt-3 text-center faq-bg pt-4 mt-5">
                        <div className="h5 mt-3 col-12 col-md-12 col-sm-12 col-xs-12">
                            Frequently Asked Questions
                            <br />
                            <button className="btn btn-transparent text-primary font-14" onClick={triggerSupportChat}>Chat with us</button>
                        </div>
                    </div>

                    <div className={`row justify-content-center pb-0 text-left ${activeFaq === "FAQ01" ? "" : "faq-bg"}`}>

                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 collapse-faq">

                            <div className="card border-0 shadow-pricing m-3">
                                <div className="border-bottom-0 d-flex card-header" data-id="FAQ01" onClick={onSwitchFAQ}>
                                    <h6 className="mr-auto my-1">Can I send bulk SMS and Email campaigns with the Starter Package?</h6>
                                    <img className={` img-fluid ${activeFaq === "FAQ01" ? "pricing-icon" : "pricing-icon-reverse"} `} src="/assets/images/temp/down-arrow.svg" />
                                </div>
                                <span className={` ${activeFaq === "FAQ01" ? "show" : "hidden"} `}>
                                    <div className="card-body">
                                        YES! You can just purchase SMS or Email packages and send campaigns.
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={`row justify-content-center pb-0 text-left ${activeFaq === "FAQ02" ? "" : "faq-bg"}`}>

                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 collapse-faq">


                            <div className="card border-0 shadow-pricing m-3">
                                <a className="card-header border-bottom-0 d-flex" data-id="FAQ02" onClick={onSwitchFAQ}>
                                    <h6 className="mr-auto my-1">Can we get customized SMS & Email packages?</h6>
                                    <img className={` img-fluid ${activeFaq === "FAQ02" ? "pricing-icon" : "pricing-icon-reverse"} `} src="/assets/images/temp/down-arrow.svg" />
                                </a>
                                <span className={` ${activeFaq === "FAQ02" ? "show" : "hidden"} `}>
                                    <div className="card-body">
                                        YES! You can, but it depends on your volume.
                                    </div>
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className={`row justify-content-center pb-0 text-left ${activeFaq === "FAQ03" ? "" : "faq-bg"}`}>

                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 collapse-faq">

                            <div className="card border-0 shadow-pricing m-3">
                                <a className="card-header border-bottom-0 d-flex" data-id="FAQ03" onClick={onSwitchFAQ}>
                                    <h6 className="mr-auto my-1">Are the prices tax inclusive?</h6>
                                    <img className={` img-fluid ${activeFaq === "FAQ03" ? "pricing-icon" : "pricing-icon-reverse"} `} src="/assets/images/temp/down-arrow.svg" />
                                </a>
                                <span className={` ${activeFaq === "FAQ03" ? "show" : "hidden"} `}>
                                    <div className="card-body">
                                        YES! They’re tax inclusive. There are no hidden fees or any other additional taxes.
                                    </div>
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className={`row justify-content-center pb-0 text-left ${activeFaq === "FAQ04" ? "" : "faq-bg"}`}>

                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 collapse-faq">

                            <div className="card border-0 shadow-pricing m-3">
                                <a className="card-header border-bottom-0 d-flex" data-id="FAQ04" onClick={onSwitchFAQ}>
                                    <h6 className="mr-auto my-1">What is the character limitation per Email?</h6>
                                    <img className={` img-fluid ${activeFaq === "FAQ04" ? "pricing-icon" : "pricing-icon-reverse"} `} src="/assets/images/temp/down-arrow.svg" />
                                </a>
                                <span className={` ${activeFaq === "FAQ04" ? "show" : "hidden"} `}>
                                    <div className="card-body">
                                        160 characters per SMS. If it’s a unicode SMS, 70 characters per SMS
                                    </div>
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className={`row justify-content-center pb-4 text-left ${activeFaq === "FAQ05" ? "" : "faq-bg"}`}>

                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 collapse-faq">

                            <div className="card border-0 shadow-pricing m-3">
                                <a className="card-header border-bottom-0 d-flex" data-id="FAQ05" onClick={onSwitchFAQ}>
                                    <h6 className="mr-auto my-1">Can I be a reseller of ShoutOUT?</h6>
                                    <img className={` img-fluid ${activeFaq === "FAQ05" ? "pricing-icon" : "pricing-icon-reverse"} `} src="/assets/images/temp/down-arrow.svg" />
                                </a>
                                <span className={` ${activeFaq === "FAQ05" ? "show" : "hidden"} `}>
                                    <div className="card-body">
                                        YES! We would love to have you as a reseller. Talk to us.
                                    </div>
                                </span>
                            </div>

                        </div>
                    </div>

                </div>
                <CallToAction title="Start growing your business today" />
                <MainFooter />
            </>}
        </div >
    }</>
    );


};

export default () => {
    const routeData = useRouteData();
    return (<Pricing {...routeData} />);
}

