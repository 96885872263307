import React from 'react';
import Modal from './../modals/Modal';
class CloseAlert extends React.Component {

    render() {
        const { showAlert, closeAlert, type = "success", message, title } = this.props;
   
        return (
            <Modal visible={showAlert} onClickBackdrop={closeAlert}>

                <div className="modal-header">

                    <h5 className={`modal-title text-${type}`}>{title}</h5>
                </div>
                <div className="modal-body">
                    <p>{message}</p>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={closeAlert}>
                        Close
          </button>
                </div>
            </Modal>
        );
    }
}

export default CloseAlert;