import React from 'react';
const Picture = ({ src, srcSets, placeholder, alt, className }) => {
    return (<picture>
        {srcSets.map(({ type, srcset }) => {
            return <source type={type}
                srcSet={srcset} key={type || "default"} />
        })}

        <img src={src}
            placeholder={placeholder}
            alt={alt} className={className} />
    </picture>)
}
export default Picture;