
import React from 'react';

export default ({ blockSize = 1, plansSubscription = {}, plansOnDemand = {}, creditValue = 1 }) => {
    const numberOfCredits = Math.round(blockSize / creditValue);
    return (
        <tr>
            <td>
                {blockSize}
            </td>
            <td>
                {numberOfCredits}
            </td>
            <td>
                {Object.keys(plansSubscription).length > 0 ? <>
                    <p className="mb-n1">{plansSubscription.currency + " " + plansSubscription.currency_amount}</p>
                    <small className="text-muted">{plansSubscription.currency + " " + (plansSubscription.currency_amount / numberOfCredits).toFixed(2)} per SMS </small>
                </> : <p>-</p>}
            </td>
            <td>
                <p className="mb-n1">{plansOnDemand.currency + " " + plansOnDemand.currency_amount}</p>
                <small className="text-muted">{plansOnDemand.currency + " " + (plansOnDemand.currency_amount / numberOfCredits).toFixed(2)} per SMS </small>

            </td>
        </tr>
    )
}