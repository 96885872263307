import React from 'react';
import { Head } from 'react-static';
import IntlTelInput, { defaultProps } from "react-intl-tel-input";
import Services from "./../../services/Services";
import UtilService from './../../services/UtilService';

import { Link } from '@reach/router';
import Modal from './../../components/modals/Modal';

import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';
import "react-intl-tel-input/dist/main.css";

import './ContactUs.css';


class ContactUs extends React.Component {

    constructor() {
        super();
        this.state = { showOther: false, isSending: false, bookDemoStatus: null, showAlert: false, country: 'lk' };
        this._getCountry = this._getCountry.bind(this);
    };

    _onSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target)
        let object = {};
        data.forEach((value, key) => { object[key] = value });
        let { name, phone, email, requirement, requirementOther } = object;
        if (requirement === "other") {
            requirement = requirementOther;
        }
        this.setState({ isSending: true });

        Services.contactUs({ name, phone, email, requirement }).then(res => {
            this.setState({ isSending: false, bookDemoStatus: "_success", showAlert: true });

        }, err => {
            this.setState({ isSending: false, bookDemoStatus: "_error", showAlert: true });
        });

    };

    _getCountry() {
        UtilService.getIpInfo().then(response => {
            this.setState({ country: response.country });
        });


    };

    componentDidMount() {
        this._getCountry();
    }

    _onHandleRequirement = (e) => {
        const requirementValue = e.target.value;

        if (requirementValue === "other") {
            this.setState({ showOther: true });
        }
        else {
            this.setState({ showOther: false });
        }
    };

    _closeAlert = () => {
        this.setState({ showAlert: false });
    };

    render() {

        const { showOther, isSending, bookDemoStatus, showAlert } = this.state;
        return (<div className="aboutus">
            <Head>
                <title>ShoutOUT | Contact Us</title>
            </Head>
            <MainNavbar />

            <div className="container pt-70 opacity-p8">
                <div className="row justify-content-between">
                    <div className="col-lg-8 col-sm-12 my-auto bring-to-top text-center">

                        <div className="heading mb-0">Contact Us</div>
                        <hr className="w-50" />
                        {(this.state.country === "lk" || this.state.country === "ng") && <div className="mb-2 mr-2 card border-0 shadow-sm rounded-0 d-inline-block py-2 px-3 text-left">
                            <p className="mb-0">Phone</p>
                            <a className="text-primary" itemProp="telephone" href="tel:94766173344">
                                94 (76) 617-3344
                            </a>
                        </div>}
                        <div className="mb-2 mr-2 card border-0 shadow-sm rounded-0 d-inline-block py-2 px-3 text-left">
                            <p className="mb-0">Email</p>
                            <a className="text-primary" itemProp="email" href="mailto:info@getshoutout.com">
                                info@getshoutout.com
                            </a>
                        </div>
                        {this.state.country === "lk" || this.state.country === "ng" ? <div className="mb-2 card border-0 shadow-sm rounded-0 d-inline-block py-2 px-3 text-left">
                            <p className="mb-0">Address</p>
                            <a className="text-primary" itemProp="address" href="https://g.page/shoutout-labs-colombo?share" target="_blank" rel="noopener noreferrer">
                                02 6<sup>th</sup> Lane, Colombo 03, Sri Lanka
                            </a>
                        </div> : ''}

                    </div>
                    <div className="col my-auto text-right">
                        <img className="map-image img-fluid" alt="ShoutOUT Map" src="/assets/images/temp/the-map.png" />
                    </div>
                </div>
                <div className="row justify-content-center">

                    <div className="col-lg-6 col-sm-11 my-auto input-sec card border-0 rounded-0 p-4">
                        <h4 className="text-center"><strong>We are happy to help!</strong></h4>
                        <br />
                        <form className="contact-form" onSubmit={this._onSubmit}>
                            <div className="form-group">
                                <label htmlFor="name" className="">Name</label>
                                <input type="text" className="form-control" name="name" placeholder="Name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone" className="">Phone</label>
                                <IntlTelInput containerClassName="intl-tel-input" fieldName="phone" inputClassName="form-control" id="phone" defaultCountry={this.state.country} geoIpLookup={this._getCountry} required autoComplete='mobile' />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" className="">Email</label>
                                <input type="email" className="form-control" name="email" placeholder="Email" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="requirement" className="">Requirement</label>
                                <select name="requirement" id="requirement" className="form-control" required onChange={this._onHandleRequirement}>
                                    <option value="sms-marketing">SMS Marketing</option>
                                    <option value="email-marketing">Email Marketing</option>
                                    <option value="loyalty-management">Loyalty Management</option>
                                    <option value="sms-gateway-api">SMS Gateway API</option>
                                    <option value="one-time-password">One Time Password (OTP)</option>
                                    <option value="messenger-chatbots">Meesenger Chatbots</option>
                                    <option value="whatsapp-chatbots">WhatsApp Chatbots </option>
                                    <option value="customer-data-platform">Customer Data Platform</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            {showOther && <div className="form-group">
                                <label htmlFor="requirementOther" className="">Mention the requirement</label>
                                <textarea className="form-control" name="requirementOther" placeholder="Requirement" rows="3" required />
                            </div>}
                            {/* <p className="text-danger">error</p> */}
                            <div className="text-right">
                                <button type="submit" className="btn btn-round btn-primary btn-lg" disabled={isSending}>{isSending ? "Sending..." : "Send Message"}</button>
                            </div>


                        </form>

                    </div>
                </div>
            </div>

            <CallToAction />
            <MainFooter />

            <Modal className="border-0 shadow-sm rounded-0" visible={showAlert} onClickBackdrop={this._closeAlert}>
                {bookDemoStatus === "_error" ?
                    <React.Fragment>
                        <div className="modal-header border-0 rounded-0">

                            <h5 className="modal-title text-danger">Failed!</h5>
                        </div>
                        <div className="modal-body border-0 rounded-0">
                            <p>Invalid details provided. Please retry.</p>
                        </div>
                    </React.Fragment> :
                    <React.Fragment>
                        <div className="modal-header border-0 rounded-0">

                            <h5 className="modal-title text-success">Success!</h5>
                        </div>
                        <div className="modal-body border-0 rounded-0">
                            <p>We've recieved your requirement and one of us from our team will contact you soon.
                                Till then, have a look at <Link to="/customers">Our Customers</Link> and <Link to="/events">Events</Link> Coming up</p>
                        </div>
                    </React.Fragment>
                }

                <div className="modal-footer border-0 rounded-0">
                    <button type="button" className="btn btn-primary rounded-0" onClick={this._closeAlert}>
                        Close
                    </button>
                </div>
            </Modal>
        </div>
        );
    }
}
export default ContactUs;