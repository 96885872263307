import React from 'react';
import { Head } from 'react-static';
import './SampleCode.css';
const NodeSampleCode = () => {
  return (

    <div className="card p-4 bg-dark text-white">
      <p className="text-comment">// Installation</p>
      <p>npm install shoutout-sdk --save</p>
      <p className="text-comment">// Usage</p>
      <p> <span className="text-orange">var</span> ShoutoutClient = <span className="text-function">require</span>(<span className="text-string">'shoutout-sdk'</span>);</p>
      <p> <span className="text-orange">var</span> apiKey = <span className="text-string">'XXXXXXXXX.XXXXXXXXX.XXXXXXXXX'</span>;</p>
      <p> <span className="text-orange">var</span> debug = true, verifySSL = false;</p>
      <p> <span className="text-orange">var</span> client = <span className="text-orange">new</span> ShoutoutClient(apiKey, debug, verifySSL);</p>
      <p> <span className="text-orange">var</span> message = {"{"}</p>
      <p> <span className="text-string pl-2">"content"</span>: {"{"}<span className="text-string">"sms"</span> : <span className="text-string">"Sent via ShoutOUT Lite"</span>{"}"},</p>
      <p>  <span className="text-string pl-2">"destinations"</span> : [<span className="text-string">"94771234567"</span>],</p>
      <p>  <span className="text-string pl-2">"source"</span> : <span className="text-string">"ShoutDEMO"</span>,</p>
      <p>  <span className="text-string pl-2">"transports"</span> : [<span className="text-string">"SMS"</span>]</p>
      <p>  {"}"};</p>

      <p> client.sendMessage(message, (error, result){" => {"}</p>
      <p>  <span className="text-orange">if</span>(error) {"{"}</p>
      <p>  <span className="text-function pl-4">console</span>.error(<span className="text-string">'Error sending message!'</span>,error);</p>
      <p className="pl-2">  {"}"} <span className="text-orange">else</span> {"{"}</p>
      <p>  <span className="text-function pl-4">console</span>.log(<span className="text-string">'Sending message successful!'</span>,result);</p>
      <p className="pl-2">  {"}"}</p>
      <p>  {"}"});</p>

    </div>


  )
}
export default NodeSampleCode;