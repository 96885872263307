import React from 'react';
import { Head } from 'react-static';
import {Link} from '@reach/router';
const InvitationFailed =(props)=>{
 
    return (
        <div className="container text-center h-100">
                  <Head>
                <title>ShoutOUT | Account Verification</title>
            </Head>
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-12 col-lg-6 col-md-10">
                    <div className="card shadow border-0">
                        <div className="card-body opacity-p8">
                            <img src="/assets/images/temp/close.svg" className="my-3 text-center border-muted border rounded-circle p-4 line-height-1" alt="Failed" width="100" />
                            
                            <h4 className="mb-4 font-weight-bold">Something Wrong With Your Invitation!</h4>
                            <h6 className="mb-4 font-weight-bold">please contact your administrator</h6>
                            <Link to="/" className="btn btn-outline-dark btn-sm btn-round py-2 px-4 mb-3">Go to Website</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InvitationFailed;