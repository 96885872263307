import React, { useState } from 'react';
import { Head } from 'react-static';

import AccountNavbar from './../../../components/navbars/AccountNavbar';
import Services from './../../../services/Services';
import CloseAlert from './../../../components/alert/CloseAlert';
import { navigate } from '@reach/router';
import UtilService from './../../../services/UtilService';
import './AccountVerify.css';
const AccountVerify = () => {
    const [otp, setOtp] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [isSendingResend, setIsSendingResend] = useState(false);
    const [alert, setAlert] = useState({
        message: "",
        type: "danger",
        title: ""
    });

    const onChangeOtp = (e) => {
        setOtp(e.target.value);
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true);
        try {
            await Services.verifyAccount(otp);
            const res = await Services.exchangeToken();
            const queryData = UtilService.queryStringToJSON(window.location.search);
            navigate('/launchpad?token=' + res.data.token + "&referrer=" + (queryData.referrer || ''));
        } catch (e) {
            console.error(e);
            setAlert({
                type: "danger",
                title: "Verification Failed",
                message: "Verification failed. Please recheck the code"
            });

        } finally {
            setIsSending(false);
        }
    }
    const resendVerification = async () => {
        try {
            setIsSendingResend(true);
            await Services.resendAccountVerificationCode();



            setAlert({
                type: "success",
                title: "Code Sent",
                message: "Code resent to your email. Please check"
            });
        } catch (e) {
            console.error(e);
            setAlert({
                type: "danger",
                title: "Resend Failed",
                message: "Code resend failed. Please try again"
            });

        } finally {
            setIsSendingResend(false);

        }
    }

    const closeAlert = () => {
        setAlert({
            title: "",
            type: "danger",
            message: ""
        })
    }

    return (
        <div className="container text-center h-100">
            <Head>
                <title>ShoutOUT | Account Verification</title>
            </Head>
            <AccountNavbar />

            <div className="row h-100 justify-content-center align-items-center pt-70 account-verify">
                <div className="col-12 col-lg-6 col-md-10">
                    <div className="card shadow border-0 input-sec">
                        <div className="card-body opacity-p8">
                            <h3 className="text-center font-weight-bold title">Let's verify your account</h3>
                            <form onSubmit={onSubmit} className="mt-4">
                                <div className="form-group">
                                    <label htmlFor="otp" className="">Enter the code you received to your email</label>
                                    <input type="text" maxLength={5} minLength={1} className="form-control text-center" placeholder="12345" value={otp} onChange={onChangeOtp} required />
                                    <small id="otpHelp" className="form-text text-muted">If you do not find an email from us, please check the spam folder.</small>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-round" disabled={isSending || resendVerification || !otp}>&nbsp;&nbsp;&nbsp;{isSending ? "Sending..." : "Submit"}&nbsp;&nbsp;&nbsp;</button>
                                </div>

                                <div className="form-group mt-2">
                                    <button type="button" className="btn btn-link " onClick={resendVerification} disabled={isSending || isSendingResend}>&nbsp;&nbsp;&nbsp;{isSendingResend ? "Resending..." : "Resend"}&nbsp;&nbsp;&nbsp;</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <CloseAlert showAlert={alert.message !== ""} closeAlert={closeAlert} message={alert.message} title={alert.title} type={alert.type} />
        </div>
    )
}
export default AccountVerify;