import React from 'react';
import './SampleCode.css';
const CURLSampleCode = () => {
  return (
    <div className="card p-4 bg-dark text-white">
      <p>curl -<span className="text-danger">X POST</span></p>
      <p>--header <span className="text-comment">'Content-Type</span>: application/json'</p>
      <p>--header <span className="text-comment">'Accept</span>: application/json'</p>
      <p>--header <span className="text-comment">'Authorization</span>: <span className="text-danger">ApiKey</span> xxxxx.xx.xx.xxxx'</p>
      <p>-d'{"{"}</p>
      <p><span className="text-string pl-2">"source"</span> : <span className="text-string">"ShoutDEMO"</span>,</p>
      <p><span className="text-string pl-2">"destinations"</span> : [</p>
      <p><span className="text-comment pl-4">'94771234567'</span></p>
      <p className="pl-2">],</p>
      <p><span className="text-string pl-2">"transports"</span> : [</p>
      <p><span className="text-string pl-4">"sms"</span></p>
      <p className="pl-2">],</p>
      <p><span className="text-string pl-2">"content"</span> : {"{"}</p>
      <p><span className="text-string pl-4">"sms"</span> : <span className="text-string pl-4">"Sent via ShoutOUT Lite"</span></p>
      <p className="pl-2">{"}"}, <span className="text-comment">'https://api.getshoutout.com/coreservice/messages'</span> </p>

    </div>

  )
}
export default CURLSampleCode;