import React from 'react';



import CustomerAiaImage from './../../images/customers/aia.png?sizes[]=134';
import CustomerSLCricketImage from './../../images/customers/cricketsl.png?sizes[]=134';
import CustomerLaugfsEcoImage from './../../images/customers/laugfsecosri.png?sizes[]=134';
import CustomerAmanaImage from './../../images/customers/amana.png?sizes[]=134';
import CustomerMobilImage from './../../images/customers/mobil.png?sizes[]=134';
import CustomerISSOImage from './../../images/customers/isso.png?sizes[]=134';
import CustomerUNDPImage from './../../images/customers/undp.png?sizes[]=134';
import CustomerNycedcImage from './../../images/customers/nycedc.png?sizs[]=134';
import CustomerDSIImage from './../../images/customers/dsi.jpg?sizes[]=134';
import CustomerVisionCareImage from './../../images/customers/visioncare.png?sizes[]=134';
import CustomerHiruTVImage from './../../images/customers/hirutv.png?sizes[]=134';
import CustomerOrelITImage from './../../images/customers/orelit.png?sizes[]=134';
import CustomerBMICHImage from './../../images/customers/bmich.png?sizes[]=134';
import CustomerDilmahImage from './../../images/customers/dilmah.png?sizes[]=134';

const companies = [
    { name: "AIA", image: CustomerAiaImage, alt: "AIA-logo", title: "AIA" },
    { name: "CricketSL", image: CustomerSLCricketImage, alt: "SLC-logo", title: "Sri Lanka Cricket" },
    { name: "Amana", image: CustomerAmanaImage, alt: "", title: "" },
    { name: "Mobil", image: CustomerMobilImage, alt: "", title: "" },
    { name: "ISSO", image: CustomerISSOImage, alt: "", title: "" },
    { name: "UNDP", image: CustomerUNDPImage, alt: "", title: "" },
    { name: "NYC EDC", image: CustomerNycedcImage, alt: "", title: "" },
    { name: "DSI", image: CustomerDSIImage, alt: "", title: "" },
    { name: "Vision Care", image: CustomerVisionCareImage, alt: "", title: "" },
    { name: "Hiru TV", image: CustomerHiruTVImage, alt: "", title: "" },
    { name: "OrelIT", image: CustomerOrelITImage, alt: "", title: "" },
    { name: "BMICH", image: CustomerBMICHImage, alt: "", title: "" },
    { name: "Laugfs Eco", image: CustomerLaugfsEcoImage, alt: "", title: "" },
    { name: "Dilmah", image: CustomerDilmahImage, alt: "", title: "" },
  ];

const CustomerLogos = () => {
    return (
        <div className="row text-center justify-content-center">
            <div className="col-lg-10 col-md-10 col-sm-12">
                <h2 className="font-weight-bold my-4 text-primary">Join thousands of small to large scale businesses using our user-friendly platforms</h2>
            </div>
            <div className="row w-100 overflow-scroll my-4 justify-content-center">
                {companies.map(({ name, image, alt, title }, index) => {
                    return (
                        <div className="text-center" key={index}>
                            <img className="filter-bnw customer-logo-placement" src={image} alt={alt} title={title} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default CustomerLogos