import React from 'react';
import { Head } from 'react-static';
import IntlTelInput from "react-intl-tel-input";
import UtilService from '../../services/UtilService';
import Services from "../../services/Services";
import { submitGTMEvent } from "../../services/AnalyticsServices";
//import "react-intl-tel-input/dist/libphonenumber.js";
import Modal from '../../components/modals/Modal';
import "react-intl-tel-input/dist/main.css";
import AccountNavbar from '../../components/navbars/AccountNavbar';
import { Link, navigate } from '@reach/router';
import './Account.css';
import './AccountCompletion.css';


export default class AccountCompletion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: 'Sri Lanka',

            mobile: '',

            _validMobile: true,
            isSending: false,
            signupStatus: null,
            // blogPosts: [],
            showAlert: false,
            countryCode: 'lk',
            channels: [],
            platformUsages: []
        };

        this._onMobileNumberChange = this._onMobileNumberChange.bind(this);
        this._getIpInfo = this._getIpInfo.bind(this);
        this._signup = this._signup.bind(this);
    }


    componentDidMount() {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/");
        }
    }

    _onMobileNumberChange(status, value, countryData, number, id) {

        let country = countryData.name.replace(/[^a-zA-Z ]/gi, '').trim();
        let mobile = number.replace(/[^0-9]/gi, '');
        this.setState({ _validMobile: status, country, mobile });

        // This is a tempory fix for '074'numbers. This shouled be updated inside the library.
        if(countryData.iso2 === 'lk' && !status){
            const dialogPrefixRegEx = new RegExp(/^[0]?947[4]/gm);
            const newDialogNumberChecker = value.match(dialogPrefixRegEx) ? true : false;

            this.setState({ _validMobile: newDialogNumberChecker, country, mobile });
        }
    }
    _getIpInfo() {
        UtilService.getIpInfo().then(resp => {
            this.setState({ countryCode: resp.country });

        });
    }


    _signup(e) {
        e.preventDefault();
        const { country, mobile, channels } = this.state;

        if (!mobile) {
            this.setState({ _validMobile: false });
            return false;
        } else {
            if (!this.state._validMobile) {
                return false;
            }
        }
        const formData = new FormData(e.target);
        const employees = formData.get('employees') || "<11", organization = formData.get('organization'),
            heardFrom = formData.get("heardFrom"),
            industry = formData.get("industry"),
            platformUsages = [formData.get("usage")],
            smsCount = formData.get("SMSCount");

        if (!organization) {
            this.setState({ showAlert: true, signupStatus: 'Organization name is required.' });
            return;
        }
        if (channels.length === 0) {
            this.setState({ showAlert: true, signupStatus: 'Channels answer is required. Please select and retry.' });
            return;
        }
        if (platformUsages.length === 0) {
            this.setState({ showAlert: true, signupStatus: 'Platform usage answer is required. Please select and retry.' });
            return;
        }

        const updateRequest = {
            country, mobile, organization,
            screeningQuestions: {
                channels,
                heardFrom,
                industry,
                platformUsages,
                smsCount

            }
        };


        // if (organization) { signupRequest.organization = organization }
        this.setState({ isSending: true });

        Services.updateProfile(updateRequest).then(res => {
            submitGTMEvent({ event: 'ACCOUNT_COMPLETION', country, mobile, organization, channels, heardFrom, industry, platformUsages, smsCount });
            navigate('/launchpad' + window.location.search);

        }, err => {
            console.error("Failed:", err);
            submitGTMEvent({ event: 'ACCOUNT_COMPLETION_ERROR', country, mobile, employees, organization, error: err });
            this.setState({ showAlert: true, isSending: false, signupStatus: 'Can not save your details at the moment. Please try again!' });
        });

    }
    _closeAlert = () => {
        this.setState({ showAlert: false });
    }

    _changeCheckboxInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const currentValue = [...this.state[name]];
        const valueIndex = currentValue.indexOf(value);

        if (valueIndex > -1) {
            currentValue.splice(valueIndex, 1);
        }
        else {
            currentValue.push(value);
        }

        this.setState({ [name]: currentValue });
    }

    componentDidMount() {
        this._getIpInfo();
    }

    render() {

        const { isSending, signupStatus, showAlert } = this.state;
        return (<div>
            <Head>
                <title>ShoutOUT | Sign Up | Account Completion</title>
            </Head>
            <AccountNavbar />
            <section className="login-block pt-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 input-sec order-3 my-auto">
                            <h2 className="text-center">Let's Complete Your Profile</h2>
                            <br />

                            <br />
                            <br />
                            <form className="login-form" onSubmit={this._signup}>

                                <div className="form-group">
                                    <label htmlFor="phone" className="">Mobile Number <span className="text-secondary">(Only for account verification {'&'} product updates)</span></label>
                                    <IntlTelInput containerClassName="intl-tel-input"
                                        inputClassName="form-control" id="mobile" defaultCountry={this.state.countryCode} geoIpLookup={this._getIpInfo} ref="mobile" onPhoneNumberBlur={this._onMobileNumberChange} required autoComplete='mobile' /> {this.state._validMobile ? null : <small className="text-danger">Invalid Mobile Number</small>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="organization" className="">Name of the organization</label>
                                    <input name="organization" type="text" className="form-control" placeholder="My Organization" required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="industry" className="">Which of the following best describes your industry</label>
                                    <select name="industry" id="industry" className="form-control" required>
                                        {/* <option value="">Select an Industry</option> */}
                                        <option value="Agency">Agency</option>
                                        <option value="Ecommerce">Ecommerce</option>
                                        <option value="Education">Education</option>
                                        <option value="Event">Event</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Fitness">Fitness</option>
                                        <option value="Government">Government</option>
                                        <option value="Health Care">Health Care</option>
                                        <option value="Human Resource">Human Resource</option>
                                        <option value="Non-Profit Service">Non-Profit Service</option>
                                        <option value="Real Estate">Real Estate</option>
                                        <option value="Religious Organization">Religious Organization</option>
                                        <option value="Restaurant">Restaurant</option>
                                        <option value="Travel and Tourism">Travel and Tourism</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="usage" className="">What are you using ShoutOUT for?</label>
                                    <select name="usage" id="usage" className="form-control" required>
                                        {/* <option value="">Select an Option</option> */}
                                        <option value="Sales">Sales</option>
                                        <option value="Marketings/ PR">Marketings/ PR</option>
                                        <option value="Recruiting">Recruiting</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="employees" className="">How many SMS do you plan to send monthly?</label>
                                    <select name="employees" id="employees" className="form-control" required>
                                        <option value="<11">1 - 10</option>
                                        <option value="<101">10 - 100</option>
                                        <option value="<1001">100 - 1000</option>
                                        <option value=">1000">More than 1000</option>

                                    </select>
                                </div> */}
                                <div className="checkbox-group">
                                    <div className="form-group">
                                        <label htmlFor="channels" className="">Channels?</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input name="channels" type="checkbox" className="form-check-input" id="sms" value="sms" onChange={this._changeCheckboxInput} />
                                        <label className="form-check-label" htmlFor="sms">SMS</label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input name="channels" type="checkbox" className="form-check-input" id="email" value="email" onChange={this._changeCheckboxInput} />
                                        <label className="form-check-label" htmlFor="email">Email</label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="SMSCount" className="">How many SMS do you plan to send monthly?</label>
                                    <select name="SMSCount" id="SMSCount" className="form-control" required>
                                        <option value="<501">0 - 500</option>
                                        <option value="<1001">500 - 1K</option>
                                        <option value="<5001">1K - 5K</option>
                                        <option value="<10001">5K - 10K</option>
                                        <option value="<20001">10K - 20K</option>
                                        <option value="<50001">20K - 50K</option>
                                        <option value="<100001">50K - 100K</option>
                                        <option value=">100000">100K</option>
                                    </select>
                                </div>

                                {/* <div className="checkbox-group">
                                    <div className="form-group">
                                        <label htmlFor="usage" className="">How do you plan to use our platform?</label>
                                    </div>
                                    <div class="form-check">
                                        <input name="platformUsages" type="checkbox" className="form-check-input" id="promotional" value="promotional" onChange={this._changeCheckboxInput} />
                                        <label className="form-check-label" htmlFor="promotional">Promotional Campaigns</label>
                                    </div>

                                    <div class="form-check">
                                        <input name="platformUsages" type="checkbox" className="form-check-input" id="integration" value="integration" onChange={this._changeCheckboxInput} />
                                        <label className="form-check-label" htmlFor="integration">API Integration</label>
                                    </div>

                                    <div class="form-check">
                                        <input name="platformUsages" type="checkbox" className="form-check-input" id="transactional" value="transactional" onChange={this._changeCheckboxInput} />
                                        <label className="form-check-label" htmlFor="transactional">Transactional Messages and Reminders/Notifications</label>
                                    </div>


                                </div> */}


                                <div className="form-group">
                                    <label htmlFor="source" className="">How did you hear about us?</label>
                                    <select name="heardFrom" id="source" className="form-control" required>
                                    <option value="">Choose an option</option>
                                    <option value="product-hunt">Product Hunt</option>
                                    <option value="google">Google</option>
                                        <option value="facebook">Facebook</option>
                                        <option value="instagram">Instagram</option>
                                        <option value="linkedin">LinkedIn</option>
                            
                                        <option value="quora">Quora</option>
                                        <option value="medium">Medium</option>
                                        <option value="medium">Blog</option>
                                        <option value="friend">Through a friend</option>
                               
                                    </select>
                                </div>


                                <div className="form-check">
                                    {/* <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                        <small className="text-dark">Already a member? <Link to="/login">Login here.</Link></small>
                                    </label> */}

                                    <div className="text-center text-md-none float-none float-md-right mb-4">
                                        <button type="submit" className="btn btn-primary btn-round" disabled={isSending}>&nbsp;&nbsp;&nbsp;{isSending ? "Saving..." : "Save"}&nbsp;&nbsp;&nbsp;</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div className="col-md-1 col-sm-12 order-2">
                        <br/>
                        <br/>
                        <br/>
                        </div> */}


                        <div className="col-md-6 col-sm-12 order-1 my-auto  d-none d-md-block">

                            <div className="text-center">

                                <img className="img-fluid" src="/assets/images/signup-page-image.svg" alt="feature" />

                            </div>
                            {/* {this.state.isLoadingBlogPosts && <img className="img-fluid" src={LoadingImage} alt="Blog Post Loading Image" />}
                        {
              this.state.blogPosts.map(({ id, srcImage, title, link, excerpt }) => (
                    <div key={id}>
                            <div className="card shadow border-0">
                            <div>
                                <img className="card-img-top" src={srcImage||EmptyBlogImage} alt="ShoutOUT Blog" />
                            </div>
                                <div className="card-body">
                                    <h5 className="card-title">{title.rendered}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} className="card-text"></div>
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                <br/><br/>
                                    <h5 className="text-secondary">Already a member? <Link to="/login">Login here</Link></h5>
                                </div>
                            </div>
                        </div>
                            ))
                        } */}

                            {/* <div className="row">
                                <div className="col-md-12 text-center">
                                    <br /><br />
                                    <h5 className="text-secondary">Already a member? <Link to="/login">Login here</Link></h5>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <Modal visible={showAlert} onClickBackdrop={this._closeAlert}>
                {signupStatus &&
                    <React.Fragment>
                        <div className="modal-header">

                            <h5 className="modal-title text-danger">Failed!</h5>
                        </div>
                        <div className="modal-body">
                            <p>{signupStatus}</p>
                        </div>
                    </React.Fragment>
                }

                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={this._closeAlert}>
                        Close
                    </button>
                </div>
            </Modal>
        </div>);
    };

};