import React from 'react';
import NavbarAI from './../../navbar/NavbarAI';
import './AnswerBot.css';
const AnswerBot = () => {
    return (<>
        <NavbarAI/>
        <div className="container h-100">
            <div className="row h-75 my-5 home-bg justify-content-center">
                <div className="col-md-6 col-sm-12 my-auto order-lg-2">
                    <img src="assets/images/ai/products/answer-bot/main-image.svg" className="img-fluid" />
                </div>
                <div className="col-md-5 col-sm-12 my-lg-auto mt-4 order-lg-1 text-lg-left text-center">
                    {/* <img src="images/shoutoutai.svg" className="w-50" alt="ShoutOUT AI Logo" /> */}
                    <h1 className="text-lg-left text-center mt-2 font-weight-bold">When you should answer them?<br /> <span className="badge-shoutout px-2">Now!</span></h1>
                </div>
            </div>

            <div className="row justify-content-center mt-4">
                <div className="col-lg-12 col-md-12">
                    <h3 className="text-center font-weight-bold my-4">
                        Features
                </h3>
                    <div className="card-deck">
                        <div className="card shadow p-3">
                            <img src="assets/images/ai/products/answer-bot/icons/new.svg" className="m-4" alt="" />
                            <h5 className="font-weight-bold mb-3">New look to the FAQs</h5>
                            <p className="mt-auto">Embrace this new normal of customer service. Transform the boring FAQ
                            questions into an
                            engaging chatbot that understand and answer sophisticated inquiries from your clients</p>
                        </div>
                        <div className="card shadow p-3">
                            <img src="assets/images/ai/products/answer-bot/icons/time.svg" className="m-4" alt="" />
                            <h5 className="font-weight-bold mb-3">Set up your bot in a few minutes</h5>
                            <p className="mt-auto">You don’t need to be tech-savvy. Integrate an answer bot to your website in a
                            few steps to
                            seamlessly handle all conversations coming from multiple users at once.</p>
                        </div>
                        <div className="card shadow p-3">
                            <img src="assets/images/ai/products/answer-bot/icons/click.svg" className="m-4" alt="" />
                            <h5 className="font-weight-bold mb-3">No typing , just clicking</h5>
                            <p className="mt-auto">Get answers to the commonly asked questions on the spot. Suggest the most
                            frequently asked
                            questions via buttons to speed up the conversation without letting the users to type</p>
                        </div>
                        <div className="card shadow p-3">
                            <img src="assets/images/ai/products/answer-bot/icons/stat.svg" className="m-4" alt="" />
                            <h5 className="font-weight-bold mb-3">Get all the stats you need</h5>
                            <p className="mt-auto">Get insight and be better .Collect information on bot responses for FAQs and
                            the inferiors in
                            answering, to enhance the overall efficiency of the bot for better engagement.</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row justify-content-center my-4">
                <div className="col-12 my-2 text-center">
                    <h3 className="text-center font-weight-bold">
                        Pricing
                </h3>
                    <p className="font-weight-bold opacity-p6">Let’s get started. Choose your plan.</p>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                    <div className="card shadow p-4 text-center">
                        <h1 className="font-weight-bold mb-0">Free</h1>
                        <small>Forever</small>
                        <div className="mt-3">
                            <p className="mb-0">10 FAQs</p>
                            <p className="mb-0">5 Buttons</p>
                            <p className="mb-0">100 Conversations <small>(per month)</small></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                    <div className="card shadow p-4 text-center">
                        <h1 className="font-weight-bold mb-0">9$</h1>
                        <small>per month</small> 
                        <div className="mt-3">
                            <p className="mb-0">Unlimited FAQs</p>
                            <p className="mb-0">10 buttons</p>
                            <p className="mb-0">1000 conversations <small>(per month)</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row justify-content-center my-4">
                <div className="col-12 my-2 text-center">
                    <h3 className="text-center font-weight-bold">
                        Need help in creating your bot?
                </h3>
                    <p className="font-weight-bold opacity-p6">We are happy to put our expertise at your service</p>
                    <a href="https://getshoutout.com/bookaDemo" target="_blank" className="btn btn-dark rounded-0" >Book a Demo</a>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col">
                    <div className="m-3 p-3 d-flex justify-content-center align-items-center">
                        <small>© 2020 Copyright <a href="https://getshoutout.com/"> ShoutOUT Labs</a></small>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default AnswerBot