import React, { useState, useEffect } from 'react'
import { useRouteData, Head } from 'react-static';

import { Link } from '@reach/router'
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';
// import Service from './../../services/Services';

// import BlogPostsComponent from './../../components/blog/BlogPost3';
import { submitGTMEvent } from './../../services/AnalyticsServices';
import UtilService from './../../services/UtilService';

import SMS from './../../images/sms.svg';
import Email from './../../images/email.svg';
import WhatsApp from './../../images/whatsapp.svg';
import Messenger from './../../images/messenger.svg';


import CustomersSlidingBar from './../customers/CustomersSlidingBar';
import './Home.css';
import CustomerLogos from './CustomerLogos';
import SignUpData from '../pricing/SignUpData';

let wordIndex = 0;
// let productIndex = 0;
const wordList = ["SMS", "EMAIL", "WHATSAPP", "MESSENGER"];



const productViews = [
  {
    title: "Dashboard",
    image: "dashboard",
    url: "dashboard"
  },
  {
    title: "People",
    image: "people",
    url: "people"
  },
  {
    title: "Campaigns",
    image: "campaigns",
    url: "campaigns"
  },
  {
    title: "Campaign Create",
    image: "campaign-create",
    url: "campaigns/create/new"
  },
  {
    title: "Customer Profile",
    image: "customer-profile",
    url: "people/john-peterson"
  },
];
const features = [
  {
    title: "Customer Segments",
    details: "Send the right message to the right customer. Segment your customer database and create more relevant campaigns.",
    image: "/assets/images/engage/segment.svg"
  },

  {
    title: "Personal Touch",
    details: "Compose more dynamic content. Send personalized messages for all your customers at once with less effort.",
    image: "/assets/images/engage/message.svg"
  },

  {
    title: "Triggered / Automated Messages",
    details: "Keep your customers informed on their every action. Setup unlimited triggers to kick off messages when and how you want.",
    image: "/assets/images/engage/bullhorn.svg"
  },

  {
    title: "Two-way communication",
    details: "Build better one on one relationship with your customers. Enjoy sending and receiving messages on the same platform.",
    image: "/assets/images/engage/two-way.svg"
  },

  {
    title: "Import Contacts",
    details: "Enjoy hassle free data importing. Upload your customer data within few seconds via excel, CSV or by integrating any application to our platform",
    image: "/assets/images/engage/add.svg"
  },

  {
    title: "Message Templates",
    details: "Compose like a pro. Get access to pre designed messaging templates, which gives a touch of professionalism to your messages.",
    image: "/assets/images/engage/sms.svg"
  },
];


const featuresNew = [
  {
    name: "List Segmentation",
    details: "Create different contact groups based on interests, demographics and user info to send relevant and targeted campaigns.",
    image: "/assets/images/home/features/list-management.png",
    imageAlt: "text-messaging-service",
    title: "list-management"
  },
  {
    name: "Personalized messaging",
    details: "Customize messages to suit each individual recipient by automatically including their names and other specific information in the message content.",
    image: "/assets/images/home/features/personalized-messaging.png",
    imageAlt: "personalized-SMS-marketing",
    title: "personalized-SMS-marketing"
  },
  {
    name: "Sender IDs / phone numbers",
    details: "Send campaigns under a unique mobile number or name to create a special presence in your recipients’ inbox and use it as a powerful branding tactic.",
    image: "/assets/images/home/features/send-sms-with-company-name.png",
    imageAlt: "send-sms-with-company-name-for-free",
    title: "sms-gateway-custom-sender-id"
  },
  {
    name: "Detailed analytics",
    details: "Track your campaigns and monitor how many SMS were delivered and opened, and who opened them.",
    image: "/assets/images/home/features/campaign-analytics.png",
    imageAlt: "campaign-analytics",
    title: "campaign-analytics"
  },
  {
    name: "Campaign scheduling",
    details: "Take all the time you need to create the perfect campaign while avoiding any last minute hassles by scheduling campaigns to be sent at a later time.",
    image: "/assets/images/home/features/scheduled-text-messages.png",
    imageAlt: "scheduled-text-messages",
    title: "scheduled-text-messages"
  },
]

const integrations = ["leadads", "messenger", "shoutoutforms", "typeform", "woocommerce"]


const renderStars = number => {
  const stars = [];
  for (var i = 0; i < number; i++) {
    stars.push(
      <img
        key={i}
        className="mr-1"
        src="./assets/images/icons/star.svg"
        alt="star"
      />
    );
  }
  return stars;
};

const Home = () => {
  const { customerReviews = [] } = useRouteData();
  const [word, setWord] = useState("SMS");
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState(0);
  // const [selectedIntegration, setSelectedIntegration] = useState(0)

  const onClickSlide = e => {
    const selectedImage = e.target.name;
    setSelectedProduct(selectedImage);
  };

  const onClickFeature = e => {
    const selectedFeatureId = parseInt(e.currentTarget.name);
    setSelectedFeature(selectedFeatureId);
  }

  // const onClickIntegration = (e) => {
  //   const integrationName = e.target.name;
  // }

  // const onClickProduct = (e) => {
  //   const direction = e.target.name;
  //  if(productIndex > 0 && productIndex < 4){
  //   if(direction === "next"){
  //     productIndex++;
  //   }
  //   else {
  //     productIndex--;
  //   }
  //  }
  //  else {
  //    productIndex = 0;
  //  }

  //  console.log(productIndex, direction)
  // }



  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const { so_user_id, so_user_email, so_user_name } = UtilService.decodeToken(token);
        submitGTMEvent({ 'event': 'login', 'userId': so_user_id, username: so_user_name, email: so_user_email });
        //updateUserInfo({ username: so_user_name, email: so_user_email, externalId: so_user_id, firstname: so_user_name });
      } catch (e) {

      }
    }

    const interval = setInterval(() => {
      wordIndex = (wordIndex + 1) % wordList.length;
      setWord(wordList[wordIndex]);
    }, 4000);
    return () => clearInterval(interval);

  }, [])

  return (<div className="home h-100 bg-header">
    <Head>
      <meta property="og:image" content="/assets/images/engage/thumbnail-shoutout.png" />
      <meta property="twitter:image" content="/assets/images/engage/thumbnail-shoutout.png" />
      <meta property="og:title" content="SMS Messaging That Converts | ShoutOUT" />
      <meta property="og:site_name" content="SMS Messaging That Converts | ShoutOUT" />
      <meta property="og:description" content="Messaging platform for SMS campaigns, shared inbox for omnichannel customer service, and two-way messaging, SMS API & OTP API for developers." />
      <link rel="canonical" href="https://getshoutout.com" />
      <meta name="description" content="Messaging platform for SMS campaigns, shared inbox for omnichannel customer service, and two-way messaging, SMS API & OTP API for developers." />
      <meta name="keywords" content="omnichannel messaging platform, omnichannel customer experience, omnichannel customer service, omnichannel communication, omnichannel inbox, WhatsApp automated reply, WhatsApp business API, Integrate chatbot with Facebook Messenger, customer service chatbot, campaign management, messaging platform, text messaging service, bulk sms, mass texting service, text messaging platform, scheduled text messages, sms campaigns, sms gateway custom sender id, send sms with company name, personalized sms marketing" />
      <title>SMS Messaging That Converts | ShoutOUT</title>
    </Head>
    <MainNavbar />
    {/* Header */}
    <div className="container">

      <div className="row phone-top-space justify-content-center">
        <div className="col-lg-9 col-md-10 col-sm-12 text-center mt-5 mb-3">
          {/*         
        {word === "SMS" || word === "WHATSAPP" ? <a className='ml-2' href="https://www.producthunt.com/posts/shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=332406&theme=light" alt="ShoutOUT - Send personalized bulk SMS to customers | Product Hunt" width="250" height="54" /></a> :
            <a className='ml-2' href="https://www.producthunt.com/posts/shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-shoutout-56aa50d3-750a-48fb-be3d-a5dd962d70ff" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=332406&theme=light&period=daily" alt="ShoutOUT - Send personalized bulk SMS to customers | Product Hunt" width="250" height="54" /></a>}
         */}
          <h1 className="heading font-weight-bold mt-4"><span className='text-primary'>SMS Messaging </span><br /> That Converts</h1>

          <p>Increase customer conversions with our user friendly text messaging platform. Send alerts, promotions, reminders, short links and marketing campaigns.</p>
          <div>
            <Link to="/signup" className="btn btn-primary border-2 border-radius-2 mt-2 mr-2">Try for Free</Link>
            <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2">Book a Demo</Link>
          </div>
          <br />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-3 mb-5">
          <img className="img-fluid" src="./assets/images/text-messaging-service.gif" alt="text-messaging-service" title="text-messaging-service" />
        </div>
      </div>







      {/* Customer Logos  */}
      <div className="container my-4">
        {/* <CustomersSlidingBar companies={companies} /> */}
        <CustomerLogos />

        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10 col-sm-12 text-center mt-5">
            {/* <h2 className="font-weight-bold mb-4">Our products</h2>
            <h5 className="font-weight-bold text-primary">ShoutOUT Engage</h5> */}
            <h2 className="font-weight-bold mb-2 text-primary">Messaging platform and campaign management for SMS</h2>
          </div>
          <div className="col-lg-8 col-md-10 col-sm-12 text-center">
            <p>Reinvent your conversations with our all-in-one messaging platform. Easily manage campaigns for SMS in one dashboard.</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-12 my-auto">
            {featuresNew.map(({ imageAlt, title, image }, index) => {
              return (
                <img key={`feature-${index}`} className={`img-fluid ${selectedFeature === index ? "feature-image-display" : "feature-image-hidden"}`} alt={imageAlt} title={title} src={image} />
              )
            })}
          </div>
          <div className="col-lg-6 col-md-8 col-sm-12 my-auto">
            <br />
            {/* <p>Reinvent your conversations with our all-in-one messaging platform. Easily manage campaigns for SMS and Email in one dashboard. </p> */}
            {featuresNew.map(({ name, title, details, images, id }, index) => {
              return (<div className="border-dark border-bottom-1 py-3" key={index}>
                <button onClick={onClickFeature} name={index} className="text-dark h5 p-0 bg-transparent w-100 my-2 border-0 rounded-1 text-left">
                  <div className="d-flex justify-content-between">
                    <span className={selectedFeature === index ? "text-primary" : ""}>{name}</span>
                    <img src={`/assets/images/home/features/${selectedFeature === index ? "chevron-down.svg" : "chevron-up.svg"} `} width="14" />
                  </div>
                </button>
                {selectedFeature === index && <p className="py-3">{details}</p>}
              </div>
              )
            })}

          </div>
        </div>
      </div>

    </div>
    {/* Product Screenshots */}
    <div className="container my-4">

      <div className="row text-center justify-content-center">
        <div className="col-lg-10 col-md-10 col-sm-12 mt-5">
          <h2 className="font-weight-bold mt-4 text-primary">Here’s what some of our clients have to say</h2>
          <h5><i>"Incredible value, great usability, great support"</i></h5>
        </div>
        <div className="col-lg-10 col-md-10 col-sm-12">
          <div className="card shadow p-4">
            <img className="mx-auto img-fluid w-10 rounded-circle" src="./assets/images/home/testimonial_image.png" />
            <div className="mb-2">
              <p className="mb-0">Christian P</p>
              <p className="text-muted">Co-Founder and CEO | Small Business - Engineering</p>
            </div>
            <p className="mb-0">
              <span className="quote-placement-1 display-2 text-muted">“</span>
              We have tried pretty much every other SMS solution out there, and ShoutOUT is by far the best we have seen. It is effortless to use, customer support responds within minutes, and the value for money is excellent. ShoutOUT has everything we needed for SMS marketing at the perfect price. We can now reach our customers seamlessly, resulting in better customer relationships and more conversions and sales.
              <br />       <br />
              I only wish I had found them sooner!
              <span className="quote-placement-2 display-2 text-muted">”</span>
            </p>
          </div>
        </div>
      </div>

      <div className="row text-center justify-content-center">
        <div className="col-lg-10 col-md-10 col-sm-12 mt-5">
          <h2 className="font-weight-bold mt-4 text-primary">How your business can use SMS messaging</h2>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2  order-md-2 order-1">
          <img className="img-fluid pb-1 pt-4 px-2" src="/assets/images/home/case-study/promotional-text-messages-examples.png" alt="promotional-text-messages-examples" title="promotional-text-messages-examples" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-lg-1 order-md-1 order-2">
          <div className="pb-4 pt-1 px-2 text-left">
            <h6 className="text-primary">Offers and promotions</h6>
            <h3>Treat your loyal customers the right way</h3>
            <p className="mb-0">
              Send promotional messages with offers and discounts to your loyal customer base. Make them feel valued, retain them, in turn.
            </p>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img className="img-fluid pb-1 pt-4 px-2" src="/assets/images/home/case-study/text-message-campaign-examples.png" alt="text-message-campaign-examples" title="text-message-campaign-examples" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
          <div className="pb-4 pt-1 px-2 text-left">
            <h6 className="text-primary">Customer reviews</h6>
            <h3>Collecting customer feedback made easier</h3>
            <p className="mb-0">
              Customer feedback is only one text away. Send review site links via a simple text, get instant reviews. Shorten URLs with the in-built link shortener.
            </p>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-md-2 order-1">
          <img className="img-fluid pb-1 pt-4 px-2" src="/assets/images/home/case-study/restaurant-sms-marketing-sample.png" alt="restaurant-sms-marketing-sample" title="sample-text-message-marketing-for-restaurants" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-lg-1 order-md-1 order-2">
          <div className="pb-4 pt-1 px-2 text-left">
            <h6 className="text-primary">Order status updates</h6>
            <h3 className="">Keep customers updated on their placed orders</h3>
            <p className="mb-0">
              Instantly update your customers on the status of their orders. Automate text messages to inform them when their orders are confirmed, processed, dispatched and delivered.
            </p>
          </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img className="img-fluid pb-1 pt-4 px-2" src="/assets/images/home/case-study/conversational-messaging.png" alt="two-way-texting-for-business" title="conversational-messaging" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
          <div className="pb-4 pt-1 px-2 text-left">
            <h6 className="text-primary">Conversational messaging</h6>
            <h3 className="">Let your customers talk to you</h3>
            <p className="mb-0">
              Take a step further from campaigns and build conversations on your customer’s favorite messaging channel. Get 10X response rate.
            </p>
          </div>
        </div>
      </div>


      <div className="row justify-content-center my-5">
        <div className="col-lg-10 col-md-10 col-sm-12">
          <div className="border-0 d-flex align-items-center justify-content-center py-3 px-4">
            <Link to="/pricing" id="body-link-pricing" className="btn btn-primary border-2 border-radius-2">See Pricing</Link>
          </div>
        </div>
      </div>


      <div className="row text-center justify-content-center">
        <div className="col-lg-10 col-md-10 col-sm-12 mt-5">
          <h2 className="font-weight-bold my-4 text-primary">More solutions that help your business grow</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12 mb-3">
          <div className="shadow border-0 d-flex align-items-center product-tile-move-left">
            <img className="img-fluid w-30" src="/assets/images/home/products/send-sms-via-api.png" alt="bulk-sms-gateway-api" title="bulk-sms-gateway-api" />
            <div className="p-4">
              <h5 className="font-weight-bold">ShoutOUT Lite</h5>
              <p>
                SMS API for developers, with two way messaging and SMS OTP. Integrate our hassle-free SMS API to send and receive SMS from your app, and generate OTP verification codes.
              </p>
              <a href="https://lite.getshoutout.com" target="_blank" className="btn btn-transparent text-primary p-0">Learn more</a>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div className="shadow border-0 d-flex flex-row-reverse align-items-center product-tile-move-right">
            <img className="img-fluid w-30" src="/assets/images/home/products/omnichannel-messaging-platform.png" alt="omnichannel-customer-experience" title="omnichannel-inbox" />
            <div className="p-4">
              <h5 className="font-weight-bold">ShoutOUT Inbox</h5>
              <p>
                Omnichannel messaging platform for customer communication. Manage all your customer conversations from one common inbox, while still letting them contact you through any channel they prefer.
              </p>
              <a href="/omnichannel-messaging-platform" target="_blank" className="btn btn-transparent text-primary p-0">Learn more</a>
            </div>
          </div>
        </div>
      </div>




    </div>


    {/* Reviews */}
    <div className="container">
      <div className="row mt-5 justify-content-center">
        <div className="col-lg-10 col-md-10 col-sm-12 text-center">
          <h2 className="font-weight-bold text-primary">Loved by thousands of customers</h2>
          <h5 className="text-center mb-4">What ShoutOUT users say</h5>
        </div>
      </div>
      <div className="row justify-content-center">
        {customerReviews.map(({ rating, name, title, source, reviewText }, index) => {
          return (
            <div className="col-lg-5 col-sm-12" key={index}>
              <div className="card shadow p-4 mb-3">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="d-flex justify-content-start align-items-end"><h1>{rating}</h1><h4>/5.0</h4></div>
                  <img className="" src={`./assets/images/reviews/${source}.png`} alt="source" />
                </div>
                <p className="mb-0">{reviewText}</p>

              </div>
            </div>
          );
        })}


      </div>
      <SignUpData />
    </div>

    <section>
      <CallToAction title={"Send Your First Campaign In Minutes"} />
    </section>

    <MainFooter />
  </div>
  )

};
export default Home;