import React from 'react';
import { Head } from 'react-static'; 
import './SampleCode.css';
const RESTSampleCode = () => {
  return (
    <div className="card p-4 bg-dark text-white">
      <p><span className="text-comment">url</span> : 'https://api.getshoutout.com/coreservice/messages',</p>
      <p><span className="text-comment">method</span> : 'POST',</p>
      <p><span className="text-comment">headers</span> : {"{"}</p>
      <p className="pl-2"><span className="text-comment">'Content-Type'</span> : 'application/json',</p>
      <p className="pl-2"><span className="text-comment">'Authorization'</span> : 'Apikey xxxxx.xx.xx.xxxx',</p>
      <p>{"}"},</p>
      <p><span className="text-comment">body</span> : JSON.stringify({"{"}</p>
      <p className="pl-2"><span className="text-comment">"source"</span> : "ShoutDEMO",</p>
      <p className="pl-2"><span className="text-comment">"destinations"</span> : ["94771234567"],</p>
      <p className="pl-2"><span className="text-comment">"transports"</span> : ["sms"],</p>
      <p className="pl-2"><span className="text-comment">"content"</span> : {"{"}</p>
      <p className="pl-4"><span className="text-comment">"sms"</span> : "Sent via ShoutOUT Lite"</p>
      <p className="pl-2">{"}"},</p>
      <p>{"}"}),</p>
    </div>


  )
}
export default RESTSampleCode;