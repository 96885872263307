import axios from "axios";
import Constants from "./../../Constants";
const ACCOUNT_URL = `${Constants.BASE_API2_URL}api/`;
const ACCOUNT_SERVICE2 = `${Constants.BASE_API_URL}authservice/`;
const BILLING_SERVICE = `${Constants.BASE_API3_URL}paymentservice/`;
const BILLING_SERVICE_TEMP = `${Constants.BASE_API3_URL_TEMP}paymentservice/`;

class Services {
  static getPricingPackages() {
    return new Promise((resolve) => {
      resolve({
        segment: [
          {
            feature: "No of people managed on the platform",
            free: { isAvailable: "Unlimited", description: "" },
            growth: { isAvailable: "Unlimited", description: "" },
            pro: { isAvailable: "Unlimited", description: "" },
          },
          {
            feature: "Customer segmentation",
            free: {
              isAvailable: "Based on customer attributes",
              description: "",
            },
            growth: {
              isAvailable: "Based on customer attributes",
              description: "",
            },
            pro: {
              isAvailable: "Based on customer attributes and actions",
              description: "",
            },
          },
          {
            feature: "360° customer profile view",
            free: {
              isAvailable: "Basic profile with all collected information",
              description: "",
            },
            growth: {
              isAvailable: "Customer profile with user activity history",
              description: "",
            },
            pro: {
              isAvailable: "Customer profile with purchase history",
              description: "",
            },
          },
          {
            feature: "Upload customer lists via CSV/Excel",
            free: { isAvailable: "Yes", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "API Integration",
            featureDescription: "includes support and documentation",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Automatic creation of Facebook custom audience",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "No", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
        ],
        engage: [
          {
            feature: "Create unlimited SMS and email campaigns",
            free: { isAvailable: "Yes", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Add personalized custom attributes to SMS and emails",
            free: { isAvailable: "Yes", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Campaign Analytics",
            free: { isAvailable: "Yes", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Add short URLs to SMS campaigns",
            free: { isAvailable: "Yes", description: "" },
            growth: { isAvailable: "Yes", description: "ShoutOUT short link" },
            pro: { isAvailable: "Yes", description: "Branded link" },
          },
          {
            feature: "Create trigger SMS/ email campaigns",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Third Party SMSC and SMTP gateway integration",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "No", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "SMS & Email Templates",
            free: { isAvailable: "Yes", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Multiple Sender IDs / Mobile Numbers",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "ShoutOUT Forms Solution powered with AI",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "Yes", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "WhatsApp Business API",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "No", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Facebook Messenger Integration",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "No", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "Advanced Analytics",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "No", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
          {
            feature: "24x7 Customer Support via Chat",
            free: { isAvailable: "No", description: "" },
            growth: { isAvailable: "No", description: "" },
            pro: { isAvailable: "Yes", description: "" },
          },
        ],
        // reward: [
        //     {
        //         feature: "Points Program",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "Yes", description: "" }
        //     },
        //     {
        //         feature: "Customer referral program",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "Yes", description: "" }
        //     },
        //     {
        //         feature: "Branded customer rewards portal",
        //         featureDescription: "Enterprise only",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "No", description: "" }
        //     },
        //     {
        //         feature: "Loyalty and reward API",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "Yes", description: "" }
        //     },
        //     {
        //         feature: "Third party reward pool",
        //         featureDescription: "Enterprise only",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "No", description: "" }
        //     },
        // ],
        // analytics: [
        //     {
        //         feature: "Standard customer reports",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "Yes", description: "" }
        //     },
        //     {
        //         feature: "Customized reports based on collectd data",
        //         featureDescription: "Enterprise only",
        //         free: { isAvailable: "No", description: "" },
        //         growth: { isAvailable: "No", description: "" },
        //         pro: { isAvailable: "No", description: "" }
        //     },
        // ]
      });
    });
  }

  static getFeatureDetails() {
    return new Promise((resolve) => {
      resolve(
        // [
        //     { id: "mobile-numbers" },
        //     { id: "sender-ids" },
        //     { id: "unlimited-contacts" },
        //     { id: "segmentation" },
        //     { id: "multiple-segments" },
        //     { id: "scheduling" },
        //     { id: "personalized" },
        //     { id: "url-shortening" },
        //     { id: "emojis" },
        //     { id: "sms-templates" },
        //     { id: "campaign-analytics" },
        // ],
        [
          {
            id: "mobile-numbers",
            name: "Mobile Numbers",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "sender-ids",
            name: "Alphanumeric Sender IDs",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "unlimited-contacts",
            name: "Quickly Upload Unlimited Contacts",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "segmentation",
            name: "Contact Filtering and Segmentation",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "multiple-segments",
            name: "Multiple segments / audiences in campaigns",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "scheduling",
            name: "Scheduling SMS Campaigns",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "personalized",
            name: "Personalized SMS with Multiple Attributes",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "url-shortening",
            name: "Built-in URL shortening",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "emojis",
            name: "Emojis",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "sms-templates",
            name: "SMS Templates",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
          {
            id: "campaign-analytics",
            name: "Click Tracking and Detailed SMS Campaign Analytics",
            image: "https://via.placeholder.com/450",
            story: "",
            challenge: "",
            solution: "",
          },
        ]
      );
    });
  }

  static getCustomerDetails() {
    return new Promise((resolve) => {
      resolve([
        {
          id: "dsityres",
          name: "DSI Tyres",
          image: "dsi.png",
          story:
            "With a history spanning all the way back to 1983, DSI tyres has come a long way to establish themselves as the market leader by a wide margin. A member of the DSI Samson Group, a prestigious conglomerate in Sri Lanka, DSI Tyres currently serves more than 60 countries across 6 continents manufacturing superior quality bicycle tyres, motorcycle tyres, lawn & garden tyres, light truck tyres, and trolley wheel tyres. Being in business for more than 3 decades with continuous additions to their product range, DSI tyres now boasts a very engaging broad clientele spread across the nation. To develop a loyalty platform to include this diverse clientele as part of their customer retention initiatives was a big bottleneck DSI tyres faced and for this ShoutOUT Labs came up with a solution.",
          challenge:
            "DSI tyres operates through third party vendors restricting themselves from having direct relationships with their end users. They opted to maintain communications with their customer base through postcards which was expensive, time consuming, and took a lot of manual data handling. The biggest challenge ShoutOUT faced was to automate the process in a cost effective way while keeping it simple for the end customers. Tracking the loyal customers who act as their brand ambassadors and rewarding them was another aspect their current loyalty program lacked. Coming up with a solution to include an end to end customer referral portal was another challenge that ShoutOUT was presented with.",
          solution:
            "A comprehensive end to end loyalty platform that automated the process of gathering customer data through incoming SMS and giving them instant digital rewards for being a part of the loyalty program was then developed to tackle the above mentioned challenges. Customers can get registered with the loyalty club and collect points by sending a text message with the unique code that comes with the tyre. DSI offers cash back rewards with mobile recharges and bill settlements at the time they send the code. Moreover, customers can refer their friends and earn more points which they can redeem for rewards they prefer. This platform also facilitates customer segmentation based on location, purchased tyre types, and purchase frequency and campaign management with real time analytics on the performance of both campaigns as well as on the overall loyalty program.",
        },
        {
          id: "mobil",
          name: "Mobil",
          image: "mobil.png",
          story:
            "For more than 150 years, Mobil helped customers around the world in every industry to lower costs, improve productivity, enhance equipment efficiency, and become safer and more profitable organizations by constantly improving their product range. Upon introduction to the Sri Lankan market, Mobil has been the image leader with its flagship brand Mobil1 which has a leading share of the worldwide synthetic motor oil market and moreover it has been asserted with premium products such as Mobil Super Series and Delvac Series. \n Mobil is attached to McShaw (Pvt) Ltd which is a subsidiary of McLerance Group.",
          challenge:
            "Having no stores of their own, Mobil has to rely on third-party venders for the final distribution of goods which restricts their interactions with the end customers. Mobil wanted to launch a campaign where they can engage with their existing customers and collect customer data to maintain a frequent relationship with their customers via different channels. Shoutout was presented with the challenge of developing a campaign that would allow Mobil to engage with the customers and gather customer data to establish direct communication henceforth.",
          solution:
            "ShoutOUT developed a one-off campaign enabling Mobil to gather information about its end users by introducing a scratch code competition. Upon purchase, the customers were asked to send the secret scratch code placed on the oil cans to see whether they have won a reward. \n ShoutOUT has developed the business logic where customers get rewarded based on random selection. Moreover, the reward pool was managed in the ShoutOUT backend where customers get rewarded instantly with a reward available in the pool. \n In the ShoutOUT dashboard, Mobil admins can see real-time logs on all the SMS received, rewards redeemed, and all the people who participated in the campaign. Further, they can instantly connect with their customers using the ShoutOUT campaign.",
        },
        {
          id: "shirohana",
          name: "Shirohana",
          image: "shirohana.png",
          story:
            "Founded in 1986, Shirohana began operations in an era when quality greenhouse-grown flowers and chic flower shops were not in existence. With the objective of offering fresh, beautiful flowers to customers, Shirohana’s first shop opened in Galle Face over 30 years ago, with a signature style of using antique and reclaimed objects together with stylish flower arrangements. A novel idea at the time, the concept of flower shops introduced by Shirohana began to set new trends in Sri Lanka, changing the perception of many people concerning the notion of sending flowers as gifts. With the growing number of outlets and therefore the customers, Shirohana wanted to move into a loyalty program to identify and reward its most loyal customers.",
          challenge:
            "Shirohana wanted to break from the herd and introduce a loyalty program that does not confine customers to earning and burning points based on their activities. Shirohana wanted to give more value to points than just redeeming the monetary value of the points from the bill value. Moreover, they have recognized that constant and effective communication needs to be placed with their customer base.",
          solution:
            "ShoutOUT developed a tier based loyalty program for Shirohana where customers earn points that give them access to additional services and third-party offers allocated to the specific tier they are in. The loyalty platform is seamlessly integrated to their existing point of sales system and customers receive real-time point updates when they make purchases. Shirohana has made their tier based loyalty program more interesting where the more the customers interact with the brand the more points they earn, eventually resulting in them moving into the immediate next tier with bigger benefits. Also, they have connected various rewards from their existing group of companies giving more exciting benefits and offers to their customer base.",
        },
        {
          id: "undp",
          name: "UNDP",
          image: "undp.png",
          story:
            "The United Nations Development Programme is the United Nations' global development network. Headquartered in New York City, the UNDP advocates for change and connects countries to knowledge, experience and resources to help people build a better life. \n One of the major projects funded by the UNDP in collaboration with the Ministry of Disaster Management is integrating data dispersed in different government institutes into a single application in order to make data more meaningful and easier to use. \n ShoutOUT Labs partnered with Loons Lab who carried out the project to provide seamless channel integration for data collection.",
          challenge:
            "The main project was designed to integrate data dispersed in different government institutes into a single platform for meaningful and easier use. \n The When it comes to data collection, they required a channel which could be used by any citizen and institute around the country. Also, the data required to be captured easily through the relevant channel and sent to the centralized platform.",
          solution:
            "ShoutOUT provided the incoming SMS solution where anyone can easily send a text message to the relevant number and update information. \n Data is captured in text format and the content is extracted and sent to the centralized system for validation. \n The solution is seamlessly integrated with their backend system where it captures the SMS content from the sender and sends the data to the centralized system via a REST API.",
        },
        {
          id: "sumathi",
          name: "Sumathi Awards",
          image: "sumathiawards.png",
          story:
            "Sumathi Awards by the Sumathi Group of Company is an annual award bestowed to distinguished individuals involved in Sri Lanka's television screen. Introduced in 1995, the Sumathi ceremony is one of the most popular television program events in Sri Lanka. Three of the award categories are based on popular votes from people where they can vote for the most popular teledrama, actor, and actress via SMS, mobile app, and newspaper coupon votes.",
          challenge:
            "Even though they had utilized the SMS voting system earlier, they had had the challenge of counting SMS votes real time and updating the results on the leaderboard. Moreover, last year (2018) they had introduced mobile app votes where people can send votes through both SMS and the mobile app and show the real time count on the mobile app leaderboard. However, Sumathi Awards wanted to give a seamless experience to the audience by providing a real-time leaderboard on the Sumathi mobile application with votes accumulated from SMS, mobile app, and newspaper coupons.",
          solution:
            "We provided a shortcode solution to receive the SMS votes for the three award categories and to send the vote count to the mobile app votes. The shortcode was available on all the operators with charging enabled for each SMS message. The ShoutOUT incoming SMS solution was powered by an AI solution where the SMS content was identified intelligently and allocated for the right contestant. Eg: Vote for Thumindu - the possibility of people sending SMS with different types of content like the contestant number or the contestant name spelt in different ways like Thumindu, Thumidu, etc. \n Further, ShoutOUT built a highly scalable platform which handled 100 SMS votes per second in the last hour of the voting campaign and it helped to run the system without any interruptions.",
        },
      ]);
    });
  }

  static getCustomerReviews() {
    return new Promise((resolve) => {
      resolve([
        {
          rating: 4.8,
          source: "gcrowd",
          name: "name",
          title: "title",
          reviewText: "Best solution to increase customer engagement",
        },
        {
          rating: 4.5,
          source: "capterra",
          name: "name",
          title: "title",
          reviewText: "Reliable packages for E-Commerce",
        },
        {
          rating: 4.3,
          source: "facebook",
          name: "name",
          title: "title",
          reviewText: "I used bulk SMS for my wedding. It was so cool!",
        },
        {
          rating: 4.1,
          source: "gartner",
          name: "name",
          title: "title",
          reviewText: "Topnotch tech with great support",
        },
        {
          rating: 4.9,
          source: "software-advice",
          name: "name",
          title: "title",
          reviewText: "A tailor-made solution right at your fingertips",
        },
        // { rating: 5, source: "facebook", name: "Tharindu Wijesekara", title: "Film Maker", reviewText: "Up and ready to support customers even on a Sunday when everybody still in bed (Rare scene in SL). Bloody 5 stars from me boys!" },
        // { rating: 4, source: "facebook", name: "Chrishan R Jayak ody", title: "Developer", reviewText: "One of the smoothest service I have ever worked with. Documentation is up-to-date with the examples. Customer service is realtime throughout the day. Highly recommend ShoutOUT for everyone." },
        //{rating:4, source:"SoftwareAdvice", name:"Sehan", title:"Developer", reviewText:"One of the smoothest service I have ever worked with. Documentation is up-to-date with the examples. Customer service is realtime throughout the day. Highly recommend ShoutOUT for everyone."},
        // { rating: 5, source: "capterra", name: "Juber A.", title: "Travel Writer", reviewText: "Awesome software, I was able to accomplish a lot in less time." },
        // { rating: 5, source: "gcrowd", name: "Anjula H", title: "Founder , Marketing and Advertising", reviewText: "ShoutOut provides a vast range of utilities to engage with your customers. The options at disposes to engage with your customer is the biggest benefit of ShoutOut - when you package these tools with an amazing customer support service and top quality user interface: you get the perfect software for customer engagement." }
      ]);
    });
  }

  static getEventData() {
    return new Promise((resolve) => {
      resolve([
        {
          id: "webinar-004",
          open: true,
          type: "Webinar",
          name: "Conversational UX in chatbot design",
          venue: "Online",
          location: "Zoom",
          dateTime: "2020-05-05 19:30:00 GMT+0530",
          dateRange: "",
          articles: [],
          agenda: [
            {
              time: "07.30 - 08.00 PM",
              title:
                "How we improved the user experience in the Prime Minister chatbot",
              description: "by Malintha Adikari",
            },
            {
              time: "08.00 - 08.30 PM",
              title: "What makes a chatbot experience great?",
              description: "by Tudor Teisanu",
            },
            {
              time: "08.30 - 08.45 PM",
              title: "Questions & Answers",
            },
          ],
          speakers: [
            {
              id: "1",
              name: "Tudor Teisanu",
              designation: "UX Designer at PARKNOW Group, Netherland",
              linkedin: "tudor-teisanu-7b08a4b2",
            },
            {
              id: "2",
              name: "Malintha Adikari",
              designation: "Head of ShoutOUT AI",
              linkedin: "malintha",
            },
          ],
          register: "https://e-url.co/3gjkuV",
          facebook: "",
          description:
            "Join the 4th phrase of the free webinar series organized by ShoutOUT Labs, this time partnering with PARKNOW Group, Netherland to share insights on conversational UX and chatbot designs with the aim to provide direct knowledge on how companies can create rapid, helpful customer interactions by crafting a superior chatbot experience.",
        },
        {
          id: "webinar-003",
          open: true,
          type: "Webinar",
          name: "ChatBot Marketing vs Email Marketing",
          venue: "Online",
          location: "Zoom",
          dateTime: "2020-04-23 19:30:00 GMT+0530",
          dateRange: "",
          articles: [],
          speakers: [
            {
              id: "1",
              name: "Ivan Vantagiato",
              designation: "Founder, Digital Marketing Tribe",
              linkedin: "ivanvantagiato",
            },
          ],
          register: "https://e-url.co/kuhbGn",
          facebook:
            "https://www.facebook.com/ShoutOUTLabs/posts/1963273433797861",
          description:
            "This is the  3rd phase of free webinar series organized by ShoutOUT Labs, this time partnering with Digital Marketing Tribe, Australia to learn the past of customer communication - Email and the future - ChatBots by an expert in the industry to jumpstart your journey with chatbots for the future. We will cover Chatbot marketing vs Email marketing. How Chatbots have brought back organic marketing to Facebook. How one wine brand used a chatbot to increase sales by 400%. Followed by a Q & A session to clear your doubts into ChatBots and how your business can get the best out of ChatBots to enhance the customer experience.",
        },
        {
          id: "webinar-002",
          open: true,
          type: "Webinar",
          name: "Taking Customer Experience to the next level with Chatbots",
          venue: "Online",
          location: "Zoom",
          dateTime: "2020-04-16 19:30:00 GMT+0530",
          dateRange: "",
          // video: "p9QqABQXzwg",
          // photos: [1, 2, 3, 4, 5],
          // photosAlbum: "https://www.facebook.com/pg/readmelk/photos/?tab=album&album_id=2216343975081128",
          articles: [],
          agenda: [
            {
              time: "07.30 - 07.50 PM",
              title: "Session 01",
              description: "by Tharidu ",
            },
            {
              time: "07.50 - 08.10 PM",
              title: "Session 02",
              description: "By Malintha",
              //  video: "Hakv26Rpq74"
            },
            {
              time: "08.10 - 08.30 PM",
              title: " Q & A Session",
            },
          ],
          speakers: [
            {
              id: "1",
              name: "Malintha Adikari",
              designation: "Head of ShoutOUT AI",
              linkedin: "malintha",
            },
            {
              id: "2",
              name: "Tharindu Dassanayake",
              designation: "CEO & Founder at ShoutOUT Labs",
              linkedin: "tharindudassanayake",
            },
          ],
          register: "registration link here",
          facebook: "facebook event link",
          description:
            "This is the 2nd phase of the free webinar series conducted by ShoutOUT Labs by partnering with APIDM to learn about chatbots. The session will cover the topics of how chatbots help to scale your messenger marketing efforts by automating customer interactions and transform the customer journey by providing speedy customer resolutions and personalized experiences without necessarily employing customer-support staff.",
        },
        {
          id: "webinar-001",
          open: true,
          type: "Webinar",
          name: "Essence of customer engagement with ShoutOUT 101",
          venue: "Online",
          location: "Zoom",
          dateTime: "2020-03-31 19:30:00 GMT+0530",
          dateRange: "",
          video: "p9QqABQXzwg",
          // photos: [1, 2, 3, 4, 5],
          // photosAlbum: "https://www.facebook.com/pg/readmelk/photos/?tab=album&album_id=2216343975081128",
          articles: [],
          agenda: [
            {
              time: "07.30 - 07.50 PM",
              title: "Omni Channel Powered Better Customer Experience",
              description: "by Anjalee",
            },
            {
              time: "07.50 - 08.10 PM",
              title: "ShoutOUT Product Demo",
              description: "By Gamidu",
              //  video: "Hakv26Rpq74"
            },
            {
              time: "08.10 - 08.30 PM",
              title: " Q & A Session",
            },
          ],
          speakers: [
            {
              id: "1",
              name: "Anjalee Fernando",
              designation: "Public Speaker",
              linkedin: "anjalee-fernando-a8079a145",
            },
            {
              id: "2",
              name: "Gamidu Wickramaarachchi",
              designation: "Sales & Marketing at ShoutOUT Labs",
              linkedin: "gamidu96",
            },
          ],
          register: "registration link here",
          facebook: "facebook event link",
          description:
            "The essence of customer engagement with ShoutOUT 101 was an ultimate guide to understand how to use the ShoutOUT platform, to create better customer engagement to provide a great customer experience. The sessions covered the topics of how to use the ShoutOUT platform to jumpstart your business, how to conduct a campaign by using the platform and what are the extra features that you could gain by using the ShoutOUT platform compared to the alternative platforms. The event consisted of two, one-hour sessions; followed by a 15 min Q&A.",
        },
        {
          id: "gdpr-101-1",
          open: true,
          type: "ShoutOUT Connect",
          name: "GDPR 101",
          venue: "Hatch",
          location: "Colombo",
          dateTime: "2018-11-30 19:00:00 GMT+0530",
          dateRange: "",
          video: "RDxUYga17oo",
          photos: [1, 2, 3, 4, 5],
          photosAlbum:
            "https://www.facebook.com/pg/readmelk/photos/?tab=album&album_id=2216343975081128",
          articles: {
            title:
              "The GDPR guide for Sri Lankan companies: Lessons from ShoutOUT Connect",
            image: 1,
            link: "https://www.readme.lk/gdpr-sri-lankan-companies",
          },
          agenda: [
            {
              time: "05.30 - 06.00 PM",
              title: "Arrivals",
            },
            {
              time: "06.00 - 06.10 PM",
              title: "Introduction",
              description: "ShoutOUT and the speakers",
            },
            {
              time: "06.10 - 06.25 PM",
              title: "Session 01",
              description: "Compliance of GDPR for companies outside the EU",
              //video: "Hakv26Rpq74"
            },
            {
              time: "06.25 - 06.40 PM",
              title: "Session 02",
              description:
                "GDPR related technical implementations for applications",
              //video: "Hakv26Rpq74"
            },
            {
              time: "06.40 - 07.30 PM",
              title: "Panel Discussion followed by Q & A ",
            },
            {
              time: "07.30 onwards",
              title: "Networking & Drinks",
            },
          ],
          speakers: [
            {
              id: "1",
              name: "Samantha De Soysa",
              designation: "Attorney At Law",
              linkedin: "samantha-de-soysa-3456b1a3",
            },
            {
              id: "2",
              name: "Asanka Nissanka",
              designation: "VP Engineering at ShoutOUT Labs",
              linkedin: "asankanissanka",
            },
          ],
          register: "registration link here",
          facebook: "facebook event link",
          description:
            "GDPR 101, the first-ever meetup hosted by ShoutOUT Labs with the aim to provide direct insights into how, as a technology startup, a company should implement the GDPR laws into the product development in an attempt to balance between the product and legal requirements. The keynote speakers Samantha de Soysa(Attorney-at-Law/Barrister(Lincoln's Inn)) and  Asanka Nissanka(VP, Technology at ShoutOUT) discussed how to approach GDPR compliance as a start-up.",
        },

        {
          id: "congress-18",
          open: true,
          type: "Trade Show",
          name: "Meet Us @ 4YFN Mobile World Congress",
          venue: "Fira Montjuïc",
          location: "Barcelona",
          dateTime: "2018-02-26 08:00:00 GMT+0530",
          dateRange: "26-28",
          articles: [],
          agenda: [],
          speakers: [],
          description:
            "Come and join us at 4YFN Barcelona, one of the most exciting technology startup events of the year, taking place as part of the Mobile World Congress Barcelona 2018! ShoutOUT will be exhibiting at 4YFN – the customer engagement and loyalty platform. We will be more than happy to set up a quick meeting, so let us know you are coming! You can find us at Hall 8, located at the Fira Montjuïc location (next to Plaza de Espanya), where 4YFN is usually hosted. Feel free to stop by Stand F4.10 to say hi!",
        },
      ]);
    });
  }

  static getSMSPricing() {
    return new Promise((resolve) => {
      resolve([
        {
          name: "Sri Lanka",
          credits: 1,
          code: "LK",
          prefix: "94",
        },
        {
          name: "Canada",
          credits: 0.9,
          code: "CA",
          prefix: "1",
        },
        {
          name: "United States",
          credits: 0.9,
          code: "US",
          prefix: "1",
        },
        {
          name: "India",
          credits: 0.4,
          code: "IN",
          prefix: "91",
        },
        {
          name: "Belize",
          credits: 6,
          code: "BZ",
          prefix: "501",
        },
        {
          name: "Faroe Islands",
          credits: 0.8,
          code: "FO",
          prefix: "298",
        },
        {
          name: "Brunei",
          credits: 3,
          code: "BN",
          prefix: "673",
        },
        {
          name: "Paraguay",
          credits: 2.5,
          code: "PY",
          prefix: "595",
        },
        {
          name: "Central African Republic",
          credits: 4,
          code: "CF",
          prefix: "236",
        },
        {
          name: "Maldives",
          credits: 3,
          code: "MV",
          prefix: "960",
        },
        {
          name: "Gambia",
          credits: 4,
          code: "GM",
          prefix: "220",
        },
        {
          name: "Bahrain",
          credits: 3,
          code: "BH",
          prefix: "973",
        },
        {
          name: "Virgin Islands, US",
          credits: "4",
          code: "VI",
          prefix: "1340",
        },
        {
          name: "Israel",
          credits: 5,
          code: "IL",
          prefix: "972",
        },
        {
          name: "Cook Islands",
          credits: 0.5,
          code: "CK",
          prefix: "682",
        },
        {
          name: "South Africa",
          credits: 1.5,
          code: "ZA",
          prefix: "27",
        },
        {
          name: "Denmark",
          credits: 2.5,
          code: "DK",
          prefix: "45",
        },
        {
          name: "Equatorial Guinea",
          credits: 4,
          code: "GQ",
          prefix: "240",
        },
        {
          name: "Thailand",
          credits: 1.5,
          code: "TH",
          prefix: "66",
        },
        {
          name: "Turkey",
          credits: 2,
          code: "TR",
          prefix: "90",
        },
        {
          name: "Greenland",
          credits: 0.8,
          code: "GL",
          prefix: "299",
        },
        {
          name: "Malta",
          credits: 7.5,
          code: "MT",
          prefix: "356",
        },
        {
          name: "Liechtenstein",
          credits: 2.5,
          code: "LI",
          prefix: "423",
        },
        {
          name: "Gibraltar",
          credits: 5,
          code: "GI",
          prefix: "350",
        },
        {
          name: "Comoros",
          credits: 5,
          code: "KM",
          prefix: "269",
        },
        {
          name: "United Arab Emirates",
          credits: 2.5,
          code: "AE",
          prefix: "971",
        },
        {
          name: "Zimbabwe",
          credits: 8,
          code: "ZW",
          prefix: "263",
        },
        {
          name: "Macau",
          credits: 5,
          code: "MO",
          prefix: "853",
        },
        {
          name: "Seychelles",
          credits: 5.5,
          code: "SC",
          prefix: "248",
        },
        {
          name: "Mauritius",
          credits: 5,
          code: "MU",
          prefix: "230",
        },
        {
          name: "Montenegro",
          credits: 7,
          code: "ME",
          prefix: "382",
        },
        {
          name: "Singapore",
          credits: 5,
          code: "SG",
          prefix: "65",
        },
        {
          name: "China",
          credits: 1,
          code: "CN",
          prefix: "86",
        },
        {
          name: "Sierra Leone",
          credits: 6,
          code: "SL",
          prefix: "232",
        },
        {
          name: "Suriname",
          credits: 7.5,
          code: "SR",
          prefix: "597",
        },
        {
          name: "Republic Of The Congo",
          credits: "6",
          code: "CG",
          prefix: "242",
        },
        {
          name: "Mozambique",
          credits: 5,
          code: "MZ",
          prefix: "258",
        },
        {
          name: "Burkina Faso",
          credits: 9,
          code: "BF",
          prefix: "226",
        },
        {
          name: "Barbados",
          credits: 7.5,
          code: "BB",
          prefix: "1246",
        },
        {
          name: "Bermuda",
          credits: 6,
          code: "BM",
          prefix: "1441",
        },
        {
          name: "Iceland",
          credits: 5,
          code: "IS",
          prefix: "354",
        },
        {
          name: "Malaysia",
          credits: 4.5,
          code: "MY",
          prefix: "60",
        },
        {
          name: "Saudi Arabia",
          credits: 6,
          code: "SA",
          prefix: "966",
        },
        {
          name: "Trinidad and Tobago",
          credits: 6,
          code: "TT",
          prefix: "1868",
        },
        {
          name: "United Kingdom",
          credits: 4,
          code: "GB",
          prefix: "44",
        },
        {
          name: "Lithuania",
          credits: 4,
          code: "LT",
          prefix: "370",
        },
        {
          name: "Poland",
          credits: 6,
          code: "PL",
          prefix: "48",
        },
        {
          name: "Serbia",
          credits: 5,
          code: "RS",
          prefix: "381",
        },
        {
          name: "Laos",
          credits: 5,
          code: "LA",
          prefix: "856",
        },
        {
          name: "El Salvador",
          credits: 8,
          code: "SV",
          prefix: "503",
        },
        {
          name: "Iran",
          credits: 6,
          code: "IR",
          prefix: "98",
        },
        {
          name: "Costa Rica",
          credits: 5,
          code: "CR",
          prefix: "506",
        },
        {
          name: "Philippines",
          credits: 2,
          code: "PH",
          prefix: "63",
        },
        {
          name: "Papua New Guinea",
          credits: 6,
          code: "PG",
          prefix: "675",
        },
        {
          name: "Peru",
          credits: 2,
          code: "PE",
          prefix: "51",
        },
        {
          name: "Antigua and Barbuda",
          credits: 8,
          code: "AG",
          prefix: "1268",
        },
        {
          name: "Puerto Rico",
          credits: 4,
          code: "PR",
          prefix: "1787",
        },
        {
          name: "Slovenia",
          credits: 3,
          code: "SI",
          prefix: "386",
        },
        {
          name: "Bhutan",
          credits: 7,
          code: "BT",
          prefix: "975",
        },
        {
          name: "Pakistan",
          credits: 6,
          code: "PK",
          prefix: "92",
        },
        {
          name: "Lebanon",
          credits: 7.5,
          code: "LB",
          prefix: "961",
        },
        {
          name: "Turks and Caicos Islands",
          credits: 7.5,
          code: "TC",
          prefix: "1649",
        },
        {
          name: "Bahamas",
          credits: 6,
          code: "BS",
          prefix: "1242",
        },
        {
          name: "Colombia",
          credits: 0.5,
          code: "CO",
          prefix: "57",
        },
        {
          name: "Croatia",
          credits: 6,
          code: "HR",
          prefix: "385",
        },
        {
          name: "Anguilla",
          credits: 7.5,
          code: "AI",
          prefix: "1264",
        },
        {
          name: "Aruba",
          credits: 8,
          code: "AW",
          prefix: "297",
        },
        {
          name: "Bonaire, Sint Eustatius and Saba",
          credits: 15,
          code: "BQ",
          prefix: "599",
        },
        {
          name: "Curacao",
          credits: 15,
          code: "CW",
          prefix: "599",
        },
        {
          name: "Dominica",
          credits: 10,
          code: "DM",
          prefix: "1767",
        },
        {
          name: "Eritrea",
          credits: 8,
          code: "ER",
          prefix: "291",
        },
        {
          name: "Ghana",
          credits: 7.5,
          code: "GH",
          prefix: "233",
        },
        {
          name: "Haiti",
          credits: 7.5,
          code: "HT",
          prefix: "509",
        },
        {
          name: "South Korea",
          credits: 2.5,
          code: "KR",
          prefix: "82",
        },
        {
          name: "Cayman Islands",
          credits: 12,
          code: "KY",
          prefix: "1345",
        },
        {
          name: "Sint Maarten (Dutch Part)",
          credits: "8",
          code: "SX",
          prefix: "1721",
        },
        {
          name: "Vanuatu",
          credits: 8,
          code: "VU",
          prefix: "678",
        },
        {
          name: "Montserrat",
          credits: 4,
          code: "MS",
          prefix: "1664",
        },
        {
          name: "Mexico",
          credits: 1.5,
          code: "MX",
          prefix: "52",
        },
        {
          name: "Venezuela",
          credits: 8,
          code: "VE",
          prefix: "58",
        },
        {
          name: "Kuwait",
          credits: 8,
          code: "KW",
          prefix: "965",
        },
        {
          name: "Saint Lucia",
          credits: 2.5,
          code: "LC",
          prefix: "1758",
        },
        {
          name: "Jamaica",
          credits: 15,
          code: "JM",
          prefix: "1876",
        },
        {
          name: "Guatemala",
          credits: 4,
          code: "GT",
          prefix: "502",
        },
        {
          name: "Portugal",
          credits: 4,
          code: "PT",
          prefix: "351",
        },
        {
          name: "Honduras",
          credits: 6.5,
          code: "HN",
          prefix: "504",
        },
        {
          name: "Mongolia",
          credits: 9,
          code: "MN",
          prefix: "976",
        },
        {
          name: "Saint Vincent and The Grenadines",
          credits: 7.5,
          code: "VC",
          prefix: "1784",
        },
        {
          name: "Togo",
          credits: 7.5,
          code: "TG",
          prefix: "228",
        },
        {
          name: "Samoa",
          credits: 5,
          code: "WS",
          prefix: "685",
        },
        {
          name: "Hong Kong",
          credits: 8,
          code: "HK",
          prefix: "852",
        },
        {
          name: "Taiwan",
          credits: 4,
          code: "TW",
          prefix: "886",
        },
        {
          name: "Virgin Islands, British",
          credits: "9",
          code: "VG",
          prefix: "1284",
        },
        {
          name: "Guyana",
          credits: 8,
          code: "GY",
          prefix: "592",
        },
        {
          name: "Czechia",
          credits: "9",
          code: "CZ",
          prefix: "420",
        },
        {
          name: "Andorra",
          credits: 10,
          code: "AD",
          prefix: "376",
        },
        {
          name: "Grenada",
          credits: 5,
          code: "GD",
          prefix: "1473",
        },
        {
          name: "Luxembourg",
          credits: 7.5,
          code: "LU",
          prefix: "352",
        },
        {
          name: "Rwanda",
          credits: 4,
          code: "RW",
          prefix: "250",
        },
        {
          name: "Dominican Republic",
          credits: 5,
          code: "DO",
          prefix: "1809",
        },
        {
          name: "Bolivia",
          credits: 8,
          code: "BO",
          prefix: "591",
        },
        {
          name: "Chile",
          credits: 5,
          code: "CL",
          prefix: "56",
        },
        {
          name: "Indonesia",
          credits: 4,
          code: "ID",
          prefix: "62",
        },
        {
          name: "Angola",
          credits: 5,
          code: "AO",
          prefix: "244",
        },
        {
          name: "Australia",
          credits: 4,
          code: "AU",
          prefix: "61",
        },
        {
          name: "Libya",
          credits: 9,
          code: "LY",
          prefix: "218",
        },
        {
          name: "Benin",
          credits: 4,
          code: "BJ",
          prefix: "229",
        },
        {
          name: "Brazil",
          credits: 2.5,
          code: "BR",
          prefix: "55",
        },
        {
          name: "Gabon",
          credits: 9,
          code: "GA",
          prefix: "241",
        },
        {
          name: "Greece",
          credits: 6,
          code: "GR",
          prefix: "30",
        },
        {
          name: "Russia",
          credits: 7.5,
          code: "RU",
          prefix: "7",
        },
        {
          name: "Sudan",
          credits: 10,
          code: "SD",
          prefix: "249",
        },
        {
          name: "South Sudan",
          credits: 7.5,
          code: "SS",
          prefix: "211",
        },
        {
          name: "Moldova",
          credits: 6,
          code: "MD",
          prefix: "373",
        },
        {
          name: "Namibia",
          credits: 4,
          code: "NA",
          prefix: "264",
        },
        {
          name: "Panama",
          credits: 6,
          code: "PA",
          prefix: "507",
        },
        {
          name: "Qatar",
          credits: 2.5,
          code: "QA",
          prefix: "974",
        },
        {
          name: "Nicaragua",
          credits: 7.5,
          code: "NI",
          prefix: "505",
        },
        {
          name: "Saint Kitts and Nevis",
          credits: 8,
          code: "KN",
          prefix: "1869",
        },
        {
          name: "Vietnam",
          credits: 5,
          code: "VN",
          prefix: "84",
        },
        {
          name: "Sweden",
          credits: 6,
          code: "SE",
          prefix: "46",
        },
        {
          name: "Kenya",
          credits: 8,
          code: "KE",
          prefix: "254",
        },
        {
          name: "Swaziland",
          credits: 8,
          code: "SZ",
          prefix: "268",
        },
        {
          name: "Guam",
          credits: 9,
          code: "GU",
          prefix: "1671",
        },
        {
          name: "Malawi",
          credits: 8,
          code: "MW",
          prefix: "265",
        },
        {
          name: "Democratic Republic of the Congo",
          credits: "11",
          code: "CD",
          prefix: "243",
        },
        {
          name: "Zambia",
          credits: 9,
          code: "ZM",
          prefix: "260",
        },
        {
          name: "Cambodia",
          credits: 10,
          code: "KH",
          prefix: "855",
        },
        {
          name: "Oman",
          credits: 7.5,
          code: "OM",
          prefix: "968",
        },
        {
          name: "Niger",
          credits: 8,
          code: "NE",
          prefix: "227",
        },
        {
          name: "Tonga",
          credits: 5,
          code: "TO",
          prefix: "676",
        },
        {
          name: "Micronesia",
          credits: 9,
          code: "FM",
          prefix: "691",
        },
        {
          name: "Liberia",
          credits: 5,
          code: "LR",
          prefix: "231",
        },
        {
          name: "Palau",
          credits: 9,
          code: "PW",
          prefix: "680",
        },
        {
          name: "Macedonia",
          credits: "11",
          code: "MK",
          prefix: "389",
        },
        {
          name: "Ethiopia",
          credits: 9,
          code: "ET",
          prefix: "251",
        },
        {
          name: "Argentina",
          credits: 5,
          code: "AR",
          prefix: "54",
        },
        {
          name: "Switzerland",
          credits: 8,
          code: "CH",
          prefix: "41",
        },
        {
          name: "Norway",
          credits: 5.5,
          code: "NO",
          prefix: "47",
        },
        {
          name: "Cape Verde",
          credits: 12,
          code: "CV",
          prefix: "238",
        },
        {
          name: "Ireland",
          credits: 7,
          code: "IE",
          prefix: "353",
        },
        {
          name: "Palestinian Territory",
          credits: 10,
          code: "PS",
          prefix: "970",
        },
        {
          name: "Northern Mariana Islands",
          credits: 7.5,
          code: "MP",
          prefix: "1670",
        },
        {
          name: "Latvia",
          credits: 6,
          code: "LV",
          prefix: "371",
        },
        {
          name: "Ecuador",
          credits: 9,
          code: "EC",
          prefix: "593",
        },
        {
          name: "Iraq",
          credits: 9,
          code: "IQ",
          prefix: "964",
        },
        {
          name: "Nauru",
          credits: "12",
          code: "NR",
          prefix: "674",
        },
        {
          name: "Romania",
          credits: 6,
          code: "RO",
          prefix: "40",
        },
        {
          name: "Uganda",
          credits: 8,
          code: "UG",
          prefix: "256",
        },
        {
          name: "Bangladesh",
          credits: 15,
          code: "BD",
          prefix: "880",
        },
        {
          name: "France",
          credits: 7.5,
          code: "FR",
          prefix: "33",
        },
        {
          name: "Egypt",
          credits: 9,
          code: "EG",
          prefix: "20",
        },
        {
          name: "Morocco",
          credits: 7.5,
          code: "MA",
          prefix: "212",
        },
        {
          name: "Tunisia",
          credits: 12.5,
          code: "TN",
          prefix: "216",
        },
        {
          name: "Somalia",
          credits: 12,
          code: "SO",
          prefix: "252",
        },
        {
          name: "Solomon Islands",
          credits: 5,
          code: "SB",
          prefix: "677",
        },
        {
          name: "Chad",
          credits: 15,
          code: "TD",
          prefix: "235",
        },
        {
          name: "Nigeria",
          credits: 9,
          code: "NG",
          prefix: "234",
        },
        {
          name: "Cuba",
          credits: 8,
          code: "CU",
          prefix: "53",
        },
        {
          name: "Kiribati",
          credits: 5,
          code: "KI",
          prefix: "686",
        },
        {
          name: "Bosnia and Herzegovina",
          credits: 6,
          code: "BA",
          prefix: "387",
        },
        {
          name: "Tanzania",
          credits: 10,
          code: "TZ",
          prefix: "255",
        },
        {
          name: "Hungary",
          credits: 9,
          code: "HU",
          prefix: "36",
        },
        {
          name: "Cyprus",
          credits: 3,
          code: "CY",
          prefix: "357",
        },
        {
          name: "Spain",
          credits: 6,
          code: "ES",
          prefix: "34",
        },
        {
          name: "Kazakhstan",
          credits: 15,
          code: "KZ",
          prefix: "7",
        },
        {
          name: "Armenia",
          credits: 15,
          code: "AM",
          prefix: "374",
        },
        {
          name: "Burundi",
          credits: 15,
          code: "BI",
          prefix: "257",
        },
        {
          name: "Ivory Coast",
          credits: 15,
          code: "CI",
          prefix: "225",
        },
        {
          name: "Finland",
          credits: 7.5,
          code: "FI",
          prefix: "358",
        },
        {
          name: "Japan",
          credits: 8,
          code: "JP",
          prefix: "81",
        },
        {
          name: "Lesotho",
          credits: 9,
          code: "LS",
          prefix: "266",
        },
        {
          name: "Madagascar",
          credits: 10,
          code: "MG",
          prefix: "261",
        },
        {
          name: "Algeria",
          credits: 18,
          code: "DZ",
          prefix: "213",
        },
        {
          name: "Belarus",
          credits: 8,
          code: "BY",
          prefix: "375",
        },
        {
          name: "San Marino",
          credits: 10,
          code: "SM",
          prefix: "378",
        },
        {
          name: "Albania",
          credits: 7,
          code: "AL",
          prefix: "355",
        },
        {
          name: "Ukraine",
          credits: 9,
          code: "UA",
          prefix: "380",
        },
        {
          name: "Kyrgyzstan",
          credits: 15,
          code: "KG",
          prefix: "996",
        },
        {
          name: "Slovakia",
          credits: 7,
          code: "SK",
          prefix: "421",
        },
        {
          name: "Afghanistan",
          credits: 20,
          code: "AF",
          prefix: "93",
        },
        {
          name: "Italy",
          credits: 7,
          code: "IT",
          prefix: "39",
        },
        {
          name: "Sao Tome and Principe",
          credits: 10,
          code: "ST",
          prefix: "239",
        },
        {
          name: "Austria",
          credits: 7,
          code: "AT",
          prefix: "43",
        },
        {
          name: "Estonia",
          credits: 6,
          code: "EE",
          prefix: "372",
        },
        {
          name: "Germany",
          credits: 8.5,
          code: "DE",
          prefix: "49",
        },
        {
          name: "Fiji",
          credits: 8,
          code: "FJ",
          prefix: "679",
        },
        {
          name: "Kosovo",
          credits: 20,
          code: "XK",
          prefix: "383",
        },
        {
          name: "Mauritania",
          credits: 12,
          code: "MR",
          prefix: "222",
        },
        {
          name: "Yemen",
          credits: 8,
          code: "YE",
          prefix: "967",
        },
        {
          name: "Uruguay",
          credits: 8,
          code: "UY",
          prefix: "598",
        },
        {
          name: "Cameroon",
          credits: 3,
          code: "CM",
          prefix: "237",
        },
        {
          name: "Georgia",
          credits: 15,
          code: "GE",
          prefix: "995",
        },
        {
          name: "Netherlands",
          credits: 9,
          code: "NL",
          prefix: "31",
        },
        {
          name: "Botswana",
          credits: 5,
          code: "BW",
          prefix: "267",
        },
        {
          name: "New Zealand",
          credits: 8,
          code: "NZ",
          prefix: "64",
        },
        {
          name: "Belgium",
          credits: 10,
          code: "BE",
          prefix: "32",
        },
        {
          name: "East Timor",
          credits: "16",
          code: "TL",
          prefix: "670",
        },
        {
          name: "Myanmar",
          credits: 12,
          code: "MM",
          prefix: "95",
        },
        {
          name: "Mali",
          credits: 8,
          code: "ML",
          prefix: "223",
        },
        {
          name: "Djibouti",
          credits: 4,
          code: "DJ",
          prefix: "253",
        },
        {
          name: "Nepal",
          credits: 12.5,
          code: "NP",
          prefix: "977",
        },
        {
          name: "Tajikistan",
          credits: 15,
          code: "TJ",
          prefix: "992",
        },
        {
          name: "Bulgaria",
          credits: 8,
          code: "BG",
          prefix: "359",
        },
        {
          name: "Uzbekistan",
          credits: 15,
          code: "UZ",
          prefix: "998",
        },
        {
          name: "Jordan",
          credits: 12.5,
          code: "JO",
          prefix: "962",
        },
        {
          name: "Senegal",
          credits: 15,
          code: "SN",
          prefix: "221",
        },
        {
          name: "Syria",
          credits: 12.5,
          code: "SY",
          prefix: "963",
        },
        {
          name: "Turkmenistan",
          credits: 5,
          code: "TM",
          prefix: "993",
        },
        {
          name: "Guinea-Bissau",
          credits: 12.5,
          code: "GW",
          prefix: "245",
        },
        {
          name: "American Samoa",
          credits: 2,
          code: "AS",
          prefix: "1684",
        },
        {
          name: "Marshall Islands",
          credits: 12.5,
          code: "MH",
          prefix: "692",
        },
        {
          name: "Ascension Island",
          credits: "20",
          code: "AC",
          prefix: "247",
        },
        {
          name: "Saint Pierre and Miquelon",
          credits: 12.5,
          code: "PM",
          prefix: "508",
        },
        {
          name: "Martinique",
          credits: 15,
          code: "MQ",
          prefix: "596",
        },
        {
          name: "Azerbaijan",
          credits: 12.5,
          code: "AZ",
          prefix: "994",
        },
        {
          name: "Guinea",
          credits: 12.5,
          code: "GN",
          prefix: "224",
        },
        {
          name: "New Caledonia",
          credits: 8,
          code: "NC",
          prefix: "687",
        },
        {
          name: "Guadeloupe",
          credits: 12.5,
          code: "GP",
          prefix: "590",
        },
        {
          name: "Monaco",
          credits: 16,
          code: "MC",
          prefix: "377",
        },
        {
          name: "French Guiana",
          credits: 12.5,
          code: "GF",
          prefix: "594",
        },
        {
          name: "Réunion Island",
          credits: "27",
          code: "RE",
          prefix: "262",
        },
        {
          name: "French Polynesia",
          credits: 8,
          code: "PF",
          prefix: "689",
        },
        {
          name: "Mayotte",
          credits: "32",
          code: "YT",
          prefix: "269",
        },
      ]);
    });
  }

  static signup(
    signupObj,
    signupMethod = "email",
    defaultProduct = Constants.ENGAGE_PRODUCT,
    token
  ) {
    return axios.post(
      ACCOUNT_SERVICE2 +
        "signup?signupMethod=" +
        signupMethod +
        "&defaultProduct=" +
        defaultProduct +
        "&verifyMethod=otp" +
        "&token=" +
        token,
      signupObj
    );
  }

  static updateProfile(updateRequest) {
    return axios({
      method: "put",
      url: ACCOUNT_SERVICE2 + "update",
      data: updateRequest,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  static getProfile() {
    return axios({
      method: "get",
      url: ACCOUNT_SERVICE2 + "profile",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  static verifyAccount(code) {
    return axios({
      method: "put",
      url: ACCOUNT_SERVICE2 + "verify",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        channel: "email",
      },
      data: {
        otpCode: code,
      },
    });
  }

  static resendAccountVerificationCode() {
    return axios({
      method: "get",
      url: ACCOUNT_SERVICE2 + "resendverification",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        verifyMethod: "otp",
      },
    });
  }

  static getBlogPosts(limitPosts = 3) {
    return axios.get(
      "https://blog.getshoutout.com/wp-json/wp/v2/posts?_embed=true&per_page=" +
        limitPosts +
        "&context=embed"
    );
  }

  static login(loginRequest, loginMethod = "email") {
    return axios.post(
      ACCOUNT_SERVICE2 + "login?loginMethod=" + loginMethod,
      loginRequest
    );
  }

  static resetPassword(email) {
    return axios.post(`${ACCOUNT_URL}accounts/password/reset`, {
      username: email,
    });
  }

  static updatePassword(password, token) {
    return axios.post(
      `${ACCOUNT_URL}accounts/password/update`,
      { password: password },
      { headers: { Authorization: "Bearer " + token } }
    );
  }

  static exchangeToken() {
    return axios({
      method: "get",
      url: ACCOUNT_SERVICE2 + "refreshtoken",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  static contactUs(formData) {
    return axios.post(
      "https://api.getshoutout.com/hookservice/hooks/ShoutOUTContactUs/form",
      formData
    );
  }

  static contactAIUs(formData) {
    return axios.post(
      "https://api.getshoutout.com/hookservice/hooks/ShoutOUTAIContactUs/form",
      formData
    );
  }

  static getPlans() {
    return axios.get(BILLING_SERVICE_TEMP + "packages?version=v4");
  }

  static getSMSPricing2() {
    return axios.get(BILLING_SERVICE_TEMP + "globalprices");
  }

  static async verifyRecaptcha(response) {
    try {
      // const captachaResponse = await axios.post(`${ACCOUNT_SERVICE2}verifyRecaptcha?token=${response}`);
      const captachaResponse = await axios({
        method: "post",
        url: `${ACCOUNT_SERVICE2}verifyRecaptcha?token=${response}`,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      return Promise.resolve({ captachaResponse });
    } catch (e) {
      return Promise.reject({ message: "recaptcha not validated" });
    }
  }
}
export default Services;
