import React from 'react';
import { Head } from 'react-static';
import { Link } from '@reach/router';
import AccountNavbar from './../../components/navbars/AccountNavbar';
const VerifyEmailFailed = () => {
    return (
        <div className="container text-center h-100">
            <Head>
                <title>ShoutOUT | Sender ID Request</title>
            </Head>
            <AccountNavbar />

            <div className="row h-100 pt-70 justify-content-center align-items-center">
                <div className="col-12 col-lg-8 col-md-10">
                    <div className="card shadow border-0">
                        <div className="card-body">
                            <img src="/assets/images/temp/close.svg" alt="Failed" width="80" />
                            <br /><br />
                            <h4>Sender id verification failed. Probably the sender ID already verified or the request is expired. Please try resend again from the dashboard</h4>
                            <br /><br />
                            <Link to="/login" className="btn btn-dark text-white btn-sm btn-round px-5">Go to Launchpad</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default VerifyEmailFailed;