import React from 'react';
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';

const solutionsData = [
    {
        title: "SMS Alerts for Weddings",
        image: "highlights/wedding-text-message-service.svg",
        imgAlt: "text-your-guests",
        imgTitle: "text-your-guests",
        link: "wedding-sms-notifications",
        description: "Wedding SMS is the new trend which both wedding planners as well as brides and grooms are embracing. SMS will help you facilitate communication with vendors and guests before, during and after the wedding.",
        list: [
            "Send wedding invitations",
            "Send venue map link",
            "Get RSVP",
            "Update guests of venue / date changes",
            "Communicate with vendors",
            "Schedule updates on wedding day events",
        ]
    },
    {
        title: "Text Messaging for Schools",
        image: "highlights/tool-to-text-students.svg",
        imgAlt: "text-messaging-for-schools",
        imgTitle: "text-alert-system-for-schools",
        link: "text-messaging-for-schools",
        description: "With SMS, educational institutes can easily manage communication with students, parents, academic and other staff without any hassle, from one place.",
        list: [
            "Send mass updates to all students",
            "Communicate with parents on students’ progress",
            "Set up meetings and send meeting links",
            "Send payment reminders and links",
            "Send online class links",
            "Update on emergencies ",

        ]
    },
    {
        title: "Group Text Messaging for Churches",
        image: "highlights/church-sms.svg",
        imgAlt: "church-texting-service",
        imgTitle: "sms-marketing-for-chruches",
        link: "group-text-messaging-for-churches",
        description: "SMS solution for churches, ministries and other religious institutes to easily and quickly reach your entire congregation, charities, donors and supporters.",
        list: [
            "Send community updates",
            "Send event updates",
            "Receive prayer requests",
            "Send donation and event registration links",
            "Update mass schedule changes",
            "Send volunteer program details",
        ]
    },
    {
        title: "Influencer Text Messaging and Texting for Content Creators",
        image: "highlights/influencer-text-messaging.svg",
        imgAlt: "performance-based-influencer-marketing",
        imgTitle: "influencer-campaign-tracking",
        link: "text-messaging-for-influencers",
        description: "Text messaging solution for influencers and content creators to better engage with your followers and subscribers, and easily promote merch and content.",
        list: [
            "Promote new content",
            "Promote product launches",
            "Drive merch sales by sending short links",
            "Share online links for \"Only Fans\" content",
            "Send donation and event registration links",
            "Send discount codes",

        ]
    },
]

const Industries = () => {
    return (
        <div className="customers">
            <Head>
                <title>SMS Marketing Solutions for Different Industries | ShoutOUT</title>
                <meta name="description" content="SMS marketing solutions for all industries from ShoutOUT. With affordable plans that help you scale. Try for free. No credit card required." />
                <link rel="canonical" href="https://getshoutout.com/industries" />
                <meta name="keywords" content="affordable SMS marketing, bulk sms, text messaging for schools, mass text messaging for schools,  church texting, church texting service, influencer text messaging, influencer marketing" />
            </Head>

            <MainNavbar />
            <div className="container pt-5">

                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="font-weight-bold mt-5 mb-2 text-primary text-center">
                            SMS Marketing Solutions for Different Industries
                        </h2>
                        <div className="h5 text-center mb-4">
                            Reliable text marketing solution for all use cases, whichever industry you come from.
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                            <Link to="/signup" className="btn btn-primary border-2 border-radius-2 mt-2 mr-2">Try for Free</Link>
                            <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2">Book a Demo</Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">


                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                                {solutionsData.map(({ image, imgAlt, imgTitle, title, link, description, list }, index) => (
                                    <div className="card shadow p-4 d-flex flex-lg-row align-items-center mb-3" key={index}>
                                        <img src={`/assets/images/solutions/${image}`} className="icon-width-3" alt={imgAlt} title={imgTitle} />
                                        <div className="card-body border-dark border-left">
                                            <h5 className="font-weight-bold">{title}</h5>
                                            <p>{description}</p>
                                            <ul className="ml-n4">
                                                {list.map((item, index) => (<li key={index}>{item}</li>))}
                                            </ul>
                                            <Link to={`industries/${link}`}>
                                                Learn More
                                                <span className="card-link-arrow"></span>
                                            </Link>
                                            {/* <p className="card-text">{description}</p> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            <CallToAction title="Start growing your business today" />
            <MainFooter />
        </div>
    )
}
export default Industries