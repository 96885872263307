import React from 'react';
import { Link } from '@reach/router'
import MainNavbar from './../components/navbars/MainNavbar';
import BoatImage from './../images/404.gif';

export default () => (
  <div className="h-100">
    <MainNavbar />
    <div className="container h-100">
      <div className="row text-center h-100">
        <div className="col-md-4 my-auto mx-auto">
          <Link to="/" className="text-dark">
            <div className="card p-4 rounded-0 border-0 shadow zoom">
              <img src={BoatImage} className="mx-auto" width="218" alt="404 ShoutOUT Labs" />
              <h1 className="mb-0">404</h1>
              <h5 className="mb-0 font-weight-bold">Lost in Space!</h5>
              <p><small>Click to go back home.</small></p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
)
