import React from 'react';
import { Head } from 'react-static';
import {Link} from '@reach/router';
import AccountNavbar from './../../components/navbars/AccountNavbar';
const VerifyEmailSuccess = () => {
    return (
        <div className="container text-center h-100">
                  <Head>
                <title>ShoutOUT | Sender ID Request</title>
            </Head>
            <AccountNavbar/>
            <div className="row h-100 pt-70 justify-content-center align-items-center">
                <div className="col-12 col-lg-6 col-md-10">
                    <div className="card shadow border-0">
                        <div className="card-body">
                            <img src="/assets/images/temp/tick.svg" alt="Verified" width="100" />
                            <br /><br />
                            <h3>Sender id verified successfully.</h3>
                            <br /><br />
                            <Link to="/login" className="btn btn-dark text-white btn-sm btn-round px-5">Go to Launchpad</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailSuccess;