import React from 'react';
import { Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';

import SMS from './../../images/sms.svg';
import Email from './../../images/email.svg';
import WhatsApp from './../../images/whatsapp.svg';
import Messenger from './../../images/messenger.svg';
import Constants from './../../../Constants';

import HomeBannerImage from './../../images/shoutout-home-banner.png?sizes[]=300,sizes[]=500,sizes[]=768';

import Picture from './../../components/picture';

import './../PricingCommon.css';
import './../ProductsCommon.css';

export default () => (
  <div className="engage">
    <Head>
      <title>Messaging Platform & Campaign Management | ShoutOUT Engage</title>
      <meta name="description" content="SMS messaging platform and campaign management that comes with personalization, customization, URL tracking and analytics." />
      <link rel="canonical" href="https://getshoutout.com/campaign-management" />
      <meta name="keywords" content="campaign management, messaging platform, text messaging service, bulk sms, mass texting service, text messaging platform, scheduled text messages, sms campaigns" />
    </Head>


    <MainNavbar />
    <div className="container pt-5">
      <div className="row my-5">
        <div className="col-lg-7 col-sm-12 my-auto order-lg-1 order-2">
          <h1 className="font-weight-bold text-center text-lg-left">SMS <span className="text-primary">Messaging</span> Platform <br /> for Campaign Management</h1>
          <h4 className="text-center text-lg-left">Messaging that converts.</h4>
          <p className="text-center text-lg-left">Reinvent your conversations with our all-in-one messaging platform. Custom and personalized SMS, at scale.</p>
          {/* <div className="d-flex justify-content-center d-lg-block">
            <a href="#video" className="btn btn-primary">Watch Video</a>
          </div> */}
          <div className="mb-4 text-lg-left text-center">
            <Link to="/signup" className="btn btn-primary border-2 border-radius-2 mt-2 mr-2">Try for Free</Link>
            <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2">Book a Demo</Link>
          </div>
        </div>
        <div className="col-lg-5 col-sm-12 order-lg-2 order-1">
          {/* <img className="rounded-circle" src="https://via.placeholder.com/500" /> */}
          <img className="img-fluid" src="/assets/images/engage/campaign-management.svg" alt="campaign-management" title="campaign-management" />
        </div>
      </div>
      <div className="row text-center mx-auto justify-content-center my-5 pt-3">
        <div className="col-lg-10 col-sm-12">
          <h2 className="font-weight-bold">Cost effective text messaging, at scale</h2>
          <h5 className="my-4">Keep your conversations and campaigns going. <span className="text-primary">Faster. Easier. Engaging.</span></h5>
        </div>
        <div className="col-lg-8 col-sm-12">
          {/* <div className="card shadow p-4"> */}
          <p className="mb-0">SMS campaigns are much more than typing a text and hitting ‘Send’. Naturally, you need a text messaging service platform that can help achieve conversational engagement that translates into more sales!</p>
          {/* </div> */}
        </div>
      </div>



      <div className="row text-center mx-auto justify-content-center my-4">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img src="/assets/images/engage/messaging-platform.svg" alt="messaging-platform" title="messaging-platform" />
            <h6 className="font-weight-bold mt-2">All-in-one view</h6>
            <p className="text-center">
              Send and receive texts at scale. 360° view of recipient profiles. Check campaign analytics with one click. Send messages under a unique business number or company name. One simple and user-friendly dashboard for all.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img className="" src="/assets/images/engage/personalized-sms-messages.svg" alt="personalized-sms-messages" title="personalized-sms-messages" />
            <h6 className="font-weight-bold mt-2">Personalize. Customize</h6>
            <p className="text-center">
              Make your SMS content unique with custom fields and attributes (name, location, dates and more). It is two-fold. Brand your campaign to promote yourself, and create your SMS such that recipients think you sent it just for them.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <img className="" src="/assets/images/engage/bulk-sms.svg" alt="bulk-sms" title="bulk-sms" />
            <h6 className="font-weight-bold mt-2">More for Less</h6>
            <p className="text-center">
              Reduce messaging and campaign costs with cost-effective bulk SMS messaging. Scale efficiently without having to worry about high costs. Benefit from our mass texting service with simple package plans to choose from.
            </p>
          </div>
        </div>
      </div>

      <div className="row text-center mx-auto justify-content-center my-5 pt-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h2 className="font-weight-bold mb-4">All-in-one platform for text messaging and campaigns</h2>
          <h5>Equip yourself with the best platform to send messages that engage and convert.</h5>
        </div>
        <div className="col-8">
          {/* <div className="card shadow p-4"> */}
          <p className="mb-0">Everything has changed. So have your customers. They expect you to value their time, respond without delay and be easily contactable. A minute’s delay can have your customer in your competitors’ hands. Why let it happen when the solution is right here. </p>
          {/* </div> */}
        </div>
      </div>

      <div className="row my-5 pt-3">
        <div className="col-lg-7 col-sm-12 order-2 my-auto">
          <h3 className="font-weight-bold text-primary">Text Messaging Platform</h3>
          <p>Reach your audience through simple two-way text messaging and SMS campaigns. Send scheduled text messages under your preferred name / ID or get a unique mobile number to promote your brand effortlessly. <br /> <br /> Personalize each text content automatically to suit individual recipients. Scale your SMS bulk as you need, reach global audiences, in seconds. Direct and monitor using the inbuilt URL shortener and click tracking. Keep improving with campaign analytics to grow and win.</p>
          <Link to="/text-messaging-service" className="btn btn-outline-primary border-radius-2 mt-2">Learn more</Link>
          {/* comments:link to SMS page */}
        </div>
        <div className="col-lg-5 col-sm-12 order-1">
          <img className="w-75" src="/assets/images/engage/text-messaging-service.svg" alt="text-messaging-service" title="text-messaging-service" />
        </div>
      </div>

      {/* <div className="row my-4">
        <div className="col-lg-7 col-sm-12 order-lg-1 order-2 my-auto">
          <h3 className="font-weight-bold text-primary">Email Messaging Platform</h3>
          <p>Send attractive email campaigns through the simple and user-friendly email platform. Effortlessly stand out in your recipients’ inboxes with a unique Email address. Automatically personalize and customize emails. Save them as templates to be used again to save more time! Easily include anchor text and images to drive recipients where you need. </p>
          <button className="btn btn-transparent btn-sm text-primary p-0">Explore our Email platform</button>
        </div>
        <div className="col-lg-5 col-sm-12 order-lg-2 order-1">
          <img className="w-75" src="/assets/images/engage/email-messaging-platform.svg" />
        </div>
      </div> */}



      <div className="row text-center mx-auto justify-content-center mt-5 pt-4">
        <div className="col-lg-10 col-md-12 col-sm-12">
          <h2 className="font-weight-bold">ShoutOUT's messaging platform comes with unique features you can't say no to!</h2>
          <h5> Let your SMS campaigns stand out</h5>
        </div>
      </div>






      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/personalized-sms-marketing.svg" alt="personalized-sms-marketing" title="personalized-sms-marketing" />
            <span className="mt-2 text-center">Personalization</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/personalized-texting-for-customers.svg" alt="personalized-texting-for-customers" title="personalized-texting-for-customers" />
            <span className="mt-2 text-center">Customized message content</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/url-shortening.svg" alt="url-shortening" title="short-links" />
            <span className="mt-2 text-center">URL shortening</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/click-tracking.svg" alt="click-tracking" title="click-tracking" />
            <span className="mt-2 text-center">URL tracking</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-colum align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/marketing-campaigns-tracking.svg" alt="digital-campaign-tracking" title="digital-campaign-tracking" />
            <span className="mt-2 text-center">Campaign analytics</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/business-sms-for-customers.svg" alt="business-sms-for-customers" title="business-sms-for-customers" />
            <span className="mt-2 text-center">Customer segmentation</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/targeted-sms-marketing.svg" alt="targeted-sms-marketing" title="targeted-sms-marketing" />
            <span className="mt-2 text-center">Multiple attributes</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/ShoutOUT-Labs.svg" alt="ShoutOUT-Labs" title="ShoutOUT-Labs" />
            <span className="mt-2 text-center">Live SMS preview</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/sender-id-sms.svg" alt="sender-id-sms" title="sender-id-sms" />
            <span className="mt-2 text-center">Sender ID</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/scheduled-text-messages.svg" alt="scheduled-text-messages" title="scheduled-text-messages" />
            <span className="mt-2 text-center">Campaign Scheduling</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/two-way-texting-for-business.svg" alt="two-way-texting-for-business" title="two-way-texting-for-business" />
            <span className="mt-2 text-center">Two way messaging</span>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="d-flex flex-column align-items-center p-4 m-2 card">
            <img className="inbox-icon-width" src="/assets/images/engage/icons/text-message-template.svg" alt="sms-message-template" title="sms-message-template" />
            <span className="mt-2 text-center">Saved templates</span>
          </div>
        </div>


        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="d-flex flex-column align-items-center p-4 m-2 card">
                            <img className="inbox-icon-width" src="/assets/images/solutions/education/icons/results.svg" />
                            <span className="mt-2 text-center">Academic performance results</span>
                        </div>
                    </div> */}
        {/* <div className="col-12 text-center">
          <a href="#" className="btn btn-primary btn-sm">Explore features</a>
          comments:link to sms page temp
        </div> */}
      </div>
      <div className="row mb-4">
        <div className="col-12 text-center">
          <Link to="/features" className="btn btn-outline-primary border-radius-2 mt-2">Learn more</Link>
        </div>
      </div>
      {/* <div id="video" className="row text-center mx-auto justify-content-center mt-5 pt-4">
                    <div className="col-lg-8 col-sm-12">
                        <h2 className="font-weight-bold">A common inbox for all conversational needs</h2>
                        <h5>Efficient, hassle free customer service is here.</h5>
                        <div className="embed-responsive embed-responsive-16by9">
                            <video controls={true} preload={true} muted={true} width="720" src="/assets/images/solutions/education/school-sms-tool.mp4" alt="text-messaging-for-schools" title="schools sms platform" />
                        </div>
                    </div>
                </div> */}


    </div>
    <CallToAction title={"Send messages that convert."} onDemo={false} pricing={true} review={true} />
    <MainFooter />
  </div>

);
