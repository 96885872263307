import React from 'react'
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';
import './Features.css';



const Features = () => {
    const { feature = [] } = useRouteData();
    return (
        <div>
            <Head>
                <title>ShoutOUT Features</title>
            </Head>

            <MainNavbar />
            <div className="container pt-5 opacity-p9">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="font-weight-bold mt-5 text-primary text-center">
                            ShoutOUT Features
                        </h1>
                        <p className="text-center">ShoutOUT Features Description here.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
                        {feature.map(({ id, name, image, story }, index) => (
                            <Link to={`/features/${id}`} key={index}>
                                <div className="zoom card shadow p-4 d-flex flex-lg-row align-items-center mb-3">
                                    {/* <img src={`/assets/images/sms/icons/${image}`} class="icon-width-2" alt="..." /> */}
                                    <img src={image} className="icon-width-2" alt="..." />
                                    <div className="card-body border-dark border-left">
                                        <h5 className="card-title">{name}</h5>
                                        <p className="card-text text-dark">{story}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>


            </div>

            <CallToAction />
            <MainFooter />
        </div>
    )
}
export default Features;