import React, { useEffect, useState, useRef } from "react";
import UtilService from './../../services/UtilService';
import Services from './../../services/Services';

const CountrySelector = ({ setCreditValue, setCountryLocation, creditValue }) => {
    const [countryFilter, setCountryFilter] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState({ name: "Sri Lanka", code: "lk" });
    const [countryBillingMap, setCountryBillingMap] = useState([]);

    const [showSelector, setShowSelector] = useState(false);


    useEffect(() => {
        const hideDropDown = (e) => {
            if (!document.getElementById('pricingselector').contains(e.target)) {
                setShowSelector(false);
            }
        }

        window.addEventListener("click", hideDropDown);

        UtilService.getIpInfo().then((response) => {

            const ipCountry = response.country.toUpperCase();
            const country = countryBillingMap.find(smsPackage => (smsPackage.countryISOCode.toUpperCase() === ipCountry));
            if (country) {

                setCountry({ name: country.countryName, code: country.countryISOCode.toLowerCase() });
                setCreditValue(country.credits);
                setCountryLocation(country.countryISOCode.toLowerCase())
            }
        }
        )

        return () => {
            window.removeEventListener("click", hideDropDown);
        }
    }, [countryBillingMap])

    useEffect(() => {
        const fetchPricing = async () => {
            try {
                setIsLoading(true);
                const countriesBillingMap = await Services.getSMSPricing2()
                setCountryBillingMap(countriesBillingMap.data);
            } catch (e) {

            } finally {
                setIsLoading(false);
            }
        }
        fetchPricing();
    }, [setCountryBillingMap])




    const countryRef = useRef();

    const filteredCountries = countryFilter ? countryBillingMap.filter(smsPackage => smsPackage.countryName.toLowerCase().includes(countryFilter)) : countryBillingMap;
    const sortedFilteredCountries = filteredCountries.sort(function (a, b) {
        if (a.countryName < b.countryName) { return -1; }
        if (a.countryName > b.countryName) { return 1; }
        return 0;
    });

    const onDropDown = (e) => {
        e.stopPropagation();
        if (!showSelector) {
            countryRef.current.focus();
        }
        setShowSelector(!showSelector);
    };

    const onDropToggle = (e) => {

        const countryName = e.currentTarget.id;
        let selectedCountry = countryBillingMap.find(smsPackage => (smsPackage.countryName === countryName));
        // setAmount = plansFiltered.find(plans => (plans.amount))
        setCreditValue(selectedCountry.credits);
        setCountry({ name: countryName, code: selectedCountry.countryISOCode.toLowerCase() });
        setShowSelector(false);
        if (countryFilter !== "") {
            setCountryFilter("");
        }
        // this.setState({ value: selectedCountry.credits, country: { name: countryName, code: selectedCountry.code.toLowerCase() }, selector: false });
        // if (this.state.filterTriggered !== "") {
        //     this.setState({ filterTriggered: "" });
        // }


    };

    const onSMSCountryInput = (e) => {
        e.stopPropagation();
        const searchCountry = e.target.value.toLowerCase();
        setCountryFilter(searchCountry);
    };


    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <div className="dropdown d-inline">
                <button className="text-left btn btn-transparent sms-country-selector p-2 border-0 rounded-1 shadow-pricing dropdown-toggle" type="button" onClick={onDropDown}>
                    <div className="d-flex align-items-center text-truncate text-dark px-3 my-auto">
                        <div className={`d-inline-block mr-2 sms-country-selector-flag ${country.code}`}></div>
                        <div className="text-truncate sms-country-selector-text font-14">{country.name}</div>
                    </div>
                </button>
                <ul className={`dropdown-menu scrollable-menu ${showSelector ? "show" : "hide"}`} id="pricingselector">
                    <li className="text-dark px-3 my-auto">
                        <input className="border-0 w-100 sms-country-selector-input" type="text" autoComplete="off" placeholder="Search.." id="sms-country-search" ref={countryRef} autoFocus onChange={onSMSCountryInput} />
                    </li>
                    {sortedFilteredCountries.map(({ countryName, countryISOCode }, index) => {
                        return (
                            <li className="sms-country-selector-item text-dark px-3 my-auto" onClick={onDropToggle} key={index} id={countryName} value={countryName}><div className={`d-inline-block mr-2 sms-country-selector-flag ${countryISOCode.toLowerCase()}`}></div><span className="font-14">{countryName}</span></li>
                        );
                    }
                    )}
                </ul>
            </div>
            <p className="mt-3 mb-0"> {creditValue} SMS Credit{creditValue > 1 ? "s":""} per SMS</p>
        </div>
    )
}
export default CountrySelector;