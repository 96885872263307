import React from 'react';
import { Head } from 'react-static'; 
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import './Policies.css';


export default () => (
    <div>
        <Head>
            <title>ShoutOUT | Privacy Policy</title>
        </Head>
        <MainNavbar/>
        <section>
    <div className="py-5">
    <div className="container cover-image2 justify-content-center text-center">
            <br/><br/><br/>
            <h5 className="subheading ">Privacy and Policy</h5>
            <div className="row hidden-md-up">
                <div className="col-md-12">
                    <div className="card shadow border-0 p-5">
                            <div className="row complete-products">
                                <div className="col-md-12">
                                    <div className="container privacy-container">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <div className="text-left">
                                                    This policy explains the what, how, and why of the information we
                                                    collect when you visit one of our
                                                    websites, or when you use our Services. It also explains the
                                                    specific ways we use and disclose that
                                                    information. We take your privacy extremely seriously, and we never
                                                    sell lists or email addresses.
                                                    <br /><br />
                                                    In the course of its activity and for the purposes of providing the
                                                    Services (as defined in our General
                                                    Terms of Use), ShoutOUT is required to collect and process the
                                                    personal data of its users (hereafter
                                                    referred to as the “Users”).
                                                    <br /><br />
                                                    ShoutOUT attaches particular importance to the respect for the
                                                    privacy of the Users and of the
                                                    confidentiality of their personal data, and is thus committed to
                                                    processing the data in compliance with
                                                    the applicable laws and regulations, and in particular Law No.
                                                    78-17 of 6th January 1978 relating to
                                                    Information Technology, Data Files and Civil Liberties (hereafter
                                                    referred to as the “Data Protection
                                                    Act”), and Regulation (EU) 2016/679 of the European Parliament and
                                                    of the Council of 27 April 2016 on
                                                    the protection of natural persons with regard to the processing of
                                                    personal data and on the free
                                                    movement of such data (hereafter referred to as the “GDPR”).
                                                    <br />
                                                    <br /><br />
                                                    <span className="blue-heading">Definitions</span>
                                                    <br />
                                                    <br />
                                                    <span className="bold-heading">Personal data:</span> any
                                                    information relating to an identified or
                                                    identifiable natural person,
                                                    that is, a
                                                    person who can be identified, directly or indirectly, by reference
                                                    to an identification number or to
                                                    one or more elements specific to that person.
                                                    <br /><br />
                                                    <span className="bold-heading">Processing of personal data:</span>
                                                    any operation or any set of
                                                    operations relating to personal
                                                    data, whatever the process used, and in particular the collection,
                                                    recording, organisation, storage,
                                                    adaptation or
                                                    alteration, retrieval, consultation, use, disclosure by
                                                    transmission, dissemination or otherwise making
                                                    available, alignment or combination, as well as locking, erasure,
                                                    or destruction.
                                                    <br /><br />
                                                    <span className="bold-heading">Cookie :</span> a cookie is a piece
                                                    of information placed on the hard
                                                    drive of Internet users by the server of
                                                    the site they visit. It contains several pieces of data: the name
                                                    of the server which installed it, an
                                                    identifier in the form of a unique number, and possibly an expiry
                                                    date. This information is sometimes
                                                    stored on the computer in a simple text file that a server accesses
                                                    to read and save pieces of
                                                    information.
                                                    <br />
                                                    <br /><br />
                                                    <span className="blue-heading">Data Controller – DPO</span>
                                                    <br />
                                                    <br />
                                                    The data controller for the processing of the personal data
                                                    referred to herein is ShoutOUT, a Company
                                                    duly incorporated in Sri Lanka bearing registration No PV 118821
                                                    and having its registered office at
                                                    410/33, Bauddhaloka Mawatha, Colombo 07.
                                                    ShoutOUT has appointed a Data Protection Officer who can be
                                                    contacted at the following address:
                                                    dpo@getshoutout.com.
                                                    <br />
                                                    <br /><br />
                                                    <span className="blue-heading">Data collected</span>
                                                    <br />
                                                    <br />
                                                    ShoutOUT collects data from Users in order to make the Services for
                                                    which they have subscribed to the
                                                    platform available to them.
                                                    <br /><br />
                                                    The mandatory or optional nature of the data provided (in order to
                                                    complete the Users’ registration and
                                                    to render the Services) is indicated at the time of collection by
                                                    an asterisk.
                                                    <br /><br />
                                                    In addition, certain data is collected automatically as a result of
                                                    the User’s actions on the site (see
                                                    the paragraph on cookies).
                                                    <br /><br /><br />
                                                    <span className="blue-heading">Purposes</span>
                                                    <br /><br />
                                                    The personal data collected by ShoutOUT during the provision of the
                                                    Services is necessary for the
                                                    performance of the contracts concluded with the Users, or to allow
                                                    ShoutOUT to pursue its legitimate
                                                    interests while respecting the rights of the Users. Certain data
                                                    may also be processed based on the
                                                    Users’ consent.
                                                    <br />
                                                    <br />
                                                    The purposes for which ShoutOUT processes data are the following:
                                                    <br /><br />

                                                    <ul>
                                                        <li>commercial and accounting management of the contract;</li>
                                                        <li>management of customer acquisition and marketing
                                                            activities;</li>
                                                        <li>detection of malicious behaviour (fraud, phishing, spam,
                                                            etc.);</li>
                                                        <li>the improvement of the Users path on the site;</li>
                                                        <li>more generally, any purpose referred to in Article 2 of
                                                            Deliberation No. 2012-209 of 21 June
                                                            2012 creating a simplified standard for the automated
                                                            processing of personal data relating to
                                                            the management of users and prospects.</li>
                                                    </ul>
                                                    <br /><br />
                                                    <span className="blue-heading">Recipients of the data</span>
                                                    <br /><br />
                                                    The personal data collected is intended for ShoutOUT’s commercial
                                                    and accounting departments. It may be
                                                    transmitted to ShoutOUT’s subsidiaries, or to third-party data
                                                    processors which ShoutOUT is authorized
                                                    to use within the context of the performance of its Services.
                                                    <br /><br />
                                                    In this context, personal data may be transferred to an EU or
                                                    non-EU country. ShoutOUT implements
                                                    guarantees ensuring the protection and security of this data, in
                                                    compliance with applicable rules and
                                                    regulations.
                                                    <br /><br />
                                                    ShoutOUT does not transfer or rent personal data to third parties
                                                    for marketing purposes without the
                                                    express consent of the Users of ShoutOUT.
                                                    <br /><br />
                                                    In addition, personal data may only be disclosed to third parties
                                                    for purposes other than marketing in
                                                    the following cases:
                                                    <br /> <br />
                                                    <ul>
                                                        <li>with their authorisation;</li>
                                                        <li>at the request of the competent legal authorities, upon
                                                            judicial request, or in the context of
                                                            a legal dispute.
                                                        </li>
                                                    </ul>
                                                    <br /> <br />
                                                    <span className="blue-heading">Data retention period</span>
                                                    <br /><br />
                                                    To satisfy its legal obligations or in order to have the necessary
                                                    elements to assert its rights,
                                                    ShoutOUT will be able to retain the data under the conditions
                                                    established by applicable rules and
                                                    regulations.
                                                    <br /><br />
                                                    Thus, personal data collected by ShoutOUT relating to the identity
                                                    and contact details of its Users is
                                                    retained for a maximum period of two years after the termination of
                                                    the contractual relationship for
                                                    Users that are customers, or from their collection by the data
                                                    controller or the last contact from the
                                                    Users that are prospects, for the data relating to the latter.
                                                    <br /><br />
                                                    The termination of the contractual relationship is understood as
                                                    the express termination of the
                                                    contract by the User, or the non-use of ShoutOUT Services for a
                                                    period of five years.
                                                    <br /><br /><br />
                                                    <span className="blue-heading">Rights of Users</span>
                                                    <br /><br /> <br />
                                                    In accordance with applicable rules, the Users have the right to
                                                    access and rectify their personal
                                                    data, which enables them to rectify, complete, update, or delete
                                                    data that is inaccurate, incomplete,
                                                    ambiguous, or outdated, or whose collection, use, communication, or
                                                    storage is prohibited.
                                                    <br /><br />
                                                    The Users also have the right to request the limitation of the
                                                    processing, and to oppose on legitimate
                                                    grounds the processing of their personal data. The User may also
                                                    communicate instructions on the fate
                                                    of their personal data in the event of their death.
                                                    <br /><br />
                                                    Where applicable, the User may request the portability of their
                                                    personal data or, where the legal basis
                                                    for the processing is consent, withdraw their consent at any time.
                                                    <br /><br />
                                                    The Users may exercise their rights by sending an email to
                                                    privacy@getshoutout.com.
                                                    These requests shall be processed within a maximum period of 30
                                                    days.
                                                    <br /><br />
                                                    The Users may also at any time modify the data pertaining to them
                                                    by logging on to
                                                    http://www.getshoutout.com and clicking on “edit my profile” or by
                                                    contacting the customer support
                                                    department at support@getshoutout.com.
                                                    <br /><br />
                                                    The Users may unsubscribe from the ShoutOUT newsletter or marketing
                                                    emails by following the unsubscribe
                                                    links in each of these emails.
                                                    <br /><br />
                                                    In the event of a dispute, the Users may file a complaint with the
                                                    CNIL, for which contact details may
                                                    be found at http://www.cnil.fr.
                                                    <br /><br />
                                                    The Users may access detailed information on the use of their
                                                    personal data, in particular concerning
                                                    the purposes of the processing, the legal bases enabling ShoutOUT
                                                    to process the data, its storage
                                                    period, its recipients, and, where applicable, its transfer to a
                                                    country outside the European Union as
                                                    well as the related compliance guarantees put in place for such
                                                    transfers. To do so, the User can send
                                                    their request by email to privacy@getshoutout.com.
                                                    <br /><br /> <br />
                                                    <span className="blue-heading">Cookies</span>
                                                    <br /><br /><br />
                                                    The ShoutOUT site uses cookies whose purpose is to facilitate
                                                    navigation on the site, to execute the
                                                    service provided by ShoutOUT, to measure the site’s audience, or to
                                                    allow pages on the site to be
                                                    shared.
                                                    <br /><br /><br />
                                                    <span className="blue-heading">Types of cookies used</span>
                                                    <br /><br />
                                                    <span className="bold-heading">The cookies necessary for navigation
                                                        on the site</span>
                                                    <br /><br />
                                                    These cookies are necessary for the functioning of the
                                                    getshoutout.com site. They allow the main
                                                    features of the site to be used.
                                                    <br /><br />
                                                    Without these cookies, Users cannot use the site normally.
                                                    <br /><br />
                                                    <span className="bold-heading">Functional cookies</span>
                                                    <br /><br />
                                                    These cookies make it possible to personalise the User experience.
                                                    <br /><br />
                                                    <span className="bold-heading">Analytical cookies</span>
                                                    <br /><br />
                                                    These cookies make it possible to examine the use and the
                                                    performance of the site and to improve its
                                                    operation by carrying out analyses of visits to the informational
                                                    pages by monitoring the open rates,
                                                    the click rates, and the bounce rates at the individual level.
                                                    <br /><br />
                                                    <span className="bold-heading">Sharing button cookies</span>
                                                    <br /><br />
                                                    These social cookies allow users to share pages and content on
                                                    third-party social networks via social
                                                    sharing buttons.
                                                    <br /><br /><br />
                                                    <span className="blue-heading">Sharing button cookies</span>
                                                    <br /><br /><br />
                                                    Users may accept or reject cookies on the site or refuse them once
                                                    and for all through their browser
                                                    settings.
                                                    <br /><br />
                                                    If the User chooses to refuse all cookies, navigation to certain
                                                    pages of the site will be limited.
                                                    <br /><br />
                                                    Depending on the browser used by Users, the methods for deleting
                                                    cookies are as follows:
                                                    <br /><br />
                                                    <span className="bold-heading">On Internet Explorer</span>
                                                    <br /><br />
                                                    <ul>
                                                        <li>Click the Tools button, then Internet Options.</li>
                                                        <li>Under the General tab, under Navigation History, click
                                                            Settings.</li>
                                                        <li>Click the Show Files button.</li>
                                                        <li>Select the cookies to refuse and click on delete.</li>
                                                    </ul>
                                                    <br /><br />
                                                    <span className="bold-heading">On Firefox</span>
                                                    <br /><br />
                                                    <ul>
                                                        <li>Click on the Browser Tools icon, select the Options menu.</li>
                                                        <li>In the window that appears, choose “Privacy” and click on
                                                            “Display cookies”.</li>
                                                        <li>Select the cookies to refuse and click on delete.</li>
                                                    </ul>
                                                    <br /><br />
                                                    <span className="bold-heading">On Safari</span>
                                                    <br /><br />
                                                    <ul>
                                                        <li>Click the Edit icon, select the Preferences menu.</li>
                                                        <li>Click on Security and then on Show cookies.</li>
                                                        <li>Select the cookies to refuse and click on delete.</li>
                                                    </ul>
                                                    <br /><br />
                                                    <span className="bold-heading">On Google Chrome</span>
                                                    <br /><br />
                                                    <ul>
                                                        <li>Click on the Tools icon, select the Options menu then click
                                                            the Advanced Options tab.</li>
                                                        <li>And access the “Confidentiality” section.</li>
                                                        <li>Click on the “Show Cookies” button.</li>
                                                        <li>Select the cookies to refuse and click on delete.</li>
                                                    </ul>
                                                    <br /><br /><br />
                                                    <span className="bold-heading">Duration of cookies</span>
                                                    <br /><br />
                                                    Cookies are placed on the User’s terminal for a maximum period of
                                                    13 months from the date of the User’s
                                                    consent.
                                                    <br /><br />
                                                    After this period, consent will be re-obtained.
                                                    <br /><br /><br />
                                                    <span className="bold-heading">Security</span>
                                                    <br /><br />
                                                    ShoutOUT has taken all necessary precautions to preserve the
                                                    security of personal data and, in
                                                    particular, to prevent it from being accessed by unauthorized third
                                                    parties, distorted, or damaged.
                                                    <br /><br />
                                                    These measures include the following:
                                                    <br /><br />
                                                    <ul>
                                                        <li>Multi-level firewall.</li>
                                                        <li>Proven solutions for anti-virus protection and detection of
                                                            intrusion attempts.</li>
                                                        <li>Encrypted data transmission using SSL/https/VPN technology.</li>
                                                        <li>Tier 3 and PCI DSS certified data centres.</li>
                                                    </ul>
                                                    <br /><br />
                                                    In addition, access to processing data on behalf of ShoutOUT by the
                                                    receiving third-party services
                                                    requires authentication of the persons accessing the data, by means
                                                    of an individual access code and
                                                    password, that is sufficiently robust and regularly renewed.
                                                    <br /><br />
                                                    Data transmitted over unsecured communication channels is subject
                                                    to technical measures designed to
                                                    make such data incomprehensible to any unauthorised person.
                                                    <br /><br />
                                                    Any questions about the security of the ShoutOUT website can be
                                                    directed to support@getshoutout.com
                                                    <br /><br /><br />
                                                    <span className="blue-heading">Modification of the Privacy Policy</span>
                                                    <br /><br /><br />
                                                    ShoutOUT reserves the right to change this Privacy Policy to comply
                                                    with changes in the applicable laws
                                                    and regulations.
                                                    <br /><br />
                                                    The Users shall be notified of any changes made to this policy via
                                                    our website or by email at least
                                                    thirty days prior, when possible, to their entry into force.
                                                    <br /><br /><br />

                                                    <span className="blue-heading">Contact</span>
                                                    <br /><br />
                                                    Any questions about ShoutOUT’s Privacy Policy can be directed by
                                                    email to privacy@getshoutout.com
                                                    <br /><br /><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        <MainFooter/>
</div>
);