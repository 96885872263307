import React, { useState } from 'react'
import { useRouteData, Head } from 'react-static';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router'
import Modal from '../../components/modals/Modal';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


import '../solutions/Solution.css';

const features = [
    {
        image: "send-sms-with-sender-id.svg",
        imgAlt: "bulk-sms-with-sender-id",
        imgTitle: "bulk-sms-with-sender-id",
        title: "Mobile Number / Sender ID",
        description: "Need a unique presence in your recipients’ inbox? Get a toll free or local mobile number dedicated to your business. Want to take it up a notch? Send text messages under your organization name, in any language, and make it a powerful branding tactic. ",
        link: ""

    },
    {
        image: "unlimited-text-marketing-plans.svg",
        imgAlt: "unlimited-text-marketing-plans",
        imgTitle: "unlimited-text-marketing-plans",
        title: "Quick Contact List Upload",
        description: "Uploading your contact base shouldn’t take a minute! Quickly and easily upload all your contacts as an excel or CSV file.",
        link: ""
    },
    {
        image: "unlimited-text-marketing.svg",
        imgAlt: "unlimited-text-marketing",
        imgTitle: "unlimited-text-marketing",
        title: "Unlimited Recipients",
        description: "No limits whatsoever! Upload unlimited contacts to your profile and send SMS campaigns to unlimited contacts. Reach as many people as you need!",
        link: ""
    },
    {
        image: "customer-segmentation.svg",
        imgAlt: "customer-segmentation",
        imgTitle: "customer-segmentation",
        title: "Powerful Filtering and Segmentation",
        description: "In the end, it is all about the right targeting! Filter contacts as you need to create different groups based on interests, demographics and user info, and send targeted campaigns only to the relevant people.",
        link: ""
    },
    {
        image: "bulk-sms-marketing-tools.svg",
        imgAlt: "bulk-sms-marketing-tools",
        imgTitle: "bulk-sms-marketing-tools",
        title: "Online Mass Texting",
        description: "Send SMS online to a large number of contacts to reach a wider local or international audience in a single campaign.",
        link: ""
    },
    {
        image: "personalized-customer-messaging.svg",
        imgAlt: "personalized-customer-messaging",
        imgTitle: "personalized-customer-messaging",
        title: "Long Text Messages",
        description: "Say all that you need to turn conversations into conversions! Send long messages of upto 459 characters without having to shorten message content.",
        link: ""
    },
    {
        image: "link-shortening.svg",
        imgAlt: "link-shortening",
        imgTitle: "link-shortening",
        title: "Built-In URL Shortening",
        description: "Get it all done in one place! The message compose box comes with built-in link shrinking to reduce the number of characters taken up by a URL in the message content.",
        link: ""
    },
    {
        image: "marketing-campaign-tracking.svg",
        imgAlt: "marketing-campaign-tracking",
        imgTitle: "marketing-campaign-tracking",
        title: "Detailed Tracking and Analytics",
        description: "Keep improving your campaigns! Click tracking lets you view how many and who exactly clicked the URL. Campaign analytics lets you monitor how many SMS were delivered and opened.",
        link: ""
    },
    {
        image: "schedulued-sms-campaigns.svg",
        imgAlt: "schedulued-sms-campaigns",
        imgTitle: "schedulued-sms-campaigns",
        title: "Scheduling",
        description: "Need to launch campaigns at the perfect time or want to plan everything ahead? Set up scheduled text messages to be sent later to get the best reach and results. ",
        link: ""
    },
    {
        image: "targeted-sms-marketing.svg",
        imgAlt: "targeted-sms-marketing",
        imgTitle: "targeted-sms-marketing",
        title: "Multiple Attributes ",
        description: "Add multiple custom data that are unique to each recipient in the message content such as name, ID number, etc. to personalize the SMS. Ideal for personalized SMS marketing!",
        link: ""
    },
    {
        image: "segmentation.svg",
        imgAlt: "customer-segmentation",
        imgTitle: "customer-segmentation",
        title: "Multiple segments",
        description: "Need to send a targeted campaign only to a select group or groups? The group text messaging service lets you select a single contact segment or multiple segments in a campaign in order to send specific, targeted messages.",
        link: ""
    },
    {
        image: "multilingual-sms.svg",
        imgAlt: "multilingual-sms",
        imgTitle: "multilingual-sms",
        title: "Languages and Unicode",
        description: "Get closer to your recipients through the language they speak. We have got you covered! Send and receive SMS in any required language. ",
        link: ""
    },
    {
        image: "sms-emoji.svg",
        imgAlt: "sms-emoji",
        imgTitle: "sms-emoji",
        title: "Emojis",
        description: "Ran out of words? Emojis are a global language that can make powerful statements while adding some fun to the message content.",
        link: ""
    },
    {
        image: "text-customers.svg",
        imgAlt: "text-customers",
        imgTitle: "text-customers",
        title: "Two-Way Messaging",
        description: "Keep conversations going and convert into sales via two messaging which lets you both send and receive text messages instantly.",
        link: ""
    },

    {
        image: "sms-gateway-api-provider.svg",
        imgAlt: "sms-gateway-api-provider",
        imgTitle: "sms-gateway-api-provider",
        title: "Messaging Service API",
        description: "Looking to send SMS via API? Easily integrate our SMS gateway with your website or app using SMS API tools.",
        link: ""
    },
    {
        image: "sms-2fa.svg",
        imgAlt: "sms-2fa",
        imgTitle: "sms-2fa",
        title: "Two Factor Authentication",
        description: "Get two factor authentication for your app and send OTP verification to users via a text message to strengthen security.",
        link: ""
    },
    {
        image: "multi-team-members.svg",
        imgAlt: "multi-team-members",
        imgTitle: "multi-team-members",
        title: "Multiple Admins",
        description: "Admin can create any number of team accounts and assign different permissions to each as required. SMS credit is distributed through your main account.",
        link: ""
    },
    {
        image: "opt-out-sms-marketing.svg",
        imgAlt: "opt-out-sms-marketing",
        imgTitle: "opt-out-sms-marketing",
        title: "Opt-Out Management ",
        description: "Include an opt-out option from the subscription to respect your recipient’s consent and reduce your costs.",
        link: ""
    },
    {
        image: "secure-text-messaging.svg",
        imgAlt: "secure-text-messaging",
        imgTitle: "secure-text-messaging",
        title: "Secure Text Messaging",
        description: "There is no need to worry about losing your data as our service is secured with SSL / HTTPS and all your data is fully backed up 24 / 7 / 365.",
        link: ""
    },


]


const SMS = () => {
    const [isOpen, setIsOpen] = useState(false);


    const viewImage = () => {
        setIsOpen(!isOpen)
    }

    return (<>
        <Helmet>
            <title>Text Messaging Service | ShoutOUT SMS</title>
            <meta name="description" content="Personalized text messaging service that provides messaging at scale and campaign management with SMS API and OTP. FREE signup with 20 FREE credits." />
            <link rel="canonical" href="https://getshoutout.com/text-messaging-service" />
            <meta name="keywords" content="text messaging service, scheduled text messages, conversational messaging, group text messaging service, send sms via api, personalized SMS marketing, text messaging service for business, mass texting, messaging service api, OTP verification, SMS gateway, bulk sms service provider, bulk sms gateway" />
        </Helmet>

        <MainNavbar />



        <div className="container opacity-p9 h-50">
            <div className="row header-space">
                <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-2 text-center text-sm-left">
                    <h1 className="font-weight-bold mt-4 mb-3">Text <span className="text-primary">messaging</span> service for winning customer relationships</h1>
                    <h5 className="mb-3"> Create human connections with personalization</h5>
                    <p>For conversational messaging that delivers, engages and converts, ShoutOUT’s text messaging service brings to you a bundle of unique, innovative features.</p>
                    <Link to="/signup" className="btn btn-outline-primary border-radius-2 mt-2">Get Started for Free</Link>
                    <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2 ml-2">Book a Demo</Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1 text-center">
                    <img className="img-fluid" src="/assets/images/sms/text-messaging-service.svg" alt="text-messaging-service" title="text-messaging-service" />
                </div>
            </div>

            <div className="row text-center mx-auto justify-content-center my-4">
                <div className="col-8">
                    <h4 className="font-weight-bold text-center my-4">A text messaging tool that scales with your business</h4>
                    <p>Powering conversations with unparalleled technology</p>
                </div>
            </div>
            <div className="row text-center mx-auto justify-content-center my-4">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card p-4">
                        <img src="/assets/images/sms/mass-texting-service.svg" alt="mass-texting-service" title="mass-texting-service" />
                        <h6 className="font-weight-bold mt-2">Wider Compatibility</h6>
                        <p>
                            Easily integrate with Woocommerce and Facebook to build the initial relationship and engagement with new leads, faster and hassle-free.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card p-4">
                        <img className="" src="/assets/images/sms/secure-sms-platform.svg" alt="secure-sms-platform" title="secure-sms-platform" />
                        <h6 className="font-weight-bold mt-2">Higher Security</h6>
                        <p>
                            Top notch security is guaranteed as ShoutOUT is deployed in a Virtual Private Cloud accessible only by authorized personnel via private secured connections.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card p-4">
                        <img className="" src="/assets/images/sms/bulk-texting-tool.svg" alt="bulk-texting-tool" title="bulk-texting-tool" />
                        <h6 className="font-weight-bold mt-2">Unmatched Scalability</h6>
                        <p>
                            Enjoy zero downtime as our technology can scale the service to receive as many SMS as you need per second.
                        </p>
                    </div>
                </div>
            </div>


            <div className="row">

                <div className="col-lg-8 col-md-10 col-sm-12 text-center mx-auto">
                    <h4 className="font-weight-bold text-center my-4">Text messaging that has it all</h4>
                    <p>Create text message campaigns that convert.</p>
                </div>
                <div className="col-12">

                    <div className="row">
                        <div className="col-lg-12 col-md-10 col-sm-12 mx-auto">
                            {features.map(({ image, title, description, link, imgAlt, imgTitle }, index) => (
                                <div key={index}>
                                    {/* <Link to={link} > */}
                                    <div className="zoom card shadow p-4 d-flex flex-lg-row align-items-center mb-3">
                                        <img src={`/assets/images/sms/icons/${image}`} className="icon-width-2" alt={imgAlt} title={imgTitle} />
                                        <div className="card-body border-dark border-left">
                                            <h5 className="card-title">{title}</h5>
                                            <p className="card-text text-dark">{description}</p>
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            ))}
                        </div>
                        {/* <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
                            {features.map(({ image, title, description, link }, index) => (
                                <div className="card shadow p-4 d-flex flex-lg-row align-items-center mb-3" key={index}>
                                    <img src={`/assets/images/sms/icons/${image}`} class="icon-width-2" alt="..." />
                                    <div className="card-body border-dark border-left">
                                        <Link to={link}> <h5 className="card-title">{title}</h5></Link>
                                        <p className="card-text">{description}</p>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    </div>
                </div>



            </div>

            <div className="row">

                <div className="col-lg-8 col-md-10 col-sm-12 text-center mx-auto">
                    <h4 className="font-weight-bold text-center my-4">Text messaging service solutions for all</h4>
                    <p>Text all the way through to customers of any type</p>
                </div>
                <div className="col-12 mt-4">

                    <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
                            <div className="card-columns">
                                <div className="card bg-sms-features">Text messaging service for businesses</div>
                                <div className="card bg-sms-features">Text messaging for education</div>
                                <div className="card bg-sms-features">Text messaging for restaurants</div>
                                <div className="card bg-sms-features">Church text messaging service</div>
                                <div className="card bg-sms-features">Real estate SMS marketing</div>
                                <div className="card bg-sms-features">Wedding text messaging service</div>
                                <div className="card bg-sms-features">Text messaging for insurance agents</div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>





            <CallToAction title="Get your hands on the best text messaging tool out there!" review={true} pricing={true}/>

            <MainFooter />


        </div>

        <Modal visible={isOpen} onClickBackdrop={viewImage}>
            <div className="modal-body">
                <img className="img-fluid" src="/assets/images/solutions/education/student-how.svg" />
            </div>


            <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={viewImage}>
                    Close
                </button>
            </div>
        </Modal>



    </>
    )
}
export default SMS;