import React from 'react'
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';


import './Customers.css';
export default () => {
  const { customer = [] } = useRouteData();

  const saarakethaDesc = "Saaraketha Lifestyle is a Sri Lankan company that promotes a wide range of healthy products that are organic, ethical and sustainably sourced. They have a large number of customers interested in their organic produce, organic spices, herbs and grains, heirloom rice bread, natural sweeteners, oils, bath & body products, spreads and dips, etc.";
  return (
    <div className="customers">
      <Head>
        <title>ShoutOUT Case Studies </title>
        <meta name="description" content="Trusted by over 10,000+ users | Here are some success stories & case studies of ShoutOUT users to get an idea about how to use ShoutOUT in your business" />
      </Head>

      <MainNavbar />
      <div className="container pt-5 opacity-p9">
        <div className="row">
          <div className="col-md-12">
            {/* <img className="img-fluid my-4" src="/assets/images/temp/customers.png" alt="" /> */}

            <div className="subheading text-center mt-5">
              Trusted by over <span className="text-primary">4000</span>+ users in <span className="text-primary">41</span> countries
              <div className="h5">
                Here are some <span className="text-success"><strong>success</strong></span> stories
              </div>
            </div>

            <div className="row justify-content-center">
             
              <div className="col-lg-3 col-md-6 col-sm-12 mb-4 card card-customers shadow border-0 p-0 zoom">
                <img className="img-fluid mx-auto" width="256" src="/assets/images/customers/saaraketha.png" alt="ShoutOUT Customer Saaraketha" />
                <div className="px-4">
                  <h4 className="card-title">Saaraketha</h4>
                  <div className="card-description card-desc-height">
                  {saarakethaDesc.substring(0, (saarakethaDesc.substring(0, 240).length, saarakethaDesc.substring(0, 240).lastIndexOf(" ")))}...
                  </div>
                </div>

                <div className="card-footer border-0 p-0">
                  <Link className="card-link-customers" to="/case-studies/personalized-sms-marketing-saaraketha">
                    Read More
                    <span className="card-link-arrow"></span>
                  </Link>
                </div>
              </div>



              {customer.map(({ id, name, image, story }, index) => {
                return (


                  <div className="col-lg-3 col-md-6 col-sm-12 mb-4 card card-customers shadow border-0 p-0 zoom" key={index}>
                    <img className="img-fluid mx-auto" width="256" src={`/assets/images/customers/${image}`} alt={`ShoutOUT Customer ${name}`} />
                    <div className="px-4">
                      <h4 className="card-title">{name}</h4>
                      <div className="card-description card-desc-height">
                        {story.substring(0, (story.substring(0, 240).length, story.substring(0, 240).lastIndexOf(" ")))}...
                      </div>
                    </div>

                    <div className="card-footer border-0 p-0">
                      <Link className="card-link-customers" to={`/customers/${id}`}>
                        Read More
                        <span className="card-link-arrow"></span>
                      </Link>
                    </div>
                  </div>
                );
              }
              )}
            </div>



          </div>
        </div>
      </div>
      <CallToAction />
      <MainFooter />
    </div>
  )
}
