import React, { useState, useEffect } from 'react';
import { Head } from 'react-static';
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';
import { Link } from '@reach/router';
import Constants from './../../../Constants';

import NodejsCodeSample from './sampleCodes/NodeSampleCode';
import PHPSampleCode from './sampleCodes/PHPSampleCode';
import CurlCodeSample from './sampleCodes/CURLSampleCode';
import RestApiCodeSample from './sampleCodes/RESTSampleCode';

import Magento from './../../images/magento.png';
import Woocommerce from './../../images/woocommerce.png';
import Shopify from './../../images/shopify.png';
import Zappier from './../../images/zappier.png';
import CustomersSlidingBar from './../customers/CustomersSlidingBar';

import CustomerAiaImage from './../../images/customers/aia.png?sizes[]=134';
import CustomerSLCricketImage from './../../images/customers/cricketsl.png?sizes[]=134';
import CustomerLaugfsEcoImage from './../../images/customers/laugfsecosri.png?sizes[]=134';
import CustomerAmanaImage from './../../images/customers/amana.png?sizes[]=134';
import CustomerMobilImage from './../../images/customers/mobil.png?sizes[]=134';
import CustomerISSOImage from './../../images/customers/isso.png?sizes[]=134';
import CustomerUNDPImage from './../../images/customers/undp.png?sizes[]=134';
import CustomerNycedcImage from './../../images/customers/nycedc.png?sizs[]=134';
import CustomerDSIImage from './../../images/customers/dsi.jpg?sizes[]=134';
import CustomerVisionCareImage from './../../images/customers/visioncare.png?sizes[]=134';
import CustomerHiruTVImage from './../../images/customers/hirutv.png?sizes[]=134';
import CustomerOrelITImage from './../../images/customers/orelit.png?sizes[]=134';
import CustomerBMICHImage from './../../images/customers/bmich.png?sizes[]=134';
import CustomerDilmahImage from './../../images/customers/dilmah.png?sizes[]=134';

import './../PricingCommon.css';
import './../ProductsCommon.css';
import './Lite.css';

let smsIndex = 0;
//const url = './assets/audio/sms-tone.mp3';
const smsTypes = ["SMS", "OTP"];
const companies = [
  // { "name": "CSBM", image: CustomerCSBMImage },
  // { "name": "Classic Travel", image: CustomerClassicTravelImage },
  // { "name": "DSI", image: CustomerDSIImage },
  // { "name": "ISSO", image: CustomerISSOImage },
  // { "name": "Mobil", image: CustomerMobilImage },
  // { "name": "Shirohana", image: CustomerShirohanaImage },
  // { "name": "Nexus Mobile", image: CustomerNexusImage },
  // { "name": "AIA", image: CustomerAiaImage },
  // { "name": "UNDP", image: CustomerUNDPImage },

  // { "name": "Yohobed", image: CustomerYohobedImage },
  // { "name": "Sampath Bank", image: CustomerSampathbankImage },
  // { "name": "ClicknShop", image: CustomerClicknshoplkImage },
  // { "name": "OrelPay", image: CustomerOrelpayImage },
  // { "name": "Laugfs Eco", image: CustomerLaugfsEcoImage },
  // { "name": "Sumathi", image: CustomerSumathiImage },
  // { "name": "Arimac", image: ArimacImage },
  // { "name": "Emojot", image: EmojotImage },
  // { "name": "Mogo Super", image: MogoImage },
  // { "name": "Odoc", image: OdocImage },
  // { "name": "Scissors", image: ScissorsImage }

  { "name": "AIA", image: CustomerAiaImage },
  { "name": "CricketSL", image: CustomerSLCricketImage },
  { "name": "Laugfs Eco", image: CustomerLaugfsEcoImage },
  { "name": "Amana", image: CustomerNycedcImage },
  { "name": "Mobil", image: CustomerMobilImage },
  { "name": "ISSO", image: CustomerISSOImage },
  { "name": "UNDP", image: CustomerUNDPImage },
  { "name": "NYC EDC", image: CustomerNycedcImage },
  { "name": "DSI", image: CustomerDSIImage },
  { "name": "Vision Care", image: CustomerVisionCareImage },
  { "name": "Hiru TV", image: CustomerHiruTVImage },
  { "name": "OrelIT", image: CustomerOrelITImage },
  { "name": "BMICH", image: CustomerBMICHImage },
  { "name": "Dilmah", image: CustomerDilmahImage },

];

/*TODO: add GTM*/
export default () => {
  const [selectedCodeView, setSelectedCodeView] = useState("NODEJS");
  const [smsType, setSmsType] = useState("SMS");
  // const [audio] = useState(new Audio(url));


  const onChangeLanguage = (e) => {
    setSelectedCodeView(e.target.value);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      smsIndex = (smsIndex + 1) % smsTypes.length;
      setSmsType(smsTypes[smsIndex]);
      // audio.play();

    }, 4000);
    return () => clearInterval(interval);

  }, [])
  return (
    <>
      <Head>
        <title>ShoutOUT Lite | SMS API for Sending, Receiving SMS {'&'} OTP</title>
        <meta name="description" content="Seamless & hassle-free integration using SDKs & API to send, receive & number verification (OTP) for any country with direct connectivity at lowest rates" />
        <meta name="keywords" content="SMS Gateway,Bulk SMS Gateway,SMS Gateway Provider,SMS API,Send SMS API,Receive SMS API,SMS OTP API,PHP SDK,NodeJS SDK,Curl SMS Gateway API,REST API SMS Gateway API,SMS Service API,PHP SMS,Best SMS API,Mobile Number Verification,OTP SMS API,One-Time-Password API,OTP Generating API,OTP Validation API" />
        <meta property="og:image" content="/assets/images/lite/thumbnail-lite.png" />
        <meta property="twitter:image" content="/assets/images/lite/thumbnail-lite.png" />
      </Head>

      <MainNavbar />

      <div className="container vh-100 lite-page" id="lite-page">
        <div className="row vh-100 lite-bg ">
          <div className="col-lg-6 col-sm-12 mb-auto my-lg-auto order-2 z-index-10">
            <div className="row">
              <div className="col-12 order-2">
                <h1 className="h6 mb-4 mb-lg-0">Hassle-free <strong>SMS API</strong> for Sending, <br />Receiving SMS and Number Verification (OTP)</h1>
              </div>
              <div className="col-12 order-1 order-lg-2">
                <h2 className="font-weight-bold my-5">Send <span className={`text-${smsType === "SMS" ? "primary" : "danger"}`}>{smsType === "SMS" ? "SMS" : "OTP"}</span> from your application within next 5 minutes</h2>
              </div>
            </div>


            <Link to={`/signup?referrer=${Constants.LITE_PRODUCT_URL}`}><button className="btn btn-primary btn-sm btn-round px-5">Get Started</button></Link>
          </div>
          <div className="col-lg-6 col-sm-12 my-auto order-lg-2 order-1">

            <div className={`mx-auto p-3 shadow-sm ${smsType === "SMS" ? "sms-content" : "otp-content"}`}>
              <div className="d-flex justify-content-between">
                <img className="mr-2" src="./assets/images/icons/sms-icon.png" alt="SMS Icon" />
                <small className="font-weight-bold mb-0 mr-auto">{smsType === "SMS" ? "Savanah" : "Verify"}</small>
                <small className="text-muted">{smsType === "SMS" ? "1m ago" : "now"}</small>
              </div>
              <p className="text-smaller mt-2 mb-0"> {smsType === "SMS" ?
                <>"Fantasy Island at Savanah Cinemas from 17 July. Reserve your seat now." <br /><a href="#">e-url.co/slKde</a><br /></>
                : <> 490298 is your Amelian OTP.<br /> Do not share it with anyone.<br /> Please note that this will expire in 5 minutes</>}
              </p>
            </div>

          </div>
        </div>
        <div className="position-relative">
          <img className="position-absolute header-bg-circle-position" src="./assets/images/bg-circle.svg" alt="" />
        </div>
      </div>




      <div className="container">
        <div className="my-5 py-5">
          <div className="text-center d-flex justify-content-center align-items-center flex-column">

            <h4 className="font-weight-bold text-center section-title mb-5">What can you do with ShoutOUT <span className="text-primary">SMS API</span>?</h4>
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4">
                <div className="card feature-card sending-color p-4 shadow rounded-0">
                  <h5 className="font-weight-bold">Sending SMS</h5>
                  <img className="img-fluid feature-image" src="/assets/images/sending-sms.svg" alt="feature" />
                  <p className="text-left text-smaller text-dark">Send SMS to any number from your application by using our SDKs or REST API with branding (Sending SMS with branding is subject to availability in some countries)</p>
                  <div className="mb-3">
                    <small className="badge badge-muted rounded-0 text-xs mr-2 ">SMS Alerts & Notifications</small>
                    <small className="badge badge-muted rounded-0 text-xs mr-2  ">Order status alerts</small>
                    <small className="badge badge-muted rounded-0 text-xs mr-2  ">SMS Reminders</small>
                    <small className="badge badge-muted rounded-0 text-xs  ">Discounts & Promotions</small>
                  </div>
                  <a className="btn btn-primary btn-sm rounded-0 mt-auto" href="https://developers.getshoutout.com/#liteapimessage_send"><small>Documentation</small></a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4 mt-4 mt-md-0">
                <div className="card feature-card receiving-color p-4 shadow rounded-0">
                  <h5 className="font-weight-bold">Receiving SMS</h5>
                  <img className="img-fluid feature-image" src="/assets/images/receiving-sms.svg" alt="feature" />
                  <small className="text-left mb-auto text-dark">Allow your users to send message via a long number to your application. Contact us for more details.</small>
                  <div className="mb-3">
                    <small className="badge badge-muted rounded-0 text-xs mr-2 ">SMS Voting</small>
                    <small className="badge badge-muted rounded-0 text-xs mr-2 ">Get Feedbacks via SMS</small>
                    <small className="badge badge-muted rounded-0 text-xs mr-2 ">Customer support via SMS</small>
                    <small className="badge badge-muted rounded-0 text-xs ">Two-way communication</small>
                  </div>
                  <Link className="btn btn-success btn-sm rounded-0 mt-auto" to="/contactus"><small>Contact us</small></Link>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4 mt-4 mt-md-0">
                <div className="card feature-card otp-color p-4 shadow rounded-0">
                  <h5 className="font-weight-bold">OTP SMS</h5>
                  <img className="img-fluid feature-image" src="/assets/images/otp-sms.svg" alt="feature" />
                  <small className="text-left mb-3 text-dark">Power up your application with Two Factor Authentication with OTP API. We provide OTP code generation and OTP validation, and you only have take care of your application logic.</small>
                  <div className="mb-3">
                    <small className="badge badge-muted rounded-0 text-xs mr-2">Number Verification</small>
                    <small className="badge badge-muted rounded-0 text-xs mr-2">Two Factor Authentication</small>
                  </div>
                  <a className="btn btn-danger btn-sm rounded-0 mt-auto" href="https://developers.getshoutout.com/#otpapiintroduction"><small>Documentation</small></a>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="my-5 text-center d-flex justify-content-center align-items-center flex-column">

          <h4 className="font-weight-bold text-center mb-5">Why ShoutOUT SMS API?</h4>
          <div className="row w-100 justify-content-center">

            <div className="col-md-3 col-sm-6 col-xs-12 text-center  mb-3">
              <div className="whyus-container p-4 shadow mx-auto">
                <img className="img-fluid whyus-image" src="/assets/images/lite/direct-connectivity.svg" alt="feature" />
              </div>
              <h6 className="text-primary my-3 font-weight-bold">Direct Connectivity</h6>
              <small>Reliable direct connectivity to multiple telecom operators in different regions.</small>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 text-center  mb-3">
              <div className="whyus-container p-4 shadow mx-auto">
                <img className="img-fluid whyus-image" src="/assets/images/lite/sender-ids.svg" alt="feature" />
              </div>
              <h6 className="text-primary my-3 font-weight-bold">Sender IDs</h6>
              <small>Obtain alphanumeric sender IDs to match your business name</small>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 text-center  mb-3">
              <div className="whyus-container p-4 shadow mx-auto">
                <img className="img-fluid whyus-image" src="/assets/images/lite/high-reliability.svg" alt="feature" />
              </div>
              <h6 className="text-primary my-3 font-weight-bold">High Reliability</h6>
              <small>We guarantee you of 99.93% uptime with auto scaling and zero maintenance windows</small>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 text-center mb-3">
              <div className="whyus-container p-4 shadow mx-auto">
                <img className="img-fluid whyus-image" src="/assets/images/lite/easy-integration.svg" alt="feature" />
              </div>
              <h6 className="text-primary my-3 font-weight-bold">Easy Integration</h6>
              <small>Seamless and hassle-free integration of SDKs and REST API with any platform</small>
            </div>
          </div>
        </div>


        <div className="my-5 py-5">
          <div className="text-center d-flex justify-content-center align-items-center flex-column">
            <h4 className="font-weight-bold text-center">Our Clients, Our Assets</h4>
            <CustomersSlidingBar companies={companies} />
          </div>
        </div>



        <div className="my-5 py-5">
          <div className="text-center d-flex justify-content-center align-items-center flex-column">
            <h4 className="font-weight-bold text-center"> How to Integrate?</h4>
            <p className="text-center">Try sample code for your favorite language and see how simple our SMS API is.</p>


            <div className="row w-100 justify-content-center">

              <div className="col-12 col-lg-10 col-sm-12 col-xs-12">
                <form className="input-sec">
                  <div className="form-group d-flex align-items-center justify-content-center">
                    <label htmlFor="source" className="mb-0 mt-1">Select language</label>
                    <select name="employees" id="source" className="form-control w-50" required onChange={onChangeLanguage}>
                      <option value="NODEJS">NODE JS</option>
                      <option value="PHP">PHP</option>
                      <option value="CURL">CURL</option>
                      <option value="RESTAPI">REST API</option>

                    </select>

                  </div>
                </form>
                <div className="code-preview text-left">
                  {selectedCodeView === "NODEJS" ?
                    <NodejsCodeSample />
                    :
                    selectedCodeView === "PHP" ?
                      <PHPSampleCode />
                      :
                      selectedCodeView === "CURL" ?
                        <CurlCodeSample />
                        :
                        <RestApiCodeSample />
                  }
                </div>
              </div>
            </div>

          </div>
        </div>



        <div className="text-center d-flex justify-content-center align-items-center flex-column my-5 py-5">

          <h4 className="font-weight-bold mb-4">Plugins</h4>
          <p className="font-weight-bold mb-4">Start sending SMS from your favourite application</p>

          <div className="row justify-content-center w-100">
            <div className="col-lg-2 col-6">
              <img className="img-fluid" src={Magento} alt="Magento Plugin" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="img-fluid" src={Shopify} alt="Shopify Plugin" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="img-fluid" src={Zappier} alt="" />
            </div>
            <div className="col-lg-2 col-6">
              <img className="img-fluid" src={Woocommerce} alt="" />
            </div>
          </div>
          <div className="text-center">
            <small>Can’t find the plugin that you looked for? <Link to="/contactus">Contact us</Link>!</small>
          </div>

        </div>



        <CallToAction title={"Start Integrating with ShoutOUT SMS API"} />
      </div>
      <MainFooter />
    </>

  )
}