import React, { useState } from 'react'
import { useRouteData, Head } from 'react-static';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router'
import Modal from '../../components/modals/Modal';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


import './Solution.css';

const Education = () => {
    const [isOpen, setIsOpen] = useState(false);


    const viewImage = () => {
        setIsOpen(!isOpen)
    }

    return (<>
        <Helmet>
            <title>Text Messaging for Schools | ShoutOUT</title>
            <meta name="description" content="Text messaging for schools is easier with personalized bulk SMS, two-way messaging & SMS OTP with campaign analytics & link shortening.Try it for free." />
            <link rel="canonical" href="https://getshoutout.com/industries/text-messaging-for-schools" />
            <meta name="keywords" content="text messaging for schools, mass text messaging for schools, text alert system for schools, ShoutOUT messaging, send messages to students, text messaging for educational institutes, tool to text students" />
        </Helmet>

        <MainNavbar />



        <div className="container opacity-p9 h-50">
            {/* <div className="back-button">
                <Link to="/solutions" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
            </div> */}
            <div className="row header-space">
                <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-2 text-center text-sm-left">
                    <h2 className="font-weight-bold mt-4">Text <span className="text-primary">Messaging</span> for Schools & Educational Institutions</h2>
                    <p className="mb-0">The ideal solution to the hassle in online learning and teaching. Easy communication with parents & students to send online class links, class cancellations, & other reminders.</p>
                    <Link to="/signup" className="btn btn-outline-primary border-radius-2 mt-2">Get started for Free</Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1 text-center">
                    <img className="img-fluid w-75" src="/assets/images/solutions/education/text-messaging-for-schools.svg" alt="text-messaging-for-schools" title="sms for schools" />
                </div>
            </div>
            <div className="row">
                {/* <div className="col-12 text-center">
                    <h4 className="font-weight-bold">Why are Bulk SMS Alerts Perfect to Communicate with Students & Parents?</h4>
                </div> */}

                <div className="col-12">
                    <h4 className="font-weight-bold text-center my-4">What can you send students & parents using SMS alerts?</h4>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/notification.svg" />
                                <span className="mt-2 text-center">Class cancellation notifications</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/timetable.svg" />
                                <span className="mt-2 text-center">New class times</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/checklist.svg" />
                                <span className="mt-2 text-center">Links to submit assignments</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-colum align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/deadline.svg" />
                                <span className="mt-2 text-center">Assignment / registration deadlines</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/class.svg" />
                                <span className="mt-2 text-center">Links to join online classes</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/link.svg" />
                                <span className="mt-2 text-center">Payment reminders & payment links</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/meeting.svg" />
                                <span className="mt-2 text-center">Links for online parents’ meetings</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/feedback.svg" />
                                <span className="mt-2 text-center">Feedback to parents</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/education/icons/results.svg" />
                                <span className="mt-2 text-center">Academic performance results</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/education/student-sms-sample.svg" alt="text-messaging-for-schools" title="sample sms for schools" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/education/sample-school-sms.svg" alt="text-messaging-for-schools" title="sms for schools template" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/education/school-sms-template.svg" alt="text-messaging-for-schools" title="sms for schools template" />
                </div>


            </div>

            <div className="row">

                <div className="col-12">
                    <h4 className="font-weight-bold text-center my-4">What makes ShoutOUT the ideal tool to text students and parents?</h4>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/education/school-sms-platform.jpg" alt="text-messaging-for-schools" title="sms contact list" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2">
                            <p className="font-weight-bold">Create groups/segments from your contact list</p>
                            <p>
                                Upload your contact list as a CSV or Excel file. You can also integrate your existing school management system.
                                <br /> <br />
                                Create groups based on the types of different people or campaigns you need to send. (e.g., Parents, Students, Grade 10 Students, Maths Class 9 etc.)
                                <br /> <br />
                                Keep all contact details on ShoutOUT and use them to send personalized text messages by including parent's name, student ID, etc. in the message content.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-2">
                            <img className="img-fluid" src="/assets/images/solutions/education/school-tecting-platform.jpg" alt="contact-group-texting" title="contact grouping" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2 order-sm-1">
                            <p className="font-weight-bold mt-auto">Send texts to multiple contact groups in one campaign</p>
                            <p>
                                Select multiple segments (that you created, such as Parents, Students, Grade 10 Students, Maths Class 9 etc.) in one campaign.
                                <br /> <br />
                                Easily send a general message such as academic time tables, payment links, emergency notices etc. to multiple groups (e.g., Grade 10 and Grade 11), in one campaign.
                                <br /> <br />
                                Eliminate the need to send several campaigns for a single text message.
                            </p>
                        </div>
                    </div>

                    {/* <div className="row my-4">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-1">
                            <img className="img-fluid" src="/assets/images/solutions/education/screenshot.png" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-auto order-2 order-sm-2">
                            <h6 className="font-weight-bold mt-auto">Send Targeted Messages to Your Contacts</h6>
                            <small>
                                Send both SMS and Email campaigns on the same platform.
                                <br />
                                Create beautiful looking email content with ShoutOUT HTML Editor and even add call to actions buttons to direct the parents and students to certain webpages (Eg: Registration, Semester Time Tables, Payments etc. )
                                <br />
                                Send text messages globally to international students with ShoutOUT’s international SMS coverage.
                            </small>
                        </div>
                    </div> */}

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/education/sms-with-company-name.jpg" alt="text-number-mass-texting" title="sms with company name" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-2 order-2">
                            <p className="font-weight-bold mt-auto">Send text messages either under your institute's name or via a unique text number</p>
                            <p>
                                Ensure that your text messages stand out in the message inbox using a unique Sender ID.
                                <br /> <br />
                                Request for a Sender ID that you would prefer, after creating a ShoutOUT profile. If all conditions are satisfied, it will be approved within one business day.
                                <br /> <br />
                                You can get your school, university, educational institution or tutor name, or a unique text number, as the Sender ID.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-2 order-1">
                            <img className="img-fluid w-100" src="/assets/images/solutions/education/url-shortening-sms.jpg" alt="url-shortening-bitly-alternative" title="link-shortening click-tracking" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-1 order-2">
                            <p className="font-weight-bold mt-auto">Include shortened online links</p>
                            <p>
                                Send online registration, payment, class, meeting, website links etc. in the text message.
                                <br /> <br />
                                {/* Text message character limit will not be affected by using URLs.
                                <br /> <br /> */}
                                The URL Shortener option automatically reduces URL length, turns it into a shortened URL, thereby reducing the number of characters taken up.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4">
                        {/* <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/education/Senders.jpg" />
                        </div> */}
                        <div className="col-12">
                            <h4 className="font-weight-bold text-center my-4">Other unique features that remove the hassle in sending text message campaigns</h4>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/schedule.svg" />
                                <p>Scheduling</p>
                                <small className="text-muted">Plan campaigns ahead. Schedule text messages to be sent later.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/templates.svg" />
                                <p>Templates</p>
                                <small className="text-muted">Save messages as templates to be used repetitively in campaigns.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/smartphone.svg" />
                                <p>Live SMS preview</p>
                                <small className="text-muted">Check the final look of your message as you type it, on a mobile phone screen.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/keyboard.svg" />
                                <p>Custom attributes</p>
                                <small className="text-muted">Automatically add person/group-specific data to the SMS content. (e.g., class name, course ID, etc.)</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12 mt-sm-4 mt-0 mx-auto">
                    <h4 className="my-4 font-weight-bold text-center">Sample ShoutOUT SMS for Schools</h4>
                    <small className="text-muted text-smaller">Hover over the SMS for features</small>
                    <div className="p-3 shadow-sm cursor-pointer sms-content-education">
                        <div className="d-flex justify-content-between">
                            <img className="mr-2" width="24" src="https://lite.getshoutout.com/assets/images/icons/sms-icon.png" alt="SMS Icon" />
                            <p className="font-weight-bold mb-0 mr-auto" data-toggle="tooltip" data-placement="top" title="Business Name">EduSmart</p>
                            <small className="text-muted">now</small>
                        </div>
                        <p className="mt-2 mb-0">
                            Hi,
                            <span className="text-purple" data-toggle="tooltip" data-placement="top" title="Personalized Name"> Amanda</span>.
                            <span className="text-success" data-toggle="tooltip" data-placement="top" title="Student's Class Variable"> Year two </span>
                            classes will resume online on
                            <span className="text-pink" data-toggle="tooltip" data-placement="top" title="Date Variable"> 1st August</span>.
                            Please find the class schedule here- <br /><a href="" data-toggle="tooltip" data-placement="top" title="Shortened URL">e-url.co/TjwPau</a>.
                            You can complete course fee payment via this link - <a href="" data-toggle="tooltip" data-placement="top" title="Shortened URL">e-url.co/UoPArt</a>.
                        </p>
                    </div>
                </div>
            </div> */}

            <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
                <h4 className="my-4 font-weight-bold text-center">How to send messages to students & parents?</h4>
                {/* <button type="button" className="btn btn-primary" onClick={viewImage}>
                    
                    </button> */}
                <div className="embed-responsive embed-responsive-16by9">
                    <video controls={true} preload={true} muted={true} width="720" src="/assets/images/solutions/education/school-sms-tool.mp4" alt="text-messaging-for-schools" title="schools sms platform" />
                </div>
            </div>
            {/* 
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold">Enjoy Stress FREE Wedding Planning!</h4>
                </div>
                <div className="col-lg-8 col-sm-12 mx-auto">
                    <div className="card p-4 shadow">
                        <div className="d-sm-flex text-sm-left text-center align-items-center">
                            <img src="/assets/images/solutions/wedding/nithushan.jpg" className="testimonial-image mr-sm-4 mr-0" />
                            <div>
                                <small><i>“Loved using this platform for our wedding invitation to keep our guests up-to-date of any possible changes in venue and times. This is a go to service for anyone planning their big day during this pandemic! Also, unlike other platforms, we were able to target our audience and focus on key attendees. Customer service was incredible! Thank you so much for helping our wedding day run smoothly!”</i></small>
                                <p className="font-weight-bold mb-0">- Nithushan & Abinaya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row mt-5 justify-content-center">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold">More on this</h4>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2021/05/send-sms-students-schools-shoutout-1-1250x710.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">SMS for Educational Institutes & Tutors – How to Send Messages to Students & Parents</p>
                            <small>How to send messages to students?” This, in fact, is a frequent and common question that schools, educational institutes, universities, private tutors, all face…</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/send-messages-students/"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2021/04/sms-alerts-sustainable-wedding-1-1250x710.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">Case Study: A Text Message Campaign for the Sustainable Wedding Function of Nithushan & Abinaya</p>
                            <small>Text message campaign, sustainability, and wedding function – how do these relate to each other or work together? In a way that’s innovative, feasible, timely, and most importantly, sustainable.</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/text-message-campaign-sustainable-wedding/"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div> */}
            </div>

            <CallToAction title="Connect to Your Students and Parents Easier than Before!" />

            <MainFooter />


        </div>

        <Modal visible={isOpen} onClickBackdrop={viewImage}>
            <div className="modal-body">
                <img className="img-fluid" src="/assets/images/solutions/education/student-how.svg" />
            </div>


            <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={viewImage}>
                    Close
                </button>
            </div>
        </Modal>



    </>
    )
}
export default Education;