import React from 'react';
import { Link } from '@reach/router';
import { Head } from 'react-static';
import LogoMini from './../../images/logo-mini.png';
import { LoyaltyIcon, EngageIcon, LiteIcon } from './../../images/SvgExport';
import UtilService from './../../services/UtilService';
import ShoutOUTService from './../../services/Services';
import { submitGTMEvent } from './../../services/AnalyticsServices';
import { navigate } from '@reach/router';
import LaunchpadImage from './../../images/launchpad-bg.png?sizes[]=300,sizes[]=500,sizes[]=768';
import Picture from './../../components/picture';
import './Account.css';
import './Launchpad.css';
import Constants from '../../../Constants';


class LaunchPad extends React.PureComponent {
    constructor() {
        super();
        this.state = { userName: '', userEmail: '', token: '', isExpand: false, profileLoaded: false, isRedirecting: false };
    }

    redirectToUrl(url, token) {
        this.setState({ isRedirecting: true });
        window.location.replace(url + "?token=" + token);
    }
    redirect(referrer, product, token) {
        if (referrer) {
            this.redirectToUrl(referrer, token);

        } else if (product) {
            switch (product) {
                case "LITE": {
                    this.redirectToUrl(Constants.LITE_PRODUCT_URL, token);
                    break;
                }
                case "LOYALTY": {
                    this.redirectToUrl(Constants.LOYALTY_PRODUCT_URL, token);
                    break;
                }

                case "ENGAGE": {
                    this.redirectToUrl(Constants.ENGAGE_PRODUCT_URL, token);
                    break;
                }
                default: {
                    //window.location.replace(Constants.ENGAGE_PRODUCT_URL + "?token=" + token);
                }
            }
        }
    }
    _checkRedirection(token, referrer) {
        if (typeof window !== 'undefined') {
            if (!token) {
                navigate('/login' + window.location.search);
            } else {
                try {
                    localStorage.setItem("token", token);
                    const { so_user_email, so_user_name, so_user_id, product, email_verified,is_team_member } = UtilService.decodeToken(token);
                    this.setState({ userName: so_user_name, userEmail: so_user_email, token });
                    submitGTMEvent({ 'event': 'login', 'userId': so_user_id, userEmail: so_user_email, userName: so_user_name });

                    ShoutOUTService.getProfile().then(res => {
                        console.debug(res);
                        const { mobile, organization } = res.data;
                        if (!email_verified) {
                            navigate('/verify/otp' + window.location.search);
                        }
                        else if (!is_team_member&&(!mobile || !organization)) {
                            navigate('/account/completion' + window.location.search);
                        } else {
                            this.redirect(referrer, product, token);

                        }

                        this.setState({ profileLoaded: true });
                    }, err => {
                        this.setState({ profileLoaded: true });

                        this.redirect(referrer, product, token);

                    })


                } catch (e) {
                    localStorage.removeItem("token");
                    navigate('/login' + window.location.search);
                }
            }
        }
    }
    _toggleCollapse = () => {
        this.setState({ isExpand: !this.state.isExpand });
    }


    componentDidMount() {
        if (typeof window !== 'undefined') {
            let { token, referrer } = UtilService.queryStringToJSON(window.location.search);
            this._checkRedirection(token, referrer);
            // if (token) {
            //     const { so_user_email, so_user_name, so_user_id } = UtilService.decodeToken(token);
            //     this.setState({ userName: so_user_name, userEmail: so_user_email, token });
            //     submitGTMEvent({ 'event': 'login', 'userId': so_user_id, userEmail: so_user_email, userName: so_user_name });

            //     updateUserInfo({ username: so_user_name, email: so_user_email, externalId: so_user_id, firstname: so_user_name });
            // } else {
            //     navigate('/login' + window.location.search);
            // }
        }
    }
    render() {
        const { token } = this.state;
        return (
            <>
                <Head>
                    <title>ShoutOUT Web | Customer Messaging Platform</title>
                </Head>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">

                    <Link to="/" className="navbar-brand">

                        <img src={LogoMini} className="img-fluid" alt="Login ShoutOUT Labs" />

                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this._toggleCollapse}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`navbar-collapse collapse ${this.state.isExpand ? 'show' : ''}`} id="navbarContent">
                        <ul className="navbar-nav ml-auto">

                            <li className="nav-item active">
                                <Link to="/logout">
                                    <div className="launchpad-user-detail">
                                        <div className="launchpad-menu">
                                            <h6>Logout | <small><span className="text-muted"> Hi,</span>{this.state.userEmail}</small></h6>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                        </ul>

                    </div>
                </nav>
                <div className="container">

                    <section className="launchpad-block">
                        {this.state.isRedirecting ? <h2 className="text-center">Redirecting. Please wait...</h2> :
                            this.state.profileLoaded ?
                                <div className="container my-auto">
                                    <h2 className="text-center">Hi {this.state.userName}, welcome back.</h2>

                                    <Picture {...LaunchpadImage} alt="Launchpad" className="img-fluid" />
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <br /><br />
                                            <h4 className="text-secondary">
                                                Please select a product to continue.
                            </h4>
                                            <br /><br />
                                        </div>
                                    </div>

                                    <div className="row">

                                        {/* <div className="col-md-3 col-sm-12 order-1 my-auto"></div> */}
                                        <div className="col order-3 ">
                                            <a href={"https://lite.getshoutout.com?token=" + token}>
                                                <div className="launchpad-card lite card shadow border-0">
                                                    <div className="card-body text-center mx-auto">
                                                        <div className="product-border mx-auto lite-border">
                                                            <div className="launchpad-product-circle">
                                                                <div className="launchpad-product-icon">
                                                                    {LiteIcon}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4 className="launchpad-card-title">Lite</h4>
                                                        <small>SMS API Dashboard</small>
                                                        {/* <a href="#" className="btn btn-primary btn-round">&nbsp;&nbsp;Continue&nbsp;&nbsp;</a> */}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col order-2 ">
                                            <a href={"https://web.getshoutout.com?token=" + token}>
                                                <div className="launchpad-card engage card shadow border-0">
                                                    <div className="card-body text-center mx-auto">
                                                        <div className="product-border mx-auto engage-border">
                                                            <div className="launchpad-product-circle">
                                                                <div className="launchpad-product-icon">
                                                                    {EngageIcon}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4 className="launchpad-card-title">Engage</h4>
                                                        <small>SMS and Email Campaign Dashboard</small>
                                                        {/* <a href="#" className="btn btn-primary btn-round">&nbsp;&nbsp;Continue&nbsp;&nbsp;</a> */}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col order-1">
                                            <a href={"https://app.topnotch.club?token=" + token}>
                                                <div className="launchpad-card loyalty card shadow border-0">
                                                    <div className="card-body text-center mx-auto">
                                                        <div className="product-border mx-auto loyalty-border">
                                                            <div className="launchpad-product-circle">
                                                                <div className="launchpad-product-icon">
                                                                    {LoyaltyIcon}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4 className="launchpad-card-title">Reward</h4>
                                                        {/* <a href="#" className="btn btn-primary btn-round">&nbsp;&nbsp;Continue&nbsp;&nbsp;</a> */}
                                                        <small>Loyalty Management, Customer Segmentation and Engagement Dashboard</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        {/* <div className="col-md-3 col-sm-12 order-4 my-auto"></div> */}

                                    </div>

                                </div> :
                                <h2 className="text-center">Loading awesomeness. Please wait...</h2>
                        }
                    </section>
                </div>
            </>
        );
    }
}

export default LaunchPad;