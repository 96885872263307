import React from 'react';
import { Head } from 'react-static';
import { Link } from '@reach/router';
import AccountNavbar from './../../components/navbars/AccountNavbar';
const AccountVerificationFailed = () => {
    return (
        <div className="container text-center h-100">
                 <Head>
                <title>ShoutOUT | Account Verification</title>
            </Head>
            <AccountNavbar />

            <div className="row h-100 justify-content-center align-items-center pt-70">
                <div className="col-12 col-lg-6 col-md-10">
                    <div className="card shadow border-0">
                    <div className="card-body opacity-p8">
                            <img className="my-3" src="/assets/images/temp/close.svg" alt="Verified" width="80" />
                            <h4 className="font-weight-bold">Account verification failed.</h4>
                            <small className="text-muted text-pre">Probably the verification 
                            link is expired. Resend the verification email and try again.</small>
                            <br/>
                            <Link to="/login" className="btn btn-outline-dark btn-sm btn-round py-2 px-4 mb-3">Login to Resend the Link</Link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default AccountVerificationFailed;