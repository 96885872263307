import React from 'react'
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


import './Customers.css';

export default ()=> {
  const {item={}}=useRouteData();
  const { name, image, story, challenge, solution } = item;
  return (<>
<Head>
  <title> {`ShoutOUT Case Studies |  ${name}`} </title>
</Head>

    <MainNavbar />



    <div className="container opacity-p8">
      <div className="row justify-content-center">

        <div className="col-md-10 col-sm-12 card shadow-sm border-0 mt-5">
          <Link to="/customers" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-12 my-auto text-lg-right order-lg-2 p-4">
              <img className="img-fluid" src={`/assets/images/customers/${image}`} alt="ShoutOUT Customer" />
            </div>
            <div className="col-md-6 col-sm-12 my-auto order-lg-1">
              <div className="p-4">
                <h6 className="border-muted border-bottom pb-1 mb-0 w-50">Customer</h6>
                <div className="heading mt-0">{name}</div>
              </div>
            </div>
          </div>





          <div className="p-4">
            <div className="subheading ">Story</div>
            <p>
              {story}
            </p>
          </div>



          <div className="p-4">
            <div className="subheading ">Challenge</div>
            <p>
              {challenge}
            </p>
          </div>


          <div className="p-4">
            <div className="subheading ">Solutions</div>
            <p>
              {solution}
            </p>
          </div>
        </div>
      </div>
    </div>


    <CallToAction />
    <MainFooter />
  </>
  )
}
