import React, { useEffect, useState } from 'react';
import { Head } from 'react-static';
import { Link } from '@reach/router';
import AccountNavbar from './../../components/navbars/AccountNavbar';
import Services from "./../../services/Services";
import { navigate } from '@reach/router';
const AccountVerificationSuccess = (props) => {
    const [isSending, setIsSending] = useState(false);
    useEffect(() => {//exchange token
        const init = async () => {

            const token = localStorage.getItem("token");
            if (token) {
                try {
                    setIsSending(true);
                    const exchangeTokenResponse = await Services.exchangeToken();
                    localStorage.setItem("token", exchangeTokenResponse.data.token);
                    if (typeof window !== 'undefined') {
                        navigate('/login' + window.location.search);
                    }
                    setIsSending(false);
                } catch (e) {
                    console.error(e);
                    setIsSending(false);
                    navigate('/logout' + window.location.search);
                }

            }

        }

        init();
    }, [])
    return (
        <div className="container text-center h-100">
            <Head>
                <title>ShoutOUT | Account Verification</title>
            </Head>
            <AccountNavbar />
            <div className="row h-100 justify-content-center align-items-center pt-70">
                <div className="col-12 col-lg-6 col-md-10">
                    <div className="card shadow border-0">
                        <div className="card-body opacity-p8">
                            <img src="/assets/images/temp/tick.svg" className="my-3 text-center border-muted border rounded-circle p-4 line-height-1" alt="Verified" width="100" />

                            <h6 className="mb-4 font-weight-bold">{isSending ? "Verifying..." : "Account verification is success."}</h6>

                            {!isSending && <Link to="/login" className="btn btn-outline-dark btn-sm btn-round py-2 px-4 mb-3">Go to Launchpad</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccountVerificationSuccess;