import React from 'react';
import Services from "./../../services/Services";
import UtilService from './../../services/UtilService';
import { submitGTMEvent } from "./../../services/AnalyticsServices";
//import "react-intl-tel-input/dist/libphonenumber.js";

import AccountNavbar from './../../components/navbars/AccountNavbar';
import { Link, navigate } from '@reach/router';
import LoginWithGoogle from './LoginWithGoogle';
import CloseAlert from './../../components/alert/CloseAlert';
import EyeImage from './../../images/eye.svg';
import './Account.css';
import './Signup.css';
import { Helmet } from 'react-helmet';
import { useSiteData, Head ,RouteData} from 'react-static';

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // country: 'Sri Lanka',
            // profile: 'sl',
            // mobile: '',

            // _validMobile: true,
            isSending: false,
            //isLoadingBlogPosts: true,
            signupStatus: null,
            // blogPosts: [],
            showAlert: false,
            signupError: false,
            viewPassword: false
        };

        this._signup = this._signup.bind(this);
        this.signup = this.signup.bind(this);
    }

    // componentDidMount() {
    //     if (typeof document !== 'undefined') {

    //         const s = document.createElement("script"); s.async = !0,
    //             s.src = "https://apis.google.com/js/platform.js";
    //         s.onload = function () {
    //             // try {
    //             //     gapi.signin2.render('my-signin2', {
    //             //         'scope': 'profile email',
    //             //         'width': 240,
    //             //         'height': 50,
    //             //         'longtitle': true,
    //             //         'theme': 'dark',
    //             //         // 'onsuccess': onSuccess,
    //             //         // 'onfailure': onFailure
    //             //     });
    //             // } catch (e) {
    //             //     console.error(e);
    //             // }
    //             window.onGoogleSigninSuccess = function (googleUser) {
    //                 console.debug("success google signin:", googleUser)
    //             }
    //         }
    //         document.body.appendChild(s);
    //     }
    // }
    // componentDidMount() {
    //     try {
    //         Services.getBlogPosts(1).then(res => {
    //             let posts = res.data.map(item => {
    //                 let { id, title, link, excerpt, _embedded } = item;
    //                 let srcImage = "";
    //                 if (_embedded && _embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'].length) {
    //                     srcImage = _embedded['wp:featuredmedia'][0].source_url;
    //                 }
    //                 return { id, title, link, excerpt, srcImage };
    //             });
    //             this.setState({ blogPosts: posts, isLoadingBlogPosts: false });
    //         }).catch(e => {
    //             this.setState({ isLoadingBlogPosts: false });
    //         });
    //     } catch (e) {

    //     }
    // }

    redirectToLaunchpad = (token) => {
        navigate("/launchpad" + (window.location.search ? (window.location.search + "&token=" + token) : "?token=" + token))
    }

    signup = (signupObject, signupMethod, token) => {
        this.setState({ isSending: true });
        Services.signup(signupObject, signupMethod, this.props.buildProduct, token).then(res => {
            const decodedToken = UtilService.decodeToken(res.data.token);
            const {so_user_email, so_user_id, so_user_name} = decodedToken;
            this.setState({ isSending: false, signupError: false, signupStatus: 'Your account created successfully. Please check your email inbox to find the verification details.' });
            submitGTMEvent({ event: 'SIGNUP_SUCCESS', userId: so_user_id, userEmail: so_user_email, userName: so_user_name});
            this.redirectToLaunchpad(res.data.token);


        }, err => {
            console.error("Failed:", err);
            submitGTMEvent({ event: 'SIGNUP_ERROR', error: err });
            this.setState({ showAlert: true, isSending: false, signupError: true, signupStatus: 'Invalid details provided. Probably this email is already associated with an account.' });
        });
    }

    _signup(e) {
        e.preventDefault();

        const formData = new FormData(e.target);
        const secreatCaptcha =  e.currentTarget.dataset.id;

        const name = formData.get('name'), email = formData.get('email'), password = formData.get('password');

          grecaptcha.ready(() => {
            
            grecaptcha.execute(secreatCaptcha, {action: 'submit'}).then((token)=> {

                if(token){

                    try{
                        const signupRequest = { email, password, name };
                        this.signup(signupRequest, "email", token);
                    }catch(e){
                        console.error(e);
                    }   
                }

            });
          });
        
    }

    signupGoogleSuccess = (idToken) => {
        this.signup(idToken, "google");
    }


    _closeAlert = () => {
        this.setState({ showAlert: false });
    }
    viewPassword = () => {
        this.setState({ viewPassword: true });
    }

    hidePassword = () => {
        this.setState({ viewPassword: false });
    }
    // responseGoogle = (response) => {
    //     console.log(response);
    //     if (typeof window !== 'undefined') {
    //         navigate('/account/completion' + window.location.search);
    //     }

    // }

    // responseGoogleError = (e) => {
    //     console.error(e);
    //     this.setState({ showAlert: true, signupError: true, signupStatus: 'Received error while signup with Google. Try again!' });
    // }

    render() {
        const { isSending, signupStatus, showAlert } = this.state;
        return ( <RouteData>
            {({ captchaApikey}) => {
       
                return(<>
        <Helmet>
        <script src={`https://www.google.com/recaptcha/api.js?render=${captchaApikey}`}></script>
    
        </Helmet>
            <Head>
                {/* <meta name="google-signin-client_id" content="406431960011-mt631pa7qu02f03dqld40aqookf658e9.apps.googleusercontent.com" /> */}
                <title>ShoutOUT | Sign up</title>
            </Head>
            <AccountNavbar />

            <div id="signup-block" className="container login-block h-100 pt-70 ">
                <div className="row h-100">
                    <div className="col-md-6 col-sm-12 input-sec order-3 my-auto">
                        <h3 className="text-center font-weight-bold">Get Started for Free</h3>
                        <br />
                        <div className="text-center">
                            <LoginWithGoogle onSuccess={this.signupGoogleSuccess} />
                            <br />
                            <p className="text-dark">OR</p>
                        </div>
                        <form className="login-form" 
                        data-id={captchaApikey}
                        onSubmit={this._signup}>

                            <div className="form-group">
                                <label htmlFor="name" className="">Name</label>
                                <input name="name" type="name" className="form-control" placeholder="John" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" className="">Email</label>
                                <input name="email" type="email" className="form-control" placeholder="sam@mycompany.com" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="">Password</label>

                                <div className="position-relative">

                                    <input name="password" aria-label="password" type={this.state.viewPassword ? "text" : "password"} className="form-control" placeholder="XXXXXX" required min={6} max={30} />
                                    <button className="btn-view-password position-absolute p-2 btn btn-default" type="button"
                                        onTouchStart={this.viewPassword}
                                        onTouchEnd={this.hidePassword}
                                        onMouseDown={this.viewPassword}
                                        onMouseUp={this.hidePassword}
                                        onMouseLeave={this.hidePassword}><img src={EyeImage} alt="View" width="22" /></button>

                                </div>
                            </div>

                        
                            <div className="form-check ml-n3">
                                <label className="form-check-label">
                                    {/* <input type="checkbox" className="form-check-input" /> */}
                                    <small className="text-dark">Already a member? <Link to="/login">Login here.</Link></small>
                                </label>
                                <hr />
                                <label className="form-check-label">
                                    <small className="text-dark">By clicking this button, you agree to ShoutOUT's <br /><Link to="/privacy">Privacy Policy</Link> & <Link to="/terms">Terms of Use</Link></small>
                                </label>
                                <div className="text-center text-md-none float-none float-md-right mb-4">
                                    <button type="submit" 
                                        className="btn btn-primary btn-round px-5" 
                                        disabled={isSending}>
                                            {isSending ? "Saving..." : "Sign Up"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>


                    <div className="col-md-6 col-sm-12 order-1 my-auto  d-none d-md-block">

                        <div className="text-center">

                            <img className="img-fluid" src="/assets/images/signup-page-image.svg" alt="feature" />

                        </div>
                        {/* {this.state.isLoadingBlogPosts && <img className="img-fluid" src={LoadingImage} alt="Blog Post Loading Image" />}
                        {
              this.state.blogPosts.map(({ id, srcImage, title, link, excerpt }) => (
                    <div key={id}>
                            <div className="card shadow border-0">
                            <div>
                                <img className="card-img-top" src={srcImage||EmptyBlogImage} alt="ShoutOUT Blog" />
                            </div>
                                <div className="card-body">
                                    <h5 className="card-title">{title.rendered}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} className="card-text"></div>
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                <br/><br/>
                                    <h5 className="text-secondary">Already a member? <Link to="/login">Login here</Link></h5>
                                </div>
                            </div>
                        </div>
                            ))
                        } */}

                        {/* <div className="row">
                            <div className="col-md-12 text-center">
                                <br /><br />
                                <h6 className="text-secondary">Already a member? <Link to="/login">Login here</Link></h6>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <CloseAlert showAlert={showAlert} closeAlert={this._closeAlert} title="Failed!" type={this.state.signupError ? 'danger' : 'success'} message={signupStatus} />
            
            </>
            )}}
        </RouteData>);
    };

};

export default () => {
    const { BUILD_PRODUCT } = useSiteData();
    return (<Signup buildProduct={BUILD_PRODUCT} />)
}

