import React from 'react'

 const MessengerPage = () => {
    return (
        <div>
            <div className="alert alert-primary text-center" role="alert">
            Messenger
        </div>

            <div className="card-deck">
                <div className="card border-secondary">

                    <div className="card-body">
                        <h5 className="card-title">Interactive messages </h5>
                        <p className="card-text">Answer all the frequently asked questions by the customers in real-time with interactive messaging, to give the customers a better customer engagement experience.</p>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        <h5 className="card-title">Templates </h5>
                        <p className="card-text">Get access to the pre-designed message templates that help to compose a perfect message to carry out the Whatsapp campaigns effectively.</p>
                    </div>
                </div>

                <div className="card">

                    <div className="card-body">
                        <h5 className="card-title">Triggered campaigns </h5>
                        <p className="card-text">Launch your campaigns at lightning speed. Design the campaigns as you wish with your own pre-defined criteria. </p>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default MessengerPage;
