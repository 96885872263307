import React, { useState } from 'react'
import { useRouteData, Head } from 'react-static';
import {Helmet} from 'react-helmet';
import { Link } from '@reach/router'
import Modal from '../../components/modals/Modal';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


import './Solution.css';

const Church = () => {
    const [isOpen, setIsOpen] = useState(false);


    const viewImage = () => {
        setIsOpen(!isOpen)
    }

    return (<>
        <Helmet>
            <title>Group Text Messaging for Churches | ShoutOUT</title>
            <meta name="description" content="Group text messaging for churches & religious institutes. Personalized bulk SMS, two-way messaging with link shortening.Try it for free." />
            <link rel="canonical" href="https://getshoutout.com/industries/group-text-messaging-for-churches" />
            <meta name="keywords" content="group text messaging, church texting, church texting service, text messaging for churches, church text messaging, church sms, mass texting for churches, mass texting service for churches, sms marketing for chruches" />
        </Helmet>

        <MainNavbar />



        <div className="container opacity-p9 h-50">
            {/* <div className="back-button">
                <Link to="/solutions" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
            </div> */}
            <div className="row header-space">
                <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-2 text-center text-sm-left">
                    <h1 className="font-weight-bold mt-4">Group Text <span className="text-primary">Messaging</span> for Churches - Reach Your Entire Congregation With Engaging SMS</h1>
                    <p className="mb-0">The ideal communication solution for churches, ministries, and other religious institutes. Easily communicate with your congregation and supporters.</p>
                    <Link to="/signup" className="btn btn-outline-primary border-radius-2 mt-2">Get started for Free</Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1 text-center">
                    <img className="img-fluid w-75 mt-5" src="/assets/images/solutions/church/group-text-messaging-for-churches.svg" alt="group-text-messaging-for-churches" title="group-text-messaging-for-churches" />
                </div>
            </div>
            <div className="row">
                {/* <div className="col-12 text-center">
                    <h4 className="font-weight-bold">Why are Bulk SMS Alerts Perfect to Communicate with Students & Parents?</h4>
                </div> */}

                <div className="col-12">
                    <h3 className="font-weight-bold text-center my-4">What can you text your congregation using a church texting service?</h3>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/community-update-sms.svg" alt="community-update-sms" title="community-update-sms" />
                                <span className="mt-2 text-center">Community updates</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/ministry-information-sms.svg" alt="ministry-information-sms" title="ministry-information-sms" />
                                <span className="mt-2 text-center">Ministry information</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/event-update-sms.svg" alt="event-update-sms" title="event-update-sms" />
                                <span className="mt-2 text-center">Event updates</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-colum align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/prayer-request-sms.svg" alt="prayer-request-sms" title="prayer-request-sms" />
                                <span className="mt-2 text-center">Prayer requests</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/event-sms-wishes.svg" alt="event-sms-wishes" title="event-sms-wishes" />
                                <span className="mt-2 text-center">Event wishes</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/volunteer-program-sms.svg" alt="volunteer-program-sms" title="volunteer-program-sms" />
                                <span className="mt-2 text-center">Volunteer program details</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/send-donation-link-sms.svg" alt="send-donation-link-sms" title="send-donation-link-sms" />
                                <span className="mt-2 text-center">Donation links</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/event-registration-link-sms.svg" alt="event-registration-link-sms" title="event-registration-link-sms" />
                                <span className="mt-2 text-center">Event registration links</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                <img className="icon-width" src="/assets/images/solutions/church/icons/send-schedule-changes-sms.svg" alt="send-schedule-changes-sms" title="send-schedule-changes-sms" />
                                <span className="mt-2 text-center">Schedule changes</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/church/church-texting-service.png" alt="church-texting-service" title="church-texting-service" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/church/church-sms.png" alt="church-sms" title="church-sms" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/church/mass-texting-for-churches.png" alt="mass-texting-for-churches" title="mass-texting-for-churches" />
                </div>


            </div>

            <div className="row">

                <div className="col-12">
                    <h4 className="font-weight-bold text-center my-4">What makes ShoutOUT the ideal mass texting service for churches?</h4>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/church/church-sms-platform.jpg" alt="church-sms-service" title="church-sms-service" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2">
                            <p className="font-weight-bold">Create groups / segments from your contact list</p>
                            <p>
                                Upload your contact list as a CSV or Excel File.
                                <br /><br />
                                Create groups based on age, location, marital status etc. Easily send separate text message campaigns for different events (e.g., youth programs, counselling programs, volunteer programs and any other community events.)
                                <br /><br />
                                Keep all contact details on ShoutOUT and use them to send personalized text messages by including each person’s name, congregation, etc. in the message content.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-2">
                            <img className="img-fluid" src="/assets/images/solutions/church/church-texting-platform.jpg" alt="church-texting-platform" title="church-texting-platform" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2 order-sm-1">
                            <p className="font-weight-bold mt-auto">Send texts to multiple contact groups in one campaign </p>
                            <p>
                                Select multiple segments (that you created, based on age, location, marital status etc.) in one campaign.
                                <br /><br />
                                Easily send a general message such as event updates, mass updates etc. to multiple groups in one camapign.
                                <br /> <br />
                                Eliminate the need to send several campaigns for a single text message.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-1">
                            <img className="img-fluid" src="/assets/images/solutions/church/send-targeted-messages.jpg" alt="church-texting" title="church-texting"/>
                        </div>
                        <div className="col-lg-6 col-sm-12 my-auto order-2 order-sm-2">
                            <p className="font-weight-bold mt-auto">Send targeted messages to your contacts </p>
                            <p>
                                Send both SMS and Email campaigns on the same platform.
                                <br /><br />
                                Create beautiful email content with ShoutOUT HTML Editor and even add call to actions buttons to direct the recipients to certain webpages (Eg: newsletters, community updates, event plans, donations etc. )
                                <br /><br />
                                Send text messages globally to international recipients with ShoutOUT’s international SMS coverage.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-2 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/church/sms-with-church-name.jpg" alt="sms-with-church-name" title="sms-with-church-name" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-1 order-2">
                            <p className="font-weight-bold mt-auto">Send text messages either via a unique text number or under church / religious institute name</p>
                            <p>
                                Ensure that your text messages stand out in the message inbox using a unique mobile number.
                                <br /><br />
                                Request for a Sender ID that you prefer, after creating a ShoutOUT profile. If all conditions are satisfied, it will be approved within one business day.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid w-100" src="/assets/images/solutions/church/url-shortening.jpg" alt="link-shortening" title="link-shortening" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-2 order-2">
                            <p className="font-weight-bold mt-auto">Include shortened online links</p>
                            <p>
                                Send event registration, donation, online sermons or counselling session links etc. in the text message.
                                <br /><br />
                                The URL Shortener option automatically reduces URL length, turns it into a shortened URL, thereby reducing the number of characters taken up.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4">
                        {/* <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/education/Senders.jpg" />
                        </div> */}
                        <div className="col-12">
                            <h4 className="font-weight-bold text-center my-4">Other unique features that remove the hassle in sending text message campaigns</h4>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/schedule.svg" />
                                <p>Scheduling</p>
                                <small className="text-muted">Schedule text messages to be sent later.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/templates.svg" />
                                <p>Templates</p>
                                <small className="text-muted">Save messages as templates to be used repetitively in campaigns.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/smartphone.svg" />
                                <p>Live SMS preview</p>
                                <small className="text-muted">Check the final look of your message as you type it, on a mobile phone screen.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/keyboard.svg" />
                                <p>Custom attributes</p>
                                <small className="text-muted">Automatically add unique data that vary according to each person / group, to the message content. (Eg: dates, times, donation IDs, etc.)</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12 mt-sm-4 mt-0 mx-auto">
                    <h4 className="my-4 font-weight-bold text-center">Sample ShoutOUT SMS for Schools</h4>
                    <small className="text-muted text-smaller">Hover over the SMS for features</small>
                    <div className="p-3 shadow-sm cursor-pointer sms-content-education">
                        <div className="d-flex justify-content-between">
                            <img className="mr-2" width="24" src="https://lite.getshoutout.com/assets/images/icons/sms-icon.png" alt="SMS Icon" />
                            <p className="font-weight-bold mb-0 mr-auto" data-toggle="tooltip" data-placement="top" title="Business Name">EduSmart</p>
                            <small className="text-muted">now</small>
                        </div>
                        <p className="mt-2 mb-0">
                            Hi,
                            <span className="text-purple" data-toggle="tooltip" data-placement="top" title="Personalized Name"> Amanda</span>.
                            <span className="text-success" data-toggle="tooltip" data-placement="top" title="Student's Class Variable"> Year two </span>
                            classes will resume online on
                            <span className="text-pink" data-toggle="tooltip" data-placement="top" title="Date Variable"> 1st August</span>.
                            Please find the class schedule here- <br /><a href="" data-toggle="tooltip" data-placement="top" title="Shortened URL">e-url.co/TjwPau</a>.
                            You can complete course fee payment via this link - <a href="" data-toggle="tooltip" data-placement="top" title="Shortened URL">e-url.co/UoPArt</a>.
                        </p>
                    </div>
                </div>
            </div> */}

            {/* <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
                <h4 className="my-4 font-weight-bold text-center">How to send messages to your congregation?</h4>
                <div className="embed-responsive embed-responsive-16by9">
                    <video controls={true} preload={true} muted={true} width="720" src="/assets/images/solutions/education/school-sms-tool.mp4" alt="text-messaging-for-schools" title="schools sms platform" />
                </div>
            </div> */}
            {/* 
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold">Enjoy Stress FREE Wedding Planning!</h4>
                </div>
                <div className="col-lg-8 col-sm-12 mx-auto">
                    <div className="card p-4 shadow">
                        <div className="d-sm-flex text-sm-left text-center align-items-center">
                            <img src="/assets/images/solutions/wedding/nithushan.jpg" className="testimonial-image mr-sm-4 mr-0" />
                            <div>
                                <small><i>“Loved using this platform for our wedding invitation to keep our guests up-to-date of any possible changes in venue and times. This is a go to service for anyone planning their big day during this pandemic! Also, unlike other platforms, we were able to target our audience and focus on key attendees. Customer service was incredible! Thank you so much for helping our wedding day run smoothly!”</i></small>
                                <p className="font-weight-bold mb-0">- Nithushan & Abinaya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row mt-5 justify-content-center">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold">More on this</h4>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2021/08/church-text-messaging-service.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">Text Messaging for Churches – Build and Retain Your Congregation</p>
                            <small>Text messaging for churches or church text messaging – why?  Churches, ministries or any other religious groups prefer an engaging community, and the key to an engaging community is proper…</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/text-messaging-for-churches/"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2021/04/sms-alerts-sustainable-wedding-1-1250x710.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">Case Study: A Text Message Campaign for the Sustainable Wedding Function of Nithushan & Abinaya</p>
                            <small>Text message campaign, sustainability, and wedding function – how do these relate to each other or work together? In a way that’s innovative, feasible, timely, and most importantly, sustainable.</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/text-message-campaign-sustainable-wedding/"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div> */}
            </div>

            <CallToAction title="Connect to Your Students and Parents Easier than Before!" />

            <MainFooter />


        </div>

        {/* <Modal visible={isOpen} onClickBackdrop={viewImage}>
            <div className="modal-body">
                <img className="img-fluid" src="/assets/images/solutions/education/student-how.svg" />
            </div>


            <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={viewImage}>
                    Close
                </button>
            </div>
        </Modal> */}



    </>
    )
}
export default Church;