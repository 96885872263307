import React from 'react';
import { Head } from 'react-static';
import { navigate } from '@reach/router';
import GoogleLogin from 'react-google-login';
import Services from "./../../services/Services";
import CloseAlert from './../../components/alert/CloseAlert';
import { submitGTMEvent } from "./../../services/AnalyticsServices";
import Google from './../../images/google.svg';

class LoginWithGoogle extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showAlert: false, buttonText: "" };
    }

    redirectToLaunchpad = (token) => {
        navigate("/launchpad" + (window.location.search ? (window.location.search + "&token=" + token) : "?token=" + token))
    }
    responseGoogle = (response) => {
        if (typeof window !== 'undefined') {

            this.setState({ buttonText: "Please wait..." });
            if (this.props.method === "LOGIN") {

                Services.login({ idToken: response.tokenId }, "google").then(response => {

                    this.setState({ buttonText: "" });
                    localStorage.setItem("token", response.data.token);

                    this.setState({ buttonText: "" });

                    this.redirectToLaunchpad(response.data.token);
                }, err => {
                    console.debug("Failed:", err);

                    this.setState({ showAlert: true, buttonText: "" });
                });


            } else {
                if (this.props.onSuccess) {
                    this.setState({ buttonText: "" });
                    this.props.onSuccess({ idToken: response.tokenId });
                }

            }

            console.log(response);
            // if (typeof window !== 'undefined') {
            //     navigate('/account/completion' + window.location.search);
            // }
        }
    }

    responseGoogleError = (e) => {
        console.error(e);
        this.setState({ showAlert: true });
    }
    closeAlert = () => {
        this.setState({ showAlert: false });
    }

    render() {
        return (<React.Fragment>
            <Head>
        <title>ShoutOUT | {this.props.method === "LOGIN" ? "Login" : "Sign Up"}</title>
            </Head>
            <GoogleLogin
                clientId="406431960011-mt631pa7qu02f03dqld40aqookf658e9.apps.googleusercontent.com"
                //buttonText={this.state.buttonText ? this.state.buttonText : (this.props.method === "LOGIN" ? "Sign In with Google" : "Sign Up with Google")}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogleError}
                cookiePolicy={'single_host_origin'}
                //theme="dark"
                render={renderProps => (
                    <button className="btn btn-outline-primary btn-round" onClick={renderProps.onClick} disabled={renderProps.disabled || this.state.buttonText}><img className="mr-2" src={Google} alt="ShoutOUT Google Authentication Button Icon" />{this.state.buttonText ? this.state.buttonText : (this.props.method === "LOGIN" ? "Sign In with Google" : "Sign Up with Google")}</button>
                )}
            />
            <CloseAlert showAlert={this.state.showAlert} closeAlert={this.closeAlert} message={`Received error while ${this.props.method === "LOGIN" ? "signin" : "signup"} with Google. Try again!`} title="Failed!" type="danger" />
        </React.Fragment>);
    }
}

export default LoginWithGoogle;