import React, { useState } from 'react';
import { Head } from 'react-static';
import NavbarAI from './navbar/NavbarAI';
import './Ai.css';

const VideoContainer = ({ selectedChatbot }) => (
  <video className="bg-white phone-container shadow" width="300" autoPlay={true} muted={false} loop key={selectedChatbot}>
    <source src={`./assets/images/ai/videos/${selectedChatbot}.mp4`} type="video/mp4" />
  </video>
)

const Ai = (props) => {

  const [selectedChatbot, setSelectedChatbot] = useState('messenger');

  const onClickChatbot = (e) => {
    const chatbot = e.currentTarget.name;
    setSelectedChatbot(chatbot);
  }


  return (<>
    <Head>
      <meta charSet="UTF-8" />
      <meta name="description" content="Enterprise chatbot engine to automate text and voice based virtual assistants that really understand your users with high scalability" />
      <title>ShoutOUT.AI | WhatsApp, Messenger and Voice Chatbots powered with AI</title>
      <meta property="og:image" content="/assets/images/ai/thumbnail-ai.png" />
      <meta property="twitter:image" content="/assets/images/ai/thumbnail-ai.png" />
    </Head>
    <NavbarAI/>
    <div className="container-fluid pb-5">
      <div className="row header">
        <div className="col-lg-5 offset-lg-1 my-lg-auto mt-5">
          {/* <img className="img-fluid my-4 header-logo" src="./assets/images/ai/logo.svg" alt="ShoutOUT AI Header Image" /> */}
          <h1 className="header-title mt-4">Virtual AI Assistants that Really Understand Users</h1>
          <h5 className="mb-0">Enterprise chatbot engine to automate text and voice based virtual assistants.</h5>
        </div>

        <div className="col-lg-6 my-lg-auto p-lg-5 text-sm-center">
          <img className="img-fluid w-100 p-3 mb-3" src="./assets/images/ai/header-image.svg" alt="ShoutOUT AI Header Image" />
        </div>

      </div>
      <div className="row justify-content-center">
        <div className="col-12 text-center pt-3">
          <h3 className="mb-3 font-weight-bold">You will love it!</h3>
        </div>
        <div className="col-lg-10 col-md-10 col-sm-12">
          <div className="card-columns text-center">
            <div className="card p-7 shadow mx-4 mb-4 h-400-f">
              <img className="img-fluid p-4" src="./assets/images/ai/icons/1.svg" alt="ShoutOUT AI Feature Icon 01" />
              <h5 className="mb-0 font-weight-bold">Natural Conversation Experience</h5>
              <p className="mb-0">Intelligent assistant that can go beyond predefined conversational flows</p>
            </div>

            <div className="card p-7 shadow mx-4 mb-4 h-400-f">
              <img className="img-fluid p-4" src="./assets/images/ai/icons/2.svg" alt="ShoutOUT AI Feature Icon 01" />
              <h5 className="mb-0 font-weight-bold">Understand What Customer Said</h5>
              <p className="mb-0">Understand your customer's intent and extract information using NLU</p>
            </div>

            <div className="card p-7 shadow mx-4 mb-4 h-400-f">
              <img className="img-fluid  p-4" src="./assets/images/ai/icons/3.svg" alt="ShoutOUT AI Feature Icon 01" />
              <h5 className="mb-0 font-weight-bold">Build up the Conversation like a Human</h5>
              <p className="mb-0">Cutting edge Artificial Intelligence technologies decide the next response from the bot</p>
            </div>

            <div className="card p-7 shadow mx-4 mb-4 h-400-f">
              <img className="img-fluid p-4" src="./assets/images/ai/icons/4.svg" alt="ShoutOUT AI Feature Icon 04" />
              <h5 className="mb-0 font-weight-bold">Full Data Control</h5>
              <p className="mb-0">Don’t send your valuable data to third party services. Remain independent: deploy on-prem.</p>
            </div>

            <div className="card p-7 shadow mx-4 mb-4 h-400-f">
              <img className="img-fluid p-4" src="./assets/images/ai/icons/5.svg" alt="ShoutOUT AI Feature Icon 05" />
              <h5 className="mb-0 font-weight-bold">Seamless Extensions</h5>
              <p className="mb-0">Connecting to the next new channel is easy, even with voice channels</p>
            </div>

            <div className="card p-7 shadow mx-4 mb-4 h-400-f">
              <img className="img-fluid p-4" src="./assets/images/ai/icons/6.svg" alt="ShoutOUT AI Feature Icon 06" />
              <h5 className="mb-0 font-weight-bold">Connect Your Nodes and 3rd Party APIs</h5>
              <p className="mb-0">Bring chatbot conversations into the real world by integrating ShoutOUT AI with yours and 3rd party APIs</p>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div className="container pb-5">
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="mb-3 font-weight-bold">Experience it Yourself</h3>
        </div>
        <div className="col-lg-6 col-sm-12 text-center">
          <VideoContainer selectedChatbot={selectedChatbot} />
        </div>
        <div className="col-lg-6 col-sm-12 text-center my-auto">
          <div className="d-flex flex-column align-items-center pt-4">
            <button name="messenger" className="border-0 bg-white shadow mb-3 p-4 chatbot-button" onClick={onClickChatbot}>
              <div className="d-flex justify-content-between align-items-center">
                <img src="./assets/images/ai/icons/messenger.svg" alt="messnger" />
                <span className={selectedChatbot === 'messenger' ? 'text-primary font-weight-bold' : ''}>Messenger Chatbot</span>
              </div>
            </button>
            <button name="whatsapp" className="border-0 bg-white shadow mb-3 p-4 chatbot-button" onClick={onClickChatbot}>
              <div className="d-flex justify-content-between align-items-center">
                <img src="./assets/images/ai/icons/whatsapp.svg" alt="whatsapp" />
                <span className={selectedChatbot === 'whatsapp' ? 'text-success font-weight-bold' : ''}>WhatsApp Chatbot</span>
              </div>
            </button>
            <button name="voice" className="border-0 bg-white shadow mb-3 p-4 chatbot-button" onClick={onClickChatbot}>
              <div className="d-flex justify-content-between align-items-center">
                <img src="./assets/images/ai/icons/voice.svg" alt="voice" />
                <span className={selectedChatbot === 'voice' ? 'text-purple font-weight-bold' : ''}>Voice Chatbot</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid py-5 architecture-bg">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <h3 className="mb-3 font-weight-bold">Built with High Scalability</h3>
        </div>
        <div className="col-12 my-3 overflow-x-scroll text-center">
          <img src="./assets/images/ai/architecture.svg" alt="architecture" />
        </div>
      </div>
    </div>

    <div className="container-fluid py-5 book-bg">
      <div className="row text-center">
        <div className="col-lg-6 col-sm-12 my-auto">
          <h4 className="mb-lg-0 font-weight-bold">Book your demo now</h4>
        </div>
        <div className="col-lg-6 col-sm-12 my-auto">
          <a href="https://calendly.com/gihan-silva-shoutout/30min" target='_blank' className="btn btn-dark btn-lg rounded-0">Book Now</a>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center text-center">
        <div className="col-lg-10 col-sm-12">
          <h3 className="mb-3 font-weight-bold">Get in touch</h3>
          <iframe className="border-0 w-100 shadow" src="https://collect.getshoutout.com/ShoutOUTAIGetInTouch?embed=true" height="280"></iframe>
          <small className='float-right text-muted'>Powered by ShoutOUT Forms</small>
        </div>
      </div>
    </div>
    <div className="container-fluid py-3">
      <div className="row text-center">
        <div className="col">
          <small>© 2020 Copyright <a href="https://getshoutout.com/"> ShoutOUT Labs</a></small>
        </div>
      </div>
    </div>
  </>)
}
export default Ai;