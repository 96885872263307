import React, { useEffect, useState } from 'react';
import { Head } from 'react-static';
import { navigate, Link } from '@reach/router';
import UtilService from './../../services/UtilService';
import './Signup.css';

const Logout = () => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem("token");
            navigate("/login" + window.location.search);

        }

    }, [])

    const onClickNavigateLogin = () => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem("token");
            navigate("/login" + window.location.search);
        }
    }


    return (<>
        <Head>
            <title>ShoutOUT | Log out</title>
        </Head>
        <div className="logging-out text-center">
            <h3 className="mb-4">Logging out...</h3>
            <button className="btn btn-primary btn-sm" onClick={onClickNavigateLogin}>Go to Login</button>
        </div>

    </>);
}


export default Logout;