import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';


const SmsPage = () => {
    return (
        <div>

    <div className="alert alert-primary text-center" role="alert">
     SMS
    </div>

        <div className="card-deck">
        <div className="card border-secondary">
          
          <div className="card-body">
            <h5 className="card-title">Dynamic Texting</h5>
            <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
          </div>
        </div>

        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Tracking & URL shorten</h5>
            <p className="card-text">Get the long URLs shorten through the same platform to save your character count. And track your customers to get insights not only on the number of clicks but also exactly on who has clicked the link and who has not?</p>
          </div>
        </div>

        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Message templates</h5>
            <p className="card-text">Store all your frequently used messages and load them whenever you need them. And get access to the pre-designed messaging templates that help you to compose a perfect message with a touch of professionalism</p>
           
          </div>
        </div>
       
        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">Global SMS coverage </h5>
            <p className="card-text">Go global with SMS to reach your customers anywhere in the world. Get access to the worldwide coverage of sending and receiving messages to all the 195 countries within a few seconds.</p>
           
          </div>
        </div>

        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title">SMS API</h5>
            <p className="card-text">Expand your texting competencies. Integrate your system with the ShoutOut powerful API and build effective SMS products.</p>
           
          </div>
        </div>
        
      </div>
      </div>
    )
}

export default SmsPage;
