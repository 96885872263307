import React from 'react';
import { Head } from 'react-static';
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import './Policies.css';


export default () => (
    <div>
        <Head>
            <title>ShoutOUT | Terms and Conditions</title>
        </Head>
        <MainNavbar/>
        <section>
    <div className="py-5">
        <div className="container cover-image2 justify-content-center text-center">
            <br/><br/><br/>
            <h5 className="subheading ">Customer Terms and Conditions Agreement</h5>
            <div className="row hidden-md-up">
                <div className="col-md-12">
                    <div className="card-product">
                        <div className="card-block">
                            <div className="row complete-products">
                                <div className="col-md-12">
                                    <div className="container privacy-container">
                                        <div className="row">
                                            <div className="col-md-12 text-left">
                                                <div>
                                                    <br/>
                                             
                                                    <p>
                                                        <strong>1.</strong> When you sign up for the Service and agree
                                                        to these
                                                        Terms, the Agreement
                                                        between you and ShoutOUT is formed, and the term of the
                                                        Agreement (the
                                                        “Term”) will begin. The Term will continue for as long as you
                                                        have a
                                                        ShoutOUT account or until you or we terminate the Agreement in
                                                        accordance
                                                        with these Terms, whichever happens first
                                                    </p>
                                                </div>

                                                <div>

                                                    <strong>2. Obligations of ShoutOUT</strong>
                                                    <div className="first-indent">
                                                        <p>2.1 ShoutOUT shall make available a Short Message
                                                            Service(SMS) based
                                                            solution for the Customer in order to enable the End Users
                                                            to send
                                                            and
                                                            receive information sent by the Customer via SMS for the
                                                            period
                                                            stated
                                                            in this Agreement.</p>

                                                        <p>2.2 ShoutOUT shall provide the Customer with the
                                                            aforementioned
                                                            Service
                                                            through an online web interface application administered by
                                                            ShoutOUT,
                                                            where the Customer shall be provided with a username and
                                                            password
                                                            to
                                                            have the privilege to access and use the application.</p>

                                                        <p>2.3 ShoutOUT shall inform the Customer of any interruption
                                                            to the
                                                            Services within the first One hour of commencement of such
                                                            interruption
                                                            provided such interruption occurs anytime between 8.30 AM
                                                            and 5.30
                                                            PM(IST) on weekdays; ShoutOUT agrees to take steps to
                                                            promptly
                                                            response
                                                            to the complaints made by the Customer regarding the
                                                            service and
                                                            rectify any problems/interruptions to the Said Service
                                                            forthwith.</p>

                                                        <p>2.4 ShoutOUT shall ensure that the said 140-character
                                                            message sent
                                                            by
                                                            the Customer is delivered on the date and time on which it
                                                            is
                                                            intended
                                                            to be sent by the Customer and the message delivered
                                                            without any
                                                            alteration, amendment, change, modification, to the.</p>

                                                        <p>2.5 ShoutOUT shall indemnify the Customer and keep the
                                                            Customer
                                                            indemnified against all direct costs, damages, losses and
                                                            expenses
                                                            incurred or suffered by the Customer due to any breach of
                                                            this
                                                            Agreement by ShoutOUT.</p>

                                                        <p>2.6 ShoutOUT shall warrant, represent and undertake
                                                            throughout the
                                                            Agreement period that it shall comply with all laws and
                                                            regulations
                                                            applicable to the provisions of the Said Service;</p>

                                                        <p>2.7 ShoutOUT shall not use the information disclosed by the
                                                            Customer
                                                            for
                                                            another purposes other than the purposes of this Agreement;</p>

                                                        <p>2.8 ShoutOUT shall ensure that the Service is utilized
                                                            solely for
                                                            the
                                                            purposes intended by this Agreement;</p>

                                                        <p>2.9 ShoutOUT shall not to misuse the mobile numbers of the
                                                            End Users
                                                            entered into the online web interface application
                                                            administered by
                                                            ShoutOUT and provided to the Customer;</p>
                                                    </div>

                                                </div>

                                                <div>

                                                    <strong>3. Customer’s Obligations</strong>

                                                    <p>The Customer shall be committed to use the online web interface
                                                        application in accordance with the utmost technical
                                                        specifications
                                                        which have been provided to the Customer by ShoutOUT and
                                                        ShoutOUT shall
                                                        broadcast SMS messages in accordance with the laws of the
                                                        countries
                                                        where the messages are sent.</p>

                                                    <div className="first-indent">
                                                        <p>3.1 The Customer commits to abide by the internal technical
                                                            and
                                                            administrative processes and procedures of ShoutOUT for the
                                                            use of
                                                            the
                                                            SMS Service on ShoutOUT Labs network which have been made
                                                            available
                                                            to
                                                            the Customer.</p>

                                                        <p> 3.2 The Customer shall not permit or allow acting or
                                                            omitting to
                                                            act in
                                                            any way, which may injure or damage any person’s property
                                                            or may
                                                            cause
                                                            the Facility or the quality of the SMS Service to be
                                                            suspended.</p>

                                                        <p>3.3 The Customer shall not use or allow its employees to use
                                                            the
                                                            online
                                                            web interface application, or have access to the Services
                                                            for any
                                                            improper, immoral, or unlawful purposes.</p>

                                                        <p>3.4 The Customer shall not use the online web interface
                                                            application
                                                            and
                                                            the SMS Services for any purpose other than the intended
                                                            approved
                                                            use
                                                            from set out in this Agreement.</p>

                                                        <p>3.5 Customer undertakes to obtain and maintain any licenses,
                                                            or
                                                            approvals as are required under the applicable laws for
                                                            carrying
                                                            out
                                                            its business and for offering the services at terminated
                                                            destination.</p>

                                                        <p>3.6 Customer undertakes to indemnify ShoutOUT and keep it
                                                            indemnified
                                                            against all liabilities, losses, costs damages, expenses,
                                                            demands,
                                                            proceedings, claims and actions ShoutOUT may suffer or
                                                            incur
                                                            (including
                                                            all legal and other charges and expenses which may be
                                                            incurred) of
                                                            whatever nature and howsoever arising as a result of The
                                                            Customer
                                                            failure to obtain or maintain such licenses or approvals
                                                            pursuant
                                                            to
                                                            this clause.</p>

                                                        <p>3.7 Customer will be solely responsible for writing and\or
                                                            generating
                                                            the SMS content and ShoutOUT shall not be responsible in
                                                            any way
                                                            for
                                                            the SMS content, and the Customer shall indemnify ShoutOUT
                                                            for any
                                                            damages\losses may be incurred by ShoutOUT from such
                                                            content.</p>

                                                        <p>3.8 The Customer shall be solely responsible for the
                                                            preparation of
                                                            all
                                                            information and shall be solely liable in respect of the
                                                            content of
                                                            the
                                                            messages, information and the quality of information; the
                                                            guidelines to
                                                            be taken into consideration by the Customer, with regards
                                                            to the
                                                            contents shall include, but not be limited to the
                                                            following:</p>
                                                    </div>


                                                    <div className="second-indent">
                                                        <p>a) Messages and information that communicate words, which
                                                            promote or
                                                            incite terrorism, the misuse of weapons, or encourage or
                                                            incite a
                                                            person to commit a criminal offence, are not permitted.</p>

                                                        <p>b) Messages and information must take into consideration
                                                            social
                                                            values
                                                            and human dignity and not be of a kind that might induce or
                                                            promote
                                                            racial or religious disharmony.</p>

                                                        <p>c) Messages and information sent as a result of spamming are
                                                            not
                                                            permitted.</p>

                                                        <p>d) Messages and information must be accurate and shall not
                                                            be
                                                            designed
                                                            to mislead or misrepresent.</p>

                                                        <p>e) Messages, information, or programs of an explicitly or
                                                            implicitly
                                                            sexual nature are not permitted.</p>

                                                        <p>f) Messages, information, or programs must not suggest
                                                            encourage or
                                                            incite any person to use harmful substances or engage in
                                                            dangerous
                                                            practices.</p>

                                                        <p>g) Messages, information, or programs, which may be
                                                            considered
                                                            unsuitable for a general audience including children, shall
                                                            not be
                                                            permitted.</p>

                                                        <p>h) Messages, information, or programs, which involve the
                                                            collection
                                                            of
                                                            personal information, such as names and addresses, must
                                                            make clear
                                                            to
                                                            all End Users the purpose for which the information is
                                                            required.</p>

                                                        <p>i) Messages, information, or programs containing
                                                            professional
                                                            services
                                                            such as financial, legal, medical, or dental services must
                                                            state
                                                            within
                                                            or after the message or program the sources of the
                                                            information and
                                                            the
                                                            professional standing of the service and must comply with
                                                            the
                                                            relevant
                                                            laws and regulations.</p>

                                                        <p>j) Messages or programs designed for, either wholly or in
                                                            part,
                                                            aimed at
                                                            an audience of children (persons under the age of 18) must
                                                            not
                                                            include,
                                                            references to sexual practices or inappropriate or
                                                            offensive
                                                            Language.</p>

                                                        <p>k) Messages, information, or programs, which reflect a
                                                            particular
                                                            religious or ethical viewpoint, must not offend the
                                                            sensibilities
                                                            of
                                                            those who hold different beliefs or opinions.</p>

                                                        <p>l) Messages, information, or programs for the purpose of
                                                            fund
                                                            raising
                                                            for charity must be in conformity with the relevant laws
                                                            and
                                                            regulation
                                                            in Sri Lanka.</p>

                                                        <p>m) Messages must not contain any notifications, advertising,
                                                            promotional
                                                            or marketing information of any mobile operator or a
                                                            competitor of
                                                            a
                                                            mobile operator.</p>
                                                    </div>

                                                </div>
                                                <div>
                                                    <strong>4. Payments</strong>
                                                    <div className="first-indent">
                                                        <p>4.1 When you sign up for a Monthly Plan, you agree to
                                                            recurring
                                                            billing. Billing occurs on the same day each month, based
                                                            on the
                                                            date that you started the Monthly Plan. Billing for Monthly
                                                            Plans
                                                            may be paused, and you may choose to close your account
                                                            permanently
                                                            at any time. You can choose either online payment or bank
                                                            transfer
                                                            method in the dashboard.</p>

                                                        <p>4.2 You may buy SMS credits to use the Service instead of
                                                            signing up
                                                            for a Monthly Plan. This is explained on the pricing page
                                                            of our
                                                            Website.</p>

                                                        <p>4.3 If you use an Add-on that has a charge, then you’ll be
                                                            billed
                                                            that additional amount with each billing cycle for as long
                                                            as the
                                                            Add-on is active. Your billing cycle for an Add-on may
                                                            differ from
                                                            your billing cycle for your Monthly Plan or other Services.</p>

                                                        <p>4.4 We may change our fees at any time by posting a new
                                                            pricing
                                                            structure to our Website and/or sending you a notification
                                                            by
                                                            email. Quoted fees don’t include sales or other
                                                            transaction-based
                                                            taxes of any kind.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <strong>5. Data Privacy, Security and Maintenance</strong>
                                                    <div className="first-indent">
                                                        <p> 5.1 The Customer is responsible for the safe keeping of the
                                                            username and password and in the event that the Customer’s
                                                            password
                                                            becomes exposed to any unauthorized party, the Customer is
                                                            responsible to take action to change it immediately using
                                                            the
                                                            methods on the site.</p>

                                                        <p>5.2 ShoutOUT reserves the right to change the Customer’s
                                                            password
                                                            and username provided prior written notice is given to the
                                                            Customer
                                                            in the manner set out in this Agreement, along with an
                                                            explanation
                                                            as to why the said change is being made, in the event the
                                                            Customer
                                                            is proven to be in breach of its obligations set out in
                                                            this
                                                            Agreement.</p>

                                                        <p>5.3 ShoutOUT shall takes all the appropriate and necessary
                                                            measures
                                                            to keep the data it receives in the course of providing the
                                                            Service
                                                            in a secure manner.</p>

                                                        <p>5.4 ShoutOUT guarantees that any information entered into
                                                            the online
                                                            web interface application by the Customer of its End Users
                                                            shall be
                                                            secure. The Customer shall be responsible for making sure
                                                            that
                                                            appropriate permission has been sought from the End Users
                                                            for
                                                            ShoutOUT to collect and process information in relation to
                                                            the End
                                                            Users.</p>

                                                        <p>5.5 ShoutOUT guarantees that a secure SSL connection sends
                                                            your
                                                            messages to our platform.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <strong>6. Termination</strong>
                                                    <div className="first-indent">
                                                        <p> 6.1 ShoutOUT has the right to terminate the SMS Services
                                                            without
                                                            notice to the Customer upon the occurrence of the
                                                            following:</p>
                                                        <div className="second-indent">
                                                            <p> a) ShoutOUT Service has been used for “Spamming”,
                                                                unauthorized/illegal activities of any kind.</p>

                                                            <p> b) Distribution of any kind of SMS prohibited by
                                                                Telecommunication Regulatory Commission (TRC).</p>
                                                        </div>
                                                        <p> 6.2 ShoutOUT shall have the right to suspend the provision
                                                            of the
                                                            Service , in the event it is proven that the Customer is in
                                                            breach
                                                            of any of its obligations under this Agreement including
                                                            but not
                                                            limited to the obligations stated under clause 3 (three)
                                                            above.
                                                            ShoutOUT shall reimburse to the Customer the monetary value
                                                            of the
                                                            remaining credit amount for the remaining period in such
                                                            occurrence.</p>

                                                        <p> 6.3 In the event ShoutOUT is in breach of its obligations
                                                            and
                                                            responsibilities set forth in this Agreement, the Customer
                                                            is
                                                            entitled to terminate this Agreement without prior notice
                                                            and
                                                            recover the balance outstanding payments made to ShoutOUT
                                                            in
                                                            addition to the legal recourse available.</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <strong>7.</strong> ShoutOUT will not hold responsible for solution
                                                    failure
                                                    /malfunctions due to failures of other mobile operator’s links used
                                                    by
                                                    customer with this application.
                                                </div>
                                                <div>
                                                    <strong>8. Severability</strong>
                                                    <p> All the provisions of this Agreement shall be considered as
                                                        separate
                                                        terms and conditions and in the event that this Agreement is
                                                        affected
                                                        by any legislation or any amendments thereto, or if the
                                                        provisions
                                                        herein contained are held to be illegal, invalid, prohibited or
                                                        unenforceable, any such provisions shall be ineffective only to
                                                        the
                                                        extent of the illegality, invalidity, prohibition or
                                                        unenforceability
                                                        and all other provisions hereof shall remain in full force and
                                                        effect
                                                        as if the illegal, invalid, prohibited or unenforceable
                                                        provisions were
                                                        not a part hereof.</p>
                                                </div>
                                                <div>
                                                    <strong> 9. Notices</strong>
                                                    <p>Any notice to you will be effective when we send it to the last
                                                        email or
                                                        physical address you gave us or when posted on our Website. Any
                                                        notice
                                                        to us will be effective when delivered to us along with a copy
                                                        to our
                                                        legal counsel: Attn. Legal Department, ShoutOUT Labs Pvt Ltd,
                                                        No 02,
                                                        6th Lane. Colombo 03, Sri Lanka.</p>
                                                </div>

                                                <div>
                                                    <strong>10. Applicable Law And Dispute Resolution</strong>
                                                    <div className="first-indent">
                                                        <p>a) This Agreement shall be governed by and construed in all
                                                            aspects
                                                            in accordance with the laws of Sri Lanka.</p>

                                                        <p>b) Any doubt, difference, controversy, dispute or claim
                                                            arising
                                                            from, out of or in connection with this agreement or on the
                                                            interpretation thereof or on the rights, duties,
                                                            obligations or
                                                            liabilities of any parties thereto or on the operation,
                                                            breach,
                                                            termination, invalidity thereof – shall in the first
                                                            instance
                                                            attempted to be resolved amicably by a process of mutual
                                                            negotiation and discussion between the Parties, and if same
                                                            cannot
                                                            be resolved amicably within thirty(30) days either Party
                                                            may
                                                            require that the dispute be referred for resolution by
                                                            Arbitration
                                                            in Sri Lanka.</p>

                                                        <p>c) The Arbitration shall be conducted To Arbitration in
                                                            accordance
                                                            with the rules of the Arbitration Centre of the Institute
                                                            for the
                                                            Development of Commercial Law and Practice, Sri Lanka. The
                                                            Party
                                                            seeking to refer such claim, dispute or difference for
                                                            Arbitration
                                                            (‘the First Party’) shall appoint an Arbitrator and provide
                                                            the
                                                            other party (‘the Other Party’) written notice of same. The
                                                            Other
                                                            Party shall, within fourteen days of notice of such
                                                            appointment,
                                                            accept the Arbitrator so appointed or appoint a second
                                                            Arbitrator,
                                                            and the two Arbitrators shall within one week thereof
                                                            jointly
                                                            appoint a third arbitrator who shall be the Chairman of the
                                                            Panel
                                                            of Arbitrators who shall proceed to determine the matter/s
                                                            in
                                                            dispute. Where the Other Party fails to consent to the
                                                            Arbitrator
                                                            or appoint an arbitrator as aforesaid the arbitrator
                                                            appointed by
                                                            the First (1st) Party shall proceed to determine the
                                                            dispute as
                                                            sole arbitrator. The Arbitration shall be conducted under
                                                            the
                                                            provisions of the Arbitration Act No. 11 of 1995 of Sri
                                                            Lanka. The
                                                            Arbitration proceedings shall be conducted in the English
                                                            Language
                                                            and be held in Colombo, Sri Lanka. The decision of the
                                                            Arbitrator
                                                            or Panel of Arbitrators, as the case may be, shall be final
                                                            and
                                                            conclusive and binding upon the Parties to the dispute.
                                                            Each of the
                                                            Parties shall be entitled to endorse the Arbitral Award and
                                                            seek
                                                            enforcement thereof before a Court of Competent
                                                            Jurisdiction in
                                                            terms of the Laws of Sri Lanka - to the jurisdiction of
                                                            which the
                                                            Parties hereby expressly consent. The cost of arbitration
                                                            shall be
                                                            charged in equal proportion to the Parties. The obligations
                                                            of the
                                                            Parties under and in terms of this agreement shall continue
                                                            during
                                                            the Arbitration proceedings and the obligations of the
                                                            Parties
                                                            other than those under dispute shall not be withheld or
                                                            suspended
                                                            on account of such proceedings.</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <strong>11. Amendment And Variation To Agreement</strong>
                                                    <p>Whenever it becomes necessary, the provisions of this Agreement
                                                        shall be
                                                        amended, modified or supplemented by mutual agreement of the
                                                        Parties.
                                                        Provided however, that any Terms and Conditions set out in this
                                                        Agreement shall not be considered as amended, modified or
                                                        supplemented
                                                        unless mutually agreed in writing and executed by the duly
                                                        authorized
                                                        representative of the Parties.</p>
                                                </div>
                                                <div>
                                                    <strong>12. Publicity</strong>
                                                    <p>Parties shall not be entitled to make, permit or authorize the
                                                        making of
                                                        any press release or public statement or disclosure concerning
                                                        the
                                                        contents of this Agreement or any of the transactions
                                                        contemplated in
                                                        it without the prior written consent of the other Party and
                                                        such
                                                        consent will not be unreasonably withheld.</p>
                                                </div>
                                                <div>
                                                    <strong> 13. Waiver</strong>
                                                    <p>No exercise or failure to exercise or delay in exercising any
                                                        right
                                                        power or remedy vested in either Party under or pursuant to
                                                        this
                                                        Agreement shall constitute a waiver by either of that or any
                                                        other
                                                        right power or remedy.</p>
                                                </div>
                                                <div>
                                                    <strong> 14. No Partnership</strong>
                                                    <p>This Agreement shall not be construed to create any relationship
                                                        of
                                                        principal and agent between Parties hereto and neither party
                                                        shall
                                                        create any contractual obligation to any third party on behalf
                                                        of the
                                                        other.</p>
                                                </div>

                                                <div>
                                                    <p>Congratulations! You’ve reached the end.</p>
                                                    <p>Thanks for taking the time to learn about ShoutOUT’s terms and
                                                        conditions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        <MainFooter/>
</div>
);