import React from "react";
import { Head } from "react-static";
import { Link, navigate } from "@reach/router";
//import LoadingImage from './../../images/loading.gif';
import Services from "./../../services/Services";
import UtilService from "./../../services/UtilService";
import AccountNavbar from "./../../components/navbars/AccountNavbar";
import LoginWithGoogle from "./LoginWithGoogle";
import "./Account.css";

const initialState = {
  isSending: false,
  errorMsg: "",
  verifyToken: "",
  otpCountdown: 300,
}; //{ email: '', password: '', isLoadingBlogPosts: true, isSending: false, errorMsg: '', blogPosts: [] }

let otpCountdownInterval;

const getExpiresInComponent = (otpCountdown) => {
  const minutes = Math.floor(otpCountdown / 60),
    seconds = String(otpCountdown % 60).padStart(2, "0");

  if (minutes > 0) {
    return `${minutes}:${seconds}s`;
  }
  return `${seconds}s`;
};
export default class Login extends React.Component {
  constructor() {
    super();
    this.state = { ...initialState };
    this._login = this._login.bind(this);
    this._verifyLogin = this._verifyLogin.bind(this);
    this._updateOtpCountdown = this._updateOtpCountdown.bind(this);
    this._backToLoginPage = this._backToLoginPage.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      //check expire time and redirect if logged in already
      try {
        const decodedToken = UtilService.decodeToken(token);
        let dateNow = new Date();
        if (decodedToken.exp < dateNow.getTime() / 1000) {
          localStorage.removeItem("token");
        } else {
          if (typeof window !== "undefined") {
            const queryData = UtilService.queryStringToJSON(
              window.location.search
            );
            navigate(
              "/launchpad?token=" +
                token +
                "&referrer=" +
                (queryData.referrer || "")
            );
          }
        }
      } catch (e) {
        localStorage.removeItem("token");
      }
    }

    // Services.getBlogPosts(1).then(res => {
    //   let posts = res.data.map(item => {
    //     let { id, title, link, excerpt, _embedded } = item;
    //     let srcImage = "";
    //     if (_embedded && _embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'].length) {
    //       srcImage = _embedded['wp:featuredmedia'][0].source_url;
    //     }
    //     return { id, title, link, excerpt, srcImage };
    //   });
    //   this.setState({ blogPosts: posts, isLoadingBlogPosts: false });
    // }).catch(e => {
    //                 this.setState({ isLoadingBlogPosts:false});
    //             });

    return () => {
      if (otpCountdownInterval) {
        clearInterval(otpCountdownInterval);
      }
    };
  }

  _updateOtpCountdown() {
    const { otpCountdown } = this.state;
    console.debug("countdown:", otpCountdown);
    if (otpCountdown > 0) {
      this.setState({ otpCountdown: otpCountdown - 1 });
    } else {
      this.setState({ ...initialState });
      if (otpCountdownInterval) {
        clearInterval(otpCountdownInterval);
      }
    }
  }
  _requestLogin(data) {
    this.setState({ isSending: true });
    Services.login(data)
      .then((res) => {
        if (res.data.verifyToken) {
          if (typeof window !== "undefined") {
            this.setState({
              verifyToken: res.data.verifyToken,
              isSending: false,
              otpCountdown: initialState.otpCountdown,
            });
            if (otpCountdownInterval) {
              clearInterval(otpCountdownInterval);
            }
            otpCountdownInterval = setInterval(this._updateOtpCountdown, 1000);
          }
        } else if (res.data.token) {
          if (typeof window !== "undefined") {
            let queryData = UtilService.queryStringToJSON(
              window.location.search
            );
            localStorage.setItem("token", res.data.token);
            // if (queryData.referrer) {
            //     window.location.replace(queryData.referrer + "?token=" + res.data.token);
            //     this.setState({ ...initialState });
            // } else {
            this.setState({ ...initialState });
            navigate(
              "/launchpad?token=" +
                res.data.token +
                "&referrer=" +
                (queryData.referrer || "")
            );
            // }
          }
        } else {
          this.setState({
            isSending: false,
            errorMsg: "Something went wrong. Contact support.",
          });
        }
      })
      .catch((e) => {
        this.setState({
          isSending: false,
          errorMsg: data.otp
            ? "Invalid or expired OTP code"
            : "Invalid credentials. Please check again.",
        });
      });
  }
  _login(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email"),
      password = formData.get("password");
    this._requestLogin({ username: email, password });
  }

  _verifyLogin(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const otp = formData.get("otp");
    this._requestLogin({ otp, verifyToken: this.state.verifyToken });
  }

  _backToLoginPage(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...initialState });
  }

  render() {
    return (
      <>
        <Head>
          <title>ShoutOUT | Login</title>
        </Head>
        <AccountNavbar />
        <div className="container login-block h-100 pt-70">
          <div
            className="row p-4 justify-content-center align-items-center h-100"
            id="loginForm"
          >
            <div className="col-md-6 col-sm-12  d-none d-md-block my-auto">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src="/assets/images/login-page-image.svg"
                  alt="ShoutOUT"
                />
              </div>
            </div>
            <div className="col-md-5 col-sm-12 input-sec order-2 my-auto">
              {!!this.state.verifyToken ? (
                <div>
                  <h3 className="text-center font-weight-bold">
                    Enter Verification Code
                  </h3>
                  <small>
                    We have sent you a verification code to your shoutout email
                    address. Please check your emails.
                  </small>
                  <br />

                  <form className="login-form pt-4" onSubmit={this._verifyLogin}>
                    <div className="form-group">
                      <label htmlFor="otp" className="">
                        OTP Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="otp"
                        id="otp"
                        placeholder="xxxxx"
                        required
                      />
                    </div>
                    {this.state.errorMsg && (
                      <p className="text-danger">{this.state.errorMsg}</p>
                    )}

                    <div className="custom-control">
                      <small className="text-danger">
                        Expires in:{" "}
                        {getExpiresInComponent(this.state.otpCountdown)}{" "}
                      </small>
                    </div>
                    <br />

                    <div className="d-block d-md-none mb-3 text-center">
                      <button
                        type="submit"
                        className="btn btn-block btn-round btn-primary btn-lg"
                        id="loginBtn"
                        disabled={this.state.isSending}
                      >
                        &nbsp;&nbsp;&nbsp;
                        {this.state.isSending ? "Login..." : "Login"}
                        &nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                    <div className="d-none d-md-block float-right">
                      <button
                        type="submit"
                        className="btn  btn-primary btn-round"
                        disabled={this.state.isSending}
                      >
                        &nbsp;&nbsp;&nbsp;
                        {this.state.isSending ? "Login..." : "Login"}
                        &nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <h3 className="text-center font-weight-bold">Log in Here</h3>
                  <br />
                  <div className="text-center mb-3">
                    <LoginWithGoogle method="LOGIN" />
                  </div>
                  <p className="text-center text-dark">OR</p>
                  <form className="login-form" onSubmit={this._login}>
                    <div className="form-group">
                      <label htmlFor="username" className="">
                        Username/ Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" className="">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        required
                      />
                    </div>
                    {this.state.errorMsg && (
                      <p className="text-danger">{this.state.errorMsg}</p>
                    )}
                    {/* <div className="form-check">
                                <label className="form-check-label">
                                    <input type="checkbox" className="form-check-input custom-control-input" />
                                    <small>Remember Me | <Link to="/forgotPassword"> Forgot Password</Link></small>
                                </label>

                            </div> */}
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="rememberMe"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="rememberMe"
                      >
                        <small className="text-dark">
                          Remember Me |{" "}
                          <Link to="/forgotPassword"> Forgot Password</Link>
                        </small>
                      </label>
                    </div>
                    <br />
                    <div className="d-block d-md-none mb-3 text-center">
                      <button
                        type="submit"
                        className="btn btn-block btn-round btn-primary btn-lg"
                        id="loginBtn"
                        disabled={this.state.isSending}
                      >
                        &nbsp;&nbsp;&nbsp;
                        {this.state.isSending ? "Login..." : "Login"}
                        &nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                    <div className="d-none d-md-block float-right">
                      <button
                        type="submit"
                        className="btn  btn-primary btn-round"
                        disabled={this.state.isSending}
                      >
                        &nbsp;&nbsp;&nbsp;
                        {this.state.isSending ? "Login..." : "Login"}
                        &nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                  </form>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 text-center">
                  <small className="text-dark">
                    Don't have an account yet? &nbsp;
                    <Link to="/signup">Sign up!</Link>
                  </small>
                  {!!this.state.verifyToken && (
                    <>
                      <hr />
                      <small className="text-dark">
                        Back to login? &nbsp;
                        <button
                          type="button"
                          className="btn  btn-link btn-sm"
                          disabled={this.state.isSending}
                          onClick={this._backToLoginPage}
                        >
                          Go Back
                        </button>
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>

            <br />
            <br />
          </div>
        </div>
      </>
    );
  }
}
