import React, { useState } from 'react'
import { useRouteData, Head } from 'react-static';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router'
import Modal from '../../components/modals/Modal';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';


// import '../solutions/Solution.css';
import './UseCases.css';

const features = [
    {
        image: "free-sender-id.svg",
        title: "Free Sender ID",
        description: "Need a unique presence in your recipients’ inbox? Get a toll free or local mobile number dedicated to your business. Want to take it up a notch? Send text messages under your organization name, in any language, and make it a powerful branding tactic. ",
        link: ""

    },
    {
        image: "quick-contact-list-upload.svg",
        title: "Quick Contact List Upload",
        description: "Uploading your contact base shouldn’t take a minute! Quickly and easily upload all your contacts as an excel or CSV file.",
        link: ""
    },
    {
        image: "unlimited-recipients.svg",
        title: "Unlimited Recipients",
        description: "No limits whatsoever! Upload unlimited contacts to your profile and send SMS campaigns to unlimited contacts. Reach as many people as you need!",
        link: ""
    },
    {
        image: "powerful-filtering-and-segmentation.svg",
        title: "Powerful Filtering and Segmentation",
        description: "In the end, it is all about the right targeting! Filter contacts as you need to create different groups based on interests, demographics and user info, and send targeted campaigns only to the relevant people.",
        link: ""
    },
    {
        image: "online-mass-texting.svg",
        title: "Online Mass Texting",
        description: "Send SMS online to a large number of contacts to reach a wider local or international audience in a single campaign.",
        link: ""
    },
    {
        image: "long-text-messages.svg",
        title: "Long Text Messages",
        description: "Say all that you need to turn conversations into conversions! Send long messages of upto 459 characters without having to shorten message content.",
        link: ""
    },
    {
        image: "built-in-url-shortening.svg",
        title: "Built-In URL Shortening",
        description: "Get it all done in one place! The message compose box comes with built-in link shrinking to reduce the number of characters taken up by a URL in the message content.",
        link: ""
    },
    {
        image: "detailed-tracking-and-analytics.svg",
        title: "Detailed Tracking and Analytics",
        description: "Keep improving your campaigns! Click tracking lets you view how many and who exactly clicked the URL. Campaign analytics lets you monitor how many SMS were delivered and opened.",
        link: ""
    },
    {
        image: "scheduling.svg",
        title: "Scheduling",
        description: "Need to launch campaigns at the perfect time or want to plan everything ahead? Set up scheduled text messages to be sent later to get the best reach and results. ",
        link: ""
    },
    {
        image: "multiple-attributes.svg",
        title: "Multiple Attributes ",
        description: "Add multiple custom data that are unique to each recipient in the message content such as name, ID number, etc. to personalize the SMS. Ideal for personalized SMS marketing!",
        link: ""
    },
    {
        image: "multiple-segments.svg",
        title: "Multiple segments",
        description: "Need to send a targeted campaign only to a select group or groups? The group text messaging service lets you select a single contact segment or multiple segments in a campaign in order to send specific, targeted messages.",
        link: ""
    },
    {
        image: "languages-and-unicode.svg",
        title: "Languages and Unicode",
        description: "Get closer to your recipients through the language they speak. We have got you covered! Send and receive SMS in any required language. ",
        link: ""
    },
    {
        image: "emojis.svg",
        title: "Emojis",
        description: "Ran out of words? Emojis are a global language that can make powerful statements while adding some fun to the message content.",
        link: ""
    },
    {
        image: "two-way-messaging.svg",
        title: "Two-Way Messaging",
        description: "Keep conversations going and convert into sales via two messaging which lets you both send and receive text messages instantly.",
        link: ""
    },

    {
        image: "messaging-service-api.svg",
        title: "Messaging Service API",
        description: "Looking to send SMS via API?Easily integrate our SMS gateway with your website or app using SMS API tools.",
        link: ""
    },
    {
        image: "two-factor-authentication.svg",
        title: "Two Factor Authentication",
        description: "Get two factor authentication for your app and send OTP verification to users via a text message to strengthen security.",
        link: ""
    },
    {
        image: "multiple-admins.svg",
        title: "Multiple Admins",
        description: "Admin can create any number of team accounts and assign different permissions to each as required. SMS credit is distributed through your main account.",
        link: ""
    },
    {
        image: "opt-out-management.svg",
        title: "Opt-Out Management ",
        description: "Include an opt-out option from the subscription to respect your recipient’s consent and reduce your costs.",
        link: ""
    },
    {
        image: "secure-text-messaging.svg",
        title: "Secure Text Messaging",
        description: "There is no need to worry about losing your data as our service is secured with SSL / HTTPS and all your data is fully backed up 24 / 7 / 365.",
        link: ""
    },


]


const Saaraketha = () => {



    return (<>
        <Helmet>
            <title>Personalized SMS Marketing Increases Customer Conversions for Saaraketha | Case Study</title>
            <meta name="description" content="Personalized SMS marketing has increased customer conversions and website traffic for Saaraketha. Learn how they did it with ShoutOUT SMS marketing platform" />
            <link rel="canonical" href="https://getshoutout.com/case-studies/personalized-sms-marketing-saaraketha" />
            <meta name="keywords" content="personalized sms marketing, scheduled text messages, SMS with company name, text messaging service for business, click tracking, promotional text messages examples, how to drive traffic to your website, increase website traffic fast, business case studies, sms marketing case study, sms marketing benefits, sms marketing platform, sms campaign tool" />

        </Helmet>

        <MainNavbar />



        <div className="container opacity-p9 h-50">

            <div className="row header-space">
                <div className="col-lg-12 col-md-12 col-sm-12 mx-auto my-5 text-center">
                    <div className="shadow p-4">
                        <h1 className="font-weight-bold mt-4">Saaraketha Increases Customer Conversions With Targeted and Personalized SMS Marketing Campaigns</h1>
                    </div>
                    {/* <Link to="/signup" className="btn btn-outline-primary border-radius-2 mt-2">Get started for Free</Link>
                    <Link to="/bookaDemo" className="btn btn-outline-dark border-radius-2 mt-2 ml-2">Book a Demo</Link> */}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                    <img className="img-fluid" src="/assets/images/case-studies/saaraketha/personalized-sms-marketing.jpg" alt="personalized-sms-marketing" title="personalized-sms-marketing" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card shadow p-4">
                        <img src="/assets/images/case-studies/saaraketha/saaraketha-organics.png" alt="saaraketha-organics" title="saaraketha-organics" />
                        <div className="mt-4">
                            <small>Website</small>
                            <p><a href="https://www.saaraketha.com" target="_blank">www.saaraketha.com</a></p>
                        </div>
                        <div>
                            <small>Industry</small>
                            <p>Food Production</p>
                        </div>
                        <div>
                            <small>Company Size</small>
                            <p>11-50 employees</p>
                        </div>
                    </div>
                    <br />
                    <div className="card shadow p-4">
                        <ul className="ml-n4">
                            <li className="mb-3">SMS templates help set up text message marketing campaigns quickly.</li>
                            <li className="mb-3">Scheduling facilitates planning marketing campaigns ahead.</li>
                            <li className="mb-3">Sending SMS with the company name results in increased open rates.</li>
                            <li className="mb-3">Custom attributes increase SMS personalization in mass texting customers.</li>
                            <li className="mb-3">The built-in link shortening feature helps reduce the number of characters.</li>
                            <li className="mb-3">Using short links and click tracking increase website traffic and conversions.</li>
                            <li className="mb-3">Analytics reports help monitor and improve campaign performance.</li>
                            <li className="mb-3">Insights on who exactly clicked the SMS links help understand customer behaviors.</li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-8 col-md-6 col-sm-12">
                    <div className="card shadow p-4">
                        <div className="mt-4">
                            <p className="use-case-title">Intro</p>
                            <p>Saaraketha Lifestyle is a Sri Lankan company that promotes a wide range of healthy products that are organic, ethical and sustainably sourced. They have a large number of customers interested in their organic produce, organic spices, herbs and grains, heirloom rice bread, natural sweeteners, oils, bath & body products, spreads and dips, etc. </p>
                        </div>
                        <br />
                        <div>
                            <p className="use-case-title">Problem</p>
                            <p className="font-weight-bold">The lack of a user-friendly bulk SMS marketing platform with personalization for promotional campaigns</p>
                            <p>Saaraketha updates their online store with the latest products and new stocks, regularly. However, they did not have a direct way to instantly inform all their customers about such updates, promotions and discounts. In addition, they also required a solution to bring more customers to their website with minimal marketing effort.</p>
                        </div>
                        <br />
                        <div className="bg-sms-features">
                            <p className="mb-0"><i>"This has been helpful, because every time we want to promote something we quickly drop an SMS. It has been successful and we’ve been using it quite a lot."</i></p>
                        </div>
                        <br />
                        <div>
                            <p className="use-case-title">Solution</p>
                            <p className="font-weight-bold">Quick text marketing campaigns with targeting, personalization and tracking</p>
                            <img className="w-100 mb-3" src="/assets/images/case-studies/saaraketha/SMS-with-company-name.gif" alt="SMS-with-company-name" title="SMS-with-company-name" />
                            <p>
                                With the ShoutOUT Engage SMS campaign tool, Saaraketha Organics now easily sets up personalized SMS marketing campaigns within minutes. Without a hassle, they launch personalized text message campaigns to their customers as soon as they update new products and stocks on their website, or when they need to run a quick promotional campaign.
                                <br />
                                <br />
                                At the same time, they also use the built-in URL shortening feature in Engage to include short links to their website, in the SMS message content, to easily drive customers to their online shop. They also use the text message templates option to save and reuse frequently used SMS messages.
                            </p>
                        </div>
                        <br />
                        <div className="bg-sms-features">
                            <p className="mb-0"><i>"The short link feature in Engage is very convenient and we use it for all our campaigns. Then there’s the tracking analytics where it shows how many people have clicked the link. We really like it because we have seen more conversions when we use the short link feature."</i></p>
                        </div>
                        <br />
                        <div>
                            <p className="use-case-title">Results</p>
                            <p className="font-weight-bold">Increased customer conversion rates and website traffic</p>
                            <p>
                                The'frequently used text message templates' feature as well as the scheduled text messages option have facilitated Saaraketha to quickly and easily set up a campaign with Engage and run promotional mass texting campaigns just the way they want.
                                <br /><br />
                                In addition, Saaraketha has been able to have a more unique presence in their customers’ SMS inboxes by sending SMS with company name, as 'Saaraketha'. They have seen an increase in the open rate for their SMS, as a result.
                                <br /><br />
                                The custom attributes feature to automatically include individual customer-specific information in the message content has helped them create perfect text campaigns with customization and personalization to appeal to each customer.
                                <br /><br />
                                Most importantly, since the built-in URL shortener lets them include website links without having to worry about exceeding the number of text message characters, they have been including website links in all their SMS campaigns. This has directly resulted in a significant increase in their website traffic as well as conversions. </p>

                            <img className="w-100 mb-3" src="/assets/images/case-studies/saaraketha/promotional-text-messages-examples.gif" alt="promotional-text-messages-examples" title="promotional-text-messages-examples" />
                            <p>
                                At the same time, Engage’s analytics reports based on click tracking have been assisting Saaraketha to monitor and improve their campaign performance. In fact, analytics gives Engage the edge over other campaign management platforms since it provides data on which customers exactly clicked the links to the website, in addition to data such as the number of SMS sent, delivered, opened, etc.
                                <br /><br />
                                These insights have helped Saaraketha to analyze different customers and their behaviors, and correctly target the right customers with SMS personalization through the right type of campaign.
                            </p>
                        </div>
                        <br />
                        <div className="bg-sms-features">
                            <p className="mb-0"><i>"We really like the option where we can send SMS with our own company name. We can choose how we want our SMS to be shown in our customers’ message inbox. In fact, we are starting another product and we can get another SMS ID to send separate campaigns under the new product name."</i></p>
                        </div>
                        <br />
                        <div>
                            {/* <p>Testimonial</p> */}
                            <p className="font-weight-bold">What Does Saaraketha Say About ShoutOUT?</p>
                            <p>
                                They have been seeing amazing results since they started using ShoutOUT Engage for their personalized text message marketing campaigns. So, Saaraketha does not hesitate to recommend ShoutOUT Engage as a user-friendly and convenient SMS marketing software platform with excellent support from the team to create personalized marketing campaigns.
                                <br /><br />
                                Moreover, they stated that they are planning to involve ShoutOUT Engage in their future projects as well. They are going to start a new brand, and given the success rate and ease of campaign management they have been experiencing so far with ShoutOUT in sending mass texts, they are planning to use ShoutOUT for the marketing purposes of that brand, too.
                                <br /><br />
                                Well, that makes us as happy as we can be as team ShoutOUT, since we strive to provide a service that one user feels happy to recommend to others.

                            </p>
                        </div>
                        <br />
                        <div>
                            {/* <p>CTA</p> */}
                            <p>
                                Stories are nice. But, they are better when they are stories about success. We help you build them.
                                <br /><br />
                                Take your text marketing campaigns to the next level with ShoutOUT Engage and increase customer engagement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <CallToAction title="Keep Your Customers Engaged With Personalized SMS Marketing" />

            <MainFooter />


        </div>

    </>
    )
}
export default Saaraketha;