import React from 'react'
import { useRouteData, Head } from 'react-static';
import { Link } from '@reach/router'
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';


import './Solution.css';

const features = [
    { image: "wedding-sms-invitation.svg", imgAlt:"wedding-sms-invitation", imgTitle:"wedding-sms-invitation", title: "Wedding invitations" },
    { image: "rsvp-sms.svg", imgAlt:"rsvp-sms", imgTitle:"rsvp-sms", title: "RSVP Links" },
    { image: "wedding-sms.svg", imgAlt:"wedding-sms", imgTitle:"wedding-sms", title: "Cancellation / postponement notifications" },
    { image: "text-wedding-venue-link.svg", imgAlt:"text-wedding-venue-link", imgTitle:"text-wedding-venue-link", title: "Links for wedding venue map location" },
    { image: "notify-wedding-venue-change.svg", imgAlt:"notify-wedding-venue-change", imgTitle:"notify-wedding-venue-change", title: "Venue changes" },
    { image: "how-to-get-a-wedding-guest-to-rsvp-via-sms.svg", imgAlt:"how-to-get-a-wedding-guest-to-rsvp-via-sms", imgTitle:"how-to-get-a-wedding-guest-to-rsvp-via-sms", title: "RSVP reminders" },
    { image: "share-wedding-album-link-via-SMS.svg", imgAlt:"share-wedding-album-link-via-SMS", imgTitle:"share-wedding-album-link-via-SMS", title: "Post- wedding online photo album links" },
    { image: "share-wedding-agenda-via-sms.svg", imgAlt:"share-wedding-agenda-via-sms", imgTitle:"share-wedding-agenda-via-sms", title: "Wedding ceremony agenda" },
    { image: "send-wedding-website-url-via-SMS.svg", imgAlt:"send-wedding-website-url-via-SMS", imgTitle:"send-wedding-website-url-via-SMS", title: "Link to your wedding website" },
]
const Weddings = () => {

    return (<>
        <Head>
            <title>Text Message Alerts for Weddings | ShoutOUT</title>
            <meta name="description" content="Text message alerts for weddings including inviting guests, vendor communication, RSVP & venue map link, to make your wedding planning easier, from ShoutOUT." />
            <link rel="canonical" href="https://getshoutout.com/industries/text-message-alerts-for-weddings" />
            <meta name="keywords" content="text message alerts for weddings, wedding sms, wedding sms invitation, wedding invitation sms, wedding text message service, text your guests, text my guests, rsvp sms, text wedding rsvp, text wedding guests, notify wedding venue change, wedding invitation text message sample, wedding rsvp text message sample, sample wedding invitation text message, rsvp wording rsvp text message sample" />

        </Head>

        <MainNavbar />



        <div className="container opacity-p9 h-50">
            {/* <div className="back-button">
                <Link to="/solutions" className="text-dark rounded-0 w-25 ml-4 mt-4"><img className="mr-2 mb-1" src="/assets/images/temp/arrow-180.svg" alt="back" />Back</Link>
            </div> */}
            <div className="row header-space">
                <div className="col-lg-6 col-md-6 col-sm-12 my-auto order-2 text-center text-sm-left">
                    <h2 className="font-weight-bold mt-4">Text Message <span className="text-primary">Alerts</span> for Weddings</h2>
                    <p className="mb-0">For wedding invitations, RSVP responses, venue / date changes, venue location links & other updates. A single click to inform your entire guest list!</p>
                    <Link to="/signup" className="btn btn-outline-primary border-radius-2 mt-2">Get Started for Free</Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1 text-center">
                    <img className="img-fluid w-75" src="/assets/images/solutions/wedding/text-message-alerts-for-weddings.svg" alt="text-message-alerts-for-weddings" title="text-message-alerts-for-weddings" />
                </div>
            </div>

            <div className="row mb-3">


                <div className="col-12">
                    <h4 className="font-weight-bold text-center my-4">What can you send using SMS alerts before, during and after your wedding?</h4>
                    <div className="row">
                        {features.map(({ image, title, imgAlt, imgTitle }, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="d-flex flex-column align-items-center p-4 m-2 card">
                                    <img src={`/assets/images/solutions/wedding/icons/${image}`} className="w-40 mx-auto" alt={imgAlt} title={imgTitle} />
                                    <span className="mt-2 text-center">{title}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/wedding/sms/sample-wedding-invitation-text-message.svg" alt="sample-wedding-invitation-text-message" title="sample-wedding-invitation-text-message" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/wedding/sms/rsvp-wording-rsvp-text-message-sample.svg" alt="rsvp-wording-rsvp-text-message-sample" title="rsvp-wording-rsvp-text-message-sample" />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <img className="w-100 zoom" src="/assets/images/solutions/wedding/sms/wedding-rsvp-text-message-sample.svg" alt="wedding-rsvp-text-message-sample" title="wedding-rsvp-text-message-sample" />
                </div>

            </div>



            <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12 text-center mt-5 mx-auto">
                    <h4 className="font-weight-bold text-center my-4">What makes ShoutOUT the ideal text message alert service for weddings?</h4>
                </div>
                <div className="col-12">
                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-2">
                            <img className="img-fluid" src="/assets/images/solutions/wedding/text-your-guests.jpg" alt="text-your-guests" title="text-your-guests" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2 order-sm-1">
                            <p className="font-weight-bold mt-auto">Create groups/segments from your contact list</p>
                            <p>
                                Upload your contact list as a CSV or Excel file.
                                <br /><br />
                                Create groups based on the types of people you need to send different SMS to. (e.g., guests, vendors, family members, bride’s maids/groomsmen etc.)
                                <br /><br />
                                Keep all contact details on ShoutOUT and use them to send personalized text messages.

                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/wedding/text-my-guests.jpg" alt="text-my-guests" title="text-my-guests" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2">
                            <p className="font-weight-bold">Send texts to multiple contact groups in one campaign</p>
                            <p>
                                Select multiple segments (that you created, such as guests, vendors, family members, bride’s maids/groomsmen etc.) in one campaign.
                                <br /><br />
                                Easily send a general message such as venue/time change, buffet opening, venue map link etc. to them in a single campaign.
                                <br /><br />
                                Eliminate the need to send several campaigns for a single text message.
                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-1 order-sm-2">
                            <img className="img-fluid" src="/assets/images/solutions/wedding/send-wedding-sms-with-bride's-name.jpg" alt="send-wedding-sms-with-bride's-name" title="send-wedding-sms-with-bride's-name" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-2 order-sm-1">
                            <p className="font-weight-bold mt-auto">Send text messages either via a unique text number or under a name you prefer</p>
                            <p>
                                Use any name you need such as the couple’s name to send SMS alerts. You can use a unique number instead of a name, too!
                                <br /><br />
                                Request for a Sender ID that you prefer, after creating a ShoutOUT profile. If all conditions are satisfied, it will be approved within one business day

                            </p>
                        </div>
                    </div>

                    <div className="row my-4 mx-2">
                        <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/wedding/text-wedding-rsvp.jpg" alt="text-wedding-rsvp" title="text-wedding-rsvp" />
                        </div>
                        <div className="col-lg-6 col-sm-12 my-sm-auto my-2 order-sm-2 order-2">
                            <p className="font-weight-bold mt-auto">Include shortened online links </p>
                            <p>
                                Send RSVP, venue map location, online streaming links etc. in the text message.
                                <br /><br />
                                The URL Shortener option automatically reduces URL length, turns it into a shortened URL, thereby reducing the number of characters taken up.
                            </p>
                        </div>
                    </div>



                    {/* <div className="row my-4">
                      
                        <div className="col-12">
                            <h4 className="font-weight-bold text-center my-4">Other unique features that remove the hassle in sending text message campaigns</h4>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/schedule.svg" />
                                <p>Scheduling</p>
                                <small className="text-muted">Plan campaigns ahead. Schedule text messages to be sent later.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/templates.svg" />
                                <p>Templates</p>
                                <small className="text-muted">Save messages as templates to be used repetitively in campaigns.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/smartphone.svg" />
                                <p>Live SMS preview</p>
                                <small className="text-muted">Check the final look of your message as you type it, on a mobile phone screen.</small>
                                <br />
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                                <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/keyboard.svg" />
                                <p>Custom attributes</p>
                                <small className="text-muted">Automatically add person/group-specific data to the SMS content. (e.g., class name, course ID, etc.)</small>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>

            <div className="row my-5">
                {/* <div className="col-lg-6 col-sm-12 order-sm-1 order-1">
                            <img className="img-fluid" src="/assets/images/solutions/education/Senders.jpg" />
                        </div> */}
                <div className="col-12">
                    <h4 className="font-weight-bold text-center my-4">Other unique features that will help you send the perfect SMS for your wedding</h4>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                        <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/schedule.svg" />
                        <p>Scheduling</p>
                        <small className="text-muted">Schedule text messages beforehand to be automatically sent when you need.</small>
                        <br />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                        <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/templates.svg" />
                        <p>Templates</p>
                        <small className="text-muted">Save messages as templates to be used again and again.</small>
                        <br />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                        <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/smartphone.svg" />
                        <p>Live SMS preview</p>
                        <small className="text-muted">Check the final look of your message as you type it, on a mobile phone screen.</small>
                        <br />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="other-height p-4 shadow d-flex flex-column align-items-center justify-content-start mb-3">
                        <img className="img-fluid other-icon-width mb-2" src="/assets/images/solutions/education/icons/other/keyboard.svg" />
                        <p>Custom attributes</p>
                        <small className="text-muted">Automatically add unique individual specific data to the message content. (Eg: names, dates, times, etc.)</small>
                    </div>
                </div>
            </div>

            <div className="row my-5">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold my-4">Enjoy stress free wedding planning!</h4>
                </div>
                <div className="col-lg-8 col-sm-12 mx-auto">
                    <div className="card p-4 shadow">
                        <div className="d-sm-flex text-sm-left text-center align-items-center">
                            <img src="/assets/images/solutions/wedding/nithushan.jpg" className="testimonial-image mr-sm-4 mr-0" />
                            <div>
                                <small><i>“Loved using this platform for our wedding invitation to keep our guests up-to-date of any possible changes in venue and times. This is a go to service for anyone planning their big day during this pandemic! Also, unlike other platforms, we were able to target our audience and focus on key attendees. Customer service was incredible! Thank you so much for helping our wedding day run smoothly!”</i></small>
                                <p className="font-weight-bold mb-0">- Nithushan & Abinaya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="row my-5 justify-content-center">
                <div className="col-12 text-center">
                    <h4 className="font-weight-bold my-4">More on this</h4>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2021/04/wedding-sms-alerts-768x435.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">Wedding SMS Alerts – Invite Your Guests the Millennial Way!</p>
                            <small>Before going into this whole new thing about wedding SMS alerts, tell me. What do you think is more hectic? Is it the wedding planning process or the wedding day?</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/wedding-sms-alerts/"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <div className="card shadow mb-3">
                        <img src="https://blog.getshoutout.com/wp-content/uploads/2021/04/sms-alerts-sustainable-wedding-1-1250x710.jpg" className="w-100" />
                        <div className="p-4">
                            <p className="font-weight-bold">Case Study: A Text Message Campaign for the Sustainable Wedding Function of Nithushan & Abinaya</p>
                            <small>Text message campaign, sustainability, and wedding function – how do these relate to each other or work together? In a way that’s innovative, feasible, timely, and most importantly, sustainable.</small>
                            <br />
                            <a target="_blank" href="https://blog.getshoutout.com/text-message-campaign-sustainable-wedding/"><small className="text-primary font-weight-bold mb-0">Read more</small></a>
                        </div>
                    </div>
                </div>
            </div>

            <CallToAction />

            <MainFooter />


        </div>

    </>
    )
}
export default Weddings;