import React from 'react';
import { Head } from 'react-static';
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import { Link } from '@reach/router'
import './Policies.css';



export default () => (
<div>
    <Head>
        <title>ShoutOUT | GDPR Compliance</title>
    </Head>
<MainNavbar />
<section>
    <div className="py-5">
       <div className="container cover-image2 justify-content-center text-center">
            <h5 className="subheading mt-5">ShoutOUT & the General Data <br/>Protection Regulation (GDPR)</h5>
            <div className="row hidden-md-up">
                <div className="col-md-12">
                    <div className="card-product">
                        <div className="card-block">
                            <div className="row complete-products">
                                <div className="col-md-12">
                                    <div className="container privacy-container">
                                        <div className="row">
                                            <div className="col-md-12 text-left">
                                            <br />

                                                <h4>Addendum Form</h4>
                                                <Link to='/policies/addendumform'>Request The Addendum</Link>

                                                <br /><br />
                                                <a href="">Preview The Addendum</a>
                                                <br />
                                                <br />
                                                <h4>What we are doing</h4>
                                                <ul>
                                                    <li>New addendum available for download which reflects GDPR
                                                        standards.</li>
                                                    <li>For every new product and enhancement, we’re proactively
                                                        applying
                                                        the Data Protection by Design
                                                        principles.</li>
                                                    <li>We’re applying GDPR standards to all data not just EU personal
                                                        data. That way, you will be well
                                                        positioned with data protection regulatory frameworks around
                                                        the
                                                        world.</li>
                                                </ul>
                                                <br />
                                                <br />
                                                <h4>FAQS</h4>
                                                <h5><strong>What is GDPR?</strong></h5>
                                                <p>
                                                    "GDPR" stands for the European Union’s General Data Protection
                                                    Regulation. It replaces the Data
                                                    Protection
                                                    Directive. The purpose of GDPR is to ensure appropriate protection
                                                    of
                                                    personal data in a digital
                                                    society.

                                                    GDPR, like the Data Protection Directive before it, finds its roots
                                                    in
                                                    Article 8(1) of the Charter
                                                    of
                                                    Fundamental Rights of the European Union, which echoes Article 12
                                                    of
                                                    the Universal Declaration on
                                                    Human
                                                    Rights adopted by the UN General Assembly in 1948, and Article
                                                    16(1) of
                                                    the Treaty on the
                                                    Functioning of
                                                    the European Union, pursuant to which “everyone has the right to
                                                    protection of personal data
                                                    concerning him
                                                    or her."

                                                    Though GDPR was adopted in 2016, it does not become enforceable
                                                    until
                                                    May 25, 2018.
                                                </p>
                                                <br />



                                                <h5><strong>What are my main responsibilities under GDPR?</strong></h5>
                                                <div>
                                                    <p> Your responsibilities under GDPR will depend on the nature of
                                                        your
                                                        business and your personal
                                                        data
                                                        processing activities. Nonetheless, broadly speaking, GDPR
                                                        requires
                                                        that personal data be:</p>

                                                    <ul>
                                                        <li>Processed lawfully, fairly and in a transparent manner</li>
                                                        <li>Collected for specified, explicit and legitimate purposes
                                                            and
                                                            not further processed in a
                                                            manner
                                                            incompatible with those purposes</li>
                                                        <li>Adequate, relevant, and limited to what is necessary for
                                                            achieving those purposes</li>
                                                        <li>Accurate and kept up to date</li>
                                                        <li>Stored no longer than necessary to achieve the purposes for
                                                            which it was collected, and</li>
                                                        <li>Properly secured against accidental loss, destruction or
                                                            damage</li>
                                                    </ul>
                                                    <p>
                                                        Further, GDPR places additional obligations on companies to
                                                        document their processing
                                                        activities and be
                                                        able to demonstrate their compliance with the above principles.
                                                        It
                                                        also codifies the
                                                        requirement that
                                                        companies apply data protection by design and by default when
                                                        developing and designing
                                                        processes,
                                                        products and systems.
                                                    </p>
                                                    <p>
                                                        In addition, if you use service providers to process personal
                                                        data
                                                        on your behalf, you will
                                                        need to
                                                        ensure that you have an appropriate contract in place that
                                                        ensures
                                                        that they are obligated to
                                                        apply
                                                        GDPR’s data processing standards. Similarly, if you are
                                                        transferring EU personal data outside
                                                        the EU,
                                                        you may only do so if it is being transferred to a country
                                                        deemed
                                                        by the EU Commission to have
                                                        adequate
                                                        data processing regulations. For transfers to countries not
                                                        deemed
                                                        adequate, you must ensure
                                                        appropriate alternative safeguards are in place. Currently,
                                                        under
                                                        the Directive, approved
                                                        transfer
                                                        safeguards include the EU-US Privacy Shield and standard
                                                        contractual clauses.
                                                    </p>
                                                    <p>
                                                        Depending on the nature of your business and your personal data
                                                        processing activities there are
                                                        various
                                                        other GDPR obligations that may apply. You should consult with
                                                        a
                                                        qualified privacy professional
                                                        to
                                                        understand how GDPR applies to your specific business.
                                                    </p>

                                                </div>
                                                <br />


                                                <h5><strong>What’s the definition of "personal data" under the GDPR?</strong></h5>
                                                <p>Personal data means data that relates to an identified or
                                                    identifiable
                                                    natural person (aka “data
                                                    subject”).
                                                    An identifiable data subject is someone who can be identified,
                                                    directly
                                                    or indirectly, such as by
                                                    reference
                                                    to an identifier like a name, an ID number, location data, an
                                                    online
                                                    identifier or to one or more
                                                    more
                                                    factors specific to the physical, physiological, genetic, mental,
                                                    economic, cultural or social
                                                    identity of
                                                    that natural person.</p>

                                                <p>Importantly, this is a very broad definition and can encompass data
                                                    like
                                                    IP addresses of a user’s
                                                    personal
                                                    device, their device ID, or their phone number. It does not matter
                                                    that
                                                    the identifier could change
                                                    (e.g.,
                                                    that the user could change their phone number or device ID). What
                                                    matters is that the information
                                                    can be
                                                    used to “pick that user out of the crowd” even if you don’t know
                                                    who
                                                    that user is.</p>

                                                <p>It is also important to note that the definition of personal data is
                                                    not
                                                    tied to concerns about
                                                    identity
                                                    theft the way that definitions of personally identifying
                                                    information
                                                    (PII) are under many US data
                                                    breach
                                                    laws. So, even if it seems like there would be little privacy harm
                                                    if
                                                    someone got ahold of your
                                                    users’ IP
                                                    addresses, that does not mean that those IP addresses are not
                                                    personal
                                                    data. It just means that
                                                    this data
                                                    may not require the same level of data protection as more sensitive
                                                    personal data like your users’
                                                    credit
                                                    card numbers.</p>

                                                <br />
                                                <h5><strong>Do I have to appoint a Data Protection Officer for the
                                                        GDPR?</strong></h5>
                                                <p>It depends. Article 37 of GDPR says that entities are required to
                                                    designate a data protection
                                                    officer if:</p>
                                                <ul>
                                                    <li>The processing is carried out by a public authority or body
                                                        (except
                                                        courts acting in their
                                                        judicial
                                                        capacity);</li>
                                                    <li>The core activity of the entity consist of personal data
                                                        processing
                                                        that amounts to or requires
                                                        regular
                                                        or systematic monitoring of EU individuals on a large scale;</li>
                                                    <li>The core activity of the entity consists of large-scale
                                                        processing
                                                        of special categories of
                                                        data
                                                        (racial or ethnic origin, political opinions, religious or
                                                        philosophical beliefs, trade union
                                                        membership, genetic data, biometric data used to identify a
                                                        person,
                                                        or data concerning health,
                                                        a
                                                        person’s sex life or sexual orientation) and personal data
                                                        relating
                                                        to criminal convictions or
                                                        offenses.</li>
                                                </ul>
                                                <p>The Working Party 29 (a group of data protection regulators in the
                                                    EU)
                                                    has provided additional
                                                    guidance to
                                                    help you determine if you fit within one of these categories. </p>

                                                <br />
                                                <h5><strong>Are there any circumstances when GDPR does not apply?</strong></h5>
                                                <p>GDPR has broad scope and reach. That said, it is not unlimited. So,
                                                    if
                                                    you do not have an
                                                    establishment in
                                                    the Union and you do not process personal data of EU individuals,
                                                    GDPR
                                                    will not apply to your
                                                    activities.
                                                    If you do not know whether you process EU personal data, then you
                                                    should consider whether you are
                                                    offering
                                                    any goods and services (even free ones) to individuals in the EU or
                                                    if
                                                    you’re monitoring the
                                                    behavior of
                                                    individuals in the EU. If so, then you are subject to GDPR. Recital
                                                    23
                                                    of GDPR does indicate that
                                                    GDPR is
                                                    not intended to apply to entities that may inadvertently process EU
                                                    personal data, but are not
                                                    trying to
                                                    provide their goods or services to people in the EU.</p>

                                                <br />
                                                <h5><strong>My company is ISO 27001 compliant. Will this help with my
                                                        compliance towards GDPR?</strong></h5>
                                                <p>Compliance with standards such as ISO 27001 can be helpful for
                                                    compliance with GDPR, particularly
                                                    with
                                                    regard to security of processing, but GDPR compliance and ISO 27001
                                                    compliance are not
                                                    interchangeable.
                                                    Therefore, you should not assume that if you’re ISO 27001 compliant
                                                    that you are also GDPR
                                                    compliant.</p>

                                                <br />

                                                <h5><strong>What are the penalties for non-compliance with GDPR?</strong></h5>
                                                <p>
                                                    Depending on the nature of the violation, data protection
                                                    authorities
                                                    may issue fines or penalties
                                                    for
                                                    non-compliance up to € 20 million or 4% of global revenue.
                                                </p>

                                                <br />
                                                <h5><strong>Further reading on GDPR</strong></h5>
                                                <p>
                                                    A lot has been written about GDPR. So a simple web search will
                                                    likely
                                                    result in quite a bit of
                                                    information.
                                                    Further, Twilio will be providing additional guidance and
                                                    information
                                                    related to GDPR via its
                                                    website. That
                                                    said, the most authoritative resources will always be those
                                                    produced by
                                                    data protection regulators
                                                    or the
                                                    Union itself. Of particular use are the guidance documents produced
                                                    by
                                                    the Working Party 29 which
                                                    provide
                                                    insight into how EU data protection regulators, who are charged
                                                    with
                                                    enforcement of the GDPR, will
                                                    likely
                                                    interpret the regulation.
                                                </p>

                                                <p>
                                                    The above information is Twilio’s interpretation of GDPR and its
                                                    requirements as of the date of
                                                    publication. Please note that not all interpretations or
                                                    requirements
                                                    of the GDPR are well-settled
                                                    and its
                                                    application is fact and context specific. This information should
                                                    not
                                                    be relied upon as legal
                                                    advice or to
                                                    determine how GDPR applies to your business or organization. We
                                                    encourage you to seek guidance of a
                                                    qualified professional with regard to how the GDPR applies
                                                    specifically
                                                    to your business or
                                                    organization
                                                    and how to ensure compliance. This information is provided “as-is”
                                                    and
                                                    may be updated or changed
                                                    without
                                                    notice. You may copy and use this posting for your internal,
                                                    reference
                                                    purposes only.
                                                </p>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<MainFooter />
</div>
);