import React from 'react';
import { Head } from 'react-static';
import MainNavbar from './../../components/navbars/MainNavbar';
import MainFooter from './../../components/footers/MainFooter';
import CallToAction from './../../components/calltoaction/CallToAction';
import RewardsAndPointsImage from './../../images/rewards-and-points.png?sizes[]=300,sizes[]=500';
import Picture from './../../components/picture';
import './../ProductsCommon.css';

export default () => (
  <div className="loyalty">
    <Head>
      <meta charSet="UTF-8" />
      <meta property="og:image" content="/assets/images/loyalty/thumbnail-loyalty.png" />
      <meta property="twitter:image" content="/assets/images/loyalty/thumbnail-loyalty.png" />
      <title>ShoutOUT | Customer Loyalty Management Platform</title>
    </Head>
    <MainNavbar />
    <div className="producthead pt-5">
      <div className="producthead-content text-md-left text-center">
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-sm-12 col-md-6 col-lg-6 order-lg-2">

              <img className="img-fluid" src="/assets/images/loyalty-page-image.svg" alt="Loyalty Reward" />

            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-producthead order-lg-1">
              <div className="platform-tag tag-color-loyalty">Loyalty Management Platform</div>
              <h1 className="producthead-heading mb-0"><span className="shoutout-keyword">Build Customer Loyalty with a </span> Gamified Program</h1>
              <h5 className="mt-2 text-secondary">Motivate actions at every stage of the customer journey with points and rewards</h5>
              <br /><br />
              <a href="https://calendly.com/gamidu/shoutoutlabs" target="_blank" className="btn btn-dark btn-round mx-2">Book a Demo</a>

            </div>

          </div>
        </div>
      </div>
      <div className="section-separator rounded bottom">
        <div className="ss-content">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 962 115" fill="#ffffff" xmlSpace="preserve" preserveAspectRatio="none" className="svg replaced-svg">
            <path className="st0" d="M0,0c0,0,100,94,481,95C862,94,962,0,962,0v115H0V0z"></path>
          </svg>
        </div>
      </div>
    </div>

    <section id="features">
      <div className="container">
        {/* <div className="row justify-content-center">
        <div className="col-sm-12 col-md-8">
          <div className="card shadow border-0 p-2 product-container-margnin-top dark-color product-border-top">
            <h3 className="subheading-02 opacity-p8 text-center p-3 px-md-5">Motivate actions at every stage of the customer journey with points and rewards</h3>
          </div>
        </div>
       </div> */}
        <br /><br />
        <div className="row feature text-center">
          <div className="col-sm-12 col-md-5 col-lg-5">

            <img className="img-fluid" src="/assets/images/why-us-loyalty.svg" alt="feature" />

          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 text-lg-left my-auto">
            <p className="feature-title">Drive Profitable Customer Actions</p>
            <p>
              Customer loyalty is a combination of both emotional and behavioural actions. ShoutOUT enables rewarding points for different customer actions like purchasing, social media sharing, referring friends, or any other trackable action.
            </p>
            <p>
              ShoutOUT provides APIs seamless integration with different customer touch points and offer points based on customer activities.
            </p>
          </div>

        </div>
        <br /><br />
        <div className="row feature text-center">
          <div className="col-sm-12 col-md-5 col-lg-5 order-lg-2">
          <Picture {...RewardsAndPointsImage} alt="Rewards and Points" className="img-fluid"/>
   

          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 order-lg-1 text-lg-left my-auto">
            <p className="feature-title">Make Point Redemption Interesting</p>

            <p>A healthy loyalty program always leads to a better point earning and redemption ratio. ShoutOUT enables various ways of redeeming points such as on purchase, via reward pool, cash back rewards, digital rewards, and many more.</p>
            <p>ShoutOUT lets you build your own branded reward portal with colors and logos of your preference and manage rewards on the backend portal.</p>
          </div>

        </div>
        <br /><br />

        <div className="row feature text-center">
          <div className="col-sm-12 col-md-5 col-lg-5">

            <img className="img-fluid" src="/assets/images/sender-id.svg" alt="feature" />

          </div>
          <div className="col-sm-12 col-md-7 col-lg-7 text-lg-left my-auto">
            <p className="feature-title">Motivate To Achieve Tiers And Milestones</p>
            <p>
              Make your loyalty program more interesting with tiers and milestones. Entice customers to achieve the next tier and let them enjoy various benefits. A tier based loyalty program always increases the frequency of your services being used to continue tier benefits.
            </p>
            <p>
              ShoutOUT enables you to create and manage different tiers for customers and set milestones and offer badges or tangible rewards.
            </p>
          </div>

        </div>
        <br /><br />
      </div>
    </section>

    <br /><br />

    <section id="features-detailed">
      <div className="container">

        <div className="row text-center justify-content-center">
          <span className="subheading">Why us?</span>
        </div>
        <div className="row text-center">
          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/gamification.png" className="img-fluid w-30" alt="Gamified Loyalty" />
            </div>
            <div className="p-4 ">
              <h4 className="card-title text-center py-3">Gamified Loyalty</h4>
              <p className="card-description text-center">Offer points for customer actions such as purchasing, referrals, social sharing, etc.</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/offermanagement.png" className="img-fluid w-30" alt="Rewards Management" />
            </div>
            <div className="p-4">
              <h4 className="card-title text-center py-3">Rewards Management</h4>
              <p className="card-description text-center">Create a branded rewards portal and manage rewards on the ShoutOUT backend</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/bag.png" className="img-fluid w-30" alt="Point Redemption" />
            </div>
            <div className="p-4">
              <h4 className="card-title text-center py-3">Point Redemption</h4>
              <p className="card-description text-center">Offer point redemption via API and vouchers</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/managetiers.png" className="img-fluid w-30" alt="Tier Creation" />
            </div>
            <div className="p-4">
              <h4 className="card-title text-center py-3">Tier Creation</h4>
              <p className="card-description text-center">Create and manage various tiers/milestones and offer attractive benefits</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/loyaltymobile.png" className="img-fluid w-30" alt="Loyalty on Mobile" />
            </div>
            <div className="p-4 ">
              <h4 className="card-title text-center py-3">Loyalty on Mobile</h4>
              <p className="card-description text-center">Make your loyalty program available as a mobile application or text-based program</p>
            </div>
          </div>

          <div className="card p-3 col-md-4 col-lg-4 border-0">
            <div className="card-img pb-3">
              <img src="/assets/images/icons/onpermcloud.png" className="img-fluid w-30" alt="On-prem or Cloud" />
            </div>
            <div className="p-4 ">
              <h4 className="card-title text-center py-3">On-prem or Cloud</h4>
              <p className="card-description text-center text-center">Build your loyalty program as an on-prem solution or a private/public cloud solution</p>
            </div>
          </div>

        </div>


      </div>
    </section>

    <br /><br />
    <CallToAction />
    <MainFooter />
  </div>

);