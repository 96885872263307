import React from 'react'
import { Head } from 'react-static';
import UtilService from './../../services/UtilService';
import AboutImage from './../../images/about.svg';
import './About.css';
import MainNavbar from '../../components/navbars/MainNavbar';
import MainFooter from '../../components/footers/MainFooter';
import CallToAction from '../../components/calltoaction/CallToAction';

export default class About extends React.Component {

  constructor() {
    super();
    this.state = { country: "lk" };
  }

  _getCountry() {
    UtilService.getIpInfo().then((response) => {
      this.setState({ country: response.country });
    })
  };

  componentDidMount() {
    this._getCountry();
  }



  render() {
    return (<div className="aboutus">
      <Head>
        <title>ShoutOUT | About Us</title>
      </Head>
      <MainNavbar />
      <div className="pt-5 container">
        {/* <header className="about-bg text-center text-white">
          <div className="masthead-content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <h1 className="mt-5">ShoutOUT Labs</h1>
                  <h4 className="text-secondary">Helping Businesses to Build Winning Customer Relationships</h4>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        <div className="row justify-content-center">
          <div className="col-md-12 col-sm-12">
            <h1 className="font-weight-bold mt-5 text-primary text-center">
              ShoutOUT Labs
            </h1>
            <p className="text-center">Helping Businesses to Build Winning Customer Relationships</p>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-12 order-1 my-auto text-center text-md-left">
                <h2 className="subheading"> Who are we?</h2>
                <p className="text-secondary">
                  ShoutOUT Labs, initially called Square Mobile, was founded in 2014
                  with the objective of building tools for businesses to create winning customer relationships.
                  That gave birth to our service, called ShoutOUT, which focuses on providing a convenient and
                  effective customer relationship management platform. Currently, ShoutOUT Labs serves over 3500
                  users in 41 countries {this.state.country === "lk" || this.state.country === "ng" ? "including leading brands in Sri Lanka and Nigeria such as DSI Tyres\, Mobil\, and Nexus alliances ShoutOUT Labs is headquartered in Colombo, Sri Lanka, with a sales office based in Lagos, Nigeria. " : "."}
                </p>
              </div>
              <div className="col-md-6 col-sm-12 order-1 order-lg-2 my-auto">
                <img src={AboutImage} className="img-fluid" alt="About ShoutOUT Labs" />
              </div>
            </div>


            <div className="row justify-content-center mt-5">
              <div className="col-md-12 col-sm-12 order-1 my-auto text-center">
                <h2 className="subheading"> Our Platforms</h2>
              </div>

              <div className="col-md-12 col-sm-12 order-1 my-auto text-left">
                <h5>Segmentation</h5>
                <p className="text-secondary">
                  The most valuable asset for any business in today’s world is customer data. ShoutOUT brings all customer data into one place to help recognize similar attributes and behaviours, and segment them into the right groups.
                </p>


                <br /><br />

                <h5>Engagement</h5>
                <p className="text-secondary">
                  The right message to the right customer at the right time shows how much you understand and care about them.
                  Make target communication and send offers via customized and personalized SMS and email based on customer preferences and behaviors.
                </p>

                <br /><br />

                <h5>Reward</h5>
                <p className="text-secondary">
                  How hard is it to make customers actually stay? Not so much if you do it the right way! Create an enticing reward program which motivates customers to keep purchasing your services.
                </p>

                <br /><br />

                <h5>ShoutOUT Lite</h5>
                <p className="text-secondary">
                  ShoutOUT Lite is an SMS API platform offering 3 APIs - Outgoing, Inbound SMS APIs, and one-time password API.
                </p>

              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <div className="col-md-12 col-sm-12 order-1 my-auto text-center">
                <h2 className="subheading"> Our Team</h2>
                <img className="img-fluid" src="/assets/images/temp/about-us-team.png" />
                <p className="pt-4 pb-2 px-2">
                  <strong>ShoutOUT</strong> was founded by three passionate engineers with the vision of refashioning the techniques businesses utilize in engaging and communicating with their customers. We have created a culture that constantly and continuously pushes the boundaries of innovation, celebrates every achievement, works hard, and enjoys life.
                </p>
              </div>
            </div>

            {this.state.country === "lk" || this.state.country === "ng" ? <div>
              <div className="row justify-content-center mt-5">
                <div className="col-md-12 col-sm-12 order-1 my-auto text-center">
                  <h2 className="subheading mb-3"> Our Offices</h2>
                  <p>Our headquarters with the development centre is located in Colombo, Sri Lanka.</p>
                  <br />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-4 col-sm-12 mt-3">
                  <div className="card shadow border-0 p-4">
                    <h2 className="subheading"> Colombo, Sri Lanka</h2>
                    <p>
                      No 02, 6th Lane, <br /> Colombo 03 Sri Lanka 00300 <br />(+94) 071 655 0512
                    </p>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-3">
                  <div className="card shadow border-0 p-4">
                    <h2 className="subheading"> Lagos, Nigeria</h2>
                    <p>
                      12 Olaribiro Street, <br />Off Allen Avenue, Ikeja, Lagos Nigeria <br />(+234) 01 342 3885
                    </p>
                  </div>
                </div>

              </div>
            </div> : ''}

          </div>
        </section>

      </div>
      <CallToAction />
      <MainFooter />
    </div>
    )
  }

}