import React from 'react';
import { Link } from '@reach/router';


const SignUpData = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12 mx-auto">
                    <h2 className="font-weight-bold mt-4 text-primary text-center">Get started and run your campaigns in minutes</h2>
                </div>
            </div>

            <div className="row text-center mx-auto justify-content-center my-4">
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card">
                        <img src="/assets/images/pricing/steps/shoutout-signup.svg" alt="shoutout-signup" title="shoutout-signup" />
                        <h6 className="signup-data-titles font-weight-bold mt-2">1. Sign up and get your free account</h6>
                       
                        <p>
                            <Link to="/signup">Sign up</Link> with ShoutOUT and verify your mobile number. Upload your contacts as Excel or CSV files, and segment them into groups if needed.
                        </p>

                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card">
                        <img className="" src="/assets/images/pricing/steps/shoutout-pricing.svg" alt="shoutout-pricing" title="shoutout-pricing" />
                        <h6 className="signup-data-titles font-weight-bold mt-2">2. Choose a plan and a mobile number</h6>
                        <p>
                            Select your preferred plan, and request the alphanumeric sender ID or mobile phone number to send campaigns, from the dashboard itself.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card">
                        <img className="" src="/assets/images/pricing/steps/affordable-sms-marketing.svg" alt="affordable-sms-marketing" title="affordable-sms-marketing" />
                        <h6 className="signup-data-titles font-weight-bold mt-2">3. Add SMS credits and send campaigns</h6>
                     
                        <p>
                            Top up the required amount of SMS credits to your account. One credit equals one SMS. You’re all set to send your first SMS campaign.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SignUpData;