import React from 'react';
import PlaceholderImage from './../../images/placeholder.png';
import { LazyImage } from "react-lazy-images";
import "./CustomersSlidingBar.css";
const CustomersSlidingBar = ({ companies=[] }) => {
    return (
        <div className="slider">
            <div className="slide-track" style={{width:154*companies.length}}>
                {companies.map(({name,image}, index) => {
                    return (
                        <div
                            key={index}
                            className={`slide card rounded-0 border-0 slider-img-${index}`}
                        >
                            <LazyImage
                                src={image}
                                alt={`ShoutOUT Customer ${name}`}
                                placeholder={({ imageProps, ref }) => (
                                    <img ref={ref} src={PlaceholderImage} alt={imageProps.alt} />
                                )}
                                actual={({ imageProps }) => <img className="no-color opacity-p8 py-4" {...imageProps} />}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}
export default CustomersSlidingBar;